import React from 'react';

import UserSkeleton from '~/boilerplates/User';
import Feed from '~/containers/User/CSAT/Feed';
import { FeedContextProvider } from '~/contexts/CSAT/FeedContext';

export default function FeedPage() {
  return (
    <UserSkeleton>
      <FeedContextProvider>
        <Feed />
      </FeedContextProvider>
    </UserSkeleton>
  );
}
