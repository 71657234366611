import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FiEdit3, FiUsers } from 'react-icons/fi';
import { IoIosArrowDown, IoIosArrowUp, IoIosTrash } from 'react-icons/io';

import Tooltip from '~/components/User/Tooltip';

import { LoaderTable } from '../Loader';
import {
  Wrapper,
  SortButton,
  Actions,
  ButtonAction,
  Name,
  AdminDescription,
} from './styles';

export default function TablePrivileges({
  columns,
  rows,
  isLoadingData,
  setFilter,
  filterFields,
  handleRemovePermission,
  setEditData,
  setSearchData,
}) {
  const filterKeys = filterFields && Object.keys(filterFields);

  const handleEdit = useCallback(
    (row) => {
      setSearchData();
      setEditData(row);
    },
    [setEditData, setSearchData]
  );

  const { t } = useTranslation();
  return (
    <Wrapper cellSpacing="0">
      <thead>
        <tr>
          <th style={{ width: '30%' }}>
            {t('tableColumns.name')}
            <SortButton onClick={() => setFilter(0)} id="sort-btn-1">
              {filterFields[filterKeys[0]] ? (
                <IoIosArrowUp />
              ) : (
                <IoIosArrowDown />
              )}
            </SortButton>
          </th>
          <th style={{ width: '40%' }}> </th>
          <th style={{ width: '20%' }}> </th>
        </tr>
      </thead>
      <tbody>
        {isLoadingData ? (
          <tr>
            {columns.map(() => (
              <td key={Math.random()}>
                <LoaderTable />
              </td>
            ))}
          </tr>
        ) : (
          <>
            {rows &&
              rows.map((row) => (
                <tr key={Math.random()}>
                  <Name isAdmin={row.is_owner}>{row.name}</Name>
                  {row.is_owner ? (
                    <AdminDescription>
                      <span>
                        <FiUsers />
                      </span>
                      Dono da Campanha
                    </AdminDescription>
                  ) : (
                    <td aria-label="td" />
                  )}
                  <Actions>
                    <ButtonAction onClick={() => handleEdit(row)} id="edit-btn">
                      <Tooltip text="Editar" />
                      <FiEdit3 />
                    </ButtonAction>
                    <ButtonAction
                      onClick={() =>
                        handleRemovePermission(row.access_id, row.key)}
                      id="remove-btn"
                    >
                      <Tooltip text="Remover" />
                      <IoIosTrash />
                    </ButtonAction>
                  </Actions>
                </tr>
              ))}
          </>
        )}
      </tbody>
    </Wrapper>
  );
}
