import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsCardChecklist } from 'react-icons/bs';
import { RiMailSendLine } from 'react-icons/ri';

import Tooltip from '~/components/User/Tooltip';

import { LoaderTable } from '../Loader';
import {
  HeaderWrapper,
  BodyWrapper,
  Actions,
  TdName,
  CheckBox,
  Table,
  SelectAllButton,
} from './styles';

export default function TableResendEmail({
  columns,
  rows,
  isLoadingData,
  setIsDisabled,
  campaignType,
  setIsCheck,
  isCheck,
}) {
  const [isCheckAll, setIsCheckAll] = useState(false);
  const { t } = useTranslation();

  const handleChange = (e) => {
    const { id, checked } = e.target;
    setIsCheck((prev) => [...prev, Number(id)]);
    if (!checked) {
      setIsCheck((prev) => prev.filter((item) => item !== Number(id)));
    }
    setIsDisabled(false);
    if (isCheck.length === 0) {
      setIsDisabled(true);
    }
  };

  const handleSelectAll = () => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(
      rows
        .filter((row) => row.answer_data === 'Não Respondido')
        .map((i) => i.id)
    );
    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  return (
    <>
      <HeaderWrapper>
        <Table cellSpacing="0">
          <thead>
            <tr>
              {columns?.map((column, index) =>
                columns.length === index + 1 ? (
                  <th
                    key={Math.random()}
                    onClick={handleSelectAll}
                    name="selectAll"
                    id="selectAll"
                  >
                    {column}
                    <SelectAllButton>
                      <Tooltip text={t('tooltips.selectAll')} />
                      <BsCardChecklist />
                    </SelectAllButton>
                  </th>
                ) : (
                  <th key={Math.random()}>{column}</th>
                )
              )}
            </tr>
          </thead>
        </Table>
      </HeaderWrapper>
      <BodyWrapper>
        <Table cellSpacing="0">
          <tbody>
            {isLoadingData ? (
              <tr>
                {columns.map(() => (
                  <td key={Math.random()}>
                    <LoaderTable />
                  </td>
                ))}
              </tr>
            ) : (
              <>
                {rows &&
                  rows.map((row) => (
                    <tr key={Math.random()}>
                      <TdName>{row.user_name}</TdName>
                      <TdName>{row.email}</TdName>
                      {campaignType === 'csat' && (
                        <TdName>{row.interaction}</TdName>
                      )}
                      <TdName>{row.answer_data}</TdName>
                      <Actions>
                        {row.answer_data === 'Não Respondido' ? (
                          <CheckBox
                            id={row.id}
                            name={row.user_name}
                            key={row.id}
                            checked={isCheck.includes(row.id)}
                            onChange={handleChange}
                          />
                        ) : (
                          <RiMailSendLine />
                        )}
                      </Actions>
                    </tr>
                  ))}
              </>
            )}
          </tbody>
        </Table>
      </BodyWrapper>
    </>
  );
}
