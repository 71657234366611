import styled from 'styled-components';

import device from '~/assets/styles/settings/Devices';

export const Wrapper = styled.div`
  gap: 1rem;
  width: 100%;
  max-width: 1300px;

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  margin-top: var(--metrics-m);
  margin-bottom: var(--metrics-xl);
`;

export const WrapperCards = styled.div`
  display: flex;
  justify-content: center;

  gap: 1rem;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-self: flex-end;
  justify-content: center;

  gap: 1rem;

  @media ${device.laptop} {
    align-self: center;
  }
`;
