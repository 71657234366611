import React, {
  useEffect, useRef
} from 'react';

import { useField } from '@unform/core';

import { useEventListener } from '~/hooks/useEventListener';

import { Field } from './styles';

export default function InputTextChat({
  name, placeholder
}) {
  const inputRef = useRef(null);
  const {
    fieldName, registerField, defaultValue
  } = useField(name);

  useEventListener('keydown', (event) => {
    if (event.which === 13) {
      event.preventDefault();

      const submitChat = document.getElementById('form-chat');

      submitChat.click();
    }
  });

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <Field
      ref={inputRef}
      defaultValue={defaultValue}
      placeholder={placeholder}
    />
  );
}
