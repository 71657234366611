import { createStore, combineReducers } from 'redux';

import BoilerplateReducer from '../reducers/Boilerplate';
import LoginReducer from '../reducers/Login';

const rootReducer = combineReducers(
  {
    boilerplate: BoilerplateReducer,
    login: LoginReducer,
  }
);

const store = createStore(rootReducer);
const { dispatch } = store;

export { store, dispatch };
