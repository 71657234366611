import React from 'react';

import UserSkeleton from '~/boilerplates/User';
import Teams from '~/containers/User/CSAT/Teams';
import { TeamsContextProvider } from '~/contexts/CSAT/TeamsContext';

export default function TeamsPage() {
  return (
    <UserSkeleton>
      <TeamsContextProvider>
        <Teams />
      </TeamsContextProvider>
    </UserSkeleton>
  );
}
