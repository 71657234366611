function hasDuplicateValues(lines) {
  let seen = new Set();
  let hasDuplicates = lines.some((line) => seen.size === seen.add(line.email).size);

  return hasDuplicates;
}

function distinctValues(lines) {
  const map = new Map();
  const results = [];

  lines.forEach((line) => {
    if (!map.has(line.email)) {
      map.set(line.email, true);
      results.push(line);
    }
  });

  return results;
}

function csvJSON(csv) {
  const lines = csv.split('\n');
  const headers = lines[0].split(';');
  const result = [];

  for (let i = 1; i < lines.length; i += 1) {
    if (lines[i]) {
      const obj = {};
      const currentLine = lines[i].split(';');

      headers.forEach((header, index) => {
        header = header.trim();

        obj[header] = currentLine[index].trim();
      });

      result.push(obj);
    }
  }

  return result;
}

function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return re.test(String(email).toLowerCase());
}

export {
  distinctValues, hasDuplicateValues, csvJSON, validateEmail
};
