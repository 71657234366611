import React, { useCallback, useState } from 'react';

import { CES, CSAT, NPS } from 'components/User/Metric';

import { ErrorMessage } from './styles';

export default function Metric({
  title, option, error, setOptionValue, language
}) {
  const [activeButton, setActiveButton] = useState();

  const handlerButton = useCallback(
    (value) => {
      setActiveButton(value);
      setOptionValue(value);
    },
    [setOptionValue]
  );

  const renderMetric = () => {
    const [quantity, type] = option ? option.split('-') : [0, ''];

    switch (title.toUpperCase()) {
      case 'NPS':
        return (
          <NPS
            activeButton={activeButton}
            handlerButton={handlerButton}
            quantity={quantity}
          />
        );
      case 'CSAT':
        return (
          <CSAT
            activeButton={activeButton}
            handlerButton={handlerButton}
            quantity={parseInt(quantity, [])}
            type={type}
            language={language}
          />
        );
      case 'CES':
        return (
          <CES activeButton={activeButton} handlerButton={handlerButton} language={language} />
        );
      default:
    }
  };

  return (
    <>
      {renderMetric()}
      {error && <ErrorMessage> {error} </ErrorMessage>}
    </>
  );
}
