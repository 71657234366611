import styled from 'styled-components';

import completedTasks from '~/assets/images/generic/completed-tasks.svg';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  height: 600px;

  p,
  strong {
    font-size: var(--font-size-xl);
    font-weight: var(--font-weight-xb);
  }

  p {
    color: var(--blue-60);
  }

  strong {
    color: var(--blue-marin);
    text-transform: uppercase;
  }
`;

export const DashboardPaper = styled.img.attrs(() => ({
  src: completedTasks,
}))`
  width: 60%;
  height: 60%;

  margin-bottom: var(--metrics-l);
`;

export const APIkey = styled.p`
  color: var(--blue-90);
  font-size: 16px
`;
