import React from 'react';
import ReactSpeedometer from 'react-d3-speedometer';
import { useTranslation } from 'react-i18next';

import BarChart from '~/components/User/BarChart';
import Breadcrumb from '~/components/User/Breadcrumb';
import LineChart from '~/components/User/LineChart';
import { LoaderChart, LoaderCircleTwo } from '~/components/User/Loader';
import Filters from '~/containers/Generic/Filters';
import { useDashboard } from '~/contexts/NPS/DashboardContext';
import {
  generateLineChart,
  generateBarChart,
  generateBarChartNPS,
  generateBarChart5Pill,
} from '~/mocks/Charts';

import {
  NavWrapper,
  GeneralInfo,
  GeneralData,
  ChartsWrapper,
  Chart,
  Group,
  Detractor,
  GroupVertical,
  Passive,
  Promoter,
  SubtitleGroup,
  ValueGroup,
} from './styles';

export default function DashboardNPS() {
  const { isLoadingData, dataDashboard, surveyOptions, setFilter } =
    useDashboard();
  const { t } = useTranslation();
  return (
    <>
      <NavWrapper>
        <Breadcrumb trails={['NPS']} currentPage={t('titles.overview')} />
        <Filters
          surveyOptions={surveyOptions}
          attendantDisabled
          setFilter={setFilter}
        />
      </NavWrapper>
      <GeneralInfo>
        {isLoadingData ? (
          <LoaderCircleTwo />
        ) : (
          <>
            <ReactSpeedometer
              segments={10}
              value={dataDashboard?.average_score}
              minValue={-100}
              maxValue={100}
              width={250}
              height={150}
              textColor="var(--gray-60)"
              labelFontSize="11px"
              valueTextFontSize="16px"
            />
            <GeneralData>
              <span>
                {dataDashboard?.quantity <= 1
                  ? t('titles.evaluation')
                  : t('titles.evaluations')}
              </span>
              <strong className="red">
                {dataDashboard?.quantity === 0 ? '-' : dataDashboard?.quantity}
              </strong>
            </GeneralData>
            <Group>
              <GroupVertical isRed>
                <Detractor />
                <SubtitleGroup>{t('titles.detractors')}</SubtitleGroup>
                <ValueGroup>
                  {dataDashboard?.detractors ? dataDashboard?.detractors : '-'}
                </ValueGroup>
              </GroupVertical>
              <GroupVertical>
                <Passive />
                <SubtitleGroup>{t('titles.neuters')}</SubtitleGroup>
                <ValueGroup>
                  {dataDashboard?.neuters ? dataDashboard?.neuters : '-'}
                </ValueGroup>
              </GroupVertical>
              <GroupVertical isGreen>
                <Promoter />
                <SubtitleGroup>{t('titles.promoters')}</SubtitleGroup>
                <ValueGroup>
                  {dataDashboard?.promoters ? dataDashboard?.promoters : '-'}
                </ValueGroup>
              </GroupVertical>
            </Group>
          </>
        )}
      </GeneralInfo>
      <ChartsWrapper>
        {isLoadingData ? (
          <LoaderChart />
        ) : (
          <Chart>
            <strong>NPS (Net Promoter Score)</strong>
            <LineChart
              data={generateLineChart(dataDashboard?.scores)}
              type="nps"
            />
          </Chart>
        )}
        {isLoadingData ? (
          <LoaderChart />
        ) : (
          <Chart>
            <strong>{t('titles.histogram')}</strong>
            <BarChart
              data={generateBarChart(dataDashboard?.histogram)}
              type="nps"
            />
          </Chart>
        )}
        {isLoadingData ? (
          <LoaderChart />
        ) : (
          <Chart>
            <strong>{t('titles.votesWith10Grades')}</strong>
            <BarChart
              data={generateBarChartNPS(dataDashboard?.analytics?.scale_10)}
              type="nps"
            />
          </Chart>
        )}
        {isLoadingData ? (
          <LoaderChart />
        ) : (
          <Chart>
            <strong>{t('titles.votesWith5Grades')}</strong>
            <BarChart
              data={generateBarChart5Pill(dataDashboard?.analytics?.scale_5)}
              type="nps"
            />
          </Chart>
        )}
      </ChartsWrapper>
    </>
  );
}
