import React from 'react';
import ContentLoader from 'react-content-loader';

const LoaderCircleThree = () => (
  <ContentLoader
    uniqueKey="circle-three"
    speed={2}
    height={248}
    width={310}
  >
    <rect x="78" y="82" rx="0" ry="0" width="177" height="14" />
    <circle cx="166" cy="130" r="19" />
  </ContentLoader>
);

const LoaderCircleTwo = () => (
  <ContentLoader
    uniqueKey="circle-two"
    speed={2}
    height={306}
    width={477}
  >
    <circle cx="109" cy="102" r="95" />
    <rect x="275" y="83" rx="0" ry="0" width="92" height="13" />
    <circle cx="323" cy="127" r="16" />
  </ContentLoader>
);

const LoaderRankCard = () => (
  <ContentLoader
    speed={2}
    width={1055}
    height={341}
  >
    <rect x="64" y="30" rx="3" ry="3" width="74" height="5" />
    <circle cx="29" cy="46" r="25" />
    <rect x="67" y="391" rx="6" ry="6" width="176" height="21" />
    <rect x="66" y="44" rx="3" ry="3" width="65" height="22" />
    <rect x="149" y="56" rx="3" ry="3" width="828" height="4" />
    <rect x="9" y="98" rx="8" ry="8" width="253" height="207" />
  </ContentLoader>
);

const LoaderCircle = () => (
  <ContentLoader
    uniqueKey="circle"
    speed={2}
    height={277}
    width={170}
  >
    <circle cx="74" cy="105" r="73" />
    <rect x="42" y="5" rx="2" ry="2" width="59" height="15" />
    <rect x="42" y="192" rx="0" ry="0" width="65" height="7" />
    <circle cx="75" cy="222" r="14" />
  </ContentLoader>
);

const LoaderEditCampaign = () => (
  <ContentLoader
    speed={2}
    width={1055}
    height={103}
  >
    <rect x="2" y="13" rx="3" ry="3" width="262" height="13" />
    <rect x="32" y="55" rx="3" ry="3" width="90" height="6" />
    <rect x="1" y="87" rx="3" ry="3" width="1055" height="7" />
    <circle cx="13" cy="59" r="10" />
    <rect x="220" y="54" rx="3" ry="3" width="90" height="6" />
    <circle cx="200" cy="58" r="10" />
    <rect x="420" y="55" rx="3" ry="3" width="90" height="6" />
    <circle cx="400" cy="59" r="10" />
    <rect x="620" y="54" rx="3" ry="3" width="90" height="6" />
    <circle cx="600" cy="58" r="10" />
    <rect x="820" y="53" rx="3" ry="3" width="90" height="6" />
    <circle cx="800" cy="57" r="10" />
    <rect x="1020" y="53" rx="3" ry="3" width="90" height="6" />
    <circle cx="1000" cy="57" r="10" />
  </ContentLoader>
);

const LoaderChart = () => (
  <ContentLoader
    uniqueKey="chart"
    speed={2}
    height={268}
    width={745}
  >
    <rect x="8" y="9" rx="0" ry="0" width="6" height="285" />
    <rect x="45" y="10" rx="0" ry="0" width="1" height="285" />
    <rect x="84" y="10" rx="0" ry="0" width="1" height="285" />
    <rect x="125" y="8" rx="0" ry="0" width="1" height="285" />
    <rect x="164" y="11" rx="0" ry="0" width="1" height="285" />
    <rect x="205" y="10" rx="0" ry="0" width="1" height="285" />
    <rect x="244" y="10" rx="0" ry="0" width="1" height="285" />
    <rect x="285" y="10" rx="0" ry="0" width="1" height="285" />
    <rect x="325" y="8" rx="0" ry="0" width="1" height="285" />
    <rect x="367" y="9" rx="0" ry="0" width="1" height="285" />
    <rect x="405" y="10" rx="0" ry="0" width="1" height="285" />
    <rect x="446" y="10" rx="0" ry="0" width="1" height="285" />
    <rect x="485" y="9" rx="0" ry="0" width="1" height="285" />
    <rect x="526" y="9" rx="0" ry="0" width="1" height="285" />
    <rect x="565" y="9" rx="0" ry="0" width="1" height="285" />
    <rect x="8" y="260" rx="0" ry="0" width="600" height="82" />
    <rect x="12" y="235" rx="0" ry="0" width="595" height="1" />
    <rect x="13" y="208" rx="0" ry="0" width="595" height="1" />
    <rect x="13" y="185" rx="0" ry="0" width="595" height="1" />
    <rect x="14" y="158" rx="0" ry="0" width="595" height="1" />
    <rect x="11" y="130" rx="0" ry="0" width="595" height="1" />
    <rect x="12" y="103" rx="0" ry="0" width="595" height="1" />
    <rect x="12" y="80" rx="0" ry="0" width="595" height="1" />
    <rect x="13" y="53" rx="0" ry="0" width="595" height="1" />
    <rect x="13" y="29" rx="0" ry="0" width="595" height="1" />
    <rect x="12" y="9" rx="0" ry="0" width="595" height="1" />
  </ContentLoader>
);

const LoaderTable = () => (
  <ContentLoader
    speed={2}
    height="10"
    width="100%"
  >
    <rect x="0" y="0" rx="2" ry="2" width="100%" height="10" />
  </ContentLoader>
);

const LoaderUserPrivileges = () => (
  <ContentLoader
    speed={2}
    width={450}
    height={120}
  >
    <rect x="3" y="4" rx="8" ry="8" width="450" height="100" />
  </ContentLoader>
);

const LoaderPagination = () => (
  <ContentLoader
    speed={2}
    height={32}
    width={280}
  >
    <rect x="0" y="5" rx="4" ry="4" width="80" height="20" />
    <rect x="90" y="0" rx="4" ry="4" width="32" height="32" />
    <rect x="130" y="0" rx="4" ry="4" width="32" height="32" />
    <rect x="170" y="0" rx="4" ry="4" width="32" height="32" />
    <rect x="210" y="5" rx="4" ry="4" width="80" height="20" />
  </ContentLoader>
);

const LoaderProfile = () => (
  <ContentLoader uniqueKey="profile" speed={2} height={60} width={214}>
    <circle cx="40" cy="30" r="20" />
    <rect x="70" y="25" rx="2" ry="2" width="130" height="10" />
  </ContentLoader>
);

export {
  LoaderCircle, LoaderCircleTwo, LoaderChart, LoaderTable, LoaderEditCampaign,
  LoaderUserPrivileges, LoaderCircleThree, LoaderRankCard, LoaderPagination,
  LoaderProfile
};
