import styled from 'styled-components';

import device from '~/assets/styles/settings/Devices';

export const NavWrapper = styled.nav`
  width: 100%;
  display: flex;

  justify-content: space-between;
  align-items: center;
`;

export const CircleWrapper = styled.div`
  position: relative;

  height: 200px;
  width: 200px;
`;

export const CircleData = styled.div`
  height: 100%;
  width: 100%;

  top: 0;
  transform: translateY(60px);
  position: absolute;
  line-height: 2rem;

  text-align: center;

  span {
    color: var(--gray-80);
  }

  strong {
    display: block;

    color: var(--gray-90);
    font-size: calc(var(--font-size-xl) * 1.4);
  }
`;

export const GeneralInfo = styled.section`
  display: flex;

  align-items: center;
  padding: 5rem 0;
  gap: 3rem;

  border-bottom: 1px solid var(--gray-10);
`;

export const GeneralData = styled.div`
  text-align: center;
  margin-left: var(--metrics-xl);
  line-height: 2rem;

  span {
    color: var(--gray-80);
  }

  strong {
    display: block;

    font-size: var(--font-size-xl);
  }

  strong.red {
    color: var(--red);
  }

  strong.green {
    color: var(--green);
  }
`;

export const ChartsWrapper = styled.section`
  display: flex;
  flex-direction: column;
  gap: 2rem;

  padding: 3rem 0;
`;

export const Chart = styled.div`
  width: 100%;
  max-width: 900px;

  color: var(--blue-60);

  strong {
    display: block;
    font-size: var(--font-size-m);
  }

  @media ${device.tablet} {
    max-width: 550px;
  }
`;
