import * as Yup from 'yup';

import i18n from '~/translate/i18n';

async function validateAddEmailNPS(payload, formRef) {
  const schema = Yup.object().shape({
    evaluator_name: Yup.string()
      .required(i18n.t('schemas.evaluator_name.required'))
      .strict(true)
      .trim(i18n.t('schemas.trim')),
    email: Yup.string()
      .required(i18n.t('schemas.email.required'))
      .email(i18n.t('schemas.email.email'))
      .strict(true)
      .trim(i18n.t('schemas.trim')),
  });

  await schema.validate(payload, {
    abortEarly: false
  });

  formRef.current.setErrors({});
}

async function validateSearchUser(payload, formRef) {
  const schema = Yup.object().shape({
    key: Yup.string()
      .required(i18n.t('schemas.key.required'))
      .strict(true)
      .trim(i18n.t('schemas.trim'))
      .min(8, i18n.t('schemas.key.min', { min: '8' }))
      .max(8, i18n.t('schemas.key.max', { max: '8' }))
      .matches(/^[a|f|c|z|t][0-9]+/gi, i18n.t('schemas.key.matches'))
    ,
  });

  await schema.validate(payload, {
    abortEarly: false
  });

  formRef.current.setErrors({});
}

export { validateAddEmailNPS, validateSearchUser };
