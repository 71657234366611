import styled, { css } from 'styled-components';

export const Wrapper = styled.table`
  width: 100%;

  tr {

    &:not(:last-child) {
      td {
        border-bottom: 2px solid var(--gray-10);
      }
    }
  }

  th {
    &:first-child {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    &:last-child {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }

  th, td {
    padding: .95rem 1rem;
  }

  th {
    color: var(--blue-90);
    background-color: var(--blue-10);

    font: 500 .825rem Lexend, sans-serif;
    text-align: left;
  }

  td {
    color: var(--gray-60);

    font-size: .75rem;
  }
`;

export const ButtonAction = styled.button.attrs(() => ({
  type: 'button'
}))`
  position: relative;
  width: 24px;
  height: 24px;
  margin-right: 1rem;

  > svg {
    transition: fill .3s;

    width: 18px;
    height: 18px;
  }

  &:hover {
    svg {
      fill: var(--red);
    }

    span {
      visibility: visible;
    }
  }
`;

export const Actions = styled.td`
  text-align: center;
`;

export const SortButton = styled.button.attrs(() => ({
  type: 'button'
}))`
  position: absolute;

  height: .9rem;
  width: .9rem;

  transform: translateX(12px);

  svg {
    fill: var(--gray-40);

    height: .9rem;
    width: .9rem;
  }
`;

export const Name = styled.td`
  ${(p) => p.isAdmin && css`
    color: var(--blue-60) !important;
    font-weight: bolder;
  `}
`;

export const AdminDescription = styled.td`
  color: var(--blue-60) !important;
  font-weight: bolder;

  span {
    vertical-align: middle;
    margin-right: 7px;

    svg {
      width: 16px;
      height: 16px;
    }
  }
`;
