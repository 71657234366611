import { notify } from '~/helpers/Toast';
import api from '~/services';
import i18n from '~/translate/i18n';

const languages = {
  'pt-BR': 'message',
  'en-US': 'message_en',
};

function getFilters(filters) {
  let params = {
    campaign_type: 'csat',
    campaign: filters.campaign,
    attendant: filters.attendant,
    start_date: filters.dates.start,
    end_date: filters.dates.end,
  };

  Object.keys(params).forEach((obj) => {
    if (!params[obj]) {
      delete params[obj];
    }
  });

  if (params.attendant) {
    params = {
      ...params,
      attendant_key: filters.attendant_key,
    };
  }

  return params;
}

const getDashboard = async (filters) =>
  api
    .get('/overview', {
      params: getFilters(filters),
    })
    .then((res) => res.data)
    .catch((err) => {
      notify('error', err.response.data.errors[0][languages[i18n.language]]);
    });

export { getDashboard };
