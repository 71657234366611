import React, { useEffect } from 'react';

import { useMatomo } from '@datapunt/matomo-tracker-react';

export default function PageTracker({ children }) {
  const { trackPageView } = useMatomo();

  useEffect(() => {
    trackPageView();
  });

  return <>{children}</>;
}
