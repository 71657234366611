import React, { useRef, useState, useEffect } from 'react';
import { AiFillInfoCircle } from 'react-icons/ai';

import { useField } from '@unform/core';
import { addDays } from 'date-fns';

import CheckBox from '~/components/User/Checkbox';

import { CustomDatePicker } from '../CustomDatePicker';
import { Wrapper, Label, ErrorMessage, Tooltip } from './styles';

import 'react-datepicker/dist/react-datepicker.css';

export default function CustomDateInput({
  name,
  title,
  initialStart,
  initialEnd,
}) {
  const datepickerRef = useRef(null);
  const { fieldName, registerField, error } = useField(name);
  const [startDate, setStartDate] = useState(initialStart ?? new Date());
  const [endDate, setEndDate] = useState(initialEnd ?? null);
  const [endDateVisibile, setEndDateVisibile] = useState(!!initialEnd);

  const onChangeStart = (value) => {
    setStartDate(value);
  };
  const onChangeEnd = (value) => {
    setEndDate(value);
  };

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: datepickerRef.current,
      path: 'props.selected',
      clearValue: (ref) => {
        ref.clear();
      },
      setValue: (_, value) => {
        setStartDate(value.startDate);
      },
      getValue: () => ({
        startDate,
        endDate: !endDateVisibile ? new Date('01/01/2099') : endDate,
      }),
    });
  }, [fieldName, registerField, endDate, startDate, endDateVisibile]);

  return (
    <>
      <Wrapper>
        <Label>{title}</Label>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div>
            <Label
              style={{
                marginBottom: '5px',
                marginTop: '15px',
                display: 'block',
              }}
            >
              Data Inicial
            </Label>
            <CustomDatePicker selected={startDate} onChange={onChangeStart} />
          </div>

          {endDateVisibile && (
            <div style={{ marginLeft: '15px' }}>
              <Label
                style={{
                  marginBottom: '5px',
                  marginTop: '15px',
                  display: 'block',
                }}
              >
                Data Final
              </Label>

              <CustomDatePicker
                selected={endDate}
                onChange={onChangeEnd}
                minDate={addDays(startDate, 1)}
              />
            </div>
          )}

          <div
            style={{ marginTop: '45px', display: 'flex', alignItems: 'center' }}
          >
            <CheckBox
              name="check"
              checked={endDateVisibile}
              onChange={() => setEndDateVisibile((p) => !p)}
              style={{
                marginLeft: '10px',
                width: '20px',
                height: '20px',
                borderRadius: '8px',
                bottom: '0',
              }}
              title="Definir data para finalizar a campanha"
            />

            <Tooltip>
              <AiFillInfoCircle />
              <span>
                Ao definir uma data final da pesquisa os usuários não
                conseguirão responder as perguntas após a data informada
              </span>
            </Tooltip>
          </div>
        </div>
      </Wrapper>
      {error && <ErrorMessage> {error} </ErrorMessage>}
    </>
  );
}
