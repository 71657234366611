import React from 'react';
import { useTranslation } from 'react-i18next';

import { useCampaign } from '~/contexts/Generic/SurveyContext';

import { NPSFinish } from './NPSFinish';
import { Wrapper, DashboardPaper, APIkey } from './styles';

export default function Finish() {
  const { isEditting, isCopying, currentMetric, dataWizard } = useCampaign();

  const { t } = useTranslation();
  return (
    <>
      {dataWizard.survey.apiKey && currentMetric === 'NPS' ? (
        <NPSFinish apiKey={dataWizard.survey.apiKey} />
      ) : (
        <>
          {dataWizard.survey.apiKey && (
            <APIkey>
              {' '}
              <strong>{t('messages.yourApikey')}</strong>{' '}
              {dataWizard.survey.apiKey}
            </APIkey>
          )}
          <Wrapper>
            <DashboardPaper />
            <p>
              {t('messages.yourCampaign')} <strong>{currentMetric} </strong>
              {t('messages.was')}{' '}
              {isEditting && !isCopying
                ? t('messages.updated')
                : t('messages.created')}{' '}
              {t('messages.withSuccess')}
            </p>
          </Wrapper>
        </>
      )}
    </>
  );
}
