import React from 'react';

import { LoaderTable } from '../Loader';
import {
 HeaderWrapper, BodyWrapper, TdName, Table, TrName
} from './styles';

export default function TableQuestionDetail({
 columns, rows, isLoadingData
}) {
  return (
    <>
      <HeaderWrapper>
        <Table cellSpacing="0">
          <thead>
            <tr>
              {columns?.map((column) => (
                <th key={Math.random()}>{column}</th>
              ))}
            </tr>
          </thead>
        </Table>
      </HeaderWrapper>
      <BodyWrapper>
        <Table cellSpacing="0">
          <tbody>
            {isLoadingData ? (
              <tr>
                {columns.map(() => (
                  <td key={Math.random()}>
                    <LoaderTable />
                  </td>
                ))}
              </tr>
            ) : (
              <>
                {rows.length > 0 ? (
                  rows.map((row, index) => (
                    <>
                      <TrName data-test={`question-${row.type}-${row.id}`} notMean={row.mean === null} key={Math.random()}>
                        {row.mean === null
                        ? (
                          <>
                            <th>{index + 1}. {row.question}</th>
                            <th>Contador</th>
                          </>
                        ) : <TdName>{index + 1}. {row.question}</TdName>}
                        {row.mean === null ? row.option.map(
                          (item, subQuestionIndex) => (
                            <>

                              <TrName data-test={`question-${row.type}-${row.id}`} subQuestion>
                                <TdName>{item.choice}</TdName>
                                <TdName data-test={`answer-${row.type}-${subQuestionIndex + 1}`}>{item.count}</TdName>
                              </TrName>

                            </>
                        )
                        ) : <TdName data-test={`answer-${row.type}-${row.id}`}>{row.mean}</TdName>}

                      </TrName>
                    </>
                  ))
                ) : (
                  <TdName>Pesquisa sem respostas.</TdName>
                )}
              </>
            )}
          </tbody>
        </Table>
      </BodyWrapper>
    </>
  );
}
