import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import Button from '~/components/User/Button';
import Spinner from '~/components/User/Spinner';
import { useCampaign } from '~/contexts/Generic/SurveyContext';
import { formatDateEN } from '~/helpers/Dates';
import { createCampaign, editCampaign } from '~/services/Generic/Survey';

import { ApiPreview } from './ApiPreview';
import { ButtonWrapperEmail } from './ButtonWrapper';
import { Wrapper, ButtonsWrapper } from './styles';

export default function Preview() {
  const {
    dataWizard,
    setDataWizard,
    backButtonOptions,
    nextButtonOptions,
    isEditting,
    isCopying,
  } = useCampaign();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const handleFinish = useCallback(async () => {
    try {
      const payload = {
        distribution: {
          emails:
            isEditting && !isCopying
              ? dataWizard.distribution.emails.filter(
                  (o) => !dataWizard.distribution.old_emails.includes(o.email)
                )
              : dataWizard.distribution.emails,
          name: dataWizard.distribution.name,
        },
        survey: {
          ...dataWizard.survey,
          metric: dataWizard.survey.metric,
          date: {
            startDate: formatDateEN(dataWizard.survey.date.startDate),
            endDate: formatDateEN(dataWizard.survey.date.endDate),
          },
          custom_questions: dataWizard.survey.custom_questions?.map(
            (question) =>
              question.appearance.includes('-')
                ? {
                    ...question,
                    question: question.question,
                    appearance: question.appearance.split('-')[1],
                    scale: parseInt(question.appearance.split('-')[0], 10),
                  }
                : {
                    ...question,
                    question: question.question,
                    appearance: question.appearance,
                  }
          ),
        },
        template_email: { ...dataWizard.template_email },
      };

      if (dataWizard.survey.metric.toUpperCase() !== 'CUSTOM') {
        const [scale, appearance] = dataWizard.survey.option.split('-');
        payload.survey = {
          ...payload.survey,
          scale: parseInt(scale, []),
          appearance,
        };
      }

      delete payload.survey.option;
      delete payload.distribution.old_emails;

      setIsLoading(true);

      if (isEditting && !isCopying) {
        await editCampaign(payload, id)
          .then((data) => {
            if (!data.code) {
              setDataWizard((prev) => ({ ...prev, step: 6 }));
            }
          })
          .finally(() => {
            setIsLoading(false);
          });
      } else {
        await createCampaign(payload)
          .then((data) => {
            if (!data.code) {
              setDataWizard((prev) => ({
                ...prev,
                step: 6,
                survey: { ...prev.survey, apiKey: data.api_key },
              }));
            }
          })

          .finally(() => {
            setIsLoading(false);
          });
      }
    } catch (err) {
      return err;
    }
  }, [dataWizard, setDataWizard, isEditting, isCopying, id]);

  return (
    <Wrapper>
      <h2>Preview</h2>

      {dataWizard.distribution.name === 'api' &&
      dataWizard.survey.metric === 'NPS' ? (
        <ApiPreview />
      ) : (
        <ButtonWrapperEmail />
      )}

      <ButtonsWrapper>
        <Button
          data-test="Voltar"
          title={t('buttons.back')}
          options={backButtonOptions}
          onClick={() => setDataWizard((prev) => ({ ...prev, step: 4 }))}
        />
        {isLoading ? (
          <Button isRequesting options={nextButtonOptions}>
            <Spinner options={{ width: '50px', height: '50px' }} />
          </Button>
        ) : (
          <Button
            id="btn-next"
            title={
              isEditting
                ? isCopying
                  ? t('buttons.copyCampaign')
                  : t('buttons.updateCampaign')
                : t('buttons.createCampaign')
            }
            options={nextButtonOptions}
            onClick={handleFinish}
          />
        )}
      </ButtonsWrapper>
    </Wrapper>
  );
}
