import styled from 'styled-components';

import { fadeIn } from '~/assets/styles/settings/Animations';
import devices from '~/assets/styles/settings/Devices';

export const WrapperNavLink = styled.li`
  width: 100%;
  margin-left: auto;

  transition: background-color .3s;

  &:hover {
    background-color: var(--blue-50);
  }
`;

export const LinkButton = styled.button.attrs(() => ({
  type: 'button'
}))`
  display: flex;
  align-items: center;
  box-sizing: border-box;

  width: 100%;
  height: 60px;
  padding: 0 var(--metrics-m);
  border-radius: 5px;

  color: var(--gray-10);
  font-size:  var(--font-size-sm);

  outline: none;
  transition: background-color 0.5s;
`;

export const LinkIcon = styled.span`
  margin-right: var(--metrics-sm);

  svg {
    color: var(--gray-10);
    height: 20px;
    width: 20px;
  }
`;

export const LinkName = styled.p`
  font-size: var(--font-size-m);
  text-align: left;

  @media ${devices.tablet} {
    display: none;
  }
`;

export const Childs = styled.div`
  display: flex;
  flex-direction: column;

  padding: var(--metrics-sm) var(--metrics-l);

  gap: 1.3rem;
  width: 90%;

  font-size: var(--font-size-sm);
  color: var(--white);

  /* animation: ${fadeIn} .8s cubic-bezier(0.390, 0.575, 0.565, 1.000) both; */
`;

export const ArrowWrapper = styled.div`
  margin-left: auto;
`;
