import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 4px;

  background-color: var(--gray-10);

  &:after {
    content: '';
    display: block;
    width: ${(props) => props.progress}%;
    height: 4px;
    background-color: var(--blue-90);

    transition: width 1s;
  }
`;
