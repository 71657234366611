import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  gap: 1rem;

  margin: var(--metrics-m) 0;

  color: var(--blue-60);
`;

export const Calendar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  min-width: 54px;
  height: 54px;
  border-radius: 50%;

  background: #FFB3001A;

  svg {
    fill: var(--orange);

    width: 20px;
    height: 20px;
  }
`;

export const DateWrapper = styled.div`
  flex-basis: 70px;

  display: flex;
  gap: .5rem;

  flex-direction: column;
  align-items: flex-start;
`;

export const WeekDay = styled.p`
  color: var(--gray-50);
  font-size: var(--font-size-smlr);

  text-transform: capitalize;
`;

export const Date = styled.p`
  color: var(--orange);
  font-size: var(--metrics-sm);

  font-weight: bold;
`;

export const Line = styled.hr`
  width: 100%;
  height: 4px;
  border-radius: 8px;

  background: linear-gradient(90deg, #FFE29D 50%, rgba(255, 179, 0, 0) 98.65%);

  margin-bottom: calc(var(--metrics-xs) * 3);
  align-self: flex-end;
`;

export const WrapperCards = styled.div`
  display: flex;
  gap: .85rem;
  flex-wrap: wrap;

  margin: var(--metrics-sm) 0;
`;
