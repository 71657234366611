import styled from 'styled-components';

export const Wrapper = styled.main`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  min-height: 100vh;

  strong, span {
    font-family: 'Arial', 'Helvetica', sans-serif;

    color: #cbd0d6;
    font-size: 15px;
  }

  strong {
    font-weight: 700;

    margin-top: 1rem;
    margin-bottom: 5px;
  }

  span {
    text-align: center;

    line-height: 10px;
    margin-top: .5rem;
  }
`;

export const CardMail = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  min-height: 458px;
  width: 720px;
  padding: 30px;

  background: rgb(244, 244, 244);

  h1, p, button {
    font-family: 'Arial', 'Helvetica', sans-serif;
  }

  h1 {
    color: #1fa2ea;
    font-size: 27px;
  }

  p {
    color: #363945;
    font-size: 16px;

    margin: 25px 0;
    line-height: 28px;

    white-space: pre-line;
  }

  button {
    height: 52px;
    width: 230px;
    border-radius: 7px;

    background-color: rgb(31, 162, 234);
    color: white;

    align-self: center;

    margin-top: 2rem;
    margin-bottom: 4rem;
  }
`;

export const Header = styled.img`
  height: 83px;
  width: 720px;
`;

export const Feedback = styled.p`
  position: absolute;
  bottom: 0;

  i {
    font-style: italic;
  }
`;
