import { Form } from '@unform/web';
import styled from 'styled-components';

export const FormWrapper = styled(Form)`
  display: flex;
  align-items: center;

  gap: 1rem;

  div, input {
    margin: 0;
  }

  button {
    margin-bottom: 5px;
  }
`;

export const SearchWrapper = styled(Form)`
  display: block;
  align-items: center;
  margin-top: 15px;

  gap: 1rem;

  div, input {
    margin: 0;
  }
`;

export const InputWrapper = styled.div`
  flex-basis: 80%;
  width: 50%;
`;
