import React, { useRef, useState, useEffect, useCallback } from 'react';
import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';

import { useField } from '@unform/core';
import enUS from 'date-fns/locale/en-US';
import ptBR from 'date-fns/locale/pt-BR';

import { formatDate, formatDateEN } from '~/helpers/Dates';
import { useEventListener } from '~/hooks/useEventListener';

import { WrapperFilter, WrapperDate, Popper, Calendar, Button } from './styles';

import 'react-datepicker/dist/react-datepicker.css';

const language = {
  'en-US': enUS,
  'pt-BR': ptBR,
};

export default function DateInput({
  name,
  setDates,
  isNotAbsolute,
  initialDates,
  ...rest
}) {
  const filterButtonRef = useRef(null);
  const datepickerWrapperRef = useRef(null);
  const datepickerRef = useRef(null);
  const { fieldName, registerField } = useField(name);
  const [endDate, setEndDate] = useState(
    initialDates && new Date(`${initialDates?.end} 00:00`)
  );
  const [startDate, setStartDate] = useState(
    initialDates && new Date(`${initialDates?.start} 00:00`)
  );
  const [inputVisible, setInputVisible] = useState(false);
  const buttonRef = useRef(null);
  const { t, i18n } = useTranslation();
  const handleChange = useCallback(
    (dates) => {
      const [start, end] = dates;

      setDates({
        start: start && formatDateEN(start),
        end: end && formatDateEN(end),
      });
      setStartDate(start);
      setEndDate(end);
    },
    [setDates]
  );

  const handleOpen = useCallback(() => {
    setInputVisible((prev) => !prev);
  }, []);

  useEffect(() => {
    if (inputVisible) {
      datepickerWrapperRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [inputVisible]);

  useEventListener('mousedown', (event) => {
    if (
      !datepickerWrapperRef?.current?.contains(event.target) &&
      !filterButtonRef?.current?.contains(event.target)
    ) {
      setInputVisible(false);
    }
  });

  const cleanup = () => {
    setDates({ start: null, end: null });
    setStartDate(null);
    setEndDate(null);
  };

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: datepickerRef.current,
      path: 'props.selected',
      clearValue: (ref) => {
        ref.clear();
      },
      setValue: (_, value) => {
        setEndDate(value.endDate);
        setStartDate(value.startDate);
      },
      getValue: () => ({ startDate, endDate }),
    });
  }, [fieldName, registerField, endDate, startDate]);

  return (
    <>
      <WrapperFilter
        ref={filterButtonRef}
        onClick={handleOpen}
        id="btn"
        data-test="filter-período"
      >
        {endDate ? (
          <>
            <span>{formatDate(startDate, i18n.language)}</span>
            <span>{endDate && ` a ${formatDate(endDate, i18n.language)}`}</span>
          </>
        ) : (
          t('titles.period')
        )}
        <span>{inputVisible ? <IoIosArrowUp /> : <IoIosArrowDown />}</span>
      </WrapperFilter>
      {inputVisible && (
        <WrapperDate ref={datepickerWrapperRef} isNotAbsolute={isNotAbsolute}>
          <DatePicker
            ref={datepickerRef}
            selected={startDate}
            onChange={handleChange}
            selectsRange
            startDate={startDate}
            endDate={endDate}
            dateFormat="dd/MM/yyyy"
            locale={language[i18n.language]}
            popperContainer={Popper}
            calendarContainer={Calendar}
            inline
            isClearable
            {...rest}
          />
          <Button onClick={cleanup} type="button" ref={buttonRef}>
            {t('titles.period')}
          </Button>
        </WrapperDate>
      )}
    </>
  );
}
