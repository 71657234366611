import React, { useEffect } from 'react';

import { MatomoProvider, useMatomo } from '@datapunt/matomo-tracker-react';

import instance from './Instance';

export default function Tracker({ children }) {
  const { trackPageView } = useMatomo();
  useEffect(() => {
    trackPageView();
  });
  return <MatomoProvider value={instance}>{children}</MatomoProvider>;
}
