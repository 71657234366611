export const SET_USER_SIGNED_IN = 'SET_LOGIN_DATA';
export const SET_LOADED_ALL_DATA = 'SET_LOADED_ALL_DATA';
export const SET_HAS_PERMISSIONS = 'SET_HAS_PERMISSIONS';

export const setUserSignedIn = (dispatch, value) => {
  dispatch({ type: SET_USER_SIGNED_IN, value });
};

export const setLoadedAllData = (dispatch, value) => {
  dispatch({ type: SET_LOADED_ALL_DATA, value });
};

export const setHasPermissions = (dispatch, value) => {
  dispatch({ type: SET_HAS_PERMISSIONS, value });
};
