import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 15px;

  margin: var(--metrics-m) 0;
`;

export const Steps = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StepName = styled.span`
  font-size: var(--font-size-sm);

  ${(p) => p.isActive && css`
    color: var(--blue-90);
    font-weight: var(--font-weight-xb);
  `}
`;
