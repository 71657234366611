import React, { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import FilterDate from '~/components/User/FilterDate';
import InputSelect from '~/components/User/InputSelect';

import { Wrapper, WrapperInput } from './styles';

export default function Filters({
  surveyOptions, agentOptions, setFilter, attendantDisabled, dateDisabled,
  setActivePage, filter
}) {
  const formRef = useRef(null);

  const handleSelectSurvey = useCallback((option) => {
    setFilter((p) => ({ ...p, campaign: option ? option.value : '' }));

    if (setActivePage) {
      setActivePage(0);
    }
  }, [setFilter, setActivePage]);

  const handleSelectAttendant = useCallback((option) => {
    const attendant = option?.value.split('-')[0] || '';
    const attendant_key = option?.value.split('-')[1] || '';

    setFilter((p) => (
      {
        ...p,
        attendant,
        attendant_key
      }));

    if (setActivePage) {
      setActivePage(0);
    }
  }, [setFilter, setActivePage]);

  const handleSelectDates = useCallback((dates) => {
    setFilter((p) => ({ ...p, dates }));
  }, [setFilter]);

  const { t } = useTranslation();
  
  return (
    <Wrapper ref={formRef}>
      <WrapperInput data-test="filter-campanha">
        <InputSelect
          name="survey"
          len={surveyOptions[0]?.label.length}
          placeholder={t('placeholders.campaign')}
          options={surveyOptions}
          noOptionsMessage={() => t('messages.noCampaigns')}
          onChange={handleSelectSurvey}
          id="survey"
          isClearable
        />
      </WrapperInput>
      { !attendantDisabled && (
        <WrapperInput data-test="filter-atendente">
          <InputSelect
            name="agent"
            placeholder={t('placeholders.attendant')}
            options={agentOptions}
            noOptionsMessage={() => t('messages.noAttendants')}
            onChange={handleSelectAttendant}
            isClearable
            isDisabled={attendantDisabled}
          />
        </WrapperInput>
      )}
      {!dateDisabled && (
      <FilterDate
        name="period"
        setDates={handleSelectDates}
        initialDates={filter?.dates}
      />
      )}
    </Wrapper>
  );
}
