import * as Yup from 'yup';

import i18n from '~/translate/i18n';

async function validateAddEmailCSAT(payload, formRef) {
  const schema = Yup.object().shape({
    attendant_key: Yup.string()
      .required(i18n.t('schemas.attendant_key.required'))
      .strict(true)
      .trim(i18n.t('schemas.trim')),
    evaluated_attendant: Yup.string()
      .required(i18n.t('schemas.evaluated_attendant.required'))
      .strict(true)
      .trim(i18n.t('schemas.trim')),
    evaluator_name: Yup.string()
      .required(i18n.t('schemas.evaluator_name.required'))
      .strict(true)
      .trim(i18n.t('schemas.trim')),
    email: Yup.string()
      .required(i18n.t('schemas.email.required'))
      .email(i18n.t('schemas.email.email'))
      .strict(true)
      .trim(i18n.t('schemas.trim')),
    ticket_number: Yup.string()
      .required(i18n.t('schemas.ticket_number.required'))
      .strict(true)
      .trim(i18n.t('schemas.trim')),
  });

  await schema.validate(payload, {
    abortEarly: false,
  });

  formRef.current.setErrors({});
}

async function validateSetSurveyCustom(payload, formRef) {


  const arrayOfValues = payload.custom_questions.map((item)=>{
    return item.is_mandatory
  })

  arrayOfValues.some((element)=> element === true)

  const schema = Yup.object().shape({
    campaignName: Yup.string()
      .required(i18n.t('schemas.campaignName.required'))
      .strict(true)
      .trim(i18n.t('schemas.trim')),
    custom_questions: Yup.array().of(Yup.object().shape({
      question: Yup.string()
        .required(
          i18n.t('schemas.question.required')
        )
        .strict(true)
        .trim(i18n.t('schemas.trim')),
      appearance: Yup.string()
        .required(
          i18n.t('schemas.appearance.required')
        )
        .strict(true),
      choices: Yup.array().of(Yup.string()
        .required(
          i18n.t('schemas.choices.required')
        )
        .strict(true)
        .trim(i18n.t('schemas.trim')),).notRequired(), 
    })).required(i18n.t('schemas.custom_questions.required')),
    date: Yup.object().shape({
      startDate: Yup.date()
        .required(i18n.t('schemas.startDate.required'))
        .nullable(),
      endDate: Yup.date()
        .required(i18n.t('schemas.endDate.required'))
        .nullable(),
    }),
  }).test({
    message:(i18n.t('messages.isMandatoryQuestion')),
    test: obj => obj.custom_questions.some((element)=> element.is_mandatory === true)
  });

  await schema.validate(payload, {
    abortEarly: false,
  });

  formRef.current.setErrors({});
}

async function validateSetTemplateEmail(payload, formRef) {
  const schema = Yup.object().shape({
    title: Yup.string()
      .required(i18n.t('schemas.title.required'))
      .strict(true)
      .trim(i18n.t('schema.trim')),
    body: Yup.string()
      .required(i18n.t('schemas.body.required'))
      .strict(true)
      .trim(i18n.t('schema.trim')),
    signature: Yup.string().required(i18n.t('schemas.signature.required'))
      .strict(true)
      .trim(i18n.t('schema.trim')),
  });

  await schema.validate(payload, {
    abortEarly: false
  });

  formRef.current.setErrors({});
}

async function validateSearchUser(payload, formRef) {
  const schema = Yup.object().shape({
    key: Yup.string()
      .required(i18n.t('schemas.key.required'))
      .strict(true)
      .trim(i18n.t('schema.trim'))
      .min(8, i18n.t('schemas.key.min', { min: '8' }))
      .max(8, i18n.t('schemas.key.min', { max: '8' }))
      .matches(
        /^[a|f|c|z|t][0-9]+/gi,
        i18n.t('schema.key.matches')
      ),
  });

  await schema.validate(payload, {
    abortEarly: false,
  });

  formRef.current.setErrors({});
}

export {
  validateAddEmailCSAT, validateSetSurveyCustom, validateSearchUser, validateSetTemplateEmail
};
