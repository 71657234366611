import React from 'react';

import UserSkeleton from '~/boilerplates/User';
import NewCampaign from '~/containers/User/Campaign/New';
import { CampaignContextProvider } from '~/contexts/Generic/SurveyContext';

export default function index() {
  return (
    <UserSkeleton>
      <CampaignContextProvider>
        <NewCampaign />
      </CampaignContextProvider>
    </UserSkeleton>
  );
}
