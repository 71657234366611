import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiArrowDown, FiArrowUp } from 'react-icons/fi';
import MiniChart from 'react-mini-chart';

import Modal from '../Modal';
import {
  Wrapper,
  AttendantLabel,
  AttendantName,
  AttendantKey,
  RankingPill,
  ScoreWrapper,
  Score,
  Caret,
  ModalWrapper
} from './styles';

export default function RankCard({ data }) {
  const [showMore, setShowMore] = useState(false);
  const { t } = useTranslation();
  return (
    <>
      <Wrapper onClick={() => setShowMore((p) => !p)} id="rank-card">
        <div>
          <AttendantLabel>
            {t('titles.attendant')}
          </AttendantLabel>
          <RankingPill>
            {data.position}º
          </RankingPill>
        </div>
        <AttendantName>
          {data.name}
        </AttendantName>
        <AttendantKey>
          {t('titles.key')}: {data.attendant_key}
        </AttendantKey>
        <ScoreWrapper>
          <AttendantLabel>
            {t('titles.grade')}
          </AttendantLabel>
          <Score status={data.arrow_status}>
            {data.score}
            <Caret>
              {data.arrow_status === 'up'
                ? <FiArrowUp />
                : data.arrow_status === 'neutral' ? '-' : <FiArrowDown />}
            </Caret>
          </Score>
        </ScoreWrapper>
      </Wrapper>
      {showMore && (
        <Modal
          title="Mais informações"
          options={{ height: '600px', width: '425px' }}
          setVisible={() => setShowMore(false)}
        >
          <ModalWrapper>
            <AttendantLabel>
              {t('titles.evaluatedAttendant')}
            </AttendantLabel>
            <p>
              {data.name}
            </p>
            <AttendantLabel>
              {t('titles.gradeHistory')}
            </AttendantLabel>
            <MiniChart
              strokeColor="var(--blue-90)"
              activePointColor="var(--blue-90)"
              activePointRadius={4}
              strokeWidth={2}
              labelFontSize={13}
              width={300}
              height={130}
              dataSet={[0, ...data.score_history]}
            />
          </ModalWrapper>
        </Modal>
      )}
    </>
  );
}
