import { notify } from '~/helpers/Toast';

const { default: api } = require('~/services');

const getInfo = async () =>
  api
    .get('/info')
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      notify('error', err.response.data);

      return err.response.data;
    });

export { getInfo };
