import * as Yup from 'yup';

async function validateBugReportForm(payload, formRef) {
  const schema = Yup.object().shape({
    description: Yup.string()
      .required('Necessário descrever o problema')
      .strict(true)
      .max(400, 'Máximo 400 caracteres')
      .min(3),
  });

  await schema.validate(payload, {
    abortEarly: false,
  });

  formRef.current.setErrors({});
}

export { validateBugReportForm };
