import { formatDate } from '~/helpers';

const timeNow = formatDate(new Date());

const messagesNegativeFeel = [
  {
    id: 1,
    type: 'sender',
    text: 'Desculpe por não ter encontrado a resposta de que você precisava',
    currentTime: timeNow
  },
  {
    id: 2,
    type: 'sender',
    text: 'Gostaria de pedir ajuda à equipe?',
    currentTime: timeNow
  },
];

const messagesMock = [
  {
    id: 1,
    type: 'sender',
    text: 'Não achou o que estava procurando?',
    currentTime: timeNow
  },
  {
    id: 2,
    type: 'sender',
    text: 'Pergunte diretamente para o time de desenvolvedores. Quem sabe não vira uma nova resposta do FAQ.',
    currentTime: timeNow
  },
  {
    id: 3,
    type: 'sender',
    text: 'Só lembrando, os desenvolvedores podem levar até 15 dias para responder.',
    currentTime: timeNow
  }
];

const machineMessage = {
  id: Math.random(),
  type: 'sender',
  text: 'Obrigado por nos enviar sua dúvida, os desenvolvedores podem levar até 15 dias para respondê-la.',
  currentTime: timeNow
};

export { messagesMock, machineMessage, messagesNegativeFeel };
