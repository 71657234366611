import React, { useEffect, useRef } from 'react';

import { useField } from '@unform/core';

import {
  Field, Label, Wrapper, ErrorMessage, IconWrapper
} from './styles';

export default function Input({
  name, title, type, children, ...props
}) {
  const inputRef = useRef(null);
  const {
    fieldName, registerField, defaultValue, error
  } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value'
    });
  }, [fieldName, registerField]);

  return (
    <Wrapper>
      <Label>{title}</Label>
      <IconWrapper>
        {children}
        <Field
          ref={inputRef}
          defaultValue={defaultValue}
          type={type}
          name={name}
          {...props}
        />
      </IconWrapper>
      { error && <ErrorMessage> { error } </ErrorMessage> }
    </Wrapper>
  );
}
