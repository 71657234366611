const getAmbiente = () => {
  const url = document.URL;

  if (
    url.includes('intranet') ||
    url.includes('servicos') ||
    url.includes('pesquisa.suporte')
  )
    return 'prd';
  if (url.includes('hm.bb.com.br')) return 'hm';
  if (url.includes('desenv.bb.com.br')) return 'des';

  return 'des';
};

const environment = getAmbiente();

function getOpenSearchUrl() {
  return {
    OPENSEARCH_URL: 'https://groot:groot9944@172.16.16.44:9200',
    OPENSEARCH_INDEX: 'logs-bb-survey-www',
  };
}

function getUrlSSO() {
  const urls = {
    prd: 'https://login.intranet.bb.com.br/sso/oauth2/authorize?response_type=code&client_id=bb-survey&client_password=9hha3b1nx8sw&scope=profile%20openid%20email&redirect_uri=https://iga-openam-auth.iga.servicos.bb.com.br/api/v1/auth/redirect',
    hm: 'https://login.hm.bb.com.br/sso/oauth2/authorize?response_type=code&client_id=bb-survey-hm&client_password=35l1ujg6bqjv&scope=profile%20openid%20email&redirect_uri=https://iga-openam-auth.iga.hm.bb.com.br/api/v1/auth/redirect',
    des: 'https://login.desenv.bb.com.br/sso/oauth2/authorize?response_type=code&client_id=bb-survey-desenv&client_password=d56avj7m4s9s&scope=profile%20openid%20email&redirect_uri=https://iga-openam-auth.iga.desenv.bb.com.br/api/v1/auth/redirect',
    local: 'http://localhost:3000/?token=APIMocker',
  };

  return urls[environment];
}

function getTokenName() {
  const tokens = {
    prd: 'bbsurvey_token_prd',
    hm: 'bbsurvey_token_hm',
    des: 'bbsurvey_token_ds',
    local: 'bbsurvey_token_local',
  };

  return tokens[environment];
}

function getUrlBackend() {
  const urls = {
    prd: 'https://survey.intranet.bb.com.br/service/api/v1',
    hm: 'https://survey.iga.hm.bb.com.br/service/api/v1',
    des: 'https://survey.iga.desenv.bb.com.br/service/api/v1',
    local: 'http://localhost:5000',
  };

  return urls[environment];
}

function getUrlBackendOldLayer() {
  const urls = {
    prd: 'http://resposta.bbsurvey.iga.servicos.bb.com.br/service/api/v1',
    hm: 'http://resposta.bbsurvey.iga.hm.bb.com.br/service/api/v1',
    des: 'http://resposta.bbsurvey.iga.desenv.bb.com.br/service/api/v1',
    local: 'http://localhost:5000',
  };

  return urls[environment];
}

function getUrlBackendNewLayer() {
  const urls = {
    prd: 'https://pesquisa.suporte.bb.com.br/service/api/v1',
  };

  return urls[environment];
}

function getUrlBackendAlexandria() {
  const urls = {
    prd: 'https://survey.intranet.bb.com.br/alexandria/api/v1/',
    hm: 'https://survey.iga.hm.bb.com.br/alexandria/api/v1/',
    des: 'https://survey.iga.desenv.bb.com.br/alexandria/api/v1/',
    local: 'http://localhost:5000',
  };

  return urls[environment];
}

const getMatomo = () => {
  const PRODUCTION_URL = 'https://webanalytics.intranet.bb.com.br/';
  const DESENV_URL = 'https://webanalytics.desenv.bb.com.br/';

  if (environment === 'prd') {
    return {
      urlBase: PRODUCTION_URL,
      siteId: 352,
    };
  }

  return {
    urlBase: DESENV_URL,
    siteId: 352,
  };
};

const getUrlHumanograma = () => 'https://humanograma.intranet.bb.com.br/';

export {
  getUrlSSO,
  getTokenName,
  getUrlBackend,
  getMatomo,
  getUrlHumanograma,
  getUrlBackendAlexandria,
  getUrlBackendOldLayer,
  getUrlBackendNewLayer,
  getOpenSearchUrl,
};
