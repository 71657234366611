import React, {
  createContext, useCallback, useContext, useEffect, useState
} from 'react';
import { useSelector } from 'react-redux';

import { getPrivileges, deleteUserPrivileges } from '~/services/Generic/SurveyPrivileges';

const SurveyPrivilegesContext = createContext({});

function SurveyPrivilegesContextProvider({ children }) {
  const [isLoadingTableData, setIsLoadingTableData] = useState(false);
  const [isSearchingUser, setIsSearchingUser] = useState(false);
  const [currentId, setCurrentId] = useState();
  const [editData, setEditData] = useState();
  const [searchData, setSearchData] = useState();
  const [tableData, setTableData] = useState([]);
  const [currentFilter, setCurrentFilter] = useState({ name: '', order: '' });
  const [isSettingPrivileges, setIsSettingPrivileges] = useState(false);
  const [isChangingData, setIsChangingData] = useState(false);
  const [filterFields, setFilterFields] = useState({
    name: false
  });
  const { user } = useSelector((store) => store.boilerplate);

  async function handleRemovePermission(accessId, key) {
    try {
      const payload = {
        access_id: accessId,
        campaign_id: currentId
      };

      await deleteUserPrivileges(payload).then((res) => {
        if (!res.code) {
          const filteredEmails = tableData.filter((data) => data.access_id !== accessId);

          setTableData((filteredEmails));
          setIsChangingData(true);
        }
      }).finally(() => {
        setEditData();
        if (user.key === key) {
          setIsSettingPrivileges(false);
        }
      });
    } catch (err) {
      return err;
    }
  }

  function setFilter(index) {
    const keys = Object.keys(filterFields);

    keys.forEach((_, i) => {
      if (i === index) {
        setCurrentFilter({
          name: keys[index],
          order: filterFields[keys[index]] ? 'asc' : 'desc'
        });

        setFilterFields((p) => ({ ...p, [keys[index]]: !p[keys[index]] }));
      } else {
        setFilterFields((p) => ({ ...p, [keys[i]]: false }));
      }
    });
  }

  function setPrivilegesData(id) {
    setCurrentId(id);
    setIsSettingPrivileges(true);
  }

  const getData = useCallback(async () => {
    setSearchData();
    setIsLoadingTableData(true);

    await getPrivileges(currentId, currentFilter).then((data) => {
      setTableData(data);
    }).finally(() => {
      setIsLoadingTableData(false);
    });
  }, [currentFilter, currentId]);

  useEffect(() => {
    if (currentId) {
      getData();
    }
  }, [currentId, currentFilter, getData]);

  useEffect(() => {
    if (isChangingData) {
      getData();
      setIsChangingData(false);
    }
  }, [isChangingData, getData]);

  return (
    <SurveyPrivilegesContext.Provider value={{
      isSettingPrivileges,
      setIsSettingPrivileges,
      isLoadingTableData,
      tableData,
      setIsChangingData,
      currentId,
      setCurrentId,
      setPrivilegesData,
      filterFields,
      setFilter,
      setEditData,
      editData,
      searchData,
      setSearchData,
      setIsSearchingUser,
      isSearchingUser,
      handleRemovePermission,
      isChangingData
    }}
    >
      {children}
    </SurveyPrivilegesContext.Provider>
  );
}

const useSurveyPrivileges = () => useContext(SurveyPrivilegesContext);

export { SurveyPrivilegesContextProvider, useSurveyPrivileges };
