import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiFillBug, AiOutlineInfoCircle } from 'react-icons/ai';

import brFlag from '~/assets/images/flags/br.png';
import usFlag from '~/assets/images/flags/us.png';
import Chat from '~/components/User/Chat';
import InputSelect from '~/components/User/InputSelect';
import { Menu, MenuItem } from '~/components/User/Menu';
import Profile from '~/components/User/Profile';
import Sidebar from '~/components/User/Sidebar';
import { useScreenshot } from '~/helpers/useScreenshot';
import PageTracker from '~/matomo/PageTracker';

import { BugReportModal } from './components/bugReportModal';
import { InfoModal } from './components/infoModal';
import {
  Wrapper,
  Content,
  Header,
  ChildWrapper,
  HeaderTitle,
  Footer,
  HeaderContent,
  SelectWrapper,
} from './styles';

const languages = [
  {
    id: 1,
    label: <img src={brFlag} alt="BR Flag" />,
    value: 'pt-BR',
  },
  {
    id: 2,
    label: <img src={usFlag} alt="US Flag" />,
    value: 'en-US',
  },
];

export default function UserSkeleton({ children, admin }) {
  const { t, i18n } = useTranslation();
  const ref = useRef(null);

  const [isBugModalModal, setIsBugModalOpen] = useState(false);
  const [isInfoModal, setIsInfoModalOpen] = useState(false);

  const [image, takeScreenshot] = useScreenshot({
    type: 'image/jpeg',
    quality: 1.0,
  });

  const openBugReport = () => {
    takeScreenshot(ref.current);
    setIsBugModalOpen(true);
  };

  const handleLanguageChange = (lan) => {
    i18n.changeLanguage(lan.value);
  };

  const handleCloseInfoModal = () => {
    setIsInfoModalOpen(false);
  };

  const handleCloseBugModal = () => {
    setIsBugModalOpen(false);
  };

  return (
    <Wrapper ref={ref}>
      <PageTracker />
      <Sidebar />
      <Content>
        <Header>
          <HeaderContent>
            <HeaderTitle>
              {admin ? t('titles.headerAdmin') : 'BB Survey'}
            </HeaderTitle>
            <Profile username="Lorem Ipsum Lorem" />
            <SelectWrapper>
              <InputSelect
                name="language"
                defaultValue={languages.find(
                  (lan) => lan.value === i18n.language
                )}
                onChange={handleLanguageChange}
                options={languages}
                len={20}
              />
            </SelectWrapper>
            <Menu>
              <MenuItem onClick={() => setIsInfoModalOpen(true)}>
                Sobre
                <AiOutlineInfoCircle size={20} />
              </MenuItem>
              <MenuItem onClick={openBugReport}>
                {t('labels.bugReport')}
                <AiFillBug size={20} />
              </MenuItem>
            </Menu>
          </HeaderContent>
        </Header>

        {isBugModalModal && (
          <BugReportModal
            handleCloseModal={handleCloseBugModal}
            image={image}
          />
        )}
        {isInfoModal && <InfoModal handleCloseModal={handleCloseInfoModal} />}

        <ChildWrapper>{children}</ChildWrapper>
        <Footer>Copyright 2023 - GESEC</Footer>
        <Chat />
      </Content>
    </Wrapper>
  );
}
