import React from 'react';

import UserSkeleton from '~/boilerplates/User';
import Admin from '~/containers/User/Admin';
import { AdminContextProvider } from '~/contexts/Admin/AdminContext';

export default function AdminPage() {
  return (
    <UserSkeleton admin>
      <AdminContextProvider>
        <Admin />
      </AdminContextProvider>
    </UserSkeleton>
  );
}
