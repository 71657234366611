import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { AiFillStar } from 'react-icons/ai';

import Button from '~/components/User/Button';
import { useCampaign } from '~/contexts/Generic/SurveyContext';

import { Neutral, Satisfied, Unsatisfied, VerySatisfied, VeryUnsatisfied } from '../InputRadioRating/styles';
import {
  Wrapper, DetailsOne, DetailsTwo, ButtonWrapper, MetricImages, Pill, Lines, PillsWrapper, EmojisWrapper, EmojiWrapper,
} from './styles';

export default function MetricCard({ icon }) {
  const { setDataWizard } = useCampaign();
  const { t } = useTranslation();
  const buttonOptions = {
    size: { width: '190px', height: '40px' },
    colors: { background: 'var(--blue-90)', text: 'var(--white)' }
  };

  
  const textMetric = {
    CSAT: {
      name: 'CSAT',
      example: t('messages.csatExample'),
      question: t('messages.csatQuestion'),
      text: t('messages.csatText'),
    },
    NPS: {
      name: 'NPS',
      example: t('messages.npsExample'),
      question: t('messages.npsQuestion'),
      text: t('messages.npsText')
    },
    CES: {
      name: 'CES',
      example: t('messages.cesExample'),
      question: t('messages.cesQuestion'),
      text: t('messages.cesText')
    },
    CUSTOM: {
      name: t('titles.customCampaign').toUpperCase(),
      example: t('messages.customExample'),
      question: t('messages.customQuestion'),
      text: t('messages.customText')
    }
  };

  const handleNext = useCallback(() => {
    setDataWizard((prev) => ({
      ...prev, step: 2, survey: { ...prev.survey, metric: icon }
    }));
  }, [setDataWizard, icon]);

  const renderMetricImage = useCallback(() => {
    switch (icon) {
      case 'CSAT':
        return [...Array(5)].map((_) => <AiFillStar key={Math.random()} id={_} />);
      case 'CES':
        return (
          <>
            <Pill color="var(--red)">{t('pills.totallyDisagree')}</Pill>
            <Pill color="var(--red-20)">{t('pills.disagree')}</Pill>
            <Pill color="var(--red-40)">{t('pills.partiallyDisagree')}</Pill>
            <Pill color="var(--yellow-80)">{t('pills.neutral')}</Pill>
            <Pill color="var(--green-40)">{t('pills.partiallyAgree')}</Pill>
            <Pill color="var(--green-20)">{t('pills.agree')}</Pill>
            <Pill color="var(--green)">{t('pills.totallyAgree')}</Pill>
          </>
        );
      case 'NPS':
        return (
          <Lines>
            <PillsWrapper>
              {[...Array(3)].map((_, index) => (
                <Pill color="var(--red)" pill-small key={Math.random()}>
                  {index + 1}
                </Pill>
              ))}
              {[...Array(1)].map(() => (
                <Pill color="var(--yellow-80)" pill-small key={Math.random()}>
                  4
                </Pill>
              ))}
              {[...Array(1)].map(() => (
                <Pill color="var(--green)" pill-small key={Math.random()}>
                  5
                </Pill>
              ))}
            </PillsWrapper>
            <PillsWrapper>
              {[...Array(6)].map((_, index) => <Pill color="var(--red)" pill-small key={Math.random()}>{index + 1}</Pill>)}
              {[...Array(2)].map((_, index) => <Pill color="var(--yellow-80)" pill-small key={Math.random()}>{6 + index + 1}</Pill>)}
              {[...Array(2)].map((_, index) => <Pill color="var(--green)" pill-small key={Math.random()}>{8 + index + 1}</Pill>)}
            </PillsWrapper>
          </Lines>
        );
      case 'CUSTOM':
        return (
          <EmojisWrapper>
            <EmojiWrapper>
              <p>{t('csatEmojis.veryUnsatisfied')}</p>
              <VeryUnsatisfied />
            </EmojiWrapper>
           
            <EmojiWrapper>
              <p>{t('csatEmojis.unsatisfied')}</p>
              <Unsatisfied />
            </EmojiWrapper>
        
            <EmojiWrapper>
              <p>{t('csatEmojis.neutral')}</p>
              <Neutral />
            </EmojiWrapper>
           
            <EmojiWrapper>
              <p>{t('csatEmojis.satisfied')}</p>
              <Satisfied />
            </EmojiWrapper>
      
            <EmojiWrapper>
              <p>{t('csatEmojis.verySatisfied')}</p>
              <VerySatisfied />
            </EmojiWrapper>
          </EmojisWrapper>
        )
      default:
        break;
    }
  }, [icon, t]);

  return (
    <Wrapper>
      <DetailsOne>
        <h4>{textMetric[icon].name}</h4>
        <p>{textMetric[icon].text}</p>
        <ButtonWrapper>
          <Button
            title={t('buttons.letStart')}
            options={buttonOptions}
            onClick={handleNext}
            id="next-btn"
            data-test={`create-${icon}-campaign`}
          />
        </ButtonWrapper>
      </DetailsOne>
      <DetailsTwo>
        <span>{textMetric[icon].example}</span>
        <strong>{textMetric[icon].question}</strong>
        <MetricImages>
          {renderMetricImage()}
        </MetricImages>
      </DetailsTwo>
    </Wrapper>
  );
}
