import { toast, Flip, Zoom } from 'react-toastify';

const notify = ((type, message) => {
  const toastConfigForm = {
    position: 'top-right',
    autoClose: 8000,
    closeOnClick: true,
    draggable: true,
    transition: Flip,
  };

  const toastConfigUnauthorized = {
    toastId: 2,
    position: 'top-right',
    autoClose: false,
    closeOnClick: true,
    draggable: true,
    transition: Zoom,
  };

  switch (type) {
    case 'success':
      toast.success(message, toastConfigForm);
      break;
    case 'warning':
      toast.warn(message, toastConfigForm);
      break;
    case 'errorUnauthorized':
      toast.error(message, toastConfigUnauthorized);
      break;
    case 'error':
      toast.error(message, toastConfigForm);
      break;
    case 'errorForm':
      toast.error(message, toastConfigForm);
      break;
    default:
      break;
  }
});

export { notify };
