import styled from 'styled-components';

import campaignPreview from '~/assets/images/generic/preview-campaign.png';
import device from '~/assets/styles/settings/Devices';

export const Wrapper = styled.div`
  align-self: center;

  display: flex;
  align-items: center;
  flex-direction: column;

  max-width: 700px;
  text-align: center;

  margin-top: var(--metrics-m);
  margin-bottom: var(--metrics-xl);

  h2 {
    color: var(--blue-90);
    margin-bottom: .8rem;
  }

  p {
    color: var(--blue-50);
    font-family: Inter,sans-serif;
    font-weight: var(--font-weight-b);
    font-size: 0.855rem;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 1rem;

  @media ${device.laptop} {
    align-self: center;
  }
`;

export const PreviewCard = styled.img.attrs(() => ({
  src: campaignPreview
}))`
  max-width: 500px;
  margin: 0 auto;

  @media ${device.laptop} {
    max-width: 350px;
  }
`;
