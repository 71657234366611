import React, { useState, useRef, useEffect } from 'react';
import { FaTimes, FaPaperPlane } from 'react-icons/fa';
import { useSelector, useDispatch } from 'react-redux';

import InputTextChat from '~/components/User/InputTextChat';
import { generateMessage } from '~/helpers';
import { messagesMock, machineMessage } from '~/mocks/Messages';
import { setChatModal } from '~/redux/actions/Boilerplate';
import { create, show } from '~/services/Generic/Issues';

import {
  Wrapper, CardChat, CardContent, Tab, Logo, LogoWrapper, Title,
  MessageWrapper, MessageResponse, MessageResponseText, CloseButton,
  Actions, SubmitButton, Time
} from './styles';

export default function Chat() {
  const dispatch = useDispatch();
  const formRef = useRef(null);
  const messagesRef = useRef(null);
  const [messages, setMessages] = useState([]);
  const [machineAnswered, setMachineAnswered] = useState(false);
  const { chatVisible } = useSelector((state) => state.boilerplate);
  const productSlug = 'bb-survey';

  useEffect(() => {
    async function getUserMessages() {
      await show(productSlug).then((data) => {
        const splitMessages = data.issue ? data.issue.split('\n') : [];

        splitMessages.forEach((message) => {
          setMessages((prev) => [
            ...prev,
            generateMessage({ text: message, date_issue: data.date_issue }),
          ]);
        });
      });
    }

    setMessages(messagesMock);
    getUserMessages();
  }, []);

  useEffect(() => {
    function scrollChat() {
      if (chatVisible) {
        messagesRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }

    scrollChat();
  }, [messages, chatVisible]);

  const closeChat = () => {
    setChatModal(dispatch, false);
  };

  // const openChat = () => {
  //   setChatModal(dispatch, true);
  // };

  const handleSubmit = (data, { reset }) => {
    reset();

    let payload = {
      product: productSlug,
      data: {
        issue: data.text,
      },
    };

    create(payload).then(() => {
      setMessages((prev) => [...prev, generateMessage(data)]);

      if (!machineAnswered) {
        setTimeout(() => {
          setMessages((prev) => [...prev, machineMessage]);
        }, [1300]);

        setMachineAnswered(true);
      }
    });
  };
  return (
    <>
      {/* Não esta sendo usado no momento 27/04/2023 */}
      {/* <Button id="btn-open" type="button" onClick={openChat}>
        <img src={chatBalloon} alt="Open Chat" />
      </Button> */}
      {chatVisible && (
        <Wrapper>
          <CardChat>
            <Tab>
              <LogoWrapper>
                <Logo />
              </LogoWrapper>
              <Title>Fale com o time do Produto</Title>
            </Tab>
            <CardContent>
              {messages.map((message) => (
                <MessageWrapper key={message.id}>
                  <MessageResponse from={message.type}>
                    <MessageResponseText from={message.type}>
                      <p>{message.text}</p>
                    </MessageResponseText>
                    <Time>{message.currentTime}</Time>
                  </MessageResponse>
                </MessageWrapper>
              ))}
              <div ref={messagesRef} />
            </CardContent>
            <Actions onSubmit={handleSubmit} ref={formRef}>
              <InputTextChat
                name="text"
                type="text"
                placeholder="Aa"
              />
              <SubmitButton type="submit" id="form-chat">
                <FaPaperPlane />
              </SubmitButton>
            </Actions>
          </CardChat>
          <CloseButton id="btn-close" onClick={closeChat}>
            <FaTimes />
          </CloseButton>
        </Wrapper>
      )}
    </>
  );
}
