import React, { useState } from 'react';
import { BiChevronDown, BiChevronUp } from 'react-icons/bi';

import { StyledLi } from './styles';

const Accordion = ({ children }) => {
  return <ul>{children}</ul>;
};

const AccordionItem = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <StyledLi isOpen={isOpen}>
      <div>
        <h3>{title}</h3>

        <button type="button" onClick={() => setIsOpen(!isOpen)}>
          {!isOpen ? <BiChevronDown size={32} /> : <BiChevronUp size={32} />}
        </button>
      </div>

      {isOpen && children}
    </StyledLi>
  );
};

export { Accordion, AccordionItem };
