import Lottie from 'react-lottie';

import styled from 'styled-components';

import checkImage from '~/assets/images/animations/checkmark.json';
import closeImage from '~/assets/images/animations/closemark.json';
import loadingImage from '~/assets/images/animations/loading.json';
import spinnerImage from '~/assets/images/animations/spinner.json';

const loadingAnimation = {
  loop: true,
  autoplay: true,
  animationData: loadingImage,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  },
};

const spinnerAnimation = {
  loop: true,
  autoplay: true,
  animationData: spinnerImage,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  },
};

const checkAnimation = {
  loop: false,
  autoplay: true,
  animationData: checkImage,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  },
};

const closeAnimation = {
  loop: false,
  autoplay: true,
  animationData: closeImage,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  },
};

export const Loading = styled(Lottie).attrs(() => ({
  options: loadingAnimation,
  width: '292px',
  height: '300px'
}))``;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  gap: .5rem;

  min-height: 100vh;

  background-color: var(--gray-10);

  h1 {
    font-size: 1.8rem;

    font-family: Lexend, sans-serif;
  }
`;

export const Spinner = styled(Lottie).attrs(() => ({
  options: spinnerAnimation,
  width: '30px',
  height: '30px'
}))``;

export const Checkmark = styled(Lottie).attrs(() => ({
  options: checkAnimation,
  width: '30px',
  height: '30px',
}))`
`;

export const Closemark = styled(Lottie).attrs(() => ({
  options: closeAnimation,
  width: '18px',
  height: '18px',
}))`
`;

export const Group = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  background-color: var(--blue-20);

  padding: .5rem 1rem;
  border-radius: 8px;
  height: 45px;

  p {
    width: 247px;
    text-align: center;

    color: var(--white);
  }

  div {
    margin-left: auto;
  }
`;
