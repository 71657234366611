import React, { useRef } from 'react';
import { AiOutlineClose } from 'react-icons/ai';

import { useEventListener } from '~/hooks/useEventListener';

import { Wrapper, Content, CloseButton, Group, TitleModal } from './styles';

export default function Modal({ children, title, setVisible, options }) {
  const contentRef = useRef(null);

  useEventListener('mousedown', (event) => {
    if (!contentRef.current.contains(event.target)) {
      setVisible(false);
    }
  });

  return (
    <Wrapper>
      <Content ref={contentRef} options={options}>
        <Group>
          <TitleModal options={options}>{title}</TitleModal>
          <CloseButton onClick={() => setVisible(false)} id="close-modal-btn">
            <AiOutlineClose />
          </CloseButton>
        </Group>
        {children}
      </Content>
    </Wrapper>
  );
}
