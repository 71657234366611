import { notify } from '~/helpers/Toast';
import api from '~/services';
import i18n from '~/translate/i18n';

function getFilters(filterField) {
  let params = {};

  if (filterField.name && filterField.order) {
    params.order_field = filterField.name;
    params.order = filterField.order;
  }

  return params;
}

const languages = {
  'pt-BR': 'message',
  'en-US': 'message_en',
};

const getPrivileges = async (id, currentFilter) =>
  api
    .get(`/permission/${id}`, {
      params: getFilters(currentFilter),
    })
    .then((res) => res.data)
    .catch((err) => {
      notify('error', err.response.data.errors[0][languages[i18n.language]]);

      return err.response.data;
    });

const createPrivilege = async (payload) =>
  api
    .post('/permission', payload)
    .then((res) => {
      notify('success', i18n.t('notify.createPrivilege.success'));

      return res.data;
    })
    .catch((err) => {
      notify('error', err.response.data.errors[0][languages[i18n.language]]);

      return err.response.data;
    });

const searchUser = async (key) =>
  api
    .get(`/user/${key}`)
    .then((res) => res.data)
    .catch((err) => {
      notify('error', err.response.data.errors[0][languages[i18n.language]]);

      return err.response.data;
    });

const editUserPrivileges = async (payload) =>
  api
    .patch('/permission', payload)
    .then((res) => {
      notify('success', i18n.t('notify.editUserPrivileges.success'));

      return res.data;
    })
    .catch((err) => {
      notify('error', err.response.data.errors[0][languages[i18n.language]]);

      return err.response.data;
    });

const deleteUserPrivileges = async (payload) =>
  api
    .delete('/permission', { data: payload })
    .then((res) => {
      notify('success', i18n.t('notify.deleteUserPrivileges.success'));

      return res.data;
    })
    .catch((err) => {
      notify('error', err.response.data.errors[0][languages[i18n.language]]);

      return err.response.data;
    });

export {
  getPrivileges,
  createPrivilege,
  searchUser,
  deleteUserPrivileges,
  editUserPrivileges,
};
