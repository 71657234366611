import styled from 'styled-components';

export const Wrapper = styled.div`
  margin: 2rem auto;
  max-width: 700px;
  margin-top: 1rem;
  p,
  strong {
    font-size: 20px;
    color: #002766;
    line-height: 2rem;
  }

  > span {
    color: #005aa5;
    font-size: 18px;
    font-weight: 400;
    margin-top: 2rem;
    display: block;
    text-align: center;
  }
`;

export const HighlightText = styled.section`
  padding: 1rem;

  svg {
    fill: #fff;
  }
`;
