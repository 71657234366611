import React from 'react';
import { Line } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';

const LineChart = ({ data, type, noplugin }) => {
  const { t } = useTranslation();
  const label = t('labels.averageGradeDay');
  const options = {
    responsive: true,
    scales: {
      y: {
        min: type === 'nps' ? -100 : 0,
        max: type !== 'ces' ? 100 : 7,
        ticks: {
          stepSize: type !== 'ces' ? 10 : 1,
          callback: (value) =>
            ['nps', 'ces'].includes(type) ? value : `${value}%`,
        },
      },
    },
    plugins: {
      legend: {
        display: !noplugin,
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem) =>
            `${label} ${tooltipItem.formattedValue}` +
            `${['nps', 'ces'].includes(type) ? '' : '%'}`,
        },
      },
    },
  };

  return (
    <>
      <Line data={data} options={options} height="100%" />
    </>
  );
};

export default LineChart;
