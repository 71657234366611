import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import ButtonLink from '~/components/User/Button';

import { Wrapper, DashboardPaper, ButtonWrapper } from './styles';

export default function Home() {
  const { user } = useSelector((store) => store.boilerplate);
  const { t } = useTranslation();
  const buttonOptions = {
    size: { width: '230px', height: '56px' },
    colors: { background: 'var(--yellow-80)', text: 'var(--white)' },
  };

  return (
    <>
      <Wrapper>
        <DashboardPaper />
        <h2>
          {t('messages.hello', { name: user?.name })}
          <br />
          {t('messages.welcomeHeader')}
        </h2>
        <p>{t('messages.welcomeP1')},</p>
        {user?.isAdmin ? (
          <>
            <p>
              {t('messages.welcomeP2')}{' '}
              <strong>{t('messages.welcomeP3')}</strong>
            </p>
            <ButtonWrapper>
              <Link to="/campaign/new">
                <ButtonLink
                  title={t('buttons.newCampaign')}
                  options={buttonOptions}
                />
              </Link>
            </ButtonWrapper>
          </>
        ) : (
          <p>
            {t('messages.welcomeP4')} <strong>{t('messages.welcomeP5')}</strong>
            {t('messages.welcomeP6')}
          </p>
        )}
      </Wrapper>
    </>
  );
}
