import React, { Fragment, useEffect, useRef } from 'react';

import { useField } from '@unform/core';

import {
  Wrapper, Label, Field, ErrorMessage, LabelTitle
} from './styles';

export default function RadioInput({
 name, options, title, setOptionValue = undefined
}) {
  const inputRefs = useRef([]);
  const {
    fieldName, registerField, defaultValue, error
  } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      path: 'value',
      ref: inputRefs.current,
      getValue(refs) {
        const checked = refs.find((ref) => ref.checked);

        return checked ? checked.value : null;
      },
      setValue(refs, value) {
        const item = refs.find((ref) => ref.value === value);

        if (item) {
          item.checked = true;
        }
      },
    });
  }, [fieldName, registerField]);

  return (
    <>
      <Wrapper>
        <LabelTitle>{title}</LabelTitle>
        {options.map((option, index) => (
          <Fragment key={option.id}>
            <Field
              ref={(ref) => {
                inputRefs.current[index] = ref;
              }}
              type="radio"
              name={fieldName}
              value={option.value}
              defaultChecked={defaultValue === option.id}
              id={option.id}
              onChange={(e) => setOptionValue && setOptionValue(parseInt(e.target.value, 2))}
            />
            <Label htmlFor={option.id}>{option.label}</Label>
          </Fragment>
        ))}
      </Wrapper>
      {error && <ErrorMessage> {error} </ErrorMessage>}
    </>
  );
}
