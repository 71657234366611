import styled from 'styled-components';

export const Wrapper = styled.table`
  width: 100%;

  tr {
    &:not(:last-child) {
      td {
        border-bottom: 2px solid var(--gray-10);
      }
    }
  }

  th {
    vertical-align: middle;

    &:first-child {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    &:last-child {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }

  th, td {
    padding: .95rem 1rem;
  }

  th {
    color: var(--blue-90);
    background-color: var(--blue-10);

    font: 500 .825rem Lexend, sans-serif;
    text-align: left;
  }

  td {
    color: var(--gray-60);
    vertical-align: middle;

    font-size: .75rem;
  }
`;

export const ButtonAction = styled.button.attrs(() => ({
  type: 'button'
}))`
  position: relative;
  width: 24px;
  height: 24px;
  margin-right: 1rem;

  > svg {
    transition: fill .3s;

    width: 18px;
    height: 18px;
  }

  &:hover {
    svg {
      fill: var(--blue-90);
    }

    span {
      visibility: visible;
    }
  }
`;

export const Actions = styled.td`
  text-align: center;
`;

export const SortButton = styled.button.attrs(() => ({
  type: 'button'
}))`
  position: absolute;

  height: .9rem;
  width: .9rem;

  transform: translateX(12px);

  svg {
    fill: var(--gray-40);

    height: .9rem;
    width: .9rem;
  }
`;

export const Status = styled.td`
  span {
    display: inline-block;
    margin-right: calc(var(--metrics-smlr) - 4px);

    vertical-align: middle;

    width: 12px;
    height: 12px;

    border-radius: 50%;
    background-color: ${(p) => (p.color ? p.color : 'var(--blue-40)')};
  }

  strong {
    vertical-align: middle;

    color: ${(p) => (p.color ? p.color : 'var(--blue-40)')};
  }
`;

export const Timer = styled.span`
  position: absolute;

  width: 12px;
  height: 12px;

  transform: translate(1.2rem, 2px);

  svg {
    fill: var(--yellow-80);
  }

  &:hover {
    span {
      visibility: visible;
    }
  }
`;
