import * as Yup from 'yup';

import { notify } from '~/helpers/Toast';

function generateFormErrors(err, formRef) {
  if (err instanceof Yup.ValidationError) {
    const errorMessages = { ...formRef?.current?.getErrors() };
    let notifyMessages = '';

    err.inner.forEach((error) => {
      notifyMessages += `• ${error.message}\n`;

      errorMessages[error.path] = error.message;
    });

    // eslint-disable-next-line no-unused-expressions
    formRef?.current?.setErrors(errorMessages);
    notify('errorForm', notifyMessages);
  }
}

export { generateFormErrors };
