import React from 'react';

import {
  Card,
  CardHeader,
  CardContent,
  CardTitle,
  Button,
  Label,
  CardFooter,
  Rating,
  ToggleGroup,
  Radio,
  RadioItem,
  ToggleGroupItem,
  Textarea,
} from 'iga-bbsurvey-react';

import { useCampaign } from '~/contexts/Generic/SurveyContext';

const renderAnswer = {
  choices: ({ choices }) => (
    <Radio>
      {choices?.map((item) => (
        <div
          key={item}
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <RadioItem value={item} id={item} />
          <Label htmlFor={item}>{item}</Label>
        </div>
      ))}
    </Radio>
  ),
  '5-star': () => <Rating variant="star" />,
  bool: () => (
    <ToggleGroup type="single">
      <ToggleGroupItem value="1">Sim</ToggleGroupItem>

      <ToggleGroupItem value="0">Não</ToggleGroupItem>
    </ToggleGroup>
  ),
  '5-pill': () => <Rating variant="number" items={5} />,
  '10-pill': () => <Rating variant="number" items={10} />,

  '5-smiley': () => <Rating variant="smiley" />,
};

const ApiPreview = () => {
  const { dataWizard } = useCampaign();

  return (
    <>
      <p>
        <strong>Observação</strong>: Veja como ficará sua campanha ao utilizar a
        opção de react.js.
      </p>

      <Card>
        <CardHeader>
          <CardTitle className="text-black-300">
            {dataWizard?.survey?.campaignName}
          </CardTitle>
        </CardHeader>
        <CardContent>
          <p style={{ textAlign: 'left' }}>{dataWizard?.survey?.question}</p>

          <Rating
            variant="number"
            items={dataWizard.survey.option === '10-pill' ? 10 : 5}
          />

          {dataWizard?.survey?.custom_questions?.map((question) => (
            <div
              key={question.id}
              style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }}
            >
              <p style={{ textAlign: 'left', marginBottom: '0.5rem' }}>
                {question.question}
              </p>

              {renderAnswer[question.appearance]?.(question)}
            </div>
          ))}
          <Label htmlFor="textArea">Conte-nos mais sobre sua experiência</Label>
          <Textarea showLetterCount />
        </CardContent>
        <CardFooter>
          <Button size="full">AVALIAR</Button>
        </CardFooter>
      </Card>
    </>
  );
};

export { ApiPreview };
