import React, { forwardRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';

import ptBR from 'date-fns/locale/pt-BR';

import { formatDate } from '~/helpers/Dates';

import { Calendar, Output, Popper } from './styles';
import 'react-datepicker/dist/react-datepicker.css';

const CustomDatePicker = forwardRef(
  ({ style, selected, onChange, ...props }, ref) => {
    const [inputVisible, setInputVisible] = useState(false);
    const [date, setDate] = useState(selected ?? new Date());

    const handleChange = (value) => {
      setInputVisible(false);
      setDate(value);
      onChange(value);
    };

    return (
      <div style={style}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Output
            onClick={() => setInputVisible((p) => !p)}
            data-test="show-calendar"
          >
            <span>{formatDate(date, 'pt-BR')}</span>
            {inputVisible ? <IoIosArrowUp /> : <IoIosArrowDown />}
          </Output>
        </div>

        {inputVisible && (
          <DatePicker
            selected={date}
            ref={ref}
            startDate={date}
            onChange={handleChange}
            dateFormat="dd/MM/yyyy"
            inline
            locale={ptBR}
            popperContainer={Popper}
            calendarContainer={Calendar}
            {...props}
          />
        )}
      </div>
    );
  }
);

export { CustomDatePicker };
