import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { FiUserCheck } from 'react-icons/fi';
import { useSelector } from 'react-redux';

import InputCheckbox from '~/components/User/InputCheckbox';
import Tooltip from '~/components/User/Tooltip';
import { useSurveyPrivileges } from '~/contexts/CUSTOM/SurveyPrivilegesContext';
import { editUserPrivileges } from '~/services/Generic/SurveyPrivileges';

import {
  UserInfo,
  UserPhoto,
  Username,
  UserWrapper,
  Actions,
  ButtonAction,
} from './styles';

export default function EditUser({ data, setEditData }) {
  const formRef = useRef(null);
  const { setIsChangingData, setIsSettingPrivileges } = useSurveyPrivileges();
  const { user } = useSelector((store) => store.boilerplate);

  const handleSubmit = async (formData) => {
    try {
      const checkbox = formData['edit-checkbox'];
      const payload = {
        access_id: data.access_id,
        is_viewer: checkbox.includes('viewer'),
        is_owner: checkbox.includes('owner'),
      };

      await editUserPrivileges(payload).finally(() => {
        setEditData();
        setIsChangingData(true);
        if (user.key === data.key) {
          setIsSettingPrivileges(false);
        }
      });
    } catch (err) {
      return err;
    }
  };

  useEffect(() => {
    const currentForm = {
      'edit-checkbox': [
        data.is_owner && 'owner',
        data.is_viewer && 'viewer',
      ].filter(Boolean),
    };

    formRef.current.setData(currentForm);
  }, [data]);

  const { t } = useTranslation();
  return (
    <UserInfo onSubmit={handleSubmit} ref={formRef}>
      <UserWrapper>
        <UserPhoto src={data?.picture} />
        <Username>{data?.name}</Username>
      </UserWrapper>
      <Actions>
        <InputCheckbox
          name="edit-checkbox"
          options={[
            {
              id: 'owner',
              value: 'owner',
              label: t('labels.campaignOwner'),
            },
            {
              id: 'viewer',
              value: 'viewer',
              label: t('labels.member'),
            },
          ]}
        />
        <ButtonAction type="submit">
          <Tooltip text="Salvar" />
          <FiUserCheck />
        </ButtonAction>
      </Actions>
    </UserInfo>
  );
}
