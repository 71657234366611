import React, { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Form } from '@unform/web';

import Button from '~/components/User/Button';
import InputRadioDistributionCard from '~/components/User/InputRadioDistributionCard';
import { useCampaign } from '~/contexts/Generic/SurveyContext';

import { Wrapper, ButtonsWrapper } from './styles';

export default function SetDistribution() {
  const {
    setDataWizard, backButtonOptions, nextButtonOptions
  } = useCampaign();

  const formRef = useRef(null);

  const { t } = useTranslation();

  const handleNext = useCallback((formData) => {
    if (formData.option === 'email') {
      setDataWizard((prev) => (
        { ...prev, step: 2.5, distribution: { ...prev.distribution, name: formData.option } }));
    }

    if (formData.option === 'api') {
      setDataWizard((prev) => (
        { ...prev, step: 3, distribution: { ...prev.distribution, name: formData.option } }));
    }
  }, [setDataWizard]);

  const optionsRatings = [
    { id: 'email', name: 'Email' },
    { id: 'api', name: 'Api' },
  ];

  return (
    <Wrapper>
      <Form ref={formRef} onSubmit={handleNext}>
        <InputRadioDistributionCard
          options={optionsRatings}
          name="option"
        />
        <ButtonsWrapper>
          <Button
            data-test="Voltar"
            title={t('buttons.back')}
            options={backButtonOptions}
            onClick={() => setDataWizard((prev) => ({ ...prev, step: 1 }))}
          />
          <Button
            data-test="Avançar"
            title={t('buttons.foward')}
            options={nextButtonOptions}
            onClick={handleNext}
          />
        </ButtonsWrapper>
      </Form>
    </Wrapper>
  );
}
