import React, { useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Form } from '@unform/web';
import { LoaderPagination } from 'components/User/Loader';

import InputSelect from '~/components/User/InputSelect';
import paginate from '~/helpers/Pagination';

import {
  Wrapper, WrapperInputSelect, Button, ButtonPN
} from './styles';

export default function Pagination({
  totalElements, numberPages, activePage,
  setActivePage, isLoading, totalPerPage,
  setTotalPerPage
}) {
  const options = [
    { label: '50', value: 50 },
    { label: '100', value: 100 },
    { label: '200', value: 200 },
    { label: 'Todos', value: 'todos' },
  ];
  const [currentOption, setCurrentOption] = useState(options[0]);
  const [pages, setPages] = useState([]);

  const handleChange = (e) => {
    setTotalPerPage(e.value);
    const indexOptions = options.findIndex((el) => el.value === e.value);
    setCurrentOption(options[indexOptions]);
  };

  const generateButtons = useCallback((p) => {
    const pNumbers = p.map((page) => (
      <Button
        isActive={activePage === page - 1}
        onClick={() => setActivePage(page - 1)}
        key={page}
      >
        {page}
      </Button>
    ));

    return (
      <>
        {pages[0] > 1 && <span>...</span>}
        {pNumbers}
        {pages?.slice(-1)[0] < numberPages && <span>...</span>}

      </>
    );
  }, [activePage, setActivePage, numberPages, pages]);

  useEffect(() => {
    setPages(paginate(
      totalElements,
      totalPerPage,
      totalPerPage === 'todos' ? 1 : 10, activePage
    ).pages);
  }, [activePage, totalElements, totalPerPage]);

  const { t } = useTranslation();
  return (
    <Wrapper>
      {isLoading ? <LoaderPagination />
        : (
          <>
            {numberPages > 0 && (
              <>
                <ButtonPN
                  type="button"
                  onClick={() => setActivePage(0)}
                  id="prev-btn"
                > {t('buttons.first')}
                </ButtonPN>
              </>
            )}
            {generateButtons(pages)}
            {numberPages > 0 && (
              <ButtonPN
                type="button"
                onClick={() => setActivePage(numberPages - 1)}
                id="next-btn"
              > {t('buttons.last')}
              </ButtonPN>
            )}
            <WrapperInputSelect>
              <Form>
                <InputSelect
                  data-test="onChange"
                  options={options}
                  onChange={handleChange}
                  placeholder="pages"
                  name="pages"
                  defaultValue={currentOption}
                  noOptionsMessage={() => t('messages.noCampagns')}
                  isSearchable={false}
                  len={1}
                />
              </Form>
            </WrapperInputSelect>
          </>
        )}
    </Wrapper>
  );
}
