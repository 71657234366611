import styled from 'styled-components';

export const Field = styled.textarea`
  display: block;

  width: 100%;
  max-width: 400px;
  height: 150px;

  border-radius: 8px;
  border: 2px solid rgba(0, 77, 158, 0.3);
  padding: var(--metrics-smlr);

  color: var(--gray-90);
  font-size: var(--font-size-sm);

  outline: none;
  box-sizing: border-box;
  resize: none;
  transition: border 0.2s;

  &::placeholder {
    font-size: var(--font-size-smlr);
    opacity: 0.8;
  }

  &:focus {
    border: 2px solid var(--blue-60);
  }
`;

export const ErrorMessage = styled.p`
  color: var(--red);

  font-size: 10px;
`;
