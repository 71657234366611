import React from 'react';
import { useParams } from 'react-router-dom';

import Campaign from '~/containers/Outside/Campaign';

export default function CampaignAnswer() {
  let { hash } = useParams();

  return (
    <Campaign hash={hash} />
  );
}
