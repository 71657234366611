import React from 'react';

import UserSkeleton from '~/boilerplates/User';
import Dashboard from '~/containers/User/NPS/Dashboard';
import { DashboardContextProvider } from '~/contexts/NPS/DashboardContext';

export default function index() {
  return (
    <UserSkeleton>
      <DashboardContextProvider>
        <Dashboard />
      </DashboardContextProvider>
    </UserSkeleton>
  );
}
