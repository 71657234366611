import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { IoIosArrowForward } from 'react-icons/io';

import Modal from '~/components/User/Modal';
import { getInfo } from '~/services/User/Info';

import { Column, Grid, ModalWrapper, SystemWrapper } from './styles';

const InfoModal = ({ handleCloseModal }) => {
  const [infoData, setInfoData] = useState();
  const { t } = useTranslation();

  useEffect(() => {
    const getData = async () => {
      const res = await getInfo();
      setInfoData(res);
    };

    getData();
  }, []);

  return (
    <Modal setVisible={handleCloseModal}>
      <ModalWrapper>
        <h1>BBsurvey</h1>
        <Grid>
          <Column>
            <h3>{t('infoModal.manager')}</h3>
            <a
              href={
                infoData?.manager?.url ?? 'https://survey.intranet.bb.com.br/'
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              {infoData?.manager?.name} <IoIosArrowForward size={16} />
            </a>
            <h3>{t('infoModal.team')}</h3>
            <a
              href={infoData?.team?.url ?? 'https://survey.intranet.bb.com.br/'}
              target="_blank"
              rel="noopener noreferrer"
            >
              {infoData?.team?.name} <IoIosArrowForward size={16} />
            </a>
            <h3>{t('infoModal.developer')}</h3>
            <p>{infoData?.developer}</p>
          </Column>

          <Column>
            <SystemWrapper>
              <span>{infoData?.system?.abbreviation}</span>
              <h3>{t('infoModal.system')}</h3>
              <p>{infoData?.system?.name}</p>
            </SystemWrapper>
            <h3>{t('infoModal.release')}</h3>
            {infoData?.projects?.map((child) => (
              <p>{child}</p>
            ))}
          </Column>
        </Grid>
      </ModalWrapper>
    </Modal>
  );
};

export { InfoModal };
