import styled from 'styled-components';

export const Field = styled.textarea`
  width: 78%;
  height: 68px;
  padding: var(--metrics-smlr) var(--metrics-sm);
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgba(184, 184, 184, 0.25);
  background-color: var(--gray-10);
  color: var(--gray-50);
  border: none;
  outline: none;
  box-sizing: border-box;
  font-size: calc(var(--font-size-sm) - 1px);

  &::placeholder {
    font-size: calc(var(--font-size-sm) - 1px);
    color: var(--gray-60);
    opacity: .3;
  }
`;
