import { notify } from '~/helpers/Toast';
import api from '~/services';
import i18n from '~/translate/i18n';

const languages = {
  'pt-BR': 'message',
  'en-US': 'message_en',
};

const executeTask = async (url) =>
  api
    .post(`adm/run/${url}`)
    .then((res) => {
      notify('warning', i18n.t('notify.executeTask.warning', { url }));

      return res.data;
    })
    .catch((err) =>
      notify('error', err.response.data.errors[0][languages[i18n.language]])
    );

export { executeTask };
