import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaSearch } from 'react-icons/fa';

import { Form } from '@unform/web';
import { searchUser } from 'services/Generic/SurveyPrivileges';

import Button from '~/components/User/Button';
import Input from '~/components/User/Input';
import { useCampaign } from '~/contexts/Generic/SurveyContext';
import { generateFormErrors } from '~/validators';
import { validateAddEmailCSAT } from '~/validators/User/CSAT';
import { validateAddEmailNPS } from '~/validators/User/NPS';

export default function AddEmail() {
  const { dataWizard, setDataWizard, currentMetric } = useCampaign();
  const isCSAT = currentMetric === 'CSAT';
  const formRef = useRef(null);
  const addEmailButtonOptions = {
    size: { width: '190px', height: '40px' },
    colors: { background: 'var(--gray-10)', text: 'var(--blue-60)' },
  };
  const [currentKey, setCurrentKey] = useState('');
  const [savedKey, setSavedKey] = useState('');

  const handleSubmit = useCallback(
    async (formData, { reset }) => {
      try {
        const { emails } = dataWizard.distribution;
        const checkEmail = emails.some((row) => row.email === formData.email);

        if (checkEmail) {
          formRef.current.setFieldError(
            'email',
            'Esse e-mail já foi adicionado'
          );
        } else if (savedKey !== currentKey) {
          formRef.current.setFieldError(
            'attendant_key',
            'A chave do atendente não é a mesma utilizada na busca'
          );
        } else {
          if (isCSAT) {
            await validateAddEmailCSAT(formData, formRef);
          } else {
            await validateAddEmailNPS(formData, formRef);
          }

          setDataWizard((prev) => ({
            ...prev,
            distribution: {
              ...prev.distribution,
              emails: [...prev.distribution.emails, formData],
            },
          }));

          reset();
        }
      } catch (err) {
        generateFormErrors(err, formRef);
      }
    },
    [setDataWizard, dataWizard, isCSAT, currentKey, savedKey]
  );

  const handleSearch = useCallback(async () => {
    const key = formRef.current.getFieldValue('attendant_key');

    try {
      await searchUser(key).then((data) => {
        formRef.current.setFieldValue(
          'evaluated_attendant',
          data.fullName || ''
        );
        setSavedKey(key);
      });
    } catch (err) {
      formRef.current.setFieldValue('evaluated_attendant', '');
      generateFormErrors(err, formRef);
    }
  }, []);
  const { t } = useTranslation();

  const renderFormNewEmail = useCallback(() => {
    switch (currentMetric) {
      case 'CSAT':
        return (
          <>
            <Input
              name="attendant_key"
              title={`${t('titles.evaluatedKey')} *`}
              placeholder={t('placeholders.insertEvaluatedKey')}
              maxLength={8}
              onBlur={handleSearch}
              onChange={(e) => setCurrentKey(e.target.value)}
            >
              <FaSearch style={{ top: '40%' }} />
            </Input>
            <Input
              title={`${t('titles.evaluatedPerson')} *`}
              name="evaluated_attendant"
              placeholder={`${t('placeholders.evaluatedName')}...`}
              disabled
            />
            <Input
              title={`${t('titles.evaluatorName')} *`}
              name="evaluator_name"
              placeholder={`${t('placeholders.insertEvaluatorName')}...`}
            />
            <Input
              title={`${t('titles.evaluatorEmail')} *`}
              name="email"
              placeholder={`${t('placeholders.insertEvaluatorEmail')}...`}
            />
            <Input
              title={`${t('titles.tickerNumber')} *`}
              name="ticket_number"
              placeholder={`${t('placeholders.insertTicket')}...`}
            />
          </>
        );
      case 'CUSTOM':
        return (
          <>
            <Input
              name="attendant_key"
              title={`${t('titles.evaluatedKey')}`}
              placeholder={t('placeholders.insertEvaluatedKey')}
              maxLength={8}
              onBlur={handleSearch}
              onChange={(e) => setCurrentKey(e.target.value)}
            >
              <FaSearch style={{ top: '40%' }} />
            </Input>
            <Input
              title={`${t('titles.evaluatedPerson')}`}
              name="evaluated_attendant"
              placeholder={`${t('placeholders.evaluatedName')}...`}
              disabled
            />
            <Input
              title={`${t('titles.evaluatorName')} *`}
              name="evaluator_name"
              placeholder={`${t('placeholders.insertEvaluatorName')}...`}
            />
            <Input
              title={`${t('titles.evaluatorEmail')} *`}
              name="email"
              placeholder={`${t('placeholders.insertEvaluatorEmail')}...`}
            />
            <Input
              title={`${t('titles.tickerNumber')}`}
              name="ticket_number"
              placeholder={`${t('placeholders.insertTicket')}...`}
            />
          </>
        );
      default:
        return(  
          <>
            <Input
              title={`${t('titles.evaluatorName')} *`}
              name="evaluator_name"
              placeholder={`${t('placeholders.insertEvaluatorName')}...`}
            />
            <Input
              title={`${t('titles.evaluatorEmail')} *`}
              name="email"
              placeholder={`${t('placeholders.insertEvaluatorEmail')}...`}
            />
          </>
        );
    }
  }, [currentMetric, handleSearch, t]);


  return (
    <Form onSubmit={handleSubmit} ref={formRef}>
      {renderFormNewEmail()}
      <Button
        options={addEmailButtonOptions}
        title={t('titles.confirm')}
        type="submit"
      />
    </Form>
  );
}
