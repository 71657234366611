import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;

  gap: 3rem;

  width: 100%;
`;

export const Group = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

export const Label = styled.label`
  position: relative;
  padding-left: var(--metrics-m);

  cursor: pointer;

  input {
    position: absolute;
    z-index: -1;
    opacity: 0;

    &:checked > div {
      display: block;
    }
  }

  span {
    display: block;
    margin-top: 2px;

    color: var(--gray-50);
    font-weight: bolder;
    font-size: var(--font-size-smlr);
    font-family: inherit;
  }

  div {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    background: var(--gray-40);
    transition: background 100ms;
    border-radius: 50%;

    &:after {
      content: '';
      position: absolute;
      display: none;
      left: .4rem;
      top: .2rem;
      width: 5px;
      height: 8px;
      border: solid var(--white);
      border-width: 0 2px 2px 0;
      transition: background 250ms;
      transform: rotate(45deg);
    }
  }

  input:focus ~ div {
    box-shadow: 0 0 0 2px rgba(52,144,220,0.5);
  }

  input:checked ~ div {
    background: var(--blue-90);
    border-color: var(--blue-50);

    &:after {
      display: inherit;
    }
  }

  input:checked ~ span {
    color: var(--blue-90);
  }

  &:hover > input ~ div {
    background: var(--blue-90);
  }

  &:hover > input ~ span {
    color: var(--blue-90);
  }
`;
