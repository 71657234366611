import React, {
  createContext, useContext, useEffect, useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { formatDateEN, getNextDays } from '~/helpers/Dates';
import { notify } from '~/helpers/Toast';
import {
  getDashboard, getFilterCampaigns, getFilterAttendants
} from '~/services/Generic/Dashboard';

const DashboardContext = createContext({});

function DashboardContextProvider({ children }) {
  const { t } = useTranslation();
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [surveyOptions, setSurveyOptions] = useState([]);
  const [attendantsOptions, setAttendantsOptions] = useState([]);
  const [dataDashboard, setDataDashboard] = useState();
  const [filter, setFilter] = useState({
    campaign: '',
    attendant: '',
    attendant_key: '',
    type: '',
    dates: {
      start: formatDateEN(getNextDays(new Date(), -10)),
      end: formatDateEN(new Date())
    }
  });

  const history = useHistory();

  useEffect(() => {
    async function getInitialData() {
      const newFilters = { ...filter };
      delete newFilters.dates;

      setIsLoadingData(true);

      await getFilterCampaigns(newFilters).then((data) => {
        if (data.length === 0) {
          history.push('/');

          notify('warning', t('notify.getFilterCampaigns.warning'));
        }

        setSurveyOptions(data);
      });

      await getFilterAttendants().then((data) => {
        setAttendantsOptions(data);
      });
    }

    getInitialData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  useEffect(() => {
    async function getData() {
      setIsLoadingData(true);

      await getDashboard(filter).then((data) => {
        setDataDashboard(data);
        setIsLoadingData(false);
      });
    }

    getData();
  }, [filter]);

  return (
    <DashboardContext.Provider value={{
      isLoadingData,
      dataDashboard,
      surveyOptions,
      attendantsOptions,
      setFilter,
      filter
    }}
    >
      {children}
    </DashboardContext.Provider>
  );
}

const useDashboard = () => useContext(DashboardContext);

export { DashboardContextProvider, useDashboard };
