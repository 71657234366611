import axios from 'axios';

import { getUrlBackendOldLayer, getUrlBackendNewLayer } from '~/helpers/Url';

const isNewSurveyPage = document.URL.includes('pesquisa.suporte');

const api = axios.create({
  baseURL: isNewSurveyPage ? getUrlBackendNewLayer() : getUrlBackendOldLayer(),
  headers: {
    'Content-type': 'application/json',
  },
});

const getCampaign = async (hash, userIP) => api.get(`/answer/${hash}`, { params: { userIP } })
  .then((res) => ({
    ...res.data,
    greetingMessage: res.data?.greeting_message,
    metric: res.data?.type.toUpperCase(),
    answerReasons: res.data?.answer_reasons
  }))
  .catch((err) => err.response?.data);

const createAnswer = async (hash, payload) => {
  await api.post(`/answer/${hash}`, payload)
    .then((res) => {
      return res.data
    })

};

const getIPData = async () => axios.get('https://geolocation-db.com/json/')
  .then((res) => ({
    IPv4: res.data.IPv4
  }))
  .catch((err) => err.response?.data);

export { getCampaign, createAnswer, getIPData };
