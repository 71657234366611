import React from 'react';
import { useTranslation } from 'react-i18next';

import Modal from '~/components/User/Modal';
import TableQuestionDetail from '~/components/User/TableQuestionDetail';

export default function ModalQuestionDetail(
  {
    setIsModalOpen,
    rows,
    isLoadingData,
    type
  }
) {
  const handleCloseModal = () => {
    setIsModalOpen((prev) => !prev);
  };

  const { t } = useTranslation();
  const columns = [t('tableColumns.questions'), t('tableColumns.mean')];

  return (
    <>
      <Modal
        title={t('titles.campaignInfo')}
        options={{
          height: '95vh', width: '50vw', fontSize: '1rem', fontWeight: 'bolder'
        }}
        setVisible={handleCloseModal}
      >
        <TableQuestionDetail
          rows={rows}
          columns={columns}
          type={type}
          isLoadingData={isLoadingData}
        />
      </Modal>
    </>
  );
}
