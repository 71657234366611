import React from 'react';

import UserSkeleton from '~/boilerplates/User';
import Survey from '~/containers/User/CUSTOM/Survey';
import { SurveyContextProvider } from '~/contexts/CUSTOM/SurveyContext';
import { SurveyPrivilegesContextProvider } from '~/contexts/CUSTOM/SurveyPrivilegesContext';

export default function TeamsPage() {
  return (
    <UserSkeleton>
      <SurveyPrivilegesContextProvider>
        <SurveyContextProvider>
          <Survey />
        </SurveyContextProvider>
      </SurveyPrivilegesContextProvider>
    </UserSkeleton>
  );
}
