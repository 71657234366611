import React from 'react';
import { CopyBlock, dracula } from 'react-code-blocks';

import { Accordion, AccordionItem } from '~/components/User/Accordion';
import { getUrlBackend } from '~/helpers/Url';

import { Wrapper, HighlightText } from './styles';

const reactCode = `
import { CampaignCard } from 'iga-bbsurvey-react'

const Pesquisa = () => {
  return(
    <CampaignCard apiKey userName userKey />
  )
}
`;

const NPSFinish = ({ apiKey }) => {
  const curlText = `
  curl -X POST \\ \r
    'https://${getUrlBackend()}/ex/ratings' \\ \r
    --header 'APIToken:${apiKey}'  \\ \r
    --header 'Content-Type aplication/json' \\ \r
    --data-raw '{ \r
      "user_name":"Usuario", \r
      "email": "mail@bb.com.br", \r
      "sent_at": "2023-01-01 00:00:00", \r
      "answered_at": "2023-01-01 00:00:00", \r
      "value": 5, \r
      "observation": "Muito bom", \r
      "solved": true
    }'

  `;

  return (
    <Wrapper>
      <strong>
        Sua campanha de Pesquisa de satisfação foi criada com sucesso!
      </strong>
      <p>
        <strong>API Key:</strong>
        {apiKey}
      </p>

      <span>Veja abaixo a documentação para API e React.js:</span>

      <Accordion>
        <AccordionItem title="API">
          <HighlightText>
            <strong>Url Base:</strong>

            <p>https://survey.api.iga.servicos.bb.com.br/api/v1</p>

            <strong>API-docs:</strong>

            <p>
              https://survey.api.iga.servicos.bb.com.br/api-docs/#/Externo/post_api_v1_ex_ratings
            </p>

            <strong>Exemplo da aplicação:</strong>
            <CopyBlock
              text={curlText}
              theme={dracula}
              codeBlock
              showLineNumbers={false}
              language="bash"
            />
          </HighlightText>
        </AccordionItem>

        <AccordionItem title="React.js">
          <HighlightText>
            <strong>Documentação:</strong>
            <p>
              https://bbsurvey-react.iga.desenv.bb.com.br/?path=/story/survey-campaigncard--nps
            </p>

            <strong>Exemplo de codigo:</strong>

            <CopyBlock
              language="jsx"
              text={reactCode}
              theme={dracula}
              codeBlock
            />
          </HighlightText>
        </AccordionItem>
      </Accordion>
    </Wrapper>
  );
};

export { NPSFinish };
