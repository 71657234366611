import React, { useEffect, useRef } from 'react';

import { useField } from '@unform/core';

import { Field, ErrorMessage } from './styles';

export default function InputTextArea({ name, placeholder, ...props }) {
  const inputRef = useRef(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <>
      <Field
        ref={inputRef}
        defaultValue={defaultValue}
        placeholder={placeholder}
        {...props}
      />
      {error && <ErrorMessage> {error} </ErrorMessage>}
    </>
  );
}
