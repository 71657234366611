import styled from 'styled-components';

export const Wrapper = styled.table`

  width:100%;
  margin-top: 15px;

  overflow-y: auto;
  max-height: 600px;
  display: block;

  thead {
    position: sticky;
    top: 0;
  }

  tr {

    &:not(:last-child) {
      td {
        border-bottom: 2px solid var(--gray-10);
      }
    }
  }

  th {

    &:first-child {
      width: 10%;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    &:nth-child(2) {
      width: 18%;
    }

    &:nth-child(3) {
      width: 22%;
    }

    &:nth-child(4) {
      width: 22%;
    }

    &:nth-child(5) {
      width: 18%;
    }

    &:last-child {
      width: 10%;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }

  th, td {
    padding: .95rem 1rem;
  }

  th {
    color: var(--blue-90);
    background-color: var(--blue-10);
    font: 500 .825rem Lexend, sans-serif;
    text-align: left;
  }

  td {
    color: var(--gray-60);
    font-size: .75rem;
  }


  td {

    &:first-child {
      width: 10%;
      max-width: 50px;
      word-wrap: break-word;
    }

    &:nth-child(2) {
      max-width: 168px;
      word-wrap: break-word;
    }
  }
`;

export const SortButton = styled.button.attrs(() => ({
  type: 'button'
}))`
  position: absolute;

  height: .9rem;
  width: .9rem;

  transform: translateX(12px);

  svg {
    fill: var(--gray-40);

    height: .9rem;
    width: .9rem;
  }
`;

export const ButtonAction = styled.button.attrs(() => ({
  type: 'button'
}))`
  position: relative;
  width: 24px;
  height: 24px;

  > svg {
    transition: fill .3s;
    vertical-align: middle;

    width: 18px;
    height: 18px;
  }

  &:hover {
    svg {
      fill: var(--blue-50);
    }

    span {
      visibility: visible;
    }
  }
`;
