import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  width: 253px;
  min-height: 207px;

  box-shadow: 0px 8px 20px 7px rgba(106, 106, 106, 0.06);
  border-radius: 8px;

  padding: var(--metrics-m);
  cursor: pointer;
  overflow-wrap: break-word;
`;

export const AttendantLabel = styled.p`
  display: inline-flex;
  font-size: var(--font-size-smlr);

  color: var(--gray-50);
`;

export const RankingPill = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  width: 40px;
  height: 23px;
  float: right;

  background-color: var(--gray-10);

  font-size: var(--metrics-smlr);
  font-weight: var(--font-weight-xb);
  color: var(--blue-50);
  letter-spacing: 2px;

  border-radius: 8px;
`;

export const AttendantName = styled.p`
  color: var(--blue-90);
  font-size: var(--font-size-m);
  font-weight: var(--font-weight-xb);

  margin-top: calc(var(--metrics-sm) - 4px);
  line-height: var(--metrics-sm);
`;

export const AttendantKey = styled.p`
  color: var(--gray-50);
  font-size: var(--font-size-smlr);
  font-weight: var(--font-weight-xb);

  margin-top: calc(var(--metrics-sm) - 4px);
  line-height: var(--metrics-sm);
`;

export const ScoreWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;

  padding-top: var(--metrics-sm);
  border-top: 1px dashed var(--gray-10);
`;

export const Score = styled.span`
  display: flex;
  align-items: center;
  gap: 1rem;

  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-xb);

  ${(p) => (p.status === 'down' ? css`
    color: var(--red);

    div {
      background-color: var(--red-10);

      svg {
        fill: var(--red);
      }
    }
  ` : css`
    color: var(--green);

    div {
      background-color: var(--green-10);

      svg {
        fill: var(--green);
      }
    }
  `)}

  letter-spacing: .5px;
`;

export const Caret = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  min-width: 32px;
  height: 32px;
  border-radius: 50%;

  svg {
    width: 12px;
    height: 12px;
  }
`;

export const ModalWrapper = styled.div`
  margin-top: var(--metrics-sm);

  display: flex;
  flex-direction: column;

  gap: 1rem;

  color: var(--blue-90);
`;
