import React, { useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { flushSync } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { AiOutlineFileText } from 'react-icons/ai';
import { BiStopCircle } from 'react-icons/bi';
import {
  FiEdit3,
  FiCopy,
  FiPauseCircle,
  FiPlayCircle,
  FiSend,
} from 'react-icons/fi';
import {
  IoIosArrowDown,
  IoIosArrowUp,
  IoMdStopwatch,
  IoMdPeople,
  IoMdInformationCircleOutline,
} from 'react-icons/io';
import { MdSummarize } from 'react-icons/md';
import { RiKey2Fill } from 'react-icons/ri';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { omit } from 'lodash';

import Tooltip from '~/components/User/Tooltip';
import { formatDatePT } from '~/helpers/Dates';
import { notify } from '~/helpers/Toast';
import { getCampaignMoreInfos } from '~/services/Generic/Survey';

import { LoaderTable } from '../Loader';
import {
  Wrapper,
  SortButton,
  Actions,
  ButtonAction,
  Status,
  Timer,
} from './styles';

export default function TableSurvey({
  columns,
  rows,
  emptyText,
  isLoadingData,
  setFilter,
  filterFields,
  setCampaignStatus,
  setPrivilegesData,
  modalResendEmail,
  modalMoreInfos,
  modalQuestionDetail,
  resendApiKey,
  modalNoYes,
  currentMetric,
}) {
  const { t } = useTranslation();
  const { user } = useSelector((store) => store.boilerplate);
  const filterKeys = filterFields && Object.keys(filterFields);
  const status = {
    'Em Andamento': 'var(--blue-40)',
    'Aguardando publicação': 'var(--yellow-80)',
    Pausada: 'var(--yellow-80)',
    Finalizada: 'var(--green)',
  };
  const csvLink = useRef(null);
  const [transactionData, setTransactionData] = useState([]);

  const showIcons = (owner, fixed_campaign) => {
    if (user?.isRoot) {
      return true;
    }
    return owner && !fixed_campaign;
  };

  const exportCSV = async (id) => {
    notify('warning', t('notify.exportCSV.waiting'));

    const data = await getCampaignMoreInfos(id).then((output) => output);
    const isCSAT = currentMetric === 'CSAT';
    const csvColumns = [
      t('csvColumns.answeredIn'),
      t('csvColumns.grade'),
      t('csvColumns.observation'),
      t('csvColumns.evaluatorName'),
      t('csvColumns.evaluatorEmail'),
      t('csvColumns.description'),
      t('csvColumns.othersAnswers'),
    ];
    if (isCSAT) {
      csvColumns.splice(3, 0, t('csvColumns.wasSoved'));
      csvColumns.splice(6, 0, t('csvColumns.answerReason'));
      csvColumns.unshift(t('csvColumns.attendant'), t('csvColumns.ticket'));
    }

    const csvRows = data?.reduce((acc, value) => {
      const newValue = {
        ...value,
        observation: value?.observation?.replace(/\n/g, '').trim(),
        solved: value.solved ? 'Sim' : 'Não',
        answered_at: formatDatePT(new Date(value.answered_at)),
        custom_answers: value.custom_answers
          ?.map(
            (ans) =>
              `- Questão: ${ans.question} Resposta: ${ans.answer ?? ' '} Motivo: ${
                ans.answer_reason ?? ' '
              }`
          )
          .join(' ')
          .trim(),
      };

      const omitArray = ['order', 'id', 'sent_at'];
      if (!isCSAT)
        omitArray.push(
          'attendant_name',
          'iteration_code',
          'solved',
          'answered_reason'
        );
      const omitValue = omit(newValue, omitArray);
      const newAcc = Array.from(
        Object.values(omitValue),
        (item) => item || '-'
      );

      acc.push(Object.values(newAcc));

      return acc;
    }, []);

    if (csvRows.length > 0) {
      flushSync(() => {
        setTransactionData([csvColumns, ...csvRows]);
      });

      csvLink.current.link.click();
      notify('success', t('notify.exportCSV.success'));
    } else {
      notify('error', t('notify.exportCSV.error'));
    }
  };

  const statusLanguage = {
    'Em Andamento': t('labels.inProgress'),
    'Aguardando publicação': t('labels.waiting'),
    Pausada: t('labels.paused'),
    Finalizada: t('labels.finalized'),
  };

  return (
    <Wrapper cellSpacing="0">
      <thead>
        <tr>
          <th style={{ width: rows?.length === 0 ? '20%' : '7%' }}>#</th>
          <th style={{ width: '23%' }}>
            {t('tableHeaders.campaign')}
            <SortButton onClick={() => setFilter(1)} id="sort-btn-1">
              {filterFields[filterKeys[1]] ? (
                <IoIosArrowUp />
              ) : (
                <IoIosArrowDown />
              )}
            </SortButton>
          </th>
          <th style={{ width: '15%' }}>
            {t('tableHeaders.endsIn')}
            <SortButton onClick={() => setFilter(2)} id="sort-btn-2">
              {filterFields[filterKeys[2]] ? (
                <IoIosArrowUp />
              ) : (
                <IoIosArrowDown />
              )}
            </SortButton>
          </th>
          <th style={{ width: '15%' }}>
            Status
            <SortButton onClick={() => setFilter(3)} id="sort-btn-3">
              {filterFields[filterKeys[3]] ? (
                <IoIosArrowUp />
              ) : (
                <IoIosArrowDown />
              )}
            </SortButton>
          </th>
          <th style={{ width: '10%' }}>{t('tableHeaders.howManyAnswered')}</th>
          <th style={{ width: '10%' }}>{t('tableHeaders.emailsSent24h')}</th>
          <th> </th>
        </tr>
      </thead>
      <tbody>
        {isLoadingData ? (
          <tr>
            {columns.map(() => (
              <td key={Math.random()}>
                <LoaderTable />
              </td>
            ))}
          </tr>
        ) : (
          <>
            {rows?.length === 0 && (
              <tr>
                <td>{emptyText}</td>
              </tr>
            )}
            {rows &&
              rows.map((row) => (
                <tr key={Math.random()}>
                  <td>
                    {row.status !== 'Finalizada' && row.next_end && (
                      <Timer>
                        <IoMdStopwatch />
                        <Tooltip
                          text={`${
                            new Date() < new Date(row.end_date)
                              ? t('tooltips.willExpire')
                              : t('tooltips.hasExpided')
                          }
                             ${row.expire_date}`}
                          color="var(--yellow-80)"
                        />
                      </Timer>
                    )}
                    {row.order}
                  </td>
                  <td>{row.name}</td>
                  <td>{row.end_date_pt}</td>
                  <Status color={status[row.status]}>
                    <span />
                    <strong>{statusLanguage[row.status]}</strong>
                  </Status>
                  <td>
                    {row.total_answered} {t('titles.of')} {row.total_ratings}{' '}
                  </td>
                  <td>{row.count_sent_emails}</td>
                  <Actions>
                    {(row.owner || user.isRoot) && (
                      <>
                        <ButtonAction
                          data-test="usuários"
                          onClick={() =>
                            setPrivilegesData(row.id, row.fixed_campaign)}
                        >
                          <Tooltip text={t('tooltips.users')} />
                          <IoMdPeople />
                        </ButtonAction>
                      </>
                    )}
                    {showIcons(row.owner, row.fixed_campaign) && (
                      <>
                        <Link to={`/campaign/copy/${row.id}`}>
                          <ButtonAction>
                            <Tooltip text={t('tooltips.duplicateCampaing')} />
                            <FiCopy />
                          </ButtonAction>
                        </Link>
                        {row.status === 'Em Andamento' && (
                          <ButtonAction
                            data-test="re-enviar-emails"
                            onClick={() => modalResendEmail(row.id)}
                          >
                            <Tooltip text={t('tooltips.resendEmais')} />
                            <FiSend />
                          </ButtonAction>
                        )}
                        {row.status !== 'Finalizada' && (
                          <>
                            <Link to={`/campaign/edit/${row.id}`}>
                              <ButtonAction>
                                <Tooltip text={t('tooltips.editCampaign')} />
                                <FiEdit3 />
                              </ButtonAction>
                            </Link>
                            <ButtonAction
                              onClick={() =>
                                setCampaignStatus(
                                  row.id,
                                  row.status,
                                  row.start_date
                                )}
                              id="pause-campaign-btn"
                            >
                              <Tooltip
                                text={
                                  row.status === 'Pausada'
                                    ? t('buttons.startCampaign')
                                    : t('buttons.pauseCampaign')
                                }
                              />
                              {row.status === 'Pausada' ? (
                                <FiPlayCircle />
                              ) : (
                                <FiPauseCircle />
                              )}
                            </ButtonAction>
                            <ButtonAction
                              onClick={() => modalNoYes(row.id)}
                              id="finish-campaign-btn"
                            >
                              <Tooltip text={t('buttons.closeCampaign')} />
                              <BiStopCircle />
                            </ButtonAction>
                          </>
                        )}
                      </>
                    )}
                    {(row.owner || user.isRoot || user.isQuality) && (
                      <>
                        <ButtonAction
                          onClick={() => modalMoreInfos(row.id)}
                          data-test="informações-campanha"
                        >
                          <Tooltip text={t('tooltips.informations')} />
                          <IoMdInformationCircleOutline />
                        </ButtonAction>
                        <ButtonAction
                          onClick={() => exportCSV(row.id)}
                          data-test={`export-csv-${row.id}`}
                        >
                          <Tooltip text={t('tooltips.exportCSV')} />
                          <AiOutlineFileText />
                        </ButtonAction>
                      </>
                    )}
                    {(row.owner || user.isRoot) && row.metric === 'api' && (
                      <>
                        <ButtonAction
                          data-test="enviar-api-key"
                          onClick={() => resendApiKey(row.id)}
                        >
                          <Tooltip text={t('tooltips.sendApiKey')} />
                          <RiKey2Fill />
                        </ButtonAction>
                      </>
                    )}

                    {(row.owner || user.isRoot) && (
                      <>
                        <ButtonAction
                          onClick={() => modalQuestionDetail(row.id)}
                          data-test="resumo-perguntas"
                        >
                          <Tooltip text={t('tooltips.questionSummary')} />
                          <MdSummarize />
                        </ButtonAction>
                      </>
                    )}
                  </Actions>
                </tr>
              ))}
          </>
        )}
      </tbody>
      <CSVLink
        data={transactionData}
        filename="transactions.csv"
        className="hidden"
        ref={csvLink}
        target="_blank"
        separator=";"
      />
    </Wrapper>
  );
}
