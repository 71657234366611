import React, { useEffect, useState } from 'react';

import HeaderEnImage from '~/assets/images/generic/header-en.jpg';
import HeaderPtImage from '~/assets/images/generic/header-mail-preview.png';

import { Wrapper, CardMail, Header, Feedback } from './styles';

const languages = {
  pt: {
    header: HeaderPtImage,
    buttonLabel: 'Responder Pesquisa',
    feedback: 'Seu feedback é muito importante e leva só alguns segundos.',
    footer: 'Banco do Brasil S/A',
    evaluator_name: '{{nome_avaliador}}',
    iteraction_number: '{{numero_ocorrencia}}',
  },
  en: {
    header: HeaderEnImage,
    buttonLabel: 'Take survey',
    feedback: 'We value your feedback',
    footer: 'Banco do Brasil',
    evaluator_name: '{{evaluator_name}}',
    iteraction_number: '{{iteraction_number}}',
  },
};

export default function PreviewMail() {
  const [data, setData] = useState({ language: 'pt' });

  useEffect(() => {
    const { template_email, survey } = JSON.parse(
      localStorage.getItem('wizardData')
    );
    const newData = {
      title: template_email.title.replace(
        languages[survey.language].evaluator_name,
        'João'
      ),
      body: template_email.body.replace(
        languages[survey.language].iteraction_number,
        '1555432'
      ),
      signature: template_email.signature[0],
      signature_2: template_email.signature[1],
      language: survey.language,
    };

    setData(newData);
  }, []);

  return (
    <Wrapper>
      <Header src={languages[data.language]?.header} />
      <CardMail>
        <h1>{data?.title}</h1>
        <p>{data?.body}</p>
        <button type="button">{languages[data.language]?.buttonLabel}</button>
        <Feedback>{languages[data.language]?.feedback}</Feedback>
      </CardMail>
      <strong>{languages[data.language]?.footer}</strong>
      <span>{data?.signature}</span>
      <span>{data?.signature_2}</span>
    </Wrapper>
  );
}
