import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: nowrap;

  gap: 1rem;

  justify-content: space-between;
`;

export const Label = styled.label`
  width: 50%;
  min-height: 80px;
  height: 106px;

  padding: var(--metrics-m);

  position: relative;
  cursor: pointer;

  border: 2px solid var(--gray-30);
  border-radius: 8px;

  color: var(--blue-50);
  font-size: var(--font-size-sm);

  strong {
    display: inline-block;
    margin-left: 10px;
  }

  svg {
    width: 24px;
    height: 24px;

    transition: filter .3s;
  }
`;

export const CheckWrapper = styled.div`
  display: inline-block;
  margin-left: auto;

  position: absolute;

  right: 0;
  transform: translateX(-20px);
`;

export const WrapperContent = styled.div`
  display: flex;
  align-items: center;
  margin-top: 15px;
`;

export const Field = styled.input`
  position: absolute;
  visibility: hidden;
  cursor: pointer;

  &:not(:checked) {
    + label {
      &:after {
        opacity: 0;
      }

      svg {
        filter: grayscale(.7);
      }

      #check-mark {
        visibility: hidden;
      }
    }
  }

  &:checked {
    + label {
      &:after {
        opacity: 1
      }

      svg {
        filter: inherit;
      }
    }
  }

  + label {
    &:after {
      content: "";
      position: absolute;

      width: 100%;
      height: 100%;
      top: -2px;
      left: -2px;

      border: 2px solid var(--blue-60);
      border-radius: 8px;
    }

    &:hover {
      svg {
        filter: inherit;
      }
    }
  }
`;

export const ErrorMessage = styled.p`
  color: var(--red);
  margin-top: 5px;
  font-size: 10px;
`;
