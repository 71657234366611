import React from 'react';

import { Wrapper } from './styles';

export default function ProgressBar({ progress }) {
  return (
    <>
      <Wrapper progress={progress} />
    </>
  );
}
