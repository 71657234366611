const messages = {
  pt: {
    translations: {
      titles: {
        headerAdmin: 'Menu Administrativo',
        header: 'BB Survey',
        headerConfigCampaign: 'Vamos configurar a sua campanha',
        stepSetMetric: 'Defina a métrica',
        stepSetDistribution: 'Distribuição',
        stepSetCampaign: 'Campanha',
        stepFinish: 'Concluído',
        average: 'Média',
        histogram: 'Histograma',
        votesWith5Grades: 'Votos com 5 notas',
        votesWith7Grades: 'Votos com 7 notas',
        votesWith10Grades: 'Votos com 10 notas',
        evaluation: 'Avaliação',
        evaluations: 'Alaliações',
        goalComparison: 'Comparação de Métricas',
        overview: 'Visão Geral',
        detractors: 'Detratores',
        neuters: 'Neutros',
        promoters: 'Promotores',
        listSentEmails: 'Lista de Email Enviados',
        whoCanView: 'Informe quem pode visualizar a campanha',
        evaluatedKey: 'Chave da Pessoa Avaliada',
        evaluatedPerson: 'Pessoa Avaliada',
        evaluatorName: 'Nome Avaliador',
        evaluatorEmail: 'Email Avaliador',
        tickerNumber: 'Número do Bilhete',
        confirm: 'confirmar',
        campaignInfo: 'Informações da Campanha',
        noAttendantPeriod: 'Não há atendentes avaliados nesse período',
        period: 'Período',
        cleanFilters: 'Limpar Filtro',
        averageClassification: 'Classificação média',
        attendants: 'Atendentes',
        attendant: 'Atendente',
        key: 'chave',
        grade: 'Nona',
        evaluatedAttendant: 'Atendente Avaliado',
        gradeHistory: 'Histórico de Notas',
        campaigns: 'Campanhas',
        seeMore: 'Ver mais',
        customQuestions: 'Questões Personalizadas',
        followCampaignProgess: 'Acompanhe o andamento das campanhas',
        errorComunicateAPI: "Erro ao comunicar com API's",
        newEmail: 'Novo E-mail',
        resendEmail: 'Reenvio de Email',
        of: 'de',
        warning: 'Aviso',
        customCampaign: 'Campanha Personalizada',
        customCampaigns: 'Campanhas Personalizadas',
        docs: 'Documentações',
      },
      messages: {
        hello: 'Olá {{name}}',
        welcomeHeader: 'Bem vindo ao BB Survey!',
        welcomeP1: 'Percebi que você é novo por aqui',
        welcomeP2: 'vamos',
        welcomeP3: 'criar uma campanha?',
        welcomeP4: 'mas você não possui',
        welcomeP5: 'papeis necessários',
        welcomeP6: 'para criar campanhas',
        csatExample:
          'Ex: O uber sempre pede ao passageiro que o avalie após uma viagem.',
        csatQuestion: 'O que você achou do atendimento do assessor Groot?',
        csatText:
          'Métrica utilizada para mensurar o nível de satisfação dos seus clientes em relação à sua empresa e em diferentes etapas da sua jornada.',
        npsExample: 'Ex: Grau de satisfação de um serviço recebido.',
        npsQuestion:
          'Em uma escala de 0 a 5 ou de 0 a 10, quais as chances de você recomendar os nossos serviços a familiares e amigos?',
        npsText:
          'Métrica que utiliza dados quantitativos e qualitativos para mensurar quanto os seus clientes estão satisfeitos com a experiência que tiveram, ou estão tendo, com a sua empresa.' +
          ' Esse indicador também visa identificar quais são as chances dos seus consumidores indicarem os seus produtos e/ou serviços para outras pessoas.',
        cesExample:
          'Ex: O grau de dificuldade para a resolução de determinado problema.',
        cesQuestion: 'O suporte técnico facilitou a resolução do meu problema?',
        cesText:
          'Métrica utilizada para avaliar o sucesso e a satisfação do consumidor. A ideia é mensurar, através de uma pontuação, quanto de esforço ele teve que aplicar para utilizar o seu produto ou serviço.',
        customExample: 'Ex: O sentimento em relação à um atendimento recebido.',
        customQuestion: 'O que você achou do nosso atendimento?',
        customText:
          'Nessa modalidade, o administrador de campanhas cria questionários personalizados com perguntas abertas, oferecendo 5 maneiras de resposta: notas, emojis, estrelas, alternativas (sim/não) e texto livre.',
        emptyText: 'Nenhum dado foi adicionado.',
        gettingUserDatas: 'Obtendo dados do usuário',
        checkingUserPermissions: 'Verificando permissões do usuário',
        observation: 'Observação',
        preview:
          'é possível retornar aos passos anteriores caso necessite ajustar algum dado.',
        yourApikey: 'Sua Api Key:',
        yourCampaign: 'Sua campanha',
        was: 'foi',
        updated: ' atualizada',
        created: ' criada',
        withSuccess: 'com sucesso!',
        noData: 'Não há dados no período selecionado',
        noAttendants: 'Não foram encontrados atendentes avaliados',
        noAnswer: 'Nenhuma resposta encontrada',
        closeCampaign: 'Confirma o encerramento da campanha?',
        noCampaign: 'Não há campanhas cadastradas',
        addCertificate:
          'Possívelmente, será necessário adicionar os certificados TLS/SSL.',
        accept:
          'para aceitar a exceção dos certificados. Certifique-se também' +
          'de aceitar as permissões de abertura de pop up do seu navegador. Após aceitar,',
        thisPortal: 'este portal',
        callContact:
          'Caso o problema persista, entre em contato com o suporte técnico.',
        csvEmailWarining:
          'Há alguma linha no CSV que não contém e-mail correto.',
        csvSizeWarning:
          'É necessário que o CSV possua linhas preenchidas nas 4 colunas.',
        csvEmailError: 'O campo de e-mail em um dos dados não está correto.',
        importEmails:
          'Falha na importação, e-mails duplicados foram encontrados no seu CSV,' +
          ' remova as linhas duplicadas e tente novamente.',
        noPerson:
          'Para prosseguir é necessário inserir pelo menos 1 pessoa a ser avaliada na campanha.',
        noKey:
          'Para prosseguir é necessário que Chave da pessoa avaliada esteja preenchida.',
        tagMessage: 'A tag já foi adicionada',
        alreadyCampaing: 'Usuário não possui campanhas cadastradas.',
        createdCampaign: 'Campanha criada com sucesso.',
        modalWarning:
          'As chaves dos atentendes importadas via CSV serão validadas após a' +
          ' criação da campanha. Caso haja inconsistências nas chaves' +
          ' informadas, um e-mail será encaminhado para o usuário.',
        isMandatoryQuestion: 'Pelo menos uma resposta deve ser obrigatória',
        infoQuestion:
          'Você pode adicionar até {{maxQuestions}} questões ao seu formulário',
      },
      buttons: {
        yes: 'Sim',
        no: 'Não',
        newCampaign: 'Nova Campanha',
        letStart: 'Vamos começar!',
        back: 'Voltar',
        foward: 'Avançar',
        addQuestion: 'Adicionar questão',
        addEvaluatorName: 'adicionar nome avaliador',
        removeEvaluatorName: 'remover nome avaliador',
        addInteractionNumber: 'adicionar número ocorrência',
        removeInteractionNumber: 'remover número ocorrência',
        campaing: 'Campanha',
        copyCampaign: 'Copiar Campanha',
        updateCampaign: 'Atualizar Campanha',
        createCampaign: 'Criar Campanha',
        pauseCampaign: 'Pausar Campanha',
        startCampaign: 'Iniciar Campanha',
        closeCampaign: 'Encerrar Campanha',
        search: 'buscar',
        newEmail: 'novo email',
        loadingMore: 'Carregar mais',
        first: 'Primeira',
        last: 'Última',
        clickHere: 'Clique aqui',
        refresh: 'Recarregue',
        send: 'Enviar',
      },

      tooltips: {
        informations: 'Informações',
        exportCSV: 'Exportar CSV',
        sendApiKey: 'Enviar Api Key (E-mail)',
        questionSummary: 'Resumo perguntas',
        willExpire: 'Expira em',
        hasExpided: 'Expirou há',
        users: 'Usuários',
        duplicateCampaing: 'Duplicar Campanha',
        resendEmais: 'Re-enviar E-mails',
        editCampaign: 'Editar Campanha',
        detail: 'Detalhar',
        overallAvgPeriod: 'Média geral do período',
        add: 'Adicionar',
        selectAll: 'Selectionar todos',
      },
      links: {
        overview: 'Visão Geral',
        attendants: 'Atendentes',
        campaigns: 'Campanhas',
        documentations: 'Documentações',
        exampleCSV: 'exemplo csv',
        importCSV: 'importar csv',
        newEmail: 'novo email',
        generalInformation: 'Infomações Gerais',
        docAPI: 'API',
      },
      pills: {
        totallyDisagree: 'Discordo Totalmente',
        disagree: 'Discordo',
        partiallyDisagree: 'Discordo Parcialmente',
        neutral: 'Neutro',
        partiallyAgree: 'Concordo Parcialmente',
        agree: 'Concordo',
        totallyAgree: 'Concordo Totalmente',
      },

      csatEmojis: {
        veryUnsatisfied: 'Muito Insatisfeito',
        unsatisfied: 'Insatisfeito',
        neutral: 'Neutro',
        satisfied: 'Satisfeito',
        verySatisfied: 'Muito Satisfeito',
      },

      tableHeaders: {
        question: 'Questão',
        answer: 'Resposta',
        evaluatedKey: 'Chave da pessoa avaliada',
        evaluatedPerson: 'Pessoa Avaliada',
        evaluatorName: 'Nome Avaliador',
        evaluatorEmail: 'Email Avaliador',
        tickerNumber: 'Número do Bilhete',
        campaign: 'Campanha',
        endsIn: 'Finaliza em',
        howManyAnswered: 'Quantos Responderam',
        emailsSent24h: 'E-mails enviados (24hrs)',
        customQuestions: 'Questões personalizadas',
        noAnsers: 'Pesquisa sem respostas.',
        missingAnswer: 'Faltam Responder',
      },

      tableColumns: {
        answeredDate: 'Data Resposta',
        avaliations: 'Avaliações',
        answers: 'Respostas',
        attendant: 'Atendente',
        campaign: 'Campanha',
        description: 'Descrição',
        email: 'Email',
        evaluator: 'Avaliador',
        key: 'Chave',
        mean: 'Média',
        meanGrade: 'Nota Média',
        name: 'Nome',
        notResolved: 'Não Resolvidas',
        grade: 'Nota',
        observations: 'Observações',
        percentMean: 'Média (%)',
        percentAnswered: 'Respondidas (%)',
        questions: 'Perguntas',
        resolved: 'Resolvido',
        sendDate: 'Data Envio',
        sendEmail: 'Enviar Email',
        ticket: 'ID Bilhete (INT)',
        ticketNumber: 'Número do Bilhete',
        total: 'Total',
        interaction: 'Interação',
      },

      labels: {
        campaignLanguage: 'Informe o idioma da campanha *',
        campaignName: 'Informe o nome da campanha *',
        campaignQuestion: 'Informe a pergunta que será feita ao avaliador *',
        campaignGreeting: 'Informe a mensagem de agradecimento',
        campaignPeriod: 'Informe o período da pesquisa *',
        campaignAppearence: 'Aparência da avaliação',
        appearenceFiveStars: 'Com 5 estrelas',
        appearenceFiveEmojis: 'Com 5 emojis',
        appearenceFiveGrades: 'Com 5 notas',
        appearenceSevenGrades: 'Com 7 notas',
        appearenceTenGrades: 'Com 10 notas',
        appearenceFiveChoices: 'Com até 5 alternativas',
        appearenceYesNo: 'Com Sim ou Não',
        appearenceText: 'Texto livre',
        portuguese: 'Português',
        english: 'Inglês',
        templateEmailTitle: 'Informe o título destaque do e-mail',
        templateEmailBody: 'Informe o texto destaque do e-mail',
        templateEmailSign: 'Informe a assinatura do e-mail',
        averageGradeDay: 'Nota média do dia:',
        averageGrade: 'Nota média',
        numberOfVotes: 'Quantidade de votos',
        votes: 'Votos',
        grade: 'Nota',
        campaignOwner: 'Dono da campanha',
        member: 'Membro',
        inProgress: 'Em Progresso',
        waiting: 'Aguardando publicação',
        finalized: 'Finalizada',
        paused: 'Pausada',
        notAnswered: 'Não Respondido',
        bugReport: 'Estou com problemas',
      },

      placeholders: {
        attendant: 'Atendente',
        campaign: 'Campanha',
        campaignName: 'Ex: Juntos somos mais fortes',
        campaignGreeting: 'Ex: Ficamos agradecidos pela avaliação',
        choosenQuestion: 'Escolha como essa questão será respondida',
        insertKey: 'Insira a chave...',
        insertSearchKey: 'Insira a chave de busca...',
        insertEvaluatedKey: 'Insira a chave da Pessoa Avaliada',
        evaluatedName: 'Nome da pessoa avaliada',
        insertEvaluatorName: 'Insira o nome do avaliador',
        insertEvaluatorEmail: 'Insira o email do avaliador',
        insertTicket: 'Insira o número do bilhete',
        signature: 'Ex: Diretoria de Tecnologia',
        signature2: 'Central de Serviços',
        rateCampaign: 'Ex: Avalie a campanha de hoje',
      },

      questionExample: {
        csat: 'Ex: O que você achou do último atendimento?',
        nps: 'Ex: Qual a chance de você recomendar o suporte técnico do BB para um colega?',
        ces: 'Ex: Quão fácil foi realizar esse processo com a empresa?',
      },

      notify: {
        exportCSV: {
          warning: 'Em processo de exportação do arquivo.',
          success: 'Exportação de arquivo concluída.',
          error: 'Essa pesquisa não possuí respostas.',
          waiting: 'Preparando arquivo',
        },
        getFilterCampaigns: {
          warning: 'Usuário não possui campanhas cadastradas.',
        },
        createCampaign: {
          success: 'Campanha criada com sucesso.',
        },
        editCampaign: {
          success: 'Campanha atualizada com sucesso.',
        },
        sendEmails: {
          warning: 'Os e-mails estão em processo de envio.',
        },
        sendApiKeyByEmail: {
          warning: 'Api Key sendo enviada por e-mail.',
        },
        createPrivilege: {
          success: 'Permissão adicionada com sucesso.',
        },
        editUserPrivileges: {
          success: 'Usuário editado com sucesso.',
        },
        deleteUserPrivileges: {
          success: 'Usuário removido com sucesso.',
        },
        executeTask: {
          warning: 'Task {{url}} sendo executada',
        },
      },

      csvColumns: {
        answeredIn: 'Respondido em',
        answerReason: 'Motivo da Resposta',
        attendant: 'Atendente',
        description: 'Descrição',
        evaluatorName: 'Nome Avaliador',
        evaluatorEmail: 'Email Avaliador',
        grade: 'Nota',
        observation: 'Observação',
        othersAnswers: 'Outras Respostas',
        ticket: 'Bilhete',
        wasSoved: 'Foi Resolvido',
      },

      bugReport: {
        title: 'Reportar Erro',
        description: 'O que está acontecendo?',
        areaPlaceholder:
          '- Descreva detalhadamente o erro ocorrido\n- Informe os passos que seguiu até o erro ocorrer ',
        characters: 'Caracteres',
        sendText:
          'Ao Clicar em enviar será aberto uma issue para análise do time de desenvolvimento.',
        teamText:
          ' O time poderá entrar em contato, preferencialmente via teams, para esclarecimento e/ou testes.',
        screenshot: 'Captura de tela',
        cancel: 'Cancelar',
        send: 'Enviar',
        issueLabel:
          'Foi registrado a issue {{issueNumber}} para análise do seu problema.',
        linkText:
          'Voce pode acompanhar o atendimento dessa issue no aplicativo Fontes através do link abaixo*:',
        accessText: '* Necessario acesso ao papel ALMFDIGA para acessar o link',
        loadingText: 'Aguarde... coletando dados.',
      },

      infoModal: {
        manager: 'Gestor',
        team: 'Equipe',
        developer: 'Desenvolvedor',
        system: 'Sistema',
        release: 'Release de software',
      },

      schemas: {
        trim: 'Não deve haver espaços no começo e no fim do texto.',

        attendant_key: {
          required: 'A chave da pessoa avaliada é um campo obrigatório',
        },

        evaluated_attendant: {
          required: 'O nome da pessoa avaliada é um campo obrigatório',
        },
        evaluator_name: {
          required: 'O nome do avaliador é um campo obrigatório',
        },
        email: {
          required: 'O email do avaliador é um campo obrigatório',
          email: 'O email inserido é inválido',
        },

        ticket_number: {
          required: 'O número do bilhete do atendimento é um campo obrigatório',
        },

        campaignName: {
          required: 'O nome da campanha é um campo obrigatório',
        },

        custom_questions: {
          required: 'Pergunta personalizada é obrigatória',
        },

        question: {
          required:
            'A pergunta que será feita ao avaliador é um campo obrigatório',
        },

        appearance: {
          required: 'O tipo de resposta é um campo obrigatório',
        },

        choices: {
          required: 'As alternativas são campos obrigatórios',
        },

        startDate: {
          required: 'É necessário inserir uma data de início para campanha',
        },

        endDate: {
          required: 'É necessário inserir uma data final para campanha',
        },

        option: {
          required: 'A aparência da avaliação é um campo obrigatório',
        },

        title: {
          required: 'O texto destaque do e-mail é um campo obrigatório',
        },

        body: {
          required: 'O corpo do e-mail é um campo obrigatório',
        },

        signature: {
          required: 'A assinatura do e-mail é um campo obrigatório',
        },

        key: {
          required: 'A chave do membro é um campo obrigatório',
          min: 'A chave deve conter {{min}} caracteres',
          max: 'A chave deve conter {{max}} caracteres',
          matches:
            'A chave deve iniciar com um caractere A F C T ou Z e possuir 7 números de 0 a 9',
        },
      },
    },
  },
};

export { messages };
