import { createInstance } from '@datapunt/matomo-tracker-react';

import { getMatomo } from '~/helpers/Url';

const data = getMatomo();

const instance = createInstance({
  urlBase: data.urlBase,
  siteId: data.siteId,
  heartBeat: {
    active: true,
    seconds: 15,
  },
  linkTracking: true,
  enableLinkTracking: true,
  configurations: {
    setSecureCookie: true,
    // setCookieSameSite: 'None',
    // async: true,
    // defer: true,
  },
});

export default instance;
