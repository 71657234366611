import React, { useContext, createContext, useState } from 'react';
import { MdOutlineHelp } from 'react-icons/md';

import Button from '../Button';
import { ItemList, List, Wrapper } from './styles';

const MenuContext = createContext({});

const Menu = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <MenuContext.Provider value={{ isOpen, setIsOpen }}>
      <Wrapper>
        <MenuButton />

        {isOpen && <List>{children}</List>}
      </Wrapper>
    </MenuContext.Provider>
  );
};

const useMenuContext = () => {
  const ctx = useContext(MenuContext);

  if (!ctx) {
    throw new Error('Sem intancia do contexto, usar dentro do provider');
  }

  return ctx;
};

const MenuButton = () => {
  const { setIsOpen, isOpen } = useMenuContext();

  return (
    <Button onClick={() => setIsOpen(!isOpen)}>
      <MdOutlineHelp size={30} color="var(--blue-90)" />
    </Button>
  );
};

const MenuItem = ({ children, onClick }) => {
  const { setIsOpen } = useMenuContext();
  return (
    <ItemList>
      <Button
        onClick={() => {
          setIsOpen(false);
          onClick();
        }}
      >
        {children}
      </Button>
    </ItemList>
  );
};

export { Menu, MenuButton, MenuItem };
