import React, { createContext, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { notify } from '~/helpers/Toast';
import { getFilterCampaigns } from '~/services/Generic/Dashboard';
import { getDashboard } from '~/services/User/CES/Dashboard';

const DashboardContext = createContext({});

function DashboardContextProvider({ children }) {
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [surveyOptions, setSurveyOptions] = useState([]);
  const [dataDashboard, setDataDashboard] = useState();
  const { t } = useTranslation();

  const [filter, setFilter] = useState({
    campaign: '',
    type: 'ces',
    dates: {
      start: '',
      end: '',
    },
  });
  const history = useHistory();

  useEffect(() => {
    async function getInitialData() {
      await getFilterCampaigns(filter).then((data) => {
        if (data.length === 0) {
          history.push('/');

          notify('warning', t('message.alreadyCampaing'));
        }

        setSurveyOptions(data);
      });
    }

    getInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  useEffect(() => {
    async function getData() {
      setIsLoadingData(true);

      await getDashboard(filter).then((data) => {
        setDataDashboard(data);
        setIsLoadingData(false);
      });
    }

    getData();
  }, [filter]);

  return (
    <DashboardContext.Provider
      value={{
        isLoadingData,
        dataDashboard,
        surveyOptions,
        setFilter,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
}

const useDashboard = () => useContext(DashboardContext);

export { DashboardContextProvider, useDashboard };
