import { Form } from '@unform/web';
import styled from 'styled-components';

export const SearchWrapper = styled(Form)`
  display: flex;
  align-items: center;

  gap: 1rem;

  div, input {
    margin: 0;
  }
`;

export const InputWrapper = styled.div`
  flex-basis: 80%;
`;
