import React from 'react';
import { useTranslation } from 'react-i18next';

import Modal from '~/components/User/Modal';
import TableTeams from '~/components/User/TableTeams';

export default function ModalTeams(
  {
    setIsModalOpen,
    teamsData,
    isLoadingData,
    currentAttendant,
  }
) {
  const { t } = useTranslation();
  const columns = [
    '#',
    t('tableColumns.ticket'),
    t('tableColumns.sendDate'),
    t('tableColumns.answeredDate'),
    t('tableColumns.resolved'),
    t('tableColumns.grade'),
  ];

  const handleCloseModal = () => {
    setIsModalOpen((prev) => !prev);
  };

  return (
    <>
      <Modal
        title={`${t('titles.listSentEmails')} - ( ${currentAttendant} )`}
        options={{
          height: '95vh', width: '800px', fontSize: '1rem', fontWeight: 'bolder'
        }}
        setVisible={handleCloseModal}
      >
        <TableTeams
          columns={columns}
          rows={teamsData}
          isLoadingData={isLoadingData}
          emptyText={t('messages.noAttendants')}
        />
      </Modal>
    </>
  );
}
