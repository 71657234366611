import React from 'react';
import { useTranslation } from 'react-i18next';
import {AiFillTool} from 'react-icons/ai'
import { FaSmile, FaTachometerAlt } from 'react-icons/fa';
import { GiPush } from 'react-icons/gi';
import { IoIosArrowDown, IoIosArrowUp , IoMdPaper } from 'react-icons/io';

import SideBarChilds from './Childs';
import {
  WrapperNavLink, LinkIcon, LinkName, LinkButton,
  Childs, ArrowWrapper
} from './styles';

export default function SideBarMenu({ name, showChilds, handleCollapse }) {
  const { t } = useTranslation();
  
  const titles = {
    csat: 'csat',
    nps: 'nps',
    ces: 'ces',
    custom: t('titles.customCampaigns'),
    docs:  t('titles.docs')

  }
  const icon = {
    csat: <FaSmile />,
    nps: <FaTachometerAlt />,
    ces: <GiPush />,
    custom: <AiFillTool />,
    docs: <IoMdPaper />
  };
  const links = {
    csat: [
      { title: t('links.overview'), to: '/csat/dashboard' },
      { title: t('links.attendants'), to: '/csat/teams' },
      { title: t('links.campaigns'), to: '/csat/campaigns' },
      { title: 'Feed', to: '/csat/feed' }
    ],
    nps: [
      { title: t('links.overview'), to: '/nps/dashboard' },
      { title: t('links.campaigns'), to: '/nps/campaigns' }
    ],
    ces: [
      { title: t('links.overview'), to: '/ces/dashboard' },
      { title: t('links.campaigns'), to: '/ces/campaigns' }
    ],
    custom: [
      { title: t('links.campaigns'), to: '/custom/campaigns' }
    ],
    docs: [
      { title: t('links.generalInformation'), to: {pathname:'https://banco365.sharepoint.com/sites/PlataformaCX/SitePages/BB-Survey.aspx' }},
      { title: t('links.docAPI'), to: {pathname:'https://survey.api.iga.servicos.bb.com.br/api-docs/#/Externo'} }
    ]

  };

  return (
    <>
      <WrapperNavLink>
        <LinkButton
          onClick={handleCollapse}
          id={`${name}-drop-btn`}
        >
          <LinkIcon>
            {icon[name]}
          </LinkIcon>
          <LinkName> {titles[name].toUpperCase()} </LinkName>
          <ArrowWrapper>
            {showChilds
              ? <IoIosArrowUp /> : <IoIosArrowDown />}
          </ArrowWrapper>
        </LinkButton>
      </WrapperNavLink>
      {showChilds && (
        <Childs>
          <SideBarChilds items={links[name]} />
        </Childs>
      )}
    </>
  );
}
