import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { LoaderEditCampaign } from '~/components/User/Loader';
import Wizard from '~/components/User/Wizard';
import { useCampaign } from '~/contexts/Generic/SurveyContext';

import Finish from './Finish';
import Preview from './Preview';
import SetDistribution from './SetDistribution';
import SetEmails from './SetDistribution/SetEmails';
import SetMetric from './SetMetric';
import SetSurvey from './SetSurvey';
import SetTemplateEmail from './SetTemplateEmail';
import { Wrapper, Title } from './styles';

export default function NewCampaign() {
  const { dataWizard, isLoadingData } = useCampaign();
  const { t } = useTranslation();
  const stepsWizard = [
    t('titles.stepSetMetric'),
    t('titles.stepSetDistribution'),
    t('titles.stepSetCampaign'),
    'Template Email',
    'Preview',
    t('titles.stepFinish'),
  ];

  const renderStep = useCallback((step) => {
    switch (step) {
      case 1:
        return <SetMetric />;
      case 2:
        return <SetDistribution />;
      case 2.5:
        return <SetEmails />;
      case 3:
        return <SetSurvey />;
      case 4:
        return <SetTemplateEmail />;
      case 5:
        return <Preview />;
      case 6:
        return <Finish />;
      default:
        break;
    }
  }, []);

  return (
    <Wrapper>
      {isLoadingData ? (
        <LoaderEditCampaign />
      ) : (
        <>
          <Title>{t('titles.headerConfigCampaign')}</Title>
          <Wizard steps={stepsWizard} currentStep={dataWizard.step} />
          {renderStep(dataWizard.step)}
        </>
      )}
    </Wrapper>
  );
}
