import { createGlobalStyle } from 'styled-components';

export const ColorsPalette = createGlobalStyle`
  :root {
    /** primary palette */

    --yellow-80: #E9B90C;
    --yellow-70: #FAD900;
    --yellow-50: #FFEE00;
    --yellow-10: #FFFBBD;

    /** support palette */

    --blue-90: #002766;
    --blue-60: #004D9E;
    --blue-50: #005AA5;
    --blue-40: #2673B3;
    --blue-20: #9FC2DF;
    --blue-10: #E4EEF6;
    --blue-marin: #00ACC1;

    /** neutral palette */

    --gray-90: #262626;
    --gray-60: #666666;
    --gray-50: #8F8F8F;
    --gray-40: #CCCCCC;
    --gray-30: #E0E0E0;
    --gray-10: #F5F5F5;

    --white: #fff;
    --red: #E50606;
    --red-10: #E506061A;
    --red-20: #FF471A;
    --red-40: #ff6a1a;
    --green: #39AC3E;
    --green-20: #4DD230;
    --green-40: #86d230;
    --green-10: #39AC3E1A;
    --orange: #FFb300;
  }
`;

export const Sizes = createGlobalStyle`
  :root {
    --font-size-xs: 0.65rem;
    --font-size-smlr: 0.75rem;
    --font-size-sm: 0.85rem;
    --font-size-m: 0.9rem;
    --font-size-l: 1rem;
    --font-size-xl: 1.15rem;

    --font-weight-l: 100;
    --font-weight-r: 300;
    --font-weight-sb: 400;
    --font-weight-b: 500;
    --font-weight-xb: 600;

    --metrics-xs: 4px;
    --metrics-smlr: 12px;
    --metrics-sm: 20px;
    --metrics-m: 24px;
    --metrics-l: 32px;
    --metrics-xl: 40px;
  }
`;
