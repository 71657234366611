import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai';

import { Form } from '@unform/web';
import styled from 'styled-components';

export const AverageChart = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  flex-basis: 30%;

  gap: 1rem;
`;

export const AverageLabel = styled.p`
  position: relative;
  font-size: var(--font-size-m);

  color: var(--gray-50);

  &:hover {
    span {
      visibility: visible;
    }
  }
`;

export const Average = styled.span`
  display: flex;
  align-items: center;
  gap: .5rem;

  font-size: var(--metrics-xl);
  color: ${(props) => (props.type === 'up' ? '#39AC3E' : '#E50606')};

  svg {
    width: 15px;
    height: 15px;

  }
`;

export const CaretUp = styled(AiFillCaretUp)``;

export const CaretDown = styled(AiFillCaretDown)``;

export const NavWrapper = styled.nav`
  width: 100%;
  display: flex;

  justify-content: space-between;
  align-items: center;
`;

export const ContentWrapper = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;

  margin-top: var(--metrics-m);
  margin-bottom: var(--metrics-xl);
`;

export const ChartsWrapper = styled.section`
  display: flex;
  justify-content: space-between;
  width: 100%;

  padding: 3rem 0;
  border-bottom: 1px dashed var(--gray-30);
`;

export const DateRank = styled.div`
  width: 100%;
`;

export const GraphicChart = styled.div`
  flex: 70%;
  color: var(--blue-60);
`;

export const CardsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  color: var(--blue-60);
`;

export const FilterPeriod = styled(Form)`
  margin-top: var(--metrics-sm);

  p {
    margin-bottom: var(--metrics-smlr);
  }
`;

export const Paragraph = styled.p`
  font-size: var(--font-size-smlr);
  font-weight: bold;
  margin-top: var(--metrics-sm);

  color: var(--blue-90);
`;
