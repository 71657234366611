const scaleColor = (average_score, type) => {
  if (type === 'ces') {
    switch (parseInt(average_score, [])) {
      case 1:
        return 'var(--red)';
      case 2:
        return 'var(--red-20)';
      case 3:
        return 'var(--red-40)';
      case 4:
        return 'var(--yellow-80)';
      case 5:
        return 'var(--green-40)';
      case 6:
        return 'var(--green-20)';
      default:
        return 'var(--green)';
    }
  }

  return 'var(--green)';
};

export { scaleColor };
