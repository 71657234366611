import { notify } from '~/helpers/Toast';

import api from '..';

const getUserData = async () =>
  api
    .get('/user-info')
    .then((res) => res.data)
    .catch((err) => {
      if (err.response) {
        if (err.response.status === 403 || err.response.status === 500) {
          notify('errorUnauthorized', err.response.data.message);
        }

        return err.response.status;
      }

      return 400;
    });

export { getUserData };
