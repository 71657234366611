import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { FaSearch } from 'react-icons/fa';

import Button from '~/components/User/Button';
import Input from '~/components/User/Input';
import Modal from '~/components/User/Modal';
import TableResendEmail from '~/components/User/TableResendEmail';

import { FormWrapper, SearchWrapper, InputWrapper } from './styles';

export default function ModalResendEmail({
  setIsModalResendEmailOpen,
  resendEmailsData,
  isLoadingData,
  currentCampaignId,
  resendEmails,
  columns,
  campaignType,
}) {
  const addButtonOptions = {
    size: { width: '100%', height: '42px' },
    colors: { background: 'var(--blue-90)', text: 'var(--white)' },
  };
  const addButtonSearchOptions = {
    size: { width: '50%', height: '42px' },
    colors: { background: 'var(--blue-90)', text: 'var(--white)' },
  };
  const [isDisabled, setIsDisabled] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const formRef = useRef(null);

  const { t } = useTranslation();

  const handleSendEmail = () => {
    resendEmails(currentCampaignId, isCheck);
    setIsModalResendEmailOpen((prev) => !prev);
  };

  const [filteredEmailData, SetFilteredEmailData] = useState([]);

  const handleCloseModal = () => {
    setIsModalResendEmailOpen((prev) => !prev);
  };

  const handleSearch = (formData) => {
    const { key } = formData;
    SetFilteredEmailData(
      resendEmailsData.filter(
        ({ user_name, email, interaction, answer_data }) =>
          user_name.toLowerCase().startsWith(key.toLowerCase()) ||
          email.toLowerCase().startsWith(key.toLowerCase()) ||
          interaction?.toLowerCase().startsWith(key.toLowerCase()) ||
          answer_data.toLowerCase().startsWith(key.toLowerCase())
      )
    );
  };

  useEffect(() => {
    setIsDisabled(false);
    if (isCheck.length === 0) {
      setIsDisabled(true);
    }
  }, [isCheck]);

  return (
    <>
      <Modal
        title={t('titles.resendEmail')}
        options={{
          height: '95vh',
          width: campaignType === 'csat' ? '87vw' : '70vw',
          fontSize: '1rem',
          fontWeight: 'bolder',
          overflowY: 'inherit',
        }}
        setVisible={handleCloseModal}
      >
        <SearchWrapper onSubmit={handleSearch} ref={formRef}>
          <InputWrapper>
            <Input name="key" placeholder={t('placeholders.insertSearchKey')}>
              <FaSearch />
            </Input>
          </InputWrapper>
          <Button options={addButtonSearchOptions} type="submit">
            {t('buttons.search')}
          </Button>
        </SearchWrapper>
        <TableResendEmail
          columns={columns}
          rows={
            filteredEmailData.length > 0 ? filteredEmailData : resendEmailsData
          }
          campaignType={campaignType}
          isLoadingData={isLoadingData}
          setIsDisabled={setIsDisabled}
          setIsCheck={setIsCheck}
          isCheck={isCheck}
        />
        <FormWrapper onSubmit={handleSendEmail}>
          <Button
            options={addButtonOptions}
            type="submit"
            isRequesting={isDisabled}
          >
            {t('buttons.send')}
          </Button>
        </FormWrapper>
      </Modal>
    </>
  );
}
