/* eslint-disable quotes */
import { formatDateTimePT } from '~/helpers/Dates';
import { notify } from '~/helpers/Toast';
import api from '~/services';
import i18n from '~/translate/i18n';

const languages = {
  'pt-BR': 'message',
  'en-US': 'message_en',
};

function getFilters(page, filterField, filterNav, totalPerPage) {
  let params = {
    type: 'csat',
    total_per_page: totalPerPage,
    page,
  };

  if (totalPerPage === 'todos') {
    delete params.page;
    delete params.total_per_page;
  }

  if (filterField.name && filterField.order) {
    params.order_field = filterField.name;
    params.order = filterField.order;
  }

  if (filterNav) {
    params = {
      ...params,
      campaign: filterNav.campaign,
      attendant: filterNav.attendant,
      start_date: filterNav.dates.start,
      end_date: filterNav.dates.end,
    };
  }

  Object.keys(params).forEach((obj) => {
    if (!params[obj]) {
      delete params[obj];
    }
  });

  if (params.attendant) {
    params = {
      ...params,
      attendant_key: filterNav.attendant_key,
    };
  }

  return params;
}

const getAttendants = async (page, filterField, filterNav, totalPerPage) =>
  api
    .get('/attendant', {
      params: getFilters(page, filterField, filterNav, totalPerPage),
    })
    .then((res) => {
      let tData = res.data;
      tData.list = res.data.list.map((attendant) => ({
        order: attendant.order,
        attendant: attendant.attendant,
        attendantKey: attendant.attendant_key,
        note: !attendant.note ? 0 : attendant.note,
        average: !attendant.average ? 0 : attendant.average,
        ratings: attendant.ratings,
        total_not_solved: attendant.total_not_solved,
        total_ratings: attendant.total_ratings,
        total_answerd_percent: Math.round(
          (attendant.ratings / attendant.total_ratings) * 100
        ),
      }));

      return tData;
    })
    .catch((err) => {
      notify('error', err.response.data.errors[0][languages[i18n.language]]);
    });

const getAttendantsDetail = async (attendant, attendant_key) =>
  api
    .get(
      `/attendant_detail?type=csat&attendant=${attendant}&attendant_key=${attendant_key}`
    )
    .then((res) =>
      res.data.map((elem) => {
        const dateSend = new Date(elem.date_send);
        const dateAnswer = new Date(elem.date_answer);
        let isSolved = 'Não respondido';
        if (elem.solved !== null) {
          if (elem.solved) {
            isSolved = 'Sim';
          } else {
            isSolved = 'Não';
          }
        }

        return {
          order: elem.order,
          interaction: elem.interaction,
          date_send: elem.date_send
            ? formatDateTimePT(dateSend)
            : 'Não enviado',
          date_answer: elem.date_answer
            ? formatDateTimePT(dateAnswer)
            : 'Não respondido',
          solved: isSolved,
          rating: elem.rating ? elem.rating : '-',
        };
      })
    )
    .catch((err) => {
      notify('error', err.response.data.errors[0][languages[i18n.language]]);
    });

export { getAttendants, getAttendantsDetail };
