import React, { useRef, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Form } from '@unform/web';
import { getYear } from 'date-fns';
import * as Yup from 'yup';

import Button from '~/components/User/Button';
import Input from '~/components/User/Input';
import InputRadioCampaign from '~/components/User/InputRadioCampaign';
import InputRadioRating from '~/components/User/InputRadioRating';
import { useCampaign } from '~/contexts/Generic/SurveyContext';
import i18n from '~/translate/i18n';
import { generateFormErrors } from '~/validators';
import { validateSetSurvey } from '~/validators/User/CSAT';
import { validateSetSurveyCustom } from '~/validators/User/CUSTOM';

import CustomDateInput from './components/CustomDate';
import CustomQuestions from './components/CustomQuestions';
import { Wrapper, ButtonsWrapper } from './styles';

const LanguageOptions = [
  {
    id: 1,
    label: i18n.t('labels.portuguese'),
    value: 'pt',
  },
  {
    id: 2,
    label: i18n.t('labels.english'),
    value: 'en',
  },
];

export default function SetSurvey() {
  const formRef = useRef(null);
  const {
    dataWizard,
    setDataWizard,
    backButtonOptions,
    nextButtonOptions,
    isEditting,
    isCopying,
    currentMetric,
    optionsRatings,
    questionExample,
  } = useCampaign();

  const { survey } = dataWizard;


  const { t } = useTranslation();
  const [currentOptionsRatings, setCurrentOptionsRatings] = useState(
    optionsRatings[currentMetric]
  );

  const { custom_questions } = dataWizard?.survey;

  const handleNext = useCallback(
    async (formData) => {
      try {
        if (formData.custom_questions) {
          formData = isEditting
            ? {
                ...formData,
                custom_questions: formData?.custom_questions
                  .map((q, index) =>
                    q.question === 'deleted'
                      ? {
                          ...q,
                          id_to_deactivate: custom_questions[index]?.id,
                        }
                      : {
                          ...q,
                          id: custom_questions[index]?.id,
                        }
                  )
                  .filter(
                    (q) => q.question !== 'deleted' || q.id_to_deactivate
                  ),
              }
            : {
                ...formData,
                custom_questions: formData?.custom_questions.filter(
                  (q) => q.question !== 'deleted'
                ),
              };
        }

        if (currentMetric === 'CUSTOM') {
          await validateSetSurveyCustom(formData, formRef);
        } else {
          await validateSetSurvey(formData, formRef);
        }

        setDataWizard((prev) => {
          let data = {
            ...prev,
            step: 4,
            survey: { ...dataWizard.survey, ...formData },
          };

          localStorage.setItem('wizardData', JSON.stringify(data));
          return data;
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach((error) => {
            if (error.path === 'date.endDate') {
              formRef.current.setFieldError('date', error.message);
            }
          });
        }

        generateFormErrors(err, formRef);
      }
    },
    [setDataWizard, dataWizard, custom_questions, isEditting, currentMetric]
  );

  const handlePrev = useCallback(() => {
    if (dataWizard.distribution.name === 'email') {
      setDataWizard((prev) => ({ ...prev, step: 2.5 }));
    }

    if (dataWizard.distribution.name === 'api') {
      setDataWizard((prev) => ({ ...prev, step: 2 }));
    }
  }, [setDataWizard, dataWizard]);

  const showButton = () => {
    let output = true;
    if (isEditting && dataWizard.distribution.name === 'api') {
      output = false;
    }
    return output;
  };

  useEffect(() => {
    if (isEditting && !isCopying) {
      const [quantity] = survey?.option?.split('-');

      setCurrentOptionsRatings((p) =>
        p.filter((o) => o.emote.quantity === parseInt(quantity, []))
      );
    }

    if ((isEditting || isCopying) && dataWizard.survey) {
      formRef.current.setData(dataWizard.survey);
    }
  }, [isEditting, isCopying, dataWizard, survey]);

  return (
    <Wrapper>
      <Form
        initialData={{
          language: survey.language === 'en' ? 2 : 1,
          campaignName: survey.campaignName,
          greetingMessage: survey.greetingMessage,
          question: survey.question,
          option: survey.option,
        }}
        ref={formRef}
        onSubmit={handleNext}
      >
        <InputRadioCampaign
          name="language"
          title={t('labels.campaignLanguage')}
          options={LanguageOptions}
        />

        <Input
          name="campaignName"
          title={t('labels.campaignName')}
          placeholder={t('placeholders.campaignName')}
        />
        {currentMetric !== 'CUSTOM' && (
          <Input
            name="question"
            title={t('labels.campaignQuestion')}
            placeholder={questionExample[currentMetric]}
          />
        )}

        <CustomQuestions
          custom_questions={custom_questions}
          formRef={formRef}
        />

        <Input
          name="greetingMessage"
          title={t('labels.campaignGreeting')}
          placeholder={t('placeholders.campaignGreeting')}
        />
        <CustomDateInput
          title={t('labels.campaignPeriod')}
          name="date"
          data-test="Choose-campaign-date"
          initialStart={survey.date.startDate}
          initialEnd={
            getYear(survey.date.endDate) !== 2099 ? survey.date.endDate : null
          }
        />
        {currentMetric !== 'CUSTOM' && (
          <InputRadioRating options={currentOptionsRatings} name="option" />
        )}
        <ButtonsWrapper>
          {showButton() && (
            <Button
              data-test="Voltar"
              title={t('buttons.back')}
              options={backButtonOptions}
              onClick={handlePrev}
            />
          )}
          <Button
            data-test="Avançar"
            title={t('buttons.foward')}
            options={nextButtonOptions}
            type="submit"
            id="next-btn"
          />
        </ButtonsWrapper>
      </Form>
    </Wrapper>
  );
}
