import styled from 'styled-components';

import device from '~/assets/styles/settings/Devices';

export const Wrapper = styled.article`
  display: flex;
  padding: var(--metrics-l);

  box-shadow: 0px 8px 24px rgba(217, 217, 217, 0.25);
  border-radius: 1rem;

  margin-bottom: var(--metrics-sm);
`;

export const DetailsOne = styled.div`
  flex-basis: 40%;
  padding-right: var(--metrics-l);

  p {
    margin-top: var(--metrics-smlr);
    color: var(--gray-50);
  }

  h4 {
    color: var(--blue-90);
  }
`;

export const DetailsTwo = styled.div`
  flex: 1;
  max-width: 490px;

  padding-left: calc(var(--metrics-xl) + .5rem);
  border-left: 1px dashed var(--gray-30);
  font-size: var(--font-size-sm);

  span {
    display: block;
    opacity: .6;

    color: var(--gray-50);
  }

  strong {
    display: block;

    color: var(--gray-90);
    font-weight: var(--font-weight-xb);
    font-size: 1.05rem;
    margin-top: var(--metrics-sm);
  }

  @media ${device.laptop} {
    max-width: 100%;
  }
`;

export const ButtonWrapper = styled.div``;

export const MetricImages = styled.div`
  display: flex;
  gap: 5px;
  margin-top: var(--metrics-m);
  flex-wrap: wrap;

  svg {
    height: 20px;
    width: 20px;

    fill: var(--yellow-80);
  }
`;

export const Pill = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: ${(p) => (p['pill-small'] ? '39px' : '80px')};
  padding: 8px 8px;

  text-align: center;

  color: var(--white);
  font-size: var(--font-size-smlr);

  border-radius: 8px;
  background-color: ${(p) => (p.color)};
`;

export const Lines = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PillsWrapper = styled.div`
  display: flex;
  gap: 5px;
  margin-top: var(--metrics-sm);
  flex-wrap: wrap;
`;


export const EmojisWrapper = styled.div`
  display: flex;
  align-items: flex-end;

  /* flex-wrap: wrap; */
  text-align: center;

  gap: 2rem;
  margin-top: var(--metrics-sm);
`;

export const EmojiWrapper = styled.div`
  display: flex;
  flex-direction: column;

  gap: 1rem;

  p {
    color: var(--gray-50);
  }
`;