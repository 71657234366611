import { createGlobalStyle } from 'styled-components';

import devices from '~/assets/styles/settings/Devices';
import 'react-toastify/dist/ReactToastify.css';

const GlobalStyle = createGlobalStyle`
  body {
    overflow-x: hidden;
  }

  body,
  textarea,
  button {
    color: var(--gray-40);

    font: 500 1rem Inter, sans-serif;
  }

  input {
    color: var(--gray-60);

    font: 300 .8rem Inter, sans-serif;

    &::placeholder {
      font: 300 .8rem Lexend, sans-serif;
    }
  }

  strong {
    font-weight: var(--font-weight-xb);
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: var(--gray-60);

    font-family: Lexend, sans-serif;
    font-weight: 600;
  }

  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  p {
    font-size: 14px;
  }

  @media ${devices.laptop} {
    html {
      font-size: 93.75%;
    }
  }

  .Toastify__toast {
    font-family: Lexend, sans-serif;

    font-size: 12px;
    line-height: 20px;
    padding: 1.5rem 1rem;
    white-space: pre-line;
  }
`;

export default GlobalStyle;
