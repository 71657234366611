import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineUserAdd } from 'react-icons/ai';

import InputCheckbox from '~/components/User/InputCheckbox';
import Tooltip from '~/components/User/Tooltip';
import { useSurveyPrivileges } from '~/contexts/NPS/SurveyPrivilegesContext';
import { createPrivilege } from '~/services/Generic/SurveyPrivileges';

import {
  UserInfo,
  UserPhoto,
  Username,
  UserWrapper,
  Actions,
  ButtonAction,
} from './styles';

export default function AddUser({ data, ukey, campaign }) {
  const formRef = useRef(null);
  const { setIsChangingData } = useSurveyPrivileges();

  const handleSubmit = async (formData) => {
    try {
      const { checkbox } = formData;
      const payload = {
        key: ukey,
        is_viewer: checkbox.includes('viewer'),
        is_owner: checkbox.includes('owner'),
        campaign,
      };

      await createPrivilege(payload).finally(() => {
        setIsChangingData(true);
      });
    } catch (err) {
      return err;
    }
  };
  const { t } = useTranslation();
  return (
    <UserInfo onSubmit={handleSubmit} ref={formRef}>
      <UserWrapper>
        <UserPhoto src={data?.picture} />
        <Username>{data?.fullName}</Username>
      </UserWrapper>
      <Actions>
        <InputCheckbox
          name="checkbox"
          options={[
            {
              id: 'owner',
              value: 'owner',
              label: t('labels.campaignOwner'),
            },
            { id: 'viewer', value: 'viewer', label: t('labels.member') },
          ]}
        />
        <ButtonAction type="submit">
          <Tooltip text="Adicionar" />
          <AiOutlineUserAdd />
        </ButtonAction>
      </Actions>
    </UserInfo>
  );
}
