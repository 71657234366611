export const SET_USER_INFO = 'SET_USER_INFO';
export const SET_CERTIFICATE_MODAL = 'SET_CERTIFICATE_MODAL';
export const SET_CHAT_MODAL = 'SET_CHAT_MODAL';
export const SET_SHOW_CHILDS_CSAT = 'SET_SHOW_CHILDS_CSAT';
export const SET_SHOW_CHILDS_NPS = 'SET_SHOW_CHILDS_NPS';
export const SET_SHOW_CHILDS_CES = 'SET_SHOW_CHILDS_CES';
export const SET_SHOW_CHILDS_CUSTOM = 'SET_SHOW_CHILDS_CUSTOM';
export const SET_SHOW_CHILDS_DOCS = 'SET_SHOW_CHILDS_DOCS'

export const setUserInfo = (dispatch, value) => {
  dispatch({ type: SET_USER_INFO, value });
};

export const setCertificateModal = (dispatch, value) => {
  dispatch({ type: SET_CERTIFICATE_MODAL, value });
};

export const setChatModal = (dispatch, value) => {
  dispatch({ type: SET_CHAT_MODAL, value });
};

export const setShowChildsCSAT = (dispatch) => {
  dispatch({ type: SET_SHOW_CHILDS_CSAT });
};

export const setShowChildsNPS = (dispatch) => {
  dispatch({ type: SET_SHOW_CHILDS_NPS });
};

export const setShowChildsCES = (dispatch) => {
  dispatch({ type: SET_SHOW_CHILDS_CES });
};

export const setShowChildsCustom = (dispatch) => {
  dispatch({ type: SET_SHOW_CHILDS_CUSTOM });
};

export const setShowChildsDocs = (dispatch) => {
  dispatch({ type: SET_SHOW_CHILDS_DOCS });
};
