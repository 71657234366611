import React, {
  createContext, useContext, useEffect, useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { formatDateEN, getNextDays } from '~/helpers/Dates';
import { notify } from '~/helpers/Toast';
import { getFilterAttendants, getFilterCampaigns } from '~/services/Generic/Dashboard';
import { getFeed } from '~/services/User/CSAT/Feed';

const FeedContext = createContext({});

function FeedContextProvider({ children }) {
  const { t } = useTranslation();
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [surveyOptions, setSurveyOptions] = useState([]);
  const [attendantsOptions, setAttendantsOptions] = useState([]);
  const [dataFeed, setDataFeed] = useState();
  const [activePage, setActivePage] = useState(1);
  const [numberPages, setNumberPages] = useState();
  const [filterNav, setFilterNav] = useState({
    campaign: '',
    attendant: '',
    attendant_key: '',
    type: 'csat',
    dates: {
      start: formatDateEN(getNextDays(new Date(), -10)),
      end: formatDateEN(new Date())
    }
  });
  const history = useHistory();

  useEffect(() => {
    async function getInitialData() {
      const newFilter = { ...filterNav, dates: { start: '', end: '' } };

      await getFilterCampaigns(newFilter).then((data) => {
        if (data.length === 0) {
          history.push('/');
          notify('warning', t('notify.getFilterCampaigns.warning'));
        }
        setSurveyOptions(data);
      });

      await getFilterAttendants().then((data) => {
        setAttendantsOptions(data);
      });
    }

    getInitialData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, filterNav]);

  useEffect(() => {
    async function getData() {
      setIsLoadingData(true);

      await getFeed(filterNav, activePage).then((data) => {
        if (activePage > 1) {
          const updateData = {
            ...dataFeed,
            list: [...dataFeed.list, ...data.list]
          };

          setDataFeed(updateData);
        } else {
          setDataFeed(data);
          setNumberPages(data.total_pages);
        }
      }).finally(() => {
        setIsLoadingData(false);
      });
    }

    getData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterNav, activePage]);

  return (
    <FeedContext.Provider value={{
      isLoadingData,
      dataFeed,
      filterNav,
      surveyOptions,
      attendantsOptions,
      setFilterNav,
      numberPages,
      setActivePage,
      activePage
    }}
    >
      {children}
    </FeedContext.Provider>
  );
}

const useFeed = () => useContext(FeedContext);

export { FeedContextProvider, useFeed };
