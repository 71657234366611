import React from 'react';
import { useTranslation } from 'react-i18next';

import { Circle } from 'rc-progress';

import BarChart from '~/components/User/BarChart';
import Breadcrumb from '~/components/User/Breadcrumb';
import LineChart from '~/components/User/LineChart';
import { LoaderChart, LoaderCircleTwo } from '~/components/User/Loader';
import Filters from '~/containers/Generic/Filters';
import { useDashboard } from '~/contexts/CES/DashboardContext';
import { scaleColor } from '~/helpers/CircleColor';
import {
  generateLineChart,
  generateBarChart,
  generateBarChart7Pill,
} from '~/mocks/Charts';

import {
  NavWrapper,
  GeneralInfo,
  ChartsWrapper,
  Chart,
  CircleWrapper,
  CircleData,
  GeneralData,
} from './styles';

export default function DashboardNPS() {
  const { isLoadingData, dataDashboard, surveyOptions, setFilter } =
    useDashboard();
  const { t } = useTranslation();

  return (
    <>
      <NavWrapper>
        <Breadcrumb trails={['CES']} currentPage={t('titles.overview')} />
        <Filters
          surveyOptions={surveyOptions}
          attendantDisabled
          setFilter={setFilter}
        />
      </NavWrapper>
      <GeneralInfo>
        {isLoadingData ? (
          <LoaderCircleTwo />
        ) : (
          <>
            <CircleWrapper>
              <Circle
                percent={(dataDashboard?.average_score * 100) / 7}
                strokeWidth="10"
                trailWidth="10"
                trailColor="var(--gray-10)"
                strokeColor={scaleColor(dataDashboard?.average_score, 'ces')}
                id="progress"
              />
              <CircleData>
                <span>{t('titles.average')}</span>
                <strong>
                  {dataDashboard?.average_score === 0
                    ? '-'
                    : `${dataDashboard?.average_score}`}
                </strong>
              </CircleData>
            </CircleWrapper>
            <GeneralData>
              <span>
                {dataDashboard?.quantity <= 1
                  ? t('titles.evaluation')
                  : t('titles.evaluations')}
              </span>
              <strong className="red">
                {dataDashboard?.quantity === 0 ? '-' : dataDashboard?.quantity}
              </strong>
            </GeneralData>
          </>
        )}
      </GeneralInfo>
      <ChartsWrapper>
        {isLoadingData ? (
          <LoaderChart />
        ) : (
          <Chart>
            <strong>CES (Customer Effort Score)</strong>
            <LineChart
              data={generateLineChart(dataDashboard?.scores)}
              type="ces"
            />
          </Chart>
        )}
        {isLoadingData ? (
          <LoaderChart />
        ) : (
          <Chart>
            <strong>{t('titles.histogram')}</strong>
            <BarChart
              data={generateBarChart(dataDashboard?.histogram)}
              type="ces"
            />
          </Chart>
        )}
        {isLoadingData ? (
          <LoaderChart />
        ) : (
          <Chart>
            <strong>{t('titles.votesWith7Grades')}</strong>
            <BarChart
              data={generateBarChart7Pill(dataDashboard?.analytics?.scale_7)}
            />
          </Chart>
        )}
      </ChartsWrapper>
    </>
  );
}
