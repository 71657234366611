import { NavLink } from 'react-router-dom';

import styled, { css } from 'styled-components';

import { fadeIn } from '~/assets/styles/settings/Animations';
import devices from '~/assets/styles/settings/Devices';

const _link_styles = css`
  display: flex;
  align-items: center;
  box-sizing: border-box;

  width: 100%;
  height: 60px;
  padding: 0 var(--metrics-m);
  border-radius: 5px;

  color: var(--gray-10);
  font-size:  var(--font-size-sm);

  outline: none;
`;

export const Wrapper = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: ${(p) => (p.isAdmin ? 'var(--metrics-m)' : 'calc(var(--metrics-m) * 4.3)')};
  background-color: var(--blue-90);

  min-width: 320px;
  overflow: auto;
  overflow-x: hidden;
  transition: width .3s;

  @media ${devices.laptopXL} {
    min-width: 300px;
  }

  @media ${devices.laptopL} {
    min-width: 223px;
  }

  @media ${devices.tablet} {
    min-width: 100px;
  }
`;

export const WrapperNavLink = styled.li`
  width: 100%;
  margin-left: auto;

  transition: background-color .3s;

  &:hover {
    background-color: var(--blue-50);
  }
`;

export const Link = styled(NavLink).attrs(() => ({
  activeStyle: {
    color: 'var(--gray-10)',
    backgroundColor: 'var(--blue-50)'
  },
}))`
  ${_link_styles};
`;

export const Sublink = styled(NavLink).attrs(() => ({
  activeStyle: {
    borderLeft: '2px solid var(--white)'
  }
}))`
  font-weight: var(--font-weight-);

  padding-left: var(--metrics-sm);
`;

export const LinkButton = styled.button.attrs(() => ({
  type: 'button'
}))`
  display: flex;
  align-items: center;
  box-sizing: border-box;

  width: 100%;
  height: 60px;
  padding: 0 var(--metrics-m);
  border-radius: 5px;

  color: var(--gray-10);
  font-size:  var(--font-size-sm);

  outline: none;
  transition: background-color 0.5s;
`;

export const LinkIcon = styled.span`
  margin-right: var(--metrics-sm);

  svg {
    color: var(--gray-10);
    height: 20px;
    width: 20px;
  }
`;

export const LinkName = styled.p`
  font-size: var(--font-size-m);

  @media ${devices.tablet} {
    display: none;
  }
`;

export const CSATChilds = styled.div`
  display: flex;
  flex-direction: column;

  padding: var(--metrics-sm) var(--metrics-l);

  gap: 1.3rem;
  width: 90%;

  font-size: var(--font-size-sm);
  color: var(--white);

  animation: ${fadeIn} .8s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
`;

export const ArrowWrapper = styled.div`
  margin-left: auto;
`;

export const LinkAnchor = styled.a`
  ${_link_styles};
`;

export const LinkNormal = styled(Link)`
  ${_link_styles};
`;
