import React from 'react';
import { Route } from 'react-router-dom';

import CampaignAnswer from '~/pages/Outside/CampaignAnswer';

export default function CampaignRoutes() {
  return (
    <>
      <Route exact path="/campaign/answer/:hash" component={CampaignAnswer} />
    </>
  );
}
