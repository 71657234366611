import * as Yup from 'yup';

async function validateCreateAnswer(payload, formRef) {
  const schema = Yup.object().shape({
    solved: Yup.string()
      .required('É necessário informar se o problema foi resolvido')
      .strict(true)
      .nullable()
  });

  await schema.validate(payload, {
    abortEarly: false
  });

  formRef.current.setErrors({});
}

export { validateCreateAnswer };
