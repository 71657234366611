import styled from 'styled-components';

export const Output = styled.button.attrs(() => ({
  type: 'button',
}))`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 190px;
  height: 40px;
  border-radius: 8px;

  background-color: var(--gray-10);
  color: var(--blue-50);

  font-weight: var(--font-weight-xb);
  cursor: pointer;

  span {
    font-size: var(--font-size-sm);
    white-space: pre;
  }

  svg {
    height: 0.9rem;
    width: 0.9rem;

    fill: var(--gray-40);

    transform: translateX(100%);
  }
`;

export const Calendar = styled.div`
  border-radius: 10px;
  box-shadow: 0 6px 12px rgba(27, 37, 86, 0.16);
  overflow: hidden;
  position: absolute;
  z-index: 10;

  font-size: 11px;
`;

export const Popper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;

  color: var(--blue-90);
`;
