import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import Breadcrumb from '~/components/User/Breadcrumb';
import Button from '~/components/User/Button';
import FeedDate from '~/components/User/FeedDate';
import FilterDate from '~/components/User/FilterDate';
import LineChart from '~/components/User/LineChart';
import {
  LoaderChart,
  LoaderRankCard,
  LoaderCircleThree,
} from '~/components/User/Loader';
import Tooltip from '~/components/User/Tooltip';
import Filters from '~/containers/Generic/Filters';
import { useFeed } from '~/contexts/CSAT/FeedContext';
import { generateLineChart } from '~/mocks/Charts';

import {
  Average,
  AverageChart,
  AverageLabel,
  CaretUp,
  CaretDown,
  ChartsWrapper,
  ContentWrapper,
  GraphicChart,
  NavWrapper,
  FilterPeriod,
  Paragraph,
} from './styles';

export default function Feed() {
  const {
    surveyOptions,
    attendantsOptions,
    setFilterNav,
    isLoadingData,
    dataFeed,
    numberPages,
    setActivePage,
    activePage,
    filterNav,
  } = useFeed();

  const graficData = dataFeed?.list
    .map((day) => ({ x: day.date, y: day.avg_group }))
    .reverse();
  const averageData = dataFeed?.avg_period_total;

  const handleSelectDates = useCallback(
    (dates) => {
      setActivePage(1);
      setFilterNav((p) => ({ ...p, dates }));
    },
    [setFilterNav, setActivePage]
  );

  const handleLoadMore = useCallback(() => {
    setActivePage((prev) => prev + 1);
  }, [setActivePage]);

  const { t } = useTranslation();
  return (
    <>
      <NavWrapper>
        <Breadcrumb trails={['CSAT']} currentPage="Feed" />
        <Filters
          surveyOptions={surveyOptions}
          agentOptions={attendantsOptions}
          setFilter={setFilterNav}
          setActivePage={setActivePage}
          dateDisabled
        />
      </NavWrapper>
      <ContentWrapper>
        <ChartsWrapper>
          <AverageChart>
            {isLoadingData ? (
              <LoaderCircleThree />
            ) : (
              <>
                <AverageLabel>
                  {t('titles.averageClassification')}
                  <Tooltip text={t('tooltips.overallAvgPeriod')} />
                </AverageLabel>
                <Average type={averageData > 50.0 ? 'up' : 'down'}>
                  {averageData || '-'}
                  {averageData > 0 &&
                    (averageData <= 50.0 ? <CaretDown /> : <CaretUp />)}
                </Average>
              </>
            )}
          </AverageChart>
          <GraphicChart>
            {isLoadingData ? (
              <LoaderChart />
            ) : (
              <LineChart noplugin data={generateLineChart(graficData)} />
            )}
          </GraphicChart>
        </ChartsWrapper>
        <FilterPeriod data-test="filter-period">
          <p>{t('titles.period')}</p>
          <FilterDate
            name="period"
            setDates={handleSelectDates}
            isNotAbsolute
            initialDates={filterNav.dates}
          />
        </FilterPeriod>
        {isLoadingData ? (
          <>
            <LoaderRankCard />
          </>
        ) : (
          <>
            {dataFeed && dataFeed.list.length > 0 ? (
              dataFeed.list.map((day) => (
                <FeedDate
                  date={new Date(`${day.date} 00:00`)}
                  ranking={day.rank}
                  key={day.date}
                />
              ))
            ) : (
              <Paragraph>{t('titles.noAttendantPeriod')}</Paragraph>
            )}
          </>
        )}
        {activePage < numberPages && (
          <Button
            title={`${t('buttons.loadingMore')}`}
            onClick={handleLoadMore}
            options={{
              size: { width: '160px', height: '40px' },
              colors: { text: 'var(--white)', background: 'var(--yellow-80)' },
            }}
          />
        )}
      </ContentWrapper>
    </>
  );
}
