import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '~/components/User/Button';

import { ConfirmationWrapper } from './styles';

const ConfirmationBug = ({ issueUrl, handleCloseModal }) => {
  const getIssueNumber = () => {
    return issueUrl.split('/').pop();
  };

  const { t } = useTranslation();

  return (
    <ConfirmationWrapper>
      <h1>Issue {getIssueNumber()}</h1>
      <p>{t('bugReport.issueLabel', { issueNumber: getIssueNumber() })}</p>
      <p>{t('bugReport.teamText')}</p>
      <p>{t('bugReport.linkText')}</p>
      <a href={issueUrl} target="_blank" rel="noopener noreferrer">
        {issueUrl}
      </a>

      <Button onClick={handleCloseModal}>Sair</Button>

      <span>{t('bugReport.accessText')}</span>
    </ConfirmationWrapper>
  );
};

export { ConfirmationBug };
