import api from '..';

function getFilters(filters) {
  let params = {
    campaign: filters.campaign,
    attendant: filters.attendant,
    type: filters.type,
  };

  if (filters.dates !== undefined) {
    params.start_date = filters.dates.start;
    params.end_date = filters.dates.end;
  }

  Object.keys(params).forEach((obj) => {
    if (!params[obj]) {
      delete params[obj];
    }
  });

  if (params.attendant) {
    params = {
      ...params,
      attendant_key: filters.attendant_key,
    };
  }

  return params;
}

const getDashboard = async (filters) => api.get('/dashboard', {
  params: getFilters(filters)
})
  .then((res) => res.data)
  .catch((err) => err);

const getFilterCampaigns = async (filters) => api.get('/filter/campaign', {
  params: getFilters(filters)
})
  .then((res) => res.data)
  .catch((err) => err);

const getFilterAttendants = async () => api.get('/filter/attendant')
  .then((res) => res.data)
  .catch((err) => err);

export {
  getFilterCampaigns, getFilterAttendants, getDashboard
};
