import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  margin-left: auto;

  height: 60px;
  padding: 0 var(--metrics-sm);

  border-radius: 8px;
  transition: filter .3s;

  cursor: pointer;

  svg, span {
    margin-left: var(--metrics-smlr);

    color: var(--gray-50);
  }

  svg {
    transform: translateY(2px);
  }

  &:hover {
    span, svg {
      filter: brightness(90%);
    }
  }
`;

export const UserPhoto = styled.img`
  width: 40px;
  height: 40px;

  object-fit: cover;

  border-radius: 50%;
`;

export const Username = styled.span`
  font-size: var(--font-size-m);
`;
