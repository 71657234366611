import { formatDatePT, formatDateEn } from '~/helpers/Dates';
import { notify } from '~/helpers/Toast';
import i18n from '~/translate/i18n';

import api from '..';

const languages = {
  'pt-BR': 'message',
  'en-US': 'message_en',
};

const languageDate = {
  'pt-BR': formatDatePT,
  'en-US': formatDateEn,
};

const createCampaign = async (payload) =>
  api
    .post('/campaign', payload)
    .then((res) => {
      notify('success', i18n.t('messages.createdCampaign'));

      return res.data;
    })
    .catch((err) => {
      notify('error', err.response.data.errors[0][languages[i18n.language]]);

      return err.response.data;
    });

const getCampaignAnswered = (id) =>
  api
    .get(`/filter/campaign_answered?campaign=${id}`)
    .then((res) => {
      const output = res.data.map((r) => {
        const line = {
          ...r,
          answer_data: r.answer_data
            ? languageDate[i18n.language](new Date(r.answer_data))
            : i18n.t('labels.notAnswered'),
        };

        return line;
      });
      return output;
    })
    .catch((err) => err);

const getTemplateMailCampaign = (type, language) =>
  api
    .get('/default_template', { params: { type, language } })
    .then((res) => res.data)
    .catch((err) => err);

const getCampaignMoreInfos = (id) =>
  api
    .get(`/rating_detail/${id}`)
    .then((res) => res.data)
    .catch((err) => err);

const getCampaignQuestionDetail = (id) =>
  api
    .get(`/question_detail/${id}`)
    .then((res) => res.data)
    .catch((err) => err);

const getDetailedCampaign = async (id) =>
  api
    .get(`/campaign/${id}`)
    .then((res) => {
      const { data } = res;
      let emails = res.data.ratings.map((line) => ({
        attendant_key: line.attendant_key,
        evaluated_attendant: line.attendant_name,
        evaluator_name: line.user_name,
        email: line.email,
        ticket_number: line.iteration_code,
      }));

      if (data.type === 'nps' || data.type === 'ces') {
        emails = emails.map((l) => {
          delete l.attendant_key;
          delete l.evaluated_attendant;
          delete l.ticket_number;

          return l;
        });
      }

      const oldEmails = res.data.ratings.map((line) => line.email);

      return {
        step: data.metric === 'email' ? 2.5 : 3,
        distribution: {
          name: data.metric,
          emails,
          old_emails: oldEmails,
        },
        survey: {
          metric: data.type,
          option: `${data.scale}-${data.appearance}`,
          campaignName: data.name,
          greetingMessage: data.greeting_message,
          question: data.question,
          date: {
            startDate: new Date(`${data.started_at} 00:00`),
            endDate: new Date(`${data.finished_at} 00:00`),
          },
          apiKey: data.api_key,
          custom_questions: data?.custom_questions?.map((q) => ({
            ...q,
            appearance: q.scale ? `${q.scale}-${q.appearance}` : q.appearance,
            choices: q.choices?.split(';'),
          })),
        },
        template_email: data.template_email,
      };
    })
    .catch((err) => {
      notify('error', err.response.data.errors[0][languages[i18n.language]]);

      return err.response.data;
    });

const editCampaign = async (payload, id) =>
  api
    .put(`/campaign/${id}`, payload)
    .then((res) => {
      notify('success', i18n.t('notify.editCampaign.success'));

      return res.data;
    })
    .catch((err) =>
      notify('error', err.response.data.errors[0][languages[i18n.language]])
    );

const editPartialCampaign = async (payload, id) =>
  api
    .patch(`/campaign/${id}`, payload)
    .then((res) => {
      notify('success', i18n.t('notify.editCampaign.success'));

      return res.data;
    })
    .catch((err) =>
      notify('error', err.response.data.errors[0][languages[i18n.language]])
    );

const sendEmails = async (payload) =>
  api
    .post('/sendmail_user_not_answered', payload)
    .then((res) => {
      notify('warning', i18n.t('notify.sendEmails.warning'));

      return res.data;
    })
    .catch((err) =>
      notify('error', err.response.data.errors[0][languages[i18n.language]])
    );

const sendApiKeyByEmail = async (campaign_id) =>
  api
    .get(`/ex/api_key/${campaign_id}`)
    .then((res) => {
      notify('warning', i18n.t('notify.sendApiKeyByEmail.warning'));

      return res.data;
    })
    .catch((err) =>
      notify('error', err.response.data.errors[0][languages[i18n.language]])
    );

const getAnswerReasons = async ({ language }) =>
  api
    .get('/answer_reasons', {
      params: {
        language,
      },
    })
    .then((res) => ({
      answerReasons: res.data?.answer_reasons,
    }))
    .catch((err) => err.response?.data);

export {
  createCampaign,
  getCampaignAnswered,
  getCampaignMoreInfos,
  getDetailedCampaign,
  editCampaign,
  editPartialCampaign,
  sendEmails,
  sendApiKeyByEmail,
  getAnswerReasons,
  getTemplateMailCampaign,
  getCampaignQuestionDetail,
};
