import styled from 'styled-components';

import device from '~/assets/styles/settings/Devices';

export const Wrapper = styled.ol`
  display: flex;

  gap: 1rem;
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-xb);

  strong {
    color: var(--blue-90);
  }

  svg {
    height: .7rem;
    width: .7rem;
  }

  @media ${device.tablet} {
    display: none;
  }
`;
