import React from 'react';
import { AiFillStar } from 'react-icons/ai';

import {
  ButtonsWrapper,
  ButtonNPS,
  Neutral,
  Satisfied,
  VerySatisfied,
  Unsatisfied,
  VeryUnsatisfied,
  EmojisWrapper,
  Button,
  ButtonStar,
  StarsWrapper,
  PillsWrapper,
  Pill,
} from './styles';

const languages = {
  CES: {
    pt: {
      strongD: 'Discordo Totalmente',
      disagree: 'Discordo',
      partiallyD: 'Discordo Parcialmente',
      neutral: 'Neutro',
      partiallyA: 'Concordo Parcialmente',
      agree: 'Concordo',
      strongA: 'Concordo Totalmente'
    },
    en: {
      strongD: 'Strongly Disagree',
      disagree: 'Disagree',
      partiallyD: 'Partially Disagree',
      neutral: 'Neutral',
      partiallyA: 'Partially Agree',
      agree: 'Agree',
      strongA: 'Strongly Agree'

    }
  },
  CSAT: {
    smile: {
      pt: {
        notSatisfied: 'Muito Insatisfeito',
        disastified: 'Insatisfeito',
        neutral: 'Neutro',
        satisfied: 'Satisfeito',
        compleSatisfied: 'Muito Satisfeito'
      },
      en: {
        notSatisfied: 'Not at all satisfied',
        disastified: 'Dissastisfied',
        neutral: 'Neutral',
        satisfied: 'Satisfied',
        compleSatisfied: 'Completely satisfied'
      }
    }
  }
};

const CES = ({ activeButton, handlerButton, language = 'pt' }) => (
  <PillsWrapper activeButton={activeButton}>
    <Pill
      title="Discordo Totalmente"
      color="var(--red)"
      onClick={() => handlerButton(1)}
    >
      {languages.CES[language].strongD}
    </Pill>
    <Pill
      title="Discordo"
      color="var(--red-20)"
      onClick={() => handlerButton(2)}
    >
      {languages.CES[language].disagree}
    </Pill>
    <Pill
      title="Discordo Parcialmente"
      color="var(--red-40)"
      onClick={() => handlerButton(3)}
    >
      {languages.CES[language].partiallyD}
    </Pill>
    <Pill
      title="Neutro"
      color="var(--yellow-80)"
      onClick={() => handlerButton(4)}
    >
      {languages.CES[language].neutral}
    </Pill>
    <Pill
      title="Concordo Parcialmente"
      color="var(--green-40)"
      onClick={() => handlerButton(5)}
    >
      {languages.CES[language].partiallyA}
    </Pill>
    <Pill
      title="Concordo"
      color="var(--green-20)"
      onClick={() => handlerButton(6)}
    >
      {languages.CES[language].agree}
    </Pill>
    <Pill
      title="Concordo Totalmente"
      color="var(--green)"
      onClick={() => handlerButton(7)}
    >
      {languages.CES[language].strongA}
    </Pill>
  </PillsWrapper>
);

const NPS = ({ activeButton, handlerButton, quantity }) => (
  <ButtonsWrapper
    activeButton={activeButton}
    quantity={quantity}
    data-test={`selected-question-score-button-${activeButton}`}
  >
    {[...Array(parseInt(quantity, 10))].map((_, index) => (
      <ButtonNPS
        title="buttonNPS"
        key={Math.random()}
        currentVote={index + 1}
        onClick={() => handlerButton(index + 1)}
        quantity={quantity}
        data-test={`question-score-${index + 1}`}
      >
        {index + 1}
      </ButtonNPS>
    ))}
  </ButtonsWrapper>
);

const CSAT = ({
 type, quantity, activeButton, handlerButton, language = 'pt'
}) => (
  <>
    {type === 'smiley' ? (
      <EmojisWrapper activeButton={activeButton}>
        <Button title="Muito Insatisfeito" onClick={() => handlerButton(1)}>
          <p>{languages.CSAT.smile[language].notSatisfied}</p>
          <VeryUnsatisfied />
        </Button>
        {quantity === 5 && (
          <Button title="Insatisfeito" onClick={() => handlerButton(2)}>
            <p>{languages.CSAT.smile[language].disastified}</p>
            <Unsatisfied />
          </Button>
        )}
        <Button
          title="Neutro"
          onClick={() => handlerButton(quantity === 5 ? 3 : 2)}
        >
          <p>{languages.CSAT.smile[language].neutral}</p>
          <Neutral />
        </Button>
        {quantity === 5 && (
          <Button title="Satisfeito" onClick={() => handlerButton(4)}>
            <p>{languages.CSAT.smile[language].satisfied}</p>
            <Satisfied />
          </Button>
        )}
        <Button
          title="Muito Satisfeito"
          onClick={() => handlerButton(quantity === 5 ? 5 : 3)}
        >
          <p>{languages.CSAT.smile[language].compleSatisfied}</p>
          <VerySatisfied />
        </Button>
      </EmojisWrapper>
    ) : (
      <StarsWrapper activeButton={activeButton} quantity={quantity}>
        {[...Array(quantity)].map((_, index) => (
          <ButtonStar
            title="Star"
            onClick={() => handlerButton(index + 1)}
            key={Math.random()}
          >
            <AiFillStar />
          </ButtonStar>
        ))}
      </StarsWrapper>
    )}
  </>
);

export { CSAT, CES, NPS };
