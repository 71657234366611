import React from 'react';

import Button from 'components/User/Button';

import { useAdmin } from '~/contexts/Admin/AdminContext';

import { Wrapper, ButtonWrapper } from './styles';

export default function Admin() {
  const { handleTask } = useAdmin();
  const buttonOptions = {
    size: { width: '250px', height: '56px' },
    colors: { background: 'var(--blue-60)', text: 'var(--white)' }
  };
  return (
    <>
      <h4>Execução Manual de Rotinas</h4>
      <Wrapper>
        <ButtonWrapper>
          <Button
            title="Atualizar equipe de atendimento"
            options={buttonOptions}
            type="button"
            onClick={() => handleTask('update_uor_manager')}
          />
          <Button
            title="Interação CSAT"
            options={buttonOptions}
            type="button"
            onClick={() => handleTask('update_csat_gsti_campaign')}
          />
        </ButtonWrapper>
        <ButtonWrapper>
          <Button
            title="Interação NPS"
            options={buttonOptions}
            type="button"
            onClick={() => handleTask('update_nps_gsti_campaign')}
          />
          <Button
            title="Reenviar emails não enviados"
            options={buttonOptions}
            type="button"
            onClick={() => handleTask('email_not_sent')}
          />
        </ButtonWrapper>
      </Wrapper>
    </>
  );
}
