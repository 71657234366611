import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Button from '~/components/User/Button';

import { ButtonsWrapper } from '../styles';

const ButtonWrapperEmail = () => {
  const { t } = useTranslation();

  const previewOptions = {
    size: { width: '150px', height: '35px', fontSize: '13px' },
    colors: {
      background: 'var(--blue-marin)',
      text: 'var(--white)',
      fontSize: '13px',
    },
  };

  return (
    <>
      <p>
        <strong>{t('messages.observation')}</strong>:{t('messages.preview')}
      </p>
      <ButtonsWrapper>
        <Link
          to="/campaign/mail-preview"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button
            title="E-mail"
            options={previewOptions}
            data-test="Preview-email"
          />
        </Link>
        <Link to="/campaign/answer" target="_blank" rel="noopener noreferrer">
          <Button
            title={t('buttons.campaing')}
            options={previewOptions}
            data-test="Preview-campaign"
          />
        </Link>
      </ButtonsWrapper>
    </>
  );
};

export { ButtonWrapperEmail };
