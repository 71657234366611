import { format, formatDistance } from 'date-fns';
import enUS from 'date-fns/locale/en-US';
import ptBR from 'date-fns/locale/pt-BR';

const language = {
  'en-US': enUS,
  'pt-BR': ptBR,
};

function getDay(date, lan = 'pt-BR') {
  return format(date, 'EEE', {
    locale: language[lan],
  });
}

function formatDate(date, lan = 'pt-BR') {
  return format(date, 'd MMM', {
    locale: language[lan],
  });
}

function formatDateEN(date) {
  return format(date, 'yyyy-MM-dd');
}

function formatDatePT(date) {
  return format(date, "dd MMM 'de' yyyy", {
    locale: ptBR,
  });
}

function formatDateEn(date) {
  return format(date, 'MMM dd yyyy', {
    locale: enUS,
  });
}

function formatDateTimePT(date) {
  return format(date, "dd MMM 'de' yyyy HH:mm", {
    locale: ptBR,
  });
}

function getNextDays(date, nDays) {
  const currentDate = new Date(date);
  const day = currentDate.setDate(currentDate.getDate() + nDays);

  return day;
}

function distanceToDate(date, lan = 'pt-BR') {
  const firstDate = new Date().setHours(0);
  const distance = formatDistance(firstDate, date, {
    locale: language[lan],
  });

  return distance;
}

export {
  formatDate,
  formatDateEN,
  formatDatePT,
  formatDateEn,
  getNextDays,
  distanceToDate,
  getDay,
  formatDateTimePT,
};
