import { formatDate } from 'helpers/Dates';

import i18n from '~/translate/i18n';

function generateDates() {
  let currentDate = new Date();

  function addDays(input, days) {
    let date = new Date(input.valueOf());
    date.setDate(date.getDate() + days);

    return date;
  }

  const data = [...Array(5)].map(() => {
    currentDate = addDays(currentDate, 1);

    return formatDate(currentDate);
  });

  return data;
}

function generateLineChart(data) {
  const xData = data
    ? data.map((c) => formatDate(new Date(c.x.concat(' 00:00')), i18n.language))
    : [];
  const yData = data ? data.map((c) => c.y) : [];

  const lineChartData = {
    labels: xData,
    datasets: [
      {
        label: i18n.t('labels.averageGrade'),
        data: yData,
        fill: false,
        backgroundColor: '#002766',
        borderColor: '#9FC2DF',
      },
    ],
  };

  return lineChartData;
}

function generateBarChart(data) {
  const xData = data
    ? data.map((c) => formatDate(new Date(c.x.concat(' 00:00')), i18n.language))
    : [];
  const yData = data ? data.map((c) => c.y) : [];

  const barChartData = {
    labels: xData,
    datasets: [
      {
        label: i18n.t('labels.numberOfVotes'),
        data: yData,
        backgroundColor: ['rgba(57, 172, 62, 0.6)'],
        borderColor: ['#29962d'],
        borderWidth: 2,
      },
    ],
  };

  return barChartData;
}

function generateBarChartNPS(data) {
  const xData = [
    `${i18n.t('labels.grade')} 1`,
    `${i18n.t('labels.grade')} 2`,
    `${i18n.t('labels.grade')} 3`,
    `${i18n.t('labels.grade')} 4`,
    `${i18n.t('labels.grade')} 5`,
    `${i18n.t('labels.grade')} 6`,
    `${i18n.t('labels.grade')} 7`,
    `${i18n.t('labels.grade')} 8`,
    `${i18n.t('labels.grade')} 9`,
    `${i18n.t('labels.grade')} 10`,
  ];
  let yData = Array(10).fill(0);

  if (data) {
    data.map((elem) => yData.splice(elem.x - 1, 1, elem.y));
  } else {
    yData = [];
  }

  const barChartDataNPS = {
    labels: xData,
    datasets: [
      {
        label: i18n.t('labels.votes'),
        data: yData,
        backgroundColor: [
          'rgba(242, 69, 40, 0.6)',
          'rgb(245, 112, 40, 0.6)',
          'rgb(246, 150, 38, 0.6)',
          'rgb(241, 186, 35, 0.6)',
          'rgb(236, 219, 35, 0.6)',
          'rgb(212, 231, 37, 0.6)',
          'rgb(174, 226, 40, 0.6)',
          'rgb(139, 220, 42, 0.6)',
          'rgb(110, 215, 46, 0.6)',
          'rgb(108, 211, 50, 0.6)',
        ],
        borderColor: [
          'rgba(242, 69, 40, 0.6)',
          'rgb(245, 112, 40, 0.6)',
          'rgb(246, 150, 38, 0.6)',
          'rgb(241, 186, 35, 0.6)',
          'rgb(236, 219, 35, 0.6)',
          'rgb(212, 231, 37, 0.6)',
          'rgb(174, 226, 40, 0.6)',
          'rgb(139, 220, 42, 0.6)',
          'rgb(110, 215, 46, 0.6)',
          'rgb(108, 211, 50, 0.6)',
        ],
        borderWidth: 2,
      },
    ],
  };

  return barChartDataNPS;
}

function generateBarChart5Pill(data) {
  const xData = [
    `${i18n.t('labels.grade')} 1`,
    `${i18n.t('labels.grade')} 2`,
    `${i18n.t('labels.grade')} 3`,
    `${i18n.t('labels.grade')} 4`,
    `${i18n.t('labels.grade')} 5`,
  ];
  let yData = Array(5).fill(0);

  if (data) {
    data.map((elem) => yData.splice(elem.x - 1, 1, elem.y));
  } else {
    yData = [];
  }

  const barChartDataNPS = {
    labels: xData,
    datasets: [
      {
        label: i18n.t('labels.votes'),
        data: yData,
        backgroundColor: [
          'rgba(242, 69, 40, 0.6)',
          'rgb(245, 112, 40, 0.6)',
          'rgb(246, 150, 38, 0.6)',
          'rgb(236, 219, 35, 0.6)',
          'rgb(108, 211, 50, 0.6)',
        ],
        borderColor: [
          'rgba(242, 69, 40, 0.6)',
          'rgb(245, 112, 40, 0.6)',
          'rgb(246, 150, 38, 0.6)',
          'rgb(236, 219, 35, 0.6)',
          'rgb(108, 211, 50, 0.6)',
        ],
        borderWidth: 2,
      },
    ],
  };

  return barChartDataNPS;
}

function generateBarChart7Pill(data) {
  const xData = [
    `${i18n.t('labels.grade')} 1`,
    `${i18n.t('labels.grade')} 2`,
    `${i18n.t('labels.grade')} 3`,
    `${i18n.t('labels.grade')} 4`,
    `${i18n.t('labels.grade')} 5`,
    `${i18n.t('labels.grade')} 6`,
    `${i18n.t('labels.grade')} 7`,
  ];
  let yData = Array(7).fill(0);

  if (data) {
    data.map((elem) => yData.splice(elem.x - 1, 1, elem.y));
  } else {
    yData = [];
  }

  const barChartDataNPS = {
    labels: xData,
    datasets: [
      {
        label: i18n.t('labels.votes'),
        data: yData,
        backgroundColor: [
          'rgba(242, 69, 40, 0.6)',
          'rgb(245, 112, 40, 0.6)',
          'rgb(246, 150, 38, 0.6)',
          'rgb(241, 186, 35, 0.6)',
          'rgb(236, 219, 35, 0.6)',
          'rgb(110, 215, 46, 0.6)',
          'rgb(108, 211, 50, 0.6)',
        ],
        borderColor: [
          'rgba(242, 69, 40, 0.6)',
          'rgb(245, 112, 40, 0.6)',
          'rgb(246, 150, 38, 0.6)',
          'rgb(241, 186, 35, 0.6)',
          'rgb(236, 219, 35, 0.6)',
          'rgb(110, 215, 46, 0.6)',
          'rgb(108, 211, 50, 0.6)',
        ],
        borderWidth: 2,
      },
    ],
  };

  return barChartDataNPS;
}

const barChartData = {
  labels: ['1', '2', '3', '4', '5'],
  datasets: [
    {
      label: i18n.t('labels.numberOfVotes'),
      data: [12, 19, 3, 5, 2],
      backgroundColor: ['rgba(57, 172, 62, 0.6)'],
      borderColor: ['#29962d'],
      borderWidth: 2,
    },
  ],
};

const lineChartData = {
  labels: generateDates(),
  datasets: [
    {
      label: i18n.t('labels.averageGrade'),
      data: [1, 2, 3, 4, 5],
      fill: false,
      backgroundColor: '#002766',
      borderColor: '#9FC2DF',
    },
  ],
};

export {
  barChartData,
  lineChartData,
  generateLineChart,
  generateBarChart,
  generateBarChartNPS,
  generateBarChart5Pill,
  generateBarChart7Pill,
};
