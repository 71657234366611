import React, { useEffect, useRef } from 'react';

import { useField } from '@unform/core';

import { CheckBoxWrapper, CheckBoxStyle, Label } from './styles';

export default function CheckBox({ name, title, ...props }) {
  const inputRef = useRef(null);
  const { fieldName, registerField, defaultValue } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: (ref) => ref.current.checked,
      clearValue: (ref) => {
        ref.current.checked = false;
      },
      setValue: (ref, value) => {
        ref.current.checked = value;
      },
    });
  }, [defaultValue, fieldName, registerField]);

  return (
    <CheckBoxWrapper>
      <CheckBoxStyle
        id={fieldName}
        ref={inputRef}
        defaultValue={defaultValue}
        {...props}
      />
      <Label htmlFor={name}>{title}</Label>
    </CheckBoxWrapper>
  );
}
