import React from 'react';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';

import Styles from '~/assets/styles';
import ModalCertificate from '~/containers/Generic/ModalCertificate';
import Tracker from '~/matomo/Tracker';
import { store } from '~/redux/store';
import Routes from '~/routes';

function App() {
  return (
    <Provider store={store}>
      <Tracker>
        <Styles />
        <ToastContainer />
        <ModalCertificate />
        <Routes />
      </Tracker>
    </Provider>
  );
}

export default App;
