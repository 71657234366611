import React from 'react';
import { useTranslation } from 'react-i18next';

import Breadcrumb from '~/components/User/Breadcrumb';
import Pagination from '~/components/User/Pagination';
import Table from '~/components/User/Table';
import Filters from '~/containers/Generic/Filters';
import ModalTeams from '~/containers/Generic/ModalTeams';
import { useTeams } from '~/contexts/CSAT/TeamsContext';

import { NavWrapper, Wrapper } from './styles';

export default function Teams() {
  const {
    tableData, isLoadingData, isLoadingPagination, activePage, setActivePage,
    numberPages, setFilter, filterFields, surveyOptions, attendantsOptions,
    setFilterNav, isLoadingModalTeams, isModalTeamsOpen, modalTeamsData, setIsModalTeamsOpen,
    modalTeamsDetail, currentAttendant, totalElements, totalPerPage, setTotalPerPage
  } = useTeams();

  const { t } = useTranslation();

  return (
    <>
      <NavWrapper>
        <Breadcrumb trails={['CSAT']} currentPage={t('titles.attendants')} />
        <Filters
          surveyOptions={surveyOptions}
          agentOptions={attendantsOptions}
          setFilter={setFilterNav}
          setActivePage={setActivePage}
        />
      </NavWrapper>
      <Wrapper>
        <Table
          columns={
            [
              '#',
              t('tableColumns.name'),
              t('tableColumns.key'),
              t('tableColumns.meanGrade'),
              t('tableColumns.percentMean'),
              t('tableColumns.avaliations'),
              t('tableColumns.notResolved'),
              t('tableColumns.total'),
              t('tableColumns.percentAnswered'),
              '']
}
          customColumnWidth={['5', '16', '9', '10', '10', '10', '10', '8', '5', '5']}
          rows={tableData}
          isLoadingData={isLoadingData}
          setFilter={setFilter}
          filterFields={filterFields}
          disabledOrderedFields={['#', t('tableColumns.percentAnswered'), '']}
          emptyText="Não foram encontrados atendentes avaliados"
          hasSort
          modalTeamsDetail={modalTeamsDetail}
          actionModal
        />
        <Pagination
          totalElements={totalElements}
          numberPages={numberPages}
          isLoading={isLoadingPagination}
          activePage={activePage}
          setActivePage={setActivePage}
          totalPerPage={totalPerPage}
          setTotalPerPage={setTotalPerPage}
        />
        {isModalTeamsOpen && (
          <ModalTeams
            setIsModalOpen={setIsModalTeamsOpen}
            isLoadingData={isLoadingModalTeams}
            teamsData={modalTeamsData}
            currentAttendant={currentAttendant}
          />
        )}
      </Wrapper>
    </>
  );
}
