import styled, { css } from 'styled-components';

export const WrapperFilter = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;

  width: 170px;
  height: 40px;
  border-radius: 8px;

  background-color: var(--gray-10);
  color: var(--blue-60);

  font-size: 13px;
  font-weight: bolder;

  cursor: pointer;

  span > svg {
    height: 18px;
    width: 18px;

    position: absolute;
    right: 7px;
    bottom: 10px;

    color: #D1D1D1;
  }
`;

export const WrapperDate = styled.div`
  ${(p) => (p.isNotAbsolute
    ? css`
      width: 228px;
      position: inherit;
      transform: translate(0px, 10px);
    `
    : css`
      position: absolute;
      transform: translate(0px, 50px);
    `)
}

  z-index: 10;
`;

export const Calendar = styled.div`
  border-radius: 10px;
  box-shadow: 0 6px 12px rgba(27, 37, 86, 0.16);
  overflow: hidden;

  font-size: 11px;
`;

export const Popper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;

  color: var(--orange);
`;

export const Button = styled.button`
  width: 100px;
  height: 30px;

  font-size: var(--font-size-smlr);
  color: var(--white);
  background-color: var(--blue-60);

  border-radius: 8px;

  margin-bottom: var(--metrics-smlr);
`;
