import React, { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Form } from '@unform/web';

import Button from '~/components/User/Button';
import Input from '~/components/User/Input';
import InputTextArea from '~/components/User/InputTextArea';
import { useCampaign } from '~/contexts/Generic/SurveyContext';
import { notify } from '~/helpers/Toast';
import { getTemplateMailCampaign } from '~/services/Generic/Survey';
import { generateFormErrors } from '~/validators';
import { validateSetTemplateEmail } from '~/validators/User/CSAT';

import {
  ButtonsWrapper,
  InputTextAreaWrapper,
  Wrapper,
  ActionsWrapper,
} from './styles';

const languageTags = {
  pt: {
    evaluator_name: '{{nome_avaliador}}',
    iteraction_number: '{{numero_ocorrencia}}',
  },
  en: {
    evaluator_name: '{{evaluator_name}}',
    iteraction_number: '{{iteraction_number}}',
  },
};

export default function SetTemplateEmail() {
  const formRef = useRef(null);
  const { setDataWizard, backButtonOptions, nextButtonOptions, dataWizard } =
    useCampaign();

  const { t } = useTranslation();

  const handleNext = useCallback(
    async (formData) => {
      try {
        await validateSetTemplateEmail(formData, formRef);

        const mail = {
          ...formData,
          signature: [formData.signature, formData.signature_2],
        };
        delete mail.signature_2;

        setDataWizard((prev) => {
          let data = { ...prev, step: 5, template_email: mail };

          localStorage.setItem('wizardData', JSON.stringify(data));

          return data;
        });
      } catch (err) {
        generateFormErrors(err, formRef);
      }
    },
    [setDataWizard]
  );

  const handlePrev = useCallback(() => {
    setDataWizard((prev) => ({ ...prev, step: 3 }));
  }, [setDataWizard]);

  useEffect(() => {
    const startData = async () => {
      const template_email = await getTemplateMailCampaign(
        dataWizard.survey.metric,
        dataWizard.survey.language
      ).then((data) => data);

      const formData = {
        title: template_email?.title,
        body: template_email?.body,
        signature: template_email?.signature[0],
        signature_2: template_email?.signature[1],
      };

      formRef.current.setData(formData);
    };

    startData();
  }, [dataWizard]);

  const removeTag = useCallback((field, tag) => {
    const fieldValue = formRef.current?.getFieldValue(field);

    if (formRef.current) {
      formRef.current.setFieldValue(field, fieldValue.replace(tag, ''));
    }
  }, []);

  const addTag = useCallback(
    (field, tag) => {
      const fieldValue = formRef.current?.getFieldValue(field);

      if (fieldValue?.includes(tag)) {
        return notify('error', t('message.tagMessage'));
      }

      if (formRef.current) {
        formRef.current.setFieldValue(field, fieldValue.concat(tag));
      }
    },
    [t]
  );

  return (
    <Wrapper>
      <Form ref={formRef} onSubmit={handleNext}>
        <Input
          name="title"
          title={`${t('labels.templateEmailTitle')} *`}
          placeholder={t('placeholders.rateCampaign')}
        />
        <ActionsWrapper>
          <Button
            title={t('buttons.addEvaluatorName')}
            options={{
              size: { width: '160px', height: '20px', fontSize: '11px' },
              colors: { background: 'var(--gray-10)', text: 'var(--blue-60)' },
            }}
            type="button"
            onClick={() =>
              addTag(
                'title',
                languageTags[dataWizard.survey.language].evaluator_name
              )}
            data-test="add-name-avaliator"
          />
          <Button
            title={t('buttons.removeEvaluatorName')}
            options={{
              size: { width: '160px', height: '20px', fontSize: '11px' },
              colors: { background: 'var(--red-10)', text: 'var(--blue-60)' },
            }}
            type="button"
            onClick={() =>
              removeTag(
                'title',
                languageTags[dataWizard.survey.language].evaluator_name
              )}
            data-test="remove-name-avaliator"
          />
        </ActionsWrapper>
        <InputTextAreaWrapper data-test="Email-text-content">
          <span>{`${t('labels.templateEmailBody')} *`}</span>
          <InputTextArea
            name="body"
            placeholder="Informe o corpo do e-mail..."
          />
          {dataWizard.survey.metric === 'CSAT' && (
            <ActionsWrapper>
              <Button
                title={t('buttons.addInteractionNumber')}
                options={{
                  size: { width: '160px', height: '20px', fontSize: '11px' },
                  colors: {
                    background: 'var(--gray-10)',
                    text: 'var(--blue-60)',
                  },
                }}
                type="button"
                onClick={() =>
                  addTag(
                    'body',
                    languageTags[dataWizard.survey.language].iteraction_number
                  )}
                data-test="add-occurrence-number"
              />
              <Button
                title={t('buttons.removeInteractionNumber')}
                options={{
                  size: { width: '180px', height: '20px', fontSize: '11px' },
                  colors: {
                    background: 'var(--red-10)',
                    text: 'var(--blue-60)',
                  },
                }}
                type="button"
                onClick={() =>
                  removeTag(
                    'body',
                    languageTags[dataWizard.survey.language].iteraction_number
                  )}
                data-test="remove-occurrence-number"
              />
            </ActionsWrapper>
          )}
        </InputTextAreaWrapper>
        <Input
          name="signature"
          title={`${t('labels.templateEmailSign')} *`}
          placeholder={t('placeholders.signature')}
        />
        <Input
          name="signature_2"
          placeholder={`${t('placeholders.signature')}, ${t(
            'placeholders.signature2'
          )}`}
        />
        <ButtonsWrapper>
          <Button
            title={t('buttons.back')}
            options={backButtonOptions}
            onClick={handlePrev}
            data-test="previous-button"
          />
          <Button
            title={t('buttons.foward')}
            options={nextButtonOptions}
            type="submit"
            data-test="next-button"
          />
        </ButtonsWrapper>
      </Form>
    </Wrapper>
  );
}
