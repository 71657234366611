import { SET_USER_SIGNED_IN, SET_LOADED_ALL_DATA, SET_HAS_PERMISSIONS } from '~/redux/actions/Login';

const INITIAL_VALUE = {
  gotUserData: false,
  gotAllData: false,
  hasPermissions: 'loading'
};

const BoilerplateReducer = (state = INITIAL_VALUE, action) => {
  switch (action.type) {
    case SET_USER_SIGNED_IN:
      return { ...state, gotUserData: action.value };
    case SET_LOADED_ALL_DATA:
      return { ...state, gotAllData: action.value };
    case SET_HAS_PERMISSIONS:
      return { ...state, hasPermissions: action.value };
    default:
      return state;
  }
};

export default BoilerplateReducer;
