import React from 'react';
import { Bar } from 'react-chartjs-2';

const VerticalBar = ({ data, scale = 10 }) => {
  const maxScale = () => {
    const dataset = data.datasets[0].data;
    if (Math.max(...dataset) === 0 || Math.max(dataset) === 0) {
      return scale;
    }
    return Math.ceil(Math.max(...dataset) / scale) * scale;
  };

  const options = {
    scales: {
      y: {
        min: 0,
        max: maxScale(),
        ticks: {
          stepSize: 1,
        },
      },
    },
  };

  return (
    <Bar
      data={data}
      options={options}
      height="100%"
    />
  );
};

export default VerticalBar;
