import Lottie from 'react-lottie';

import styled from 'styled-components';

import detractor from '~/assets/images/animations/detractor.json';
import passive from '~/assets/images/animations/passive.json';
import promoter from '~/assets/images/animations/promoter.json';
import { fadeIn } from '~/assets/styles/settings/Animations';
import device from '~/assets/styles/settings/Devices';

export const NavWrapper = styled.nav`
  width: 100%;
  display: flex;

  justify-content: space-between;
  align-items: center;
`;

export const GeneralInfo = styled.section`
  display: flex;

  align-items: center;
  padding: 5rem 0;
  gap: 0.6rem;

  border-bottom: 1px solid var(--gray-10);
`;

export const CircleData = styled.div`
  height: 100%;
  width: 100%;

  top: 0;
  transform: translateY(60px);
  position: absolute;
  line-height: 2rem;

  text-align: center;

  span {
    color: var(--gray-80);
  }

  strong {
    display: block;

    color: var(--gray-90);
    font-size: calc(var(--font-size-xl) * 1.4);
  }
`;

export const GeneralData = styled.div`
  text-align: center;
  margin: 0 4rem;
  line-height: 2rem;

  span {
    color: var(--gray-80);
  }

  strong {
    display: block;

    font-size: var(--font-size-xl);
  }

  strong.red {
    color: var(--red);
  }

  strong.green {
    color: var(--green);
  }
`;

export const ChartsWrapper = styled.section`
  display: flex;
  flex-direction: column;
  gap: 2rem;

  padding: 3rem 0;
`;

export const Chart = styled.div`
  width: 100%;
  max-width: 900px;

  color: var(--blue-60);

  strong {
    display: block;
    font-size: var(--font-size-m);
  }

  @media ${device.tablet} {
    max-width: 550px;
  }
`;

const animationDetractor = {
  loop: true,
  autoplay: true,
  animationData: detractor,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  },
};

const animationPassive = {
  loop: true,
  autoplay: true,
  animationData: passive,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  },
};

const animationPromoter = {
  loop: true,
  autoplay: true,
  animationData: promoter,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  },
};

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const CardWrapper = styled.div`
  flex-basis: 45%;
  flex-direction: column;

  display: flex;

  padding: var(--metrics-sm);
  box-sizing: border-box;

  border-radius: 30px;

  animation: ${fadeIn} .8s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
`;

export const CardSubtitle = styled.p`
  color: var(--gray-60);

  font-size: var(--font-size-xs);
  margin-bottom: var(--metrics-sm);
`;

export const Group = styled.div`
  display: flex;
  gap: 1rem;
`;

export const GroupVertical = styled.div`
  min-width: 87px;

  display: flex;
  align-items: center;
  flex-direction: column;
  height: 8rem;
  padding: var(--metrics-sm);

  box-sizing: border-box;
  border-radius: 8px;
  box-shadow: -6px -6px 6px rgba(213, 213, 213, 0.25), 6px 6px 6px rgba(190, 190, 190, 0.25);

  transition: all .3s;

  filter: grayscale(1);

  &:hover {
    filter: grayscale(0);

    background-color: ${(p) => (p.isRed ? 'var(--red-20)' : p.isGreen ? 'var(--green-20)' : 'var(--yellow-80)')};
    transform: translateY(-5px);

    p, span {
      color: var(--white);
    }
  }

  p, span {
    color: var(--gray-60);
  }
`;

export const ValueGroup = styled.span`
  color: var(--gray-60);

  font-size: var(--font-size-xs);
  font-weight: bold;
`;

export const Detractor = styled(Lottie).attrs(() => ({
  options: animationDetractor,
  width: '30px',
  height: '30px'
}))``;

export const Passive = styled(Lottie).attrs(() => ({
  options: animationPassive,
  width: '30px',
  height: '30px'
}))``;

export const Promoter = styled(Lottie).attrs(() => ({
  options: animationPromoter,
  width: '30px',
  height: '30px',
}))`
`;

export const SubtitleGroup = styled.p`
  color: var(--gray-60);

  font-size: var(--font-size-xs);
  margin-bottom: var(--metrics-smlr);
  margin-top: var(--metrics-smlr);
`;
