import styled from 'styled-components';

export const Wrapper = styled.div`
  flex: 1;
  gap: 2rem;

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;

  margin-top: var(--metrics-m);
  margin-bottom: var(--metrics-xl);
`;

export const NavWrapper = styled.nav`
  width: 100%;
  display: flex;

  justify-content: space-between;
  align-items: center;
`;

export const Paragraph = styled.p`
    margin-top: var(--metrics-sm);
    line-height: 24px;

    font-size: var(--font-size-sm);
    color: var(--gray-50);
`;

export const ButtonWrapper = styled.div`
    display: flex;
    gap: 20px;
`;
