import React, { useState, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '~/components/User/Button';
import Modal from '~/components/User/Modal';
import Table from '~/components/User/Table';
import { useCampaign } from '~/contexts/Generic/SurveyContext';
import { csvJSON, hasDuplicateValues, validateEmail } from '~/helpers/Files';
import { notify } from '~/helpers/Toast';

import AddEmail from './AddEmail';
import {
  Wrapper,
  ButtonsWrapper,
  FileUpload,
  FileDownload,
  Paragraph,
} from './styles';

export default function SetEmails() {
  const {
    dataWizard,
    setDataWizard,
    backButtonOptions,
    nextButtonOptions,
    isEditting,
    isCopying,
    currentMetric,
  } = useCampaign();
  const [modalVisible, setModalVisible] = useState(false);
  const [modalWarningVisible, setModalWarningVisible] = useState(false);
  const [hasCSV, setHasCSV] = useState(false);
  const csvInputRef = useRef(null);
  const { t } = useTranslation();
  const addEmailButtonOptions = {
    size: { width: '190px', height: '40px' },
    colors: { background: 'var(--gray-10)', text: 'var(--blue-60)' },
  };
  const addButtonOptions = {
    size: { width: '190px', height: '42px' },
    colors: { background: 'var(--blue-90)', text: 'var(--white)' },
  };
  const columns = {
    CSAT: [
      t('tableHeaders.evaluatedKey'),
      t('tableHeaders.evaluatedPerson'),
      t('tableHeaders.evaluatorName'),
      t('tableHeaders.evaluatorEmail'),
      t('tableHeaders.tickerNumber'),
    ],
    NPS: [t('tableHeaders.evaluatorName'), t('tableHeaders.evaluatorEmail')],
    CES: [t('tableHeaders.evaluatorName'), t('tableHeaders.evaluatorEmail')],
    CUSTOM: [
      t('tableHeaders.evaluatedKey'),
      t('tableHeaders.evaluatedPerson'),
      t('tableHeaders.evaluatorName'),
      t('tableHeaders.evaluatorEmail'),
      t('tableHeaders.tickerNumber'),
    ],
  };
  const isCSAT = currentMetric === 'CSAT';
  const isCUSTOM = currentMetric === 'CUSTOM';

  const checkInvalidCSVEmailsNPS = useCallback(
    (emails) => {
      const hasInvalidEmail = emails
        .map((row) => Object.values(row).slice(0, 2))
        .some((value) => !validateEmail(value[1]));

      if (hasInvalidEmail) {
        notify('warning', t('messages.csvEmailWarining'));
      }

      return hasInvalidEmail;
    },
    [t]
  );

  const checkInvalidCSVEmailsCSAT = useCallback(
    (emails) => {
      const hasInvalidSize = emails.some(
        (row) => Object.values(row).length < 5
      );

      const hasInvalidEmail = emails
        .map((row) => Object.values(row).slice(0, 5))
        .some((value) => !validateEmail(value[3]));

      if (hasInvalidSize) {
        notify('warning', t('messages.csvSizeWarning'));
      } else if (hasInvalidEmail) {
        notify('warning', t('messages.csvEmailError'));
      }

      return hasInvalidSize || hasInvalidEmail;
    },
    [t]
  );

  const checkDuplicateEmails = useCallback(
    (allEmails) => {
      if (!hasDuplicateValues(allEmails)) {
        setDataWizard((prev) => ({
          ...prev,
          distribution: {
            ...prev.distribution,
            emails: allEmails,
          },
        }));
      } else {
        notify('warning', t('messages.importEmails'));
      }
    },
    [setDataWizard, t]
  );

  const handleFile = useCallback(
    (e) => {
      const { emails } = dataWizard.distribution;
      const file = e.target.files[0];
      const reader = new FileReader();
      let allEmails = [];

      reader.onloadend = (evt) => {
        const jsonCSV = csvJSON(evt.target.result);

        if (!isCSAT && !isCUSTOM) {
          allEmails = [...emails, ...jsonCSV];
          if (!checkInvalidCSVEmailsNPS(allEmails)) {
            checkDuplicateEmails(allEmails);
          }
        } else {
          const jsonCSVCSAT = jsonCSV.map((item) => ({
            attendant_key: item?.attendant_key?.toUpperCase(),
            evaluated_attendant: '',
            evaluator_name: item?.evaluator_name,
            email: item?.email,
            ticket_number: item?.ticket_number,
          }));
          allEmails = [...emails, ...jsonCSVCSAT];
          if (!checkInvalidCSVEmailsCSAT(allEmails)) {
            checkDuplicateEmails(allEmails);
          }
        }

        csvInputRef.current.value = '';
      };

      reader.readAsBinaryString(file);
      setHasCSV(true);
    },
    [
      dataWizard,
      checkInvalidCSVEmailsNPS,
      checkDuplicateEmails,
      checkInvalidCSVEmailsCSAT,
      isCSAT,
      isCUSTOM,
    ]
  );

  const handleNext = useCallback(() => {
    if (dataWizard.distribution.emails.length === 0) {
      return notify('errorForm', t('messages.noPerson'));
    }

    if (
      dataWizard.survey.metric.toLowerCase() === 'csat' &&
      dataWizard.distribution.emails.some(
        (item) => !!item.attendant_key === false
      )
    ) {
      return notify('errorForm', t('messages.noKey'));
    }

    setDataWizard((prev) => ({ ...prev, step: 3 }));
  }, [setDataWizard, dataWizard, t]);

  const handleModal = () => {
    if (hasCSV && (isCSAT || isCUSTOM)) {
      setModalWarningVisible(true);
    } else {
      handleNext();
    }
  };

  const handleRemoveRow = useCallback(
    (index) => {
      const { emails } = dataWizard.distribution;
      const filteredEmails = emails.filter((_, i) => index !== i);

      setDataWizard((prev) => ({
        ...prev,
        distribution: {
          ...prev.distribution,
          emails: filteredEmails,
        },
      }));
    },
    [setDataWizard, dataWizard]
  );

  return (
    <Wrapper>
      <ButtonsWrapper>
        <FileDownload download metric={currentMetric}>
          {t('links.exampleCSV')}
        </FileDownload>
        <FileUpload htmlFor="csv">{t('links.importCSV')}</FileUpload>
        <input
          id="csv"
          ref={csvInputRef}
          type="file"
          accept=".csv"
          onChange={handleFile}
        />
        <Button
          title={t('buttons.newEmail')}
          options={addEmailButtonOptions}
          onClick={() => setModalVisible(true)}
          data-test="Add-new-email"
        />
      </ButtonsWrapper>
      <Table
        columns={columns[currentMetric]}
        rows={dataWizard.distribution.emails}
        editEmails={dataWizard.distribution.old_emails}
        isCopying={isCopying}
        emptyText={t('messages.emptyText')}
        handleRemoveRow={(index) => handleRemoveRow(index)}
      />
      <ButtonsWrapper>
        {!isEditting && (
          <Button
            data-test="Voltar"
            title={t('buttons.back')}
            onClick={() => setDataWizard((prev) => ({ ...prev, step: 2 }))}
            options={backButtonOptions}
          />
        )}
        <Button
          data-test="Avançar"
          title={t('buttons.foward')}
          options={nextButtonOptions}
          onClick={handleModal}
        />
      </ButtonsWrapper>
      {modalVisible && (
        <Modal
          title={t('titles.newEmail')}
          options={{ height: '600px', width: '425px' }}
          setVisible={() => setModalVisible(false)}
        >
          <AddEmail />
        </Modal>
      )}

      {modalWarningVisible && (
        <Modal
          title={t('titles.warning')}
          options={{ height: '600px', width: '425px' }}
          setVisible={() => setModalWarningVisible(false)}
        >
          <Paragraph>{t('messages.modalWarning')}</Paragraph>
          <Button type="button" onClick={handleNext} options={addButtonOptions}>
            OK
          </Button>
        </Modal>
      )}
    </Wrapper>
  );
}
