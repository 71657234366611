import styled from 'styled-components';

export const Wrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;

  visibility: hidden;
  position: absolute;
  z-index: 2;

  min-width: 140px;
  height: 37px;

  padding: 5px 0;
  background-color: ${(p) => (p.color ? p.color : 'var(--gray-90)')};

  font-size: var(--font-size-smlr);
  color: var(--white);
  text-align: center;

  border-radius: .5rem;

  bottom: 150%;
  left: 50%;
  margin-left: -70px;

  &:after {
    content: " ";
    position: absolute;
    top: 100%; /* At the bottom of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: ${(p) => (p.color ? p.color : 'var(--gray-90)')} transparent transparent transparent;
  }
`;
