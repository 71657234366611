import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;

  gap: .8rem;
`;

export const WrapperInputSelect = styled.div`
  min-width: 85px;
  width: 85px;
`;

export const Button = styled.button`
  width: 32px;
  height: 32px;
  border-radius: 8px;

  font-weight: var(--font-weight-xb);
  font-size: var(--font-size-smlr);

  ${(p) => (p.isActive
    ? css`
      background-color: var(--blue-60);
      color: var(--white);
    ` : css`
      font-weight: var(--font-weight-l);
      background-color: var(--blue-10);
      color: var(--blue-40);
    `)}
`;

export const ButtonPN = styled.button`
  font-size: var(--font-size-sm);
`;
