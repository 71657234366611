import styled from 'styled-components';

export const BodyWrapper = styled.div`
  overflow-y: auto;
  overflow-x: hidden;

  max-height: 300px;
`;

export const Table = styled.table`
  width: 100%;
  margin-top: 15px;

  th, td {
    padding: 0.95rem 0.75rem;
    box-sizing: border-box;
    text-align: left;

    &:first-child {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;

      width: 200px;
    }

    &:last-child {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;

      width: 100px;
    }
  }

  th {
    color: var(--blue-90);
    background-color: var(--blue-10);
    font: 500 0.825rem Lexend, sans-serif;
    vertical-align: middle;
  }
`;

export const ThName = styled.td`
  vertical-align: middle;
  word-wrap: break-word;
  border-bottom: 2px solid var(--gray-10);

  color: var(--gray-60);
  font-size: 0.75rem;

`;
