import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Button from '~/components/User/Button';
import InputTextArea from '~/components/User/InputTextArea';
import { LoaderCircle } from '~/components/User/Loader';
import Modal from '~/components/User/Modal';
import { createBugReport } from '~/services/User/BuReport';
import { generateFormErrors } from '~/validators';
import { validateBugReportForm } from '~/validators/User/BugReport';

import { ConfirmationBug } from '../confirmationBug';
import {
  ModalForm,
  BugWrapper,
  ScreenshotWrapper,
  ButtonsWrapper,
  ModalWrapper,
} from './styles';

const BugReportModal = ({ handleCloseModal, image }) => {
  const { t } = useTranslation();
  const formRef = useRef(null);
  const { user } = useSelector((store) => store.boilerplate);
  const [textAreaValue, setTextAreaValue] = useState();
  const [isConfirmation, setIsConfirmation] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  const [issueUrl, setIssueUrl] = useState(1223);

  const handleSubmit = async ({ description }) => {
    const payload = {
      title: `bugReport ${user.key} ${user.name}`,
      description,
      screenshot: image.split(',')[1],
    };

    try {
      await validateBugReportForm(payload, formRef);
      setIsloading(true);

      await createBugReport(payload)
        .then((res) => {
          setIssueUrl(res.web_url);
        })
        .finally(() => {
          if (issueUrl) {
            setIsConfirmation(true);
          }
          setIsloading(false);
        });
    } catch (err) {
      generateFormErrors(err, formRef);
    }
  };

  const setVisible = () => {
    handleCloseModal();
    setIsConfirmation(false);
  };

  return (
    <Modal setVisible={setVisible} bgColor="var(--gray-60);">
      <ModalWrapper>
        {isLoading ? (
          <>
            <LoaderCircle />
            <h2>{t('bugReport.loadingText')}</h2>
          </>
        ) : isConfirmation ? (
          <ConfirmationBug handleCloseModal={setVisible} issueUrl={issueUrl} />
        ) : (
          <ModalForm ref={formRef} onSubmit={handleSubmit}>
            <BugWrapper>
              <h1>{t('bugReport.title')}</h1>
              <p>{t('bugReport.description')}</p>
              <InputTextArea
                name="description"
                onChange={(e) => setTextAreaValue(e.target.value)}
                placeholder={t('bugReport.areaPlaceholder')}
                style={{
                  maxWidth: 'unset',
                  borderRadius: '0px',
                  borderColor: 'var(--gray-90)',
                  borderWidth: '1px',
                  marginTop: '12px',
                }}
              />
              <span>
                {t('bugReport.characters')}: {textAreaValue?.length ?? 0}/400
              </span>
              <p>{t('bugReport.sendText')}</p>
              <p>{t('bugReport.teamText')}</p>
            </BugWrapper>
            <ScreenshotWrapper>
              <h2>{t('bugReport.screenshot')}</h2>

              <img alt="screenshot" src={image} />
              <ButtonsWrapper>
                <Button type="button" onClick={setVisible}>
                  {t('bugReport.cancel')}
                </Button>
                <Button type="submit">{t('bugReport.send')}</Button>
              </ButtonsWrapper>
            </ScreenshotWrapper>
          </ModalForm>
        )}
      </ModalWrapper>
    </Modal>
  );
};

export { BugReportModal };
