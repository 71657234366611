import styled from 'styled-components';

import csvExampleCES from '~/assets/others/example-ces.csv';
import csvExampleCSAT from '~/assets/others/example-csat.csv';
import csvExampleNPS from '~/assets/others/example-nps.csv';
import device from '~/assets/styles/settings/Devices';

export const Wrapper = styled.div`
  flex: 1;
  gap: 1rem;

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  margin-top: var(--metrics-m);
  margin-bottom: var(--metrics-xl);
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-self: flex-end;

  gap: 1rem;

  @media ${device.laptop} {
    align-self: center;
  }
`;

export const FileUpload = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 8px;
  width: 190px;
  height: 40px;

  transition: filter .3s;
  margin-top: var(--metrics-m);
  margin-bottom: var(--metrics-m);

  background-color: var(--blue-marin);
  color: var(--white);
  font-size: var(--font-size-m);

  cursor: pointer;

  &:hover {
    filter: brightness(.9);
  }
`;

export const FileDownload = styled.a.attrs((p) => ({
  href: (p.metric === 'CSAT' ? csvExampleCSAT : p.metric === 'NPS' ? csvExampleNPS : csvExampleCES)
}))`
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 8px;
  width: 190px;
  height: 40px;

  transition: filter .3s;
  margin-top: var(--metrics-m);
  margin-bottom: var(--metrics-m);

  border: 1px solid var(--blue-marin);
  color: var(--blue-marin);
  font-size: var(--font-size-m);

  cursor: pointer;

  &:hover {
    filter: brightness(.9);
  }
`;

export const Paragraph = styled.p`
    margin-top: var(--metrics-sm);
    line-height: 24px;

    font-size: var(--font-size-sm);
    color: var(--gray-50);
`;
