import React from 'react';

import UserSkeleton from '~/boilerplates/User';
import Home from '~/containers/User/Home';

export default function index() {
  return (
    <UserSkeleton>
      <Home />
    </UserSkeleton>
  );
}
