import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import ButtonLink from '~/components/User/Button';

import {
  Wrapper, DashboardPaper, ButtonWrapper
} from './styles';

export default function NotFound() {
  const { user } = useSelector((store) => store.boilerplate);
  const { t } = useTranslation();
  const buttonOptions = {
    size: { width: '230px', height: '56px' },
    colors: { background: 'var(--yellow-80)', text: 'var(--white)' }
  };

  return (
    <>
      <Wrapper>
        <DashboardPaper />
        <h2>Oops! Não há nada por aqui</h2>
        {user?.isAdmin && (
          <>
            <p>Deseja <strong>criar uma campanha?</strong></p>
            <ButtonWrapper>
              <Link to="/campaign/new">
                <ButtonLink
                  title={t('buttons.newCampaign')}
                  options={buttonOptions}
                />
              </Link>
            </ButtonWrapper>
          </>
        )}
      </Wrapper>
    </>
  );
}
