import React from 'react';
import { useSelector } from 'react-redux';

import { LoaderProfile } from 'components/User/Loader';

import { Wrapper, UserPhoto, Username } from './styles';

export default function Profile() {
  const { user } = useSelector((store) => store.boilerplate);

  return (
    <Wrapper>
      {user?.name ? (
        <>
          <UserPhoto src={user.photo} />
          <Username>{user.name}</Username>
        </>
      ) : (
        <LoaderProfile />
      )}
    </Wrapper>
  );
}
