import React from 'react';
import { useTranslation } from 'react-i18next';

import { Circle } from 'rc-progress';

import BarChart from '~/components/User/BarChart';
import Breadcrumb from '~/components/User/Breadcrumb';
import LineChart from '~/components/User/LineChart';
import { LoaderChart, LoaderCircleTwo } from '~/components/User/Loader';
import Filters from '~/containers/Generic/Filters';
import { useDashboard } from '~/contexts/CSAT/DashboardContext';
import {
  generateLineChart,
  generateBarChart,
  generateBarChart5Pill,
} from '~/mocks/Charts';

import {
  NavWrapper,
  CircleWrapper,
  CircleData,
  GeneralInfo,
  GeneralData,
  ChartsWrapper,
  Chart,
} from './styles';

export default function DashboardCSAT() {
  const {
    isLoadingData,
    dataDashboard,
    surveyOptions,
    attendantsOptions,
    setFilter,
  } = useDashboard();
  const { t } = useTranslation();

  return (
    <>
      <NavWrapper>
        <Breadcrumb trails={['CSAT']} currentPage={t('titles.overview')} />
        <Filters
          surveyOptions={surveyOptions}
          agentOptions={attendantsOptions}
          setFilter={setFilter}
        />
      </NavWrapper>
      <GeneralInfo>
        {isLoadingData ? (
          <LoaderCircleTwo />
        ) : (
          <>
            <CircleWrapper>
              <Circle
                percent={dataDashboard?.average_score}
                strokeWidth="10"
                trailWidth="10"
                trailColor="var(--gray-10)"
                strokeColor="var(--green)"
                id="progress"
              />
              <CircleData>
                <span>{t('titles.average')}</span>
                <strong>
                  {dataDashboard?.average_score === 0
                    ? '-'
                    : `${dataDashboard?.average_score} %`}
                </strong>
              </CircleData>
            </CircleWrapper>
            <GeneralData>
              <span>
                {dataDashboard?.quantity <= 1 ? t('titles.evaluation') : t('titles.evaluations')}
              </span>
              <strong className="red">
                {dataDashboard?.quantity === 0 ? '-' : dataDashboard?.quantity}
              </strong>
            </GeneralData>
          </>
        )}
      </GeneralInfo>
      <ChartsWrapper>
        {isLoadingData ? (
          <LoaderChart />
        ) : (
          <Chart>
            <strong>CSAT (Customer Satisfaction Score)</strong>
            <LineChart data={generateLineChart(dataDashboard?.scores)} />
          </Chart>
        )}
        {isLoadingData ? (
          <LoaderChart />
        ) : (
          <Chart>
            <strong>{t('titles.histogram')}</strong>
            <BarChart data={generateBarChart(dataDashboard?.histogram)} />
          </Chart>
        )}
        {isLoadingData ? (
          <LoaderChart />
        ) : (
          <Chart>
            <strong>{t('titles.votesWith5Grades')}</strong>
            <BarChart
              data={generateBarChart5Pill(dataDashboard?.analytics?.scale_5)}
            />
          </Chart>
        )}
      </ChartsWrapper>
    </>
  );
}
