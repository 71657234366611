import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import { PrivateRoute } from 'components/User/PrivateRoute';
import { TecRoute } from 'components/User/TecRoute';

import AnswerCampaign from '~/pages/Outside/Campaign';
import PreviewMail from '~/pages/Outside/PreviewMail';
import Admin from '~/pages/User/Admin';
import CopySurvey from '~/pages/User/Campaign/Copy';
import EditSurvey from '~/pages/User/Campaign/Edit';
import NewCampaign from '~/pages/User/Campaign/New';
import CESSurvey from '~/pages/User/CES/Campaign';
import CESDashboard from '~/pages/User/CES/Dashboard';
import CSATSurvey from '~/pages/User/CSAT/Campaign';
import CSATDashboard from '~/pages/User/CSAT/Dashboard';
import CSATFeed from '~/pages/User/CSAT/Feed';
import CSATTeams from '~/pages/User/CSAT/Teams';
import CustomSurvey from '~/pages/User/CUSTOM/Campaign'
import Dashboard from '~/pages/User/Dashboard';
import Home from '~/pages/User/Home';
import NotFound from '~/pages/User/NotFound';
import NPSSurvey from '~/pages/User/NPS/Campaign';
import NPSDashboard from '~/pages/User/NPS/Dashboard';

export default function UserRoutes() {
  const { user } = useSelector((store) => store.boilerplate);

  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <TecRoute exact path="/admin" component={Admin} user={user} />
      <Route exact path="/dashboard" component={Dashboard} />
      <PrivateRoute exact path="/campaign/new" component={NewCampaign} user={user} />
      <PrivateRoute exact path="/campaign/answer" component={AnswerCampaign} user={user} />
      <Route exact path="/campaign/edit/:id" component={EditSurvey} />
      <Route exact path="/campaign/copy/:id" component={CopySurvey} />
      <Route exact path="/csat/dashboard" component={CSATDashboard} />
      <Route exact path="/csat/teams" component={CSATTeams} />
      <Route exact path="/csat/feed" component={CSATFeed} />
      <Route exact path="/csat/campaigns" component={CSATSurvey} />
      <Route exact path="/nps/dashboard" component={NPSDashboard} />
      <Route exact path="/nps/campaigns" component={NPSSurvey} />
      <Route exact path="/ces/dashboard" component={CESDashboard} />
      <Route exact path="/ces/campaigns" component={CESSurvey} />
      <Route exact path="/custom/campaigns" component={CustomSurvey} />
      <Route exact path="/campaign/mail-preview" component={PreviewMail} />
      <Route path="*" component={NotFound} />
    </Switch>
  );
}
