import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaSearch } from 'react-icons/fa';

import Button from '~/components/User/Button';
import Input from '~/components/User/Input';
import { LoaderUserPrivileges } from '~/components/User/Loader';
import Modal from '~/components/User/Modal';
import TablePrivileges from '~/components/User/TablePrivileges';
import { useSurveyPrivileges } from '~/contexts/NPS/SurveyPrivilegesContext';
import { searchUser } from '~/services/Generic/SurveyPrivileges';
import { generateFormErrors } from '~/validators';
import { validateSearchUser } from '~/validators/User/NPS';

import AddUser from './AddUser';
import EditUser from './EditUser';
import {
  SearchWrapper, InputWrapper
} from './styles';

export default function SetPrivileges() {
  const formRef = useRef(null);
  const {
    isSettingPrivileges, tableData, isLoadingTableData,
    setIsSettingPrivileges, currentId, setFilter,
    filterFields, editData, setEditData, setSearchData,
    searchData, setIsSearchingUser, isSearchingUser,
    handleRemovePermission
  } = useSurveyPrivileges();
  const [searchKey, setSearchKey] = useState('');

  const addButtonOptions = {
    size: { width: '190px', height: '42px' },
    colors: { background: 'var(--blue-90)', text: 'var(--white)' }
  };

  const handleCloseModal = () => {
    setIsSettingPrivileges(false);
    setSearchData();
    setEditData();
  };

  const handleSearch = async (formData) => {
    try {
      setSearchData();
      setEditData();
      setIsSearchingUser(true);
      setSearchKey(formData.key);

      await validateSearchUser(formData, formRef);

      searchUser(formData.key).then((data) => {
        if (!data.code) {
          setSearchData(data);
        }
      }).finally(() => {
        setIsSearchingUser(false);
      });
    } catch (err) {
      setIsSearchingUser(false);
      generateFormErrors(err, formRef);
    }
  };

  const { t } = useTranslation();

  return (
    <>
      {isSettingPrivileges && (
        <Modal
          title={t('titles.whoCanView')}
          options={{
            height: '95vh', width: '596px', fontSize: '1rem', fontWeight: 'bolder'
          }}
          setVisible={handleCloseModal}
        >
          <SearchWrapper onSubmit={handleSearch} ref={formRef}>
            <InputWrapper>
              <Input
                name="key"
                placeholder="Insira a chave..."
                maxLength={8}
              >
                <FaSearch />
              </Input>
            </InputWrapper>
            <Button
              options={addButtonOptions}
              type="submit"
            >
              {t('buttons.search')}
            </Button>
          </SearchWrapper>
          {isSearchingUser ? (
            <LoaderUserPrivileges />
          ) : (
            <>
              {searchData && (
                <AddUser
                  data={searchData}
                  ukey={searchKey}
                  campaign={currentId}
                />
              )}
              {editData && (
                <EditUser
                  data={editData}
                  setEditData={setEditData}
                />
              )}
            </>
          )}
          <TablePrivileges
            columns={['name']}
            rows={tableData}
            isLoadingData={isLoadingTableData}
            setFilter={setFilter}
            filterFields={filterFields}
            handleRemovePermission={handleRemovePermission}
            setEditData={setEditData}
            setSearchData={setSearchData}
          />
        </Modal>
      )}
    </>
  );
}
