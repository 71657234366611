import styled from 'styled-components';

export const CheckBoxWrapper = styled.div`
  text-align: center;
  position: relative;
`;

export const CheckBoxStyle = styled.input.attrs(() => ({
  type: 'checkbox',
}))`
  all: unset;
  border-radius: 50%;
  border: 2px solid var(--gray-50);
  top: 0;
  left: 0;
  width: 13px;
  height: 13px;
  display: inline-block;
  position: relative;
  transition: 100ms;
  cursor: pointer;

  &:checked {
    background: var(--blue-90);
    border-color: var(--blue-50);
    display: inline-block;
    cursor: ${({ disabled }) => (disabled ? 'disabled' : 'pointer')};

    &:after {
      content: '';
      position: absolute;
      margin-left: -3.2px;
      margin-top: 20%;
      width: 5px;
      height: 8px;
      border: solid var(--white);
      border-width: 0 2px 2px 0;
      transition: background 250ms;
      transform: rotate(45deg);
    }
  }
`;

export const Label = styled.label`
  color: var(--blue-60);
  margin-left: 6px;
  top: 4px;
  position: absolute;
  white-space: nowrap;
  box-sizing: border-box;
  text-align: left;
  font-size: 13px;
`;
