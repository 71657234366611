import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Modal from '~/components/User/Modal';
import {
  getUrlBackend, getUrlBackendAlexandria
} from '~/helpers/Url';
import { setCertificateModal } from '~/redux/actions/Boilerplate';

import { Button, Paragraph } from './styles';

function ModalCertificate() {
  const { modalVisible } = useSelector((store) => store.boilerplate);
  const dispatch = useDispatch();

  function cleanUrlPath(url) {
    return url.replace(/api\/v1(.*)/g, 'health');
  }

  const openApiPages = () => {
    const currentPath = window.location.pathname;

    setTimeout(() => {
      if (!currentPath.includes('/campaign/answer/')) {
        window.open(cleanUrlPath(getUrlBackend()));
        window.open(cleanUrlPath(getUrlBackendAlexandria()));
      } else {
        window.open(cleanUrlPath(getUrlBackend()));
      }
    }, 1001);
  };
  const { t } = useTranslation();
  return (
    <>
      {modalVisible
        && (
          <Modal
            title={t('titles.errorComunicateAPI')}
            options={{ height: '600px', width: '425px' }}
            setVisible={() => setCertificateModal(dispatch, false)}
          >
            <Paragraph>
              {t('messages.addCertificate')}
              <Button type="button" id="btn" onClick={openApiPages}> {t('buttons.clickHere')} </Button>
              {t('messages.accept')},
              <Button type="button" id="btn-reload" onClick={() => window.location.reload()}> {t('buttons.refresh')} </Button>
              {t('messages.thisPortal')}. <br /><br />
              {t('messages.callContact')}.
            </Paragraph>
          </Modal>
        )}
    </>
  );
}

export default ModalCertificate;
