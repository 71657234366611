import { getUrlSSO, getTokenName } from '~/helpers/Url';

const TOKEN_KEY = getTokenName();
const REDIRECT_KEY = 'redirect_url';

const asyncLocalStorage = {
  setItem: (key, value) => Promise.resolve().then(() => {
    localStorage.setItem(key, value);
  }),
};

function getRedirectUrl() {
  const url = localStorage.getItem(REDIRECT_KEY)
    ? localStorage.getItem(REDIRECT_KEY)
    : '/';
  return url;
}

function setToken(token) {
  localStorage.setItem(TOKEN_KEY, token);

  return window.location.assign(getRedirectUrl());
}

function getToken() {
  return localStorage.getItem(TOKEN_KEY);
}

function destroyToken() {
  const URL_SSO = getUrlSSO();
  const CURRENT_URL = window.location.pathname;

  if (!CURRENT_URL.includes('/campaign/answer/')) {
    localStorage.removeItem(TOKEN_KEY);

    asyncLocalStorage.setItem(REDIRECT_KEY, CURRENT_URL).then(() => {
      window.location.assign(URL_SSO);
    });
  }
}

export {
  getToken, destroyToken, setToken
};
