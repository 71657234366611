import styled from 'styled-components';

export const Paragraph = styled.p`
    margin-top: var(--metrics-sm);
    line-height: 24px;

    font-size: var(--font-size-sm);
    color: var(--gray-50);
`;

export const Button = styled.button`
    color: var(--blue-marin);
    white-space: pre-wrap;
    font-size: var(--font-size-sm);

    margin: 0;
    padding: 0;
    border: none;
    cursor: pointer;
    background: none;
`;
