import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import BBimage from '~/assets/images/generic/bb-image.png';
import Button from '~/components/User/Button';
import InputCheckbox from '~/components/User/InputCheckbox';
import InputRadioQuestionarie from '~/components/User/InputRadioCampaign';
import InputTextArea from '~/components/User/InputTextArea';
import CustomQuestions from '~/containers/Outside/Campaign/CustomQuestions';
import { notify } from '~/helpers/Toast';
import { getAnswerReasons } from '~/services/Generic/Survey';
import {
  getCampaign,
  createAnswer,
  getIPData,
} from '~/services/Outside/Survey';
import { validateCreateAnswer } from '~/validators/Outside/Survey';

import Metric from './Metric';
import {
  Wrapper,
  Header,
  Card,
  ImageFinish,
  QuestionOne,
  QuestionTwo,
  HeaderTitle,
  ContentWrapper,
  ContentTitle,
  ContentFinishWrapper,
  ErrorMessage,
  InputCheckboxWrapper,
  HeaderBBimage,
} from './styles';

const languages = {
  pt: {
    headerTitle: 'Pesquisa de satisfação',
    greetingMessage: 'Obrigado por responder a pesquisa.',
    greeting: 'Olá',
    inviteMessage: 'Queremos saber a sua opinião.',
    questionsLabel: 'Questões a serem respondidas',
    isSolvedLabel: 'O seu problema foi resolvido? ',
    isSolvedOptions: {
      yes: 'Sim',
      no: 'Não',
    },
    feedbackLabel: 'Conte-nos mais sobre sua experiência',
    errorScore: 'É necessário inserir uma pontuação.',
    errorReason: 'É necessário selecionar um motivo.',
    errorSolved: 'É necessário informar se o problema foi resolvido',
    sendLabel: 'Enviar',
  },
  en: {
    headerTitle: 'Customer Satisfaction Survey',
    greetingMessage: 'Thank you for you time and your answers',
    greeting: 'Dear ',
    inviteMessage: 'We would like to hear you!',
    questionsLabel: 'Questions to be answered',
    isSolvedLabel: 'Did we solve your problem? ',
    isSolvedOptions: {
      yes: 'Yes',
      no: 'No',
    },
    feedbackLabel: 'Please share your experience with us:',
    errorScore: 'You must enter a score.',
    errorReason: 'You must select a reason.',
    errorSolved: 'It is necessary to inform if the problem has been solved',
    sendLabel: 'Send',
  },
};

export default function CampaignOutside({ hash }) {
  const [showGreeting, setShowGreeting] = useState(false);
  const [alreadyAnswered, setAlreadyAnswered] = useState(false);
  const [errorMetric, setErrorMetric] = useState('');
  const [optionValue, setOptionValue] = useState();
  const [data, setData] = useState();
  const [messageError, setMessageError] = useState('');
  const [userIP, setUserIP] = useState('');
  const [custom_answers, setCustom_answers] = useState([]);
  const [custom_answersErrors, setCustom_answersErrors] = useState(false);
  const formRef = useRef(null);
  const buttonOptionsYes = {
    size: { width: '120px', height: '40px' },
    colors: { background: 'var(--blue-90)', text: 'var(--white)' },
  };
  const isCSAT = data?.metric?.toUpperCase() === 'CSAT';
  const isCUSTOM = data?.metric?.toUpperCase() === 'CUSTOM';
  const [errorReason, setErrorReason] = useState('');


  const CheckMandatoryAnswers = useCallback(() => {
    const indexCustomQuestionsMandatory = []
    data.custom_questions.forEach((item, index)=> {
      
      if (item?.is_mandatory || item?.is_mandatory === undefined){
        indexCustomQuestionsMandatory.push(item.id ?? index)
      }
     
    })

    const indexCustomAnswers = custom_answers.map((item)=> item.question_id)

    return indexCustomQuestionsMandatory.every(arr=> indexCustomAnswers.includes(arr))
    // eslint-disable-next-line
  }, [custom_answers, data?.custom_questions]); 


  

  const handleSubmit = useCallback(
    async (formData) => {
     
      try {
        if(isCUSTOM){
          setOptionValue(true)
        }
        
        if (!optionValue) {
          setErrorMetric(languages[data.language]?.errorScore);
        } else {
          setErrorMetric('');
        }
        
        if (data?.custom_questions) {
          setCustom_answersErrors(false);
          // eslint-disable-next-line no-unused-expressions
          if (!CheckMandatoryAnswers()) {
            setCustom_answersErrors(true);
            return;
          }
        }
        
        if (isCSAT) {
          await validateCreateAnswer(formData, formRef)
          if (optionValue && !formData.answered_reason[0]) {
            setErrorReason(languages[data.language]?.erroReason);
            return;
          }
          if (optionValue && formData.answered_reason[0]) {
            setErrorReason('');
          }
        }
      
        if (hash) {
          let payload = {
            ...formData,
            value: optionValue,
            custom_answers,
          };

          if (isCUSTOM){
            delete payload.value
          }

          if (isCSAT) {
            const [answer] = formData.answered_reason;
            payload.solved = parseInt(formData.solved, []);
            payload.answered_reason = answer;
            payload.userIP = userIP;
          }

          if (isCUSTOM ? !custom_answersErrors : optionValue) {
            createAnswer(hash, payload).then(() => {          
              setShowGreeting(true)
            })
            .catch((err) => {
              const { message } = err.response.data?.errors[0];
              notify('error', message)
            })
          }
        } else if (isCUSTOM ? !custom_answersErrors : optionValue) {
          setShowGreeting(true);
        }
      } catch (err) {
        formRef.current.setFieldError(
          'solved',
          languages[data.language]?.errorSolved
        );
      }
    },
    [optionValue,
      isCSAT,
      isCUSTOM,
      data,
      hash,
      custom_answersErrors,
      custom_answers,
      userIP,
      CheckMandatoryAnswers]
  );

  const positive_options = data?.answerReasons?.positive_options?.map(
    (option, index) => ({
      id: index + 2,
      value: option,
      label: option,
    })
  );

  const negative_options = data?.answerReasons?.negative_options?.map(
    (option, index) => ({
      id: index + 2,
      value: option,
      label: option,
    })
  );

  const handleOptions = (options, id) =>
    options.map((option, index) => ({
      id: `${index}${id}`,
      value: option,
      label: option,
    }));

  const handleCustomAnswer = (index, val) => {
    const item = { question_id: index, answer: val };
    setCustom_answers((prev) => [
      ...prev.filter((q) => q.question_id !== index),
      item,
    ]);
    setCustom_answersErrors(false);
  };

  useEffect(() => {
    const getData = async () => {
      try {
        let ip = '';
        if (hash?.length === 32) {
          await getIPData()
            .then((res) => {
              if (res.IPv4) {
                setUserIP(res.IPv4);
                ip = res.IPv4;
              }
            })
            .catch((err) => err);
        }

        if (hash) {
          await getCampaign(hash, ip)
            .then((campaignData) => {
              if (!campaignData?.errors) {
                setData(campaignData);

                return;
              }
              const { code, message } = campaignData?.errors[0];
              if (code === 4010) {
                setAlreadyAnswered(true);
                setMessageError(message);
              } else {
                throw new Error(message);
              }
            })
            .catch((err) => err);
        } else {
          let localData = JSON.parse(localStorage.getItem('wizardData')).survey;
          localData = {
            ...localData,
            description: 'Caso X',
            interaction: 'INT111111111111111',
            custom_questions: localData?.custom_questions?.filter(
              (q) => !q.id_to_deactivate
            ),
          };
          setData(localData);
          getAnswerReasons({
            language: localData.language,
          }).then((resp) => {
            setData((prev) => ({ ...prev, answerReasons: resp.answerReasons }));
          });
        }
      } catch (err) {
        return err;
      }
    };
    getData();
  }, [hash]);

  const hasTitle = useMemo(
    () => data?.interaction || data?.description,
    [data]
  );

  const NumberQuestion = () => {
    
    return (
      <>
        <QuestionOne data-test="Main-question-title">
          <span>{isCSAT ? '2' : '1'}.</span> {data?.question} *
        </QuestionOne>
        <Metric
          title={data?.metric}
          option={data?.option}
          setOptionValue={setOptionValue}
          error={errorMetric}
          language={data.language}
        />
        {(optionValue && isCSAT) && (
        <>
          {optionValue > 3 ? (
            <>
              <p>
                <span>2.1</span>{' '}
                {data.answerReasons?.positive_question}
              </p>
              <InputCheckboxWrapper>
                <InputCheckbox
                  name="answered_reason"
                  options={positive_options}
                />
              </InputCheckboxWrapper>
            </>
          ) : (
            <>
              <p>
                <span>2.1</span>{' '}
                {data.answerReasons?.negative_question}
              </p>
              <InputCheckboxWrapper>
                <InputCheckbox
                  name="answered_reason"
                  options={negative_options}
                />
              </InputCheckboxWrapper>
            </>
          )}

          <ErrorMessage> {errorReason} </ErrorMessage>
        </>
      )}
      
      </>
    )}

  return (
    <Wrapper>
      <Header />
      {alreadyAnswered ? (
        <>
          <HeaderBBimage src={BBimage} />
          <HeaderTitle>
            <h1>{languages[data?.language || 'pt'].headerTitle}</h1>
          </HeaderTitle>
          <ContentFinishWrapper>
            <ImageFinish />
            <p>{messageError}</p>
          </ContentFinishWrapper>
        </>
      ) : (
        <Card ref={formRef} onSubmit={handleSubmit}>
          <HeaderBBimage src={BBimage} />

          {data && (
            <>
              {showGreeting ? (
                <>
                  <HeaderTitle>
                    <h1>{languages[data.language]?.headerTitle}</h1>
                  </HeaderTitle>
                  <ContentFinishWrapper>
                    <ImageFinish />
                    <p>
                      {data?.greetingMessage || languages[data.language]?.greetingMessage}
                    </p>
                  </ContentFinishWrapper>
                </>
              ) : (
                <>
                  <HeaderTitle>
                    <h1>{languages[data.language]?.headerTitle}</h1>
                    <strong>
                      {data.user_name &&
                        `${languages[data.language]?.greeting}, ${
                          data.user_name
                        }. `}
                      {languages[data.language]?.inviteMessage}
                    </strong>
                  </HeaderTitle>
                  <ContentWrapper>
                    <ContentTitle>
                      {' '}
                      {hasTitle ? (
                        <>
                          {' '}
                          {data.interaction}{' '}
                          {data.description && data.interaction && ' - '}{' '}
                          {data.description}{' '}
                        </>
                      ) : (
                        languages[data.language]?.questionsLabel
                      )}
                    </ContentTitle>
                    {isCSAT && (
                      <>
                        <p data-test="Main-question-solved-title">
                          <span>1.</span>{' '}
                          {languages[data.language]?.isSolvedLabel} *
                        </p>
                        <InputRadioQuestionarie
                          options={[
                            {
                              id: 1,
                              value: 1,
                              label:
                                languages[data.language]?.isSolvedOptions.yes,
                            },
                            {
                              id: 0,
                              value: 0,
                              label:
                                languages[data.language]?.isSolvedOptions.no,
                            },
                          ]}
                          name="solved"
                        />
                      </>
                    )}
                    {!isCUSTOM && (
                    <NumberQuestion />
                  )}

                    {data?.custom_questions?.map((question, index) => (
                      <>
                        <p
                          data-test={`Extra-question-title-${
                            isCSAT ? index + 3 : isCUSTOM? index + 1 : index + 2
                          }`}
                        >
                          <span>{isCSAT ? index + 3 : isCUSTOM? index + 1 : index + 2}. </span>
                          {question.question} {question?.is_mandatory || question?.is_mandatory === undefined ? '*' : ''}
                        </p>
                        <CustomQuestions
                          question={question}
                          index={index}
                          custom_answers={custom_answers}
                          handleCustomAnswer={handleCustomAnswer}
                          handleOptions={handleOptions}
                          custom_answersErrors={custom_answersErrors}
                          language={data.language}
                        />
                      </>
                    ))}

                    {!isCUSTOM && (
                    <>
                      <QuestionTwo>
                        <span>
                          {data?.custom_questions?.length
                          ? isCSAT
                            ? data?.custom_questions?.length + 3
                            : data?.custom_questions?.length + 2
                          : isCSAT
                          ? 3
                          : 2}
                          .{' '}
                        </span>
                        {languages[data.language]?.feedbackLabel}
                      </QuestionTwo>
                      <InputTextArea name="observation" />
                    </>
                  )}
                    <Button
                      title={languages[data.language]?.sendLabel}
                      options={buttonOptionsYes}
                    />
                  </ContentWrapper>
                </>
              )}
            </>
          )}
        </Card>
      )}
    </Wrapper>
  );
}
