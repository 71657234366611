import styled from 'styled-components';

export const StyledLi = styled.li`
  border: 3px solid #b4b9c1;
  border-radius: 4px;
  background-color: #fefefe;

  max-height: ${({ isOpen }) => (isOpen ? '800px' : '60px')};
  max-width: 600px;
  margin: 1rem auto 0;

  transition: max-height 0.5s;

  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem;

    background-color: ${({ isOpen }) => (isOpen ? '#e8efff' : '#fefefe')};
  }

  > h3 {
    color: #152766;
    font-weight: 700;
  }

  svg {
    fill: #313338;
  }
`;
