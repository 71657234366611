import React from 'react';
import { useTranslation } from 'react-i18next';

import Modal from '~/components/User/Modal';
import TableMoreInfos from '~/components/User/TableMoreInfos';

export default function ModalMoreInfos({
  setIsModalResendEmailOpen,
  moreInfosData,
  isLoadingData,
  type,
}) {
  const handleCloseModal = () => {
    setIsModalResendEmailOpen((prev) => !prev);
  };
  const { t } = useTranslation();

  const columns = {
    CSAT: [
      '#',
      t('tableColumns.attendant'),
      t('tableColumns.ticketNumber'),
      t('tableColumns.resolved'),
      t('tableColumns.sendDate'),
      t('tableColumns.answeredDate'),
      t('tableColumns.grade'),
      t('tableColumns.observations'),
      t('tableColumns.description'),
    ],
    CES: [
      '#',
      t('tableColumns.evaluator'),
      t('tableColumns.sendDate'),
      t('tableColumns.answeredDate'),
      t('tableColumns.grade'),
      t('tableColumns.observations'),
      t('tableColumns.description'),
    ],
    NPS: [
      '#',
      t('tableColumns.evaluator'),
      t('tableColumns.sendDate'),
      t('tableColumns.answeredDate'),
      t('tableColumns.grade'),
      t('tableColumns.observations'),
      t('tableColumns.description'),
    ],

    CUSTOM: [
      '#',
      t('tableColumns.evaluator'),
      t('tableColumns.sendDate'),
      t('tableColumns.answeredDate'),
      t('tableColumns.observations'),
      t('tableColumns.description'),
    ],
  };

  return (
    <Modal
      title={t('titles.campaignInfo')}
      options={{
          height: '95vh',
          width: '95vw',
          fontSize: '1rem',
          fontWeight: 'bolder',
          overflowY: 'unset',
        }}
      setVisible={handleCloseModal}
    >
      <TableMoreInfos
        rows={moreInfosData}
        columns={columns[type]}
        type={type}
        isLoadingData={isLoadingData}
      />
    </Modal>
  );
}
