import { NavLink } from 'react-router-dom';

import styled from 'styled-components';

export const SubLink = styled(NavLink).attrs(() => ({
  activeStyle: {
    borderLeft: '2px solid var(--white)'
  }
}))`
  font-weight: var(--font-weight-);

  padding-left: var(--metrics-sm);
`;
