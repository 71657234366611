import styled from 'styled-components';

export const Wrapper = styled.div`
  margin-top: var(--metrics-sm);
`;

export const Label = styled.label`
  color: var(--blue-50);

  font-family: Inter, sans-serif;
  font-weight: var(--font-weight-b);
  font-size: 0.855rem;
`;

export const ErrorMessage = styled.p`
  margin-top: 5px;

  color: var(--red);
  font-size: 10px;
`;

export const Tooltip = styled.div`
  margin-left: 240px;
  cursor: pointer;
  bottom: 2px;

  position: relative;
  svg {
    fill: var(--yellow-80);
  }

  :hover {
    span {
      display: block;
    }
  }

  span {
    position: absolute;
    left: -275px;
    display: none;

    background: var(--gray-10);
    color: var(--gray-60);
    font-size: 12px;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid var(--gray-40);
    white-space: nowrap;
  }
`;
