import React from 'react';

import InputCheckbox from '~/components/User/InputCheckbox';
import InputRadioQuestionarie from '~/components/User/InputRadioCampaign';
import InputTextArea from '~/components/User/InputTextArea';

import Metric from '../Metric';
import { ErrorMessage, InputCheckboxWrapper } from '../styles';

export default function CustomQuestions({
  question,
  index,
  custom_answers,
  handleCustomAnswer,
  handleOptions,
  custom_answersErrors,
  language,
}) {

  
  const is_mandatory = question?.is_mandatory === undefined ? true : question?.is_mandatory

  const showCustomQuestion = () => {
    if (question.id_to_deactivate) {
      return;
    }
    const id = question.id || index;
    if (question.appearance === 'choices') {
      const options = question.choices.includes(';')
        ? question.choices.split(';')
        : question.choices;
      return (
        <>
          <InputCheckboxWrapper>
            <InputCheckbox
              name={`custom_answers[${index}].answer`}
              options={handleOptions(options, id)}
              setOptionValue={(val) => handleCustomAnswer(id, val)}
            />
          </InputCheckboxWrapper>
          {(custom_answersErrors && is_mandatory) &&
            !custom_answers.some((item) => item.question_id === id) && (
              <ErrorMessage>É necessário selecionar uma opção.</ErrorMessage>
            )}
        </>
      );
    }
    if (question.appearance === 'bool') {
      return (
        <>
          <InputRadioQuestionarie
            options={[
              { id: `s${id}`, label: 'sim', value: 1 },
              { id: `n${id}`, label: 'não', value: 0 },
            ]}
            setOptionValue={(val) => handleCustomAnswer(id, val)}
            name={`custom_answers[${index}].answer`}
          />
          {(custom_answersErrors && is_mandatory) &&
            !custom_answers.some((item) => item.question_id === id) && (
              <ErrorMessage>É necessário selecionar uma opção.</ErrorMessage>
            )}
        </>
      );
    }

    if (question.appearance === 'text') {
      return (
        <>
          <InputTextArea 
            name={`custom_answers[${index}].answer`}
            onChange={(e) => handleCustomAnswer(id, e.target.value)}
      
          />
          {(custom_answersErrors && is_mandatory) &&
            !custom_answers.some((item) => item.question_id === id) && (
              <ErrorMessage>O texto é obrigatótio.</ErrorMessage>
            )}
        </>
      );
    }

    const option = question.appearance.includes('-')
      ? question.appearance
      : `${question.scale}-${question.appearance}`;
    
   
    const metric = option.includes('pill') ? 'NPS' : 'CSAT';
    return (
      <Metric
        title={metric}
        option={option}
        setOptionValue={(val) => handleCustomAnswer(id, val)}
        error={
          (custom_answersErrors && is_mandatory) &&
          !custom_answers.some((item) => item.question_id === id) &&
          'É necessário inserir uma pontuação.'
        }
        language={language}
        name={`custom_answers[${index}].answer`}
      />
    );
  };

  return <>{showCustomQuestion()}</>;
}
