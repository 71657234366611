import styled from 'styled-components';

import device from '~/assets/styles/settings/Devices';

export const Wrapper = styled.div`
  gap: 1rem;

  max-width: 700px;

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  margin-top: var(--metrics-m);
  margin-bottom: var(--metrics-xl);

  input[name="signature_2"] {
    transform: translateY(-20px);
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  gap: 1rem;

  @media ${device.laptop} {
    justify-content: center;
  }
`;

export const InputTextAreaWrapper = styled.div`
  margin-top: var(--metrics-sm);

  span {
    color: var(--blue-50);

    font-family: Inter, sans-serif;
    font-weight: var(--font-weight-b);
    font-size: 0.855rem;
  }

  textarea {
    font: 300 0.8rem Inter,sans-serif;
    color: var(--gray-60);

    margin-top: 8px;

    max-width: inherit;
  }
`;

export const ActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  gap: .5rem;

  @media ${device.laptop} {
    justify-content: center;
  }

  button {
    margin: var(--metrics-smlr) 0;
  }
`;
