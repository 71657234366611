import React from 'react';
import { useTranslation } from 'react-i18next';
import { IoIosCalendar } from 'react-icons/io';

import { formatDate, getDay } from '~/helpers/Dates';

import RankCard from '../RankCard';
import {
  Wrapper, DateWrapper, Calendar, WeekDay, Date, Line, WrapperCards
} from './styles';

export default function FeedDate({ date, ranking }) {
  const { i18n } = useTranslation();
  const weekDay = getDay(date, i18n.language);
  const yearDay = formatDate(date, i18n.language);

  return (
    <>
      <Wrapper>
        <Calendar>
          <IoIosCalendar />
        </Calendar>
        <DateWrapper>
          <WeekDay>{weekDay}</WeekDay>
          <Date>{yearDay}</Date>
        </DateWrapper>
        <Line />
      </Wrapper>
      <WrapperCards>
        {ranking.map((attendant) => (
          <RankCard data={attendant} key={attendant.position} />
        ))}
      </WrapperCards>
    </>
  );
}
