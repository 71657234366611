import { formatDatePT, distanceToDate, formatDateEn } from '~/helpers/Dates';
import api from '~/services';

const language = {
  'en-US': formatDateEn,
  'pt-BR': formatDatePT,
};

function getFilters(page, filterField, filterNav, totalPerPage) {
  let params = {
    type: 'ces',
    total_per_page: totalPerPage,
    page,
  };

  if (filterField.name && filterField.order) {
    params.order_field = filterField.name;
    params.order = filterField.order;
  }

  if (filterNav) {
    params = {
      ...params,
      campaign: filterNav.campaign,
      attendant: filterNav.attendant,
      start_date: filterNav.dates.start,
      end_date: filterNav.dates.end,
    };
  }

  Object.keys(params).forEach((obj) => {
    if (!params[obj]) {
      delete params[obj];
    }
  });

  return params;
}

const getCampaigns = async (
  page,
  filterField,
  filterNav,
  totalPerPage,
  lan = 'pt-BR'
) =>
  api
    .get('/survey', {
      params: getFilters(page, filterField, filterNav, totalPerPage),
    })
    .then((res) => {
      const list = res.data.list.map((row) => {
        const endDate = new Date(`${row.end_date} 00:00`);

        return {
          ...row,
          expire_date: distanceToDate(endDate, lan),
          end_date_pt: language[lan]?.(endDate),
        };
      });

      res.data.list = list;

      return res.data;
    })
    .catch((err) => err.response.status);

export { getCampaigns };
