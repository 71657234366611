import styled, { css } from 'styled-components';

import { fadeIn, slideInBlurredTop } from '~/assets/styles/settings/Animations';

export const Wrapper = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);

  right: 0;
  bottom: 0;
  z-index: 4;

  animation: ${fadeIn} 0.8s cubic-bezier(0.39, 0.575, 0.565, 1) both;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  padding: var(--metrics-m);
  border-radius: 5px;

  animation: ${slideInBlurredTop} cubic-bezier(0.23, 1, 0.32, 1) 1s both;
  box-shadow: -6px -6px 6px rgba(213, 213, 213, 0.25),
    6px 6px 6px rgba(190, 190, 190, 0.25);
  background: var(--white);

  ${(p) =>
    p?.options &&
    css`
      overflow-y: ${p.options.overflowY ? p.options.overflowY : 'auto'};
      max-height: ${p.options.height};
      width: ${p.options.width};
    `};
`;

export const CloseButton = styled.button`
  align-self: flex-end;
  height: 22px;
  width: 22px;

  background: none;
  cursor: pointer;
  outline: none;
  border: none;

  font-size: var(--font-size-sm);

  svg {
    transition: filter 1s;
    fill: var(--red);
  }

  &:hover {
    filter: brightness(50%);
  }
`;

export const Group = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TitleModal = styled.h3`
  color: var(--blue-90);

  font-weight: ${({ options }) => options?.fontWeight || 500};
  font-size: ${({ options }) => options?.fontSize || '.9rem'};
  font-family: Inter, sans-serif;
`;
