import styled from 'styled-components';

import device from '~/assets/styles/settings/Devices';

export const NavWrapper = styled.nav`
  width: 100%;
  display: flex;

  justify-content: space-between;
  align-items: center;
`;

export const CircleWrapper = styled.div`
  display: flex;
  flex-direction: column;

  position: relative;
  align-items: center;
  justify-content: center;
  color: var(--gray-50);

  gap: 1rem;

  h3 {
    color: var(--blue-90);
    font-weight: var(--font-weight-xb);
  }
`;

export const CircleData = styled.div`
  height: 100%;
  width: 100%;

  top: 0;
  transform: translateY(80px);
  position: absolute;
  line-height: 2rem;

  text-align: center;

  span {
    color: var(--gray-80);
  }

  strong {
    display: block;

    color: var(--gray-90);
    font-size: calc(var(--font-size-xl) * 1.4);
    letter-spacing: 1px;
  }
`;

export const CircleContainer = styled.div`
  height: 170px;
  min-width: 170px;
`;

export const GeneralInfo = styled.section`
  display: flex;

  align-items: center;
  padding: 5rem 0;
  gap: 7rem;

  border-bottom: 1px solid var(--gray-10);
`;

export const GeneralData = styled.div`
  text-align: center;
  margin-left: var(--metrics-xl);
  line-height: 2rem;

  span {
    color: var(--gray-80);
  }

  strong {
    display: block;

    font-size: var(--font-size-xl);
  }

  strong.red {
    color: var(--red);
  }

  strong.green {
    color: var(--green);
  }
`;

export const ChartsWrapper = styled.section`
  display: flex;
  flex-direction: column;
  gap: 2rem;

  padding: 3rem 0;

  h3 {
    color: var(--blue-90);
  }
`;

export const Chart = styled.div`
  width: 100%;
  max-width: 900px;

  color: var(--blue-60);

  strong {
    display: block;
    font-size: var(--font-size-m);
  }

  @media ${device.tablet} {
    max-width: 550px;
  }
`;

export const QuantityRatings = styled.span`
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-xb);
  color: var(--gray-90);
`;

export const Paragraph = styled.p`
  font-size: var(--font-size-sm);
  font-weight: bold;
  color: var(--blue-90);
`;
