import { Form } from '@unform/web';
import styled from 'styled-components';

import personalInformationFinish from '~/assets/images/generic/personal-information-4.svg';
import { fadeIn } from '~/assets/styles/settings/Animations';

export const Wrapper = styled.main`
  display: flex;
  align-items: center;

  flex-direction: column;
  flex-wrap: wrap;

  min-height: 100vh;

  color: var(--gray-60);
  background-color: var(--blue-10);
`;

export const Header = styled.header`
  position: fixed;

  width: 100%;
  height: 135px;

  background: var(--white);
`;

export const HeaderBBimage = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  width: 125px;
  z-index: 1;

`;

export const Card = styled(Form)`
  max-width: 638px;
  max-height: 1500px;
  min-height: 600px;
  width: 90%;
  position: relative;

  background: white;
  z-index: 2;
  border-radius: 8px;
  margin-bottom: var(--metrics-sm);

  animation: ${fadeIn} 1s cubic-bezier(0.390, 0.575, 0.565, 1.000) both .5s;

  span {
    font-weight: bolder;
    color: var(--blue-90);
  }

  strong {
    display: block;

    margin: var(--metrics-smlr) 0;
  }
`;

export const ImageFinish = styled.img.attrs(() => ({
  src: personalInformationFinish
}))`
  width: 80%;
  max-width: 370px;
  height: auto;

  animation: ${fadeIn} .2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both .8s;
`;

export const QuestionOne = styled.p`
`;

export const QuestionTwo = styled.p`
`;

export const ButtonsWrapper = styled.div`
  display: flex;

  gap: 1rem;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 1.5rem;

  justify-content: center;

  padding: var(--metrics-l) var(--metrics-m);
`;

export const HeaderTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;

  width: 100%;
  height: 135px;

  background-color: var(--blue-90);

  h1, strong, p {
    color: var(--white);
  }
`;

export const ContentTitle = styled.strong`
  font-size: 1.05rem;
  font-weight: bolder;

  color: var(--blue-90);
`;

export const ContentFinishWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  gap: 2rem;
  flex-direction: column;

  height: calc(600px - 135px);

  p {
    color: var(--blue-90);
    font-weight: bolder;
  }
`;

export const ErrorMessage = styled.p`
  color: var(--red);
  margin-top: 1rem;
  font-size: 10px;
`;

export const InputCheckboxWrapper = styled.div`
  margin-left:5px;
  div {
    display: block;
    div {
      display: flex;
      & span{
        margin:0;
        font-size: 13px;
      }
      &:first-child label {
        margin-top: 0px;
        margin-bottom:10px;
      }
      &:last-child label {
        margin-top: 10px;
        margin-bottom:0px;
      }
      &:not(:first-child):not(:last-child) label {
        margin: 10px 0px;
      }
    }
  }
`;
