import Lottie from 'react-lottie';

import styled from 'styled-components';

import neutral from '~/assets/images/animations/neutral-face.json';
import satisfied from '~/assets/images/animations/satisfied-face.json';
import unsatisfied from '~/assets/images/animations/unsatisfied-face.json';
import verySatisfied from '~/assets/images/animations/very-satisfied-face.json';
import veryUnsafisfied from '~/assets/images/animations/very-unsatisfied-face.json';

let animationData = {
  loop: true,
  autoplay: true,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  },
};

const neutralAnimation = { ...animationData, animationData: neutral };
const satisfiedAnimation = { ...animationData, animationData: satisfied };
const verySatisfiedAnimation = { ...animationData, animationData: verySatisfied };
const unsatisfiedAnimation = { ...animationData, animationData: unsatisfied };
const veryUnsatisfiedAnimation = { ...animationData, animationData: veryUnsafisfied };

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  gap: 5px;
  color: var(--white);

  button:nth-child(-n+${(p) => p.activeButton}) {
    background-color: ${(p) => (p.activeButton < 7
    ? 'var(--red)' : p.activeButton > 8 ? 'var(--green)' : 'var(--yellow-80)')};
  }

  button:nth-child(${(p) => p.activeButton}) {
    border: 1px solid var(--blue-50);
  }

  button:nth-last-child(-n+${(p) => 10 - p.activeButton}) {
    background-color: var(--gray-50);
  }
`;

export const ButtonNPS = styled.button.attrs(() => ({
  type: 'button'
}))`
  height: 40px;
  width: 40px;
  border-radius: 8px;

  transition: all .3s;

  border: none;
  outline: none;
  cursor: pointer;

  color: var(--white);

  background-color: ${(p) => (p.currentVote < 7
    ? 'var(--red)' : p.currentVote > 8 ? 'var(--green)' : 'var(--yellow-80)')};

  font-weight: bold;
  font-size: var(--font-size-sm);

  &:hover {
    transform: translateY(-5px);
  }
`;

export const Pill = styled.button.attrs(() => ({
  type: 'button'
}))`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 80px;
  padding: 8px 8px;

  text-align: center;

  color: var(--white);
  font-size: var(--font-size-smlr);

  border-radius: 8px;
  background-color: ${(p) => (p.color)};
`;

export const VeryUnsatisfied = styled(Lottie).attrs(() => ({
  options: veryUnsatisfiedAnimation,
  width: '30px',
  height: '30px'
}))``;

export const Unsatisfied = styled(Lottie).attrs(() => ({
  options: unsatisfiedAnimation,
  width: '30px',
  height: '30px'
}))``;

export const Neutral = styled(Lottie).attrs(() => ({
  options: neutralAnimation,
  width: '30px',
  height: '30px'
}))``;

export const Satisfied = styled(Lottie).attrs(() => ({
  options: satisfiedAnimation,
  width: '30px',
  height: '30px'
}))``;

export const VerySatisfied = styled(Lottie).attrs(() => ({
  options: verySatisfiedAnimation,
  width: '30px',
  height: '30px',
}))`
`;

export const Button = styled.button.attrs(() => ({
  type: 'button'
}))`
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;

  text-align: center;

  padding: var(--metrics-smlr);

  width: 88px;
  min-height: 110px;
  border-radius: 8px;

  background-color: var(--gray-10);
  color: var(--gray-50);
  border: 2px solid var(--gray-30);

  outline: none;

  transition: filter .3s;

  &:hover {
    filter: brightness(.9);
  }

  svg {
    filter: grayscale(1);
  }
`;

export const ButtonStar = styled.button.attrs(() => ({
  type: 'button'
}))`
  width: 33px;
  min-height: 33px;

  outline: none;

  transition: filter .3s;

  &:hover {
    filter: brightness(.9);

    svg {
      fill: var(--yellow-70);
    }
  }

  svg {
    width: 100%;
    height: 100%;

    fill: var(--gray-50);
  }
`;

export const StarsWrapper = styled.div`
  display: flex;

  &:hover > button > svg {
    fill: var(--yellow-70);
  }

  button:hover ~ button > svg {
    fill: var(--gray-50);
  }

  button:nth-child(-n+${(p) => p.activeButton}) {
    svg {
      fill: var(--yellow-70);
    }
  }
`;

export const EmojisWrapper = styled.div`
  display: flex;

  flex-wrap: wrap;
  text-align: center;

  gap: 2rem;

  button:nth-child(${(p) => p.activeButton}) {
    border: 2px solid var(--green);
    background-color: var(--blue-50);

    p {
      color: var(--white);
    }

    svg {
      filter: none;
    }
  }
`;

export const PillsWrapper = styled.div`
  display: flex;

  flex-wrap: wrap;
  text-align: center;

  gap: 5px;

  button:nth-child(-n+${(p) => p.activeButton - 1}) {
    background-color: var(--gray-50);
  }

  button:nth-child(+n+${(p) => p.activeButton + 1}) {
    background-color: var(--gray-50);
  }
`;

export const ErrorMessage = styled.p`
  color: var(--red);
  margin-top: 1rem;
  font-size: 10px;
`;
