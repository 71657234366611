import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  margin: 0 auto;
  width: 100%;
  max-width: 1300px;

  flex-direction: column;
`;

export const Title = styled.h3`
  font-weight: var(--font-weight-sb);

  color: var(--gray-40);
`;
