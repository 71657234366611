import Lottie from 'react-lottie';

import styled from 'styled-components';

import loadingImage from '~/assets/images/animations/loading-spinner.json';

const loadingAnimation = {
  loop: true,
  autoplay: true,
  animationData: loadingImage,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  },
};

export const Spinner = styled(Lottie).attrs((p) => ({
  options: loadingAnimation,
  width: p.options.width,
  height: p.options.height
}))`
`;
