import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  IoIosArrowDown, IoIosArrowUp, IoIosTrash, IoIosInformationCircle
} from 'react-icons/io';

import { LoaderTable } from 'components/User/Loader';

import Tooltip from '~/components/User/Tooltip';

import {
  Wrapper, SortButton, ButtonAction, ButtonActionModal
} from './styles';

export default function Table({
  columns, rows, emptyText, hasSort, isLoadingData, setFilter, filterFields,
  disabledOrderedFields, handleRemoveRow, editEmails, isCopying, modalTeamsDetail,
  actionModal, customColumnWidth
}) {
  const filterKeys = filterFields && Object.keys(filterFields);
  const averageColumnWidth = parseInt((100 / columns?.length), []).toString();
  const columnWidth = customColumnWidth || Array(columns?.length).fill(averageColumnWidth);

  const handleFilter = useCallback((fieldName) => {
    setFilter(fieldName);
  }, [setFilter]);

  const isRemovable = (email) => {
    let output = false
    if (!editEmails.includes(email) || isCopying) {
      output =  true;
    }
    return output;
  };

  const { t } = useTranslation();

  return (
    <Wrapper cellSpacing="0">
      <thead>
        <tr>
          {columns?.map((column, index) => (
            <th
              style={{ width: columnWidth[index].concat('%') }}
              key={Math.random()}
            >
              {column}
              {hasSort && !(disabledOrderedFields.includes(column)) && (
                <SortButton onClick={() => handleFilter(index)} id="sort-btn">
                  {filterFields[filterKeys[index]] ? <IoIosArrowUp /> : <IoIosArrowDown />}
                </SortButton>
              )}
            </th>
          ))}
          {handleRemoveRow && <th> </th>}
        </tr>
      </thead>
      <tbody>
        {isLoadingData ? (
          <tr>
            {columns?.map(() => (
              <td key={Math.random()}>
                <LoaderTable />
              </td>
            ))}
          </tr>
        )
          : (
            <>
              {rows?.length === 0
          && (
          <tr>
            <td colSpan={columns?.length}>{emptyText}</td>
          </tr>
          )}
              {rows?.map((row, index) => (
                <tr key={Math.random()}>
                  {Object.keys(row).slice(0, columns.length).map((key) => (
                    <td key={Math.random()}>
                      {row[key]}
                    </td>
                  ))}
                  {actionModal && (
                    <td>
                      <ButtonActionModal
                        title="modalButton"
                        onClick={() => modalTeamsDetail(row.attendant, row.attendantKey)}
                      >
                        <Tooltip text={t('tooltips.detail')} />
                        <IoIosInformationCircle />
                      </ButtonActionModal>
                    </td>
                  )}
                  {handleRemoveRow && (
                    editEmails ? isRemovable(row.email) && (
                      <td>
                        <ButtonAction onClick={() => handleRemoveRow(index)} id="remove-row">
                          <IoIosTrash />
                        </ButtonAction>
                      </td>
                    )
                      : (
                        <td>
                          <ButtonAction onClick={() => handleRemoveRow(index)} id="remove-row">
                            <IoIosTrash />
                          </ButtonAction>
                        </td>
                      )
                  )}
                </tr>
              ))}
            </>
          )}
      </tbody>
    </Wrapper>
  );
}
