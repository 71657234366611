import { Form } from '@unform/web';
import styled from 'styled-components';

export const UserInfo = styled(Form)`
  display: flex;
  flex-direction: column;

  height: 100px;
  width: 450px;

  gap: .5rem;

  background-color: var(--gray-10);
  border-radius: 8px;

  margin-bottom: var(--metrics-sm);
  padding: var(--metrics-smlr) var(--metrics-m);
`;

export const UserPhoto = styled.img.attrs(() => ({
  height: '37',
  width: '37'
}))`
  border-radius: 50%;
  object-fit: cover;
`;

export const Username = styled.p`
  color: var(--blue-60);

  font-weight: bolder;
`;

export const UserWrapper = styled.div`
  display: flex;
  align-items: center;

  gap: var(--metrics-sm);
`;

export const Actions = styled.div`
  display: flex;
  justify-content: space-between;

  gap: 1rem;

  padding-left: 55px;
`;

export const ButtonAction = styled.button`
  position: relative;
  width: 24px;
  height: 24px;

  > svg {
    fill: var(--blue-90);
    transition: fill .3s;

    width: 18px;
    height: 18px;
  }

  &:hover {
    svg {
      fill: var(--red);
    }

    span {
      visibility: visible;
    }
  }
`;
