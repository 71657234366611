import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
`;

export const List = styled.div`
  padding: 16px;
  background-color: var(--blue-90);
  border-radius: 8px;
  position: absolute;
  left: -270%;
  width: 220px;
  bottom: -90%;

  z-index: 1;

  ::after {
    content: '';
    position: absolute;
    top: -14px;
    left: 50%;
    margin-left: -5px;
    border-width: 8px;
    border-style: solid;
    border-color: transparent transparent var(--blue-90) transparent;
  }
`;

export const ItemList = styled.li`
  white-space: nowrap;
  text-align: right;

  button {
    margin: 8px 0px;
    color: #fff;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;

    svg {
      margin-left: 4px;
    }
  }
`;
