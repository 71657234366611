import React from 'react';

import GlobalStyle from './elements/Base';
import { ColorsPalette, Sizes } from './elements/Variables';
import Reset from './generic/Reset';

export default function GlobalStyles() {
  return (
    <>
      <Reset />
      <GlobalStyle />
      <ColorsPalette />
      <Sizes />
    </>
  );
}
