import React, {
  Fragment, useCallback, useEffect, useRef
} from 'react';
import { AiFillCheckCircle } from 'react-icons/ai';
import { BsFileEarmarkCode } from 'react-icons/bs';
import { IoMdMail } from 'react-icons/io';

import { useField } from '@unform/core';

import {
  Wrapper, Label, Field, ErrorMessage, CheckWrapper, WrapperContent
} from './styles';

export default function InputRadioRating({ name, options }) {
  const inputRefs = useRef([]);
  const {
    fieldName, registerField, defaultValue, error
  } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      path: 'value',
      ref: inputRefs.current,
      getValue(refs) {
        const checked = refs.find((ref) => ref.checked);

        return checked ? checked.value : null;
      },
      setValue(refs, value) {
        const item = refs.find((ref) => ref.value === value);

        if (item) {
          item.checked = true;
        }
      }
    });
  }, [fieldName, registerField]);

  const renderIcon = useCallback((id) => {
    switch (id) {
      case 'email':
        return (
          <IoMdMail />
        );
      case 'api':
        return (
          <BsFileEarmarkCode />
        );
      default:
        return <> </>;
    }
  }, []);

  return (
    <Wrapper>
      {options?.map((option, index) => (
        <Fragment key={option.id}>
          <Field
            ref={(ref) => { inputRefs.current[index] = ref; }}
            type="radio"
            name={fieldName}
            value={option.id}
            defaultChecked={defaultValue === option.id}
            id={option.id}
          />
          <Label htmlFor={option.id}>
            <WrapperContent>
              {option.id && renderIcon(option.id)}
              <strong>{option.name}</strong>
              <CheckWrapper id="check-mark">
                <AiFillCheckCircle />
              </CheckWrapper>
            </WrapperContent>
          </Label>
        </Fragment>
      ))}
      { error && <ErrorMessage> { error } </ErrorMessage> }
    </Wrapper>
  );
}
