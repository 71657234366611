import React, { createContext, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { getDetailedCampaign } from '~/services/Generic/Survey';

const CampaignContext = createContext({});

function CampaignContextProvider({ children, id, isCopying }) {
  const { t } = useTranslation();
  const [isEditting, setIsEditting] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(!!id);
  const history = useHistory();
  const backButtonOptions = {
    size: { width: '230px', height: '56px' },
    colors: { background: 'var(--gray-10)', text: 'var(--blue-60)' },
  };
  const nextButtonOptions = {
    ...backButtonOptions,
    colors: { background: 'var(--blue-90)', text: 'var(--white)' },
  };
  const buttonOptions = {
    size: { width: '190px', height: '40px' },
    colors: { background: 'var(--blue-90)', text: 'var(--white)' },
  };
  const addButtonOptions = {
    size: { width: '40px', height: '40px' },
    colors: { background: 'var(--blue-90)', text: 'var(--white)' },
  };
  const deleteButtonOptions = {
    size: { width: '40px', height: '40px' },
    colors: { background: 'var(--red)', text: 'var(--white)' },
  };
  const options = [
    { label: t('labels.appearenceFiveGrades'), value: '5-pill' },
    { label: t('labels.appearenceTenGrades'), value: '10-pill' },
    { label: t('labels.appearenceFiveStars'), value: '5-star' },
    { label: t('labels.appearenceFiveEmojis'), value: '5-smiley' },
    { label: t('labels.appearenceFiveChoices'), value: 'choices' },
    { label: t('labels.appearenceYesNo'), value: 'bool' },
    { label: t('labels.appearenceText'), value: 'text' },
  ];
  const optionsRatings = {
    CSAT: [
      {
        id: '5-star',
        name: t('labels.appearenceFiveStars'),
        emote: { image: 'Star', quantity: 5 },
      },
      {
        id: '5-smiley',
        name: t('labels.appearenceFiveEmojis'),
        emote: { image: 'Emoji', quantity: 5 },
      },
    ],
    NPS: [
      {
        id: '5-pill',
        name: t('labels.appearenceFiveGrades'),
        emote: { image: 'Pill', quantity: 5 },
      },
      {
        id: '10-pill',
        name: t('labels.appearenceTenGrades'),
        emote: { image: 'Pill', quantity: 10 },
      },
    ],
    CES: [
      {
        id: '7-pill',
        name: t('labels.appearenceSevenGrades'),
        emote: { image: 'Pill', quantity: 7 },
      },
    ],
    CUSTOM: [
      {
        id: '7-pill',
        name: t('labels.appearenceSevenGrades'),
        emote: { image: 'Pill', quantity: 7 },
      },
    ],
  };
  const questionExample = {
    CSAT: t('questionExample.csat'),
    NPS: t('questionExample.nps'),
    CES: t('questionExample.ces'),
    CUSTOM: t('questionExample.ces'),
  };
  const [dataWizard, setDataWizard] = useState({
    step: 1,
    distribution: {
      name: '',
      emails: [],
    },
    survey: {
      metric: '',
      campaignName: '',
      greetingMessage: '',
      question: '',
      date: { startDate: new Date(), endDate: null },
    },
  });
  const currentMetric = dataWizard.survey.metric.toUpperCase();

  useEffect(() => {
    async function getData() {
      await getDetailedCampaign(id).then((data) => {
        setIsLoadingData(false);

        if (!data.code) {
          setIsEditting(true);
          setDataWizard(data);
        } else {
          history.push('/not-found');
        }
      });
    }

    if (id) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  return (
    <CampaignContext.Provider
      value={{
        dataWizard,
        setDataWizard,
        backButtonOptions,
        nextButtonOptions,
        isEditting,
        isCopying,
        isLoadingData,
        currentMetric,
        options,
        optionsRatings,
        questionExample,
        buttonOptions,
        addButtonOptions,
        deleteButtonOptions,
      }}
    >
      {children}
    </CampaignContext.Provider>
  );
}

const useCampaign = () => useContext(CampaignContext);

export { CampaignContextProvider, useCampaign };
