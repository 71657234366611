import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { IoMdCheckmark, IoMdClose } from 'react-icons/io';

import { formatDatePT } from '~/helpers/Dates';

import { LoaderTable } from '../Loader';
import TableCustomAnswers from '../TableCustomAnswers';
import { HeaderWrapper, BodyWrapper, ThName, Table } from './styles';

export default function TableMoreInfos({ columns, rows, isLoadingData, type }) {
  const solved = {
    [true]: <IoMdCheckmark />,
    [false]: <IoMdClose />,
  };

  const [indexesActives, setIndexesActives] = useState([]);
  const [showCustomAnswers, setShowCustomAnswers] = useState(false);
  const [customAnswersToShow, setCustomAnswersToShow] = useState();

  const handleWrap = useCallback(
    (index) => {
      if (indexesActives.includes(index)) {
        setIndexesActives((prev) => prev.filter((i) => i !== index));
      } else {
        setIndexesActives((prev) => [...prev, index]);
      }
    },
    [indexesActives]
  );

  const { t } = useTranslation();

  return (
    <>
      <HeaderWrapper>
        <Table cellSpacing="0">
          <thead>
            <tr>
              {columns?.map((column) => (
                <th key={Math.random()}>{column}</th>
              ))}
              <th>{t('tableHeaders.customQuestions')}</th>
            </tr>
          </thead>
        </Table>
      </HeaderWrapper>
      <BodyWrapper>
        <Table cellSpacing="0">
          <tbody>
            {isLoadingData ? (
              <tr>
                {columns.map(() => (
                  <td key={Math.random()}>
                    <LoaderTable />
                  </td>
                ))}
              </tr>
            ) : (
              <>
                {rows.length > 0 ? (
                  rows.map((row, index) => (
                    <tr key={Math.random()}>
                      <ThName>{row.order}</ThName>
                      {type === 'CSAT' ? (
                        <>
                          <ThName>{row.attendant_name}</ThName>
                          <ThName>{row.iteration_code}</ThName>
                          <ThName>{solved[row.solved]}</ThName>
                        </>
                        ) : (
                          <ThName>{row.user_name}</ThName>
                        )}
                      <ThName>{formatDatePT(new Date(row.sent_at))}</ThName>
                      <ThName>
                        {formatDatePT(new Date(row.answered_at))}
                      </ThName>
                      {type !== 'CUSTOM' && (
                      <ThName data-test={`main-answer-${index + 1}`}>
                        {row.rating}
                      </ThName>
                        )}

                      <ThName isWrap={indexesActives.includes(index)}>
                        {row.answered_reason && (
                        <>
                          <strong>R. {row.answered_reason}</strong>
                          <br />
                          <br />
                        </>
                          )}
                        <span
                          onClick={() => handleWrap(index)}
                          onKeyDown={() => handleWrap()}
                          role="button"
                          tabIndex={index}
                        >
                          {row.observation ?? 'Sem observações.'}
                        </span>
                      </ThName>
                      <ThName data-test='description'>
                        {row.description ?? '-'}
                      </ThName>
                      <ThName
                        onClick={() => {
                            setShowCustomAnswers(true);
                            setCustomAnswersToShow(index);
                          }}
                      >
                        {row.custom_answers && (
                        <span data-test="ver mais">
                          {t('titles.seeMore')}
                        </span>
                          )}
                      </ThName>
                    </tr>
                  ))
                ) : (
                  <ThName>{t('tableHeaders.noAnsers')}</ThName>
                )}
              </>
            )}
          </tbody>
        </Table>
      </BodyWrapper>
      {showCustomAnswers && (
        <TableCustomAnswers
          handleCloseModal={() => setShowCustomAnswers((prev) => !prev)}
          questions={rows[customAnswersToShow].custom_answers}
        />
      )}
    </>
  );
}
