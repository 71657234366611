import React from 'react';

import { SubLink } from './styles';

export default function SideBarChilds({ items }) {
  return (
    <>
      {items.map(({ title, to }) => (
        <SubLink to={to} key={Math.random()} target={to.pathname ? "_blank": ""}>
          {title}
        </SubLink>
      ))}
    </>
  );
}
