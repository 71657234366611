import React from 'react';
import ReactSpeedometer from 'react-d3-speedometer';
import { useTranslation } from 'react-i18next';

import { Circle } from 'rc-progress';

import LineChart from '~/components/User/LineChart';
import { LoaderCircle, LoaderChart } from '~/components/User/Loader';
import Filters from '~/containers/Generic/Filters';
import { useDashboard } from '~/contexts/Generic/DashboardContext';
import { scaleColor } from '~/helpers/CircleColor';
import { generateLineChart } from '~/mocks/Charts';

import {
  NavWrapper,
  CircleWrapper,
  CircleData,
  GeneralInfo,
  ChartsWrapper,
  Chart,
  CircleContainer,
  QuantityRatings,
  Paragraph,
} from './styles';

export default function Dashboard() {
  const {
    isLoadingData,
    dataDashboard,
    surveyOptions,
    attendantsOptions,
    setFilter,
    filter,
  } = useDashboard();

  const totalQuantity =
    dataDashboard?.csat.quantity +
    dataDashboard?.ces.quantity +
    dataDashboard?.nps.quantity;

  const { t } = useTranslation();

  return (
    <>
      <NavWrapper>
        {totalQuantity === 0 ? (
          <Paragraph>{t('messages.noData')}</Paragraph>
        ) : (
          <p>{t('titles.followCampaignProgess')}</p>
        )}
        <Filters
          surveyOptions={surveyOptions}
          agentOptions={attendantsOptions}
          setFilter={setFilter}
          filter={filter}
        />
      </NavWrapper>
      <GeneralInfo>
        <CircleWrapper>
          {isLoadingData ? (
            <LoaderCircle />
          ) : (
            dataDashboard && (
              <>
                <h3>CSAT</h3>
                <CircleContainer>
                  <Circle
                    percent={dataDashboard.csat?.average_score}
                    strokeWidth="7"
                    trailWidth="7"
                    trailColor="var(--gray-10)"
                    strokeColor="var(--green)"
                    id="progress"
                  />
                </CircleContainer>
                <CircleData>
                  <span>{t('titles.average')}</span>
                  <strong>
                    {dataDashboard.csat?.average_score
                      ? `${dataDashboard.csat?.average_score}%`
                      : '-'}
                  </strong>
                </CircleData>
                <p>{t('titles.evaluation')}</p>
                <QuantityRatings>
                  {dataDashboard.csat?.quantity
                    ? `${dataDashboard.csat?.quantity}`
                    : '-'}
                </QuantityRatings>
              </>
            )
          )}
        </CircleWrapper>
        <CircleWrapper>
          {isLoadingData ? (
            <LoaderCircle />
          ) : (
            dataDashboard && (
              <>
                <h3>CES</h3>
                <CircleContainer>
                  <Circle
                    percent={(dataDashboard.ces?.average_score * 100) / 7}
                    strokeWidth="7"
                    trailWidth="7"
                    trailColor="var(--gray-10)"
                    strokeColor={scaleColor(
                      dataDashboard.ces?.average_score,
                      'ces'
                    )}
                    id="progress"
                  />
                </CircleContainer>
                <CircleData>
                  <span>{t('titles.average')}</span>
                  <strong>
                    {dataDashboard.ces?.average_score
                      ? `${dataDashboard.ces?.average_score}`
                      : '-'}
                  </strong>
                </CircleData>
                <p>{t('titles.evaluation')}</p>
                <QuantityRatings>
                  {dataDashboard.ces?.quantity
                    ? `${dataDashboard.ces?.quantity}`
                    : '-'}
                </QuantityRatings>
              </>
            )
          )}
        </CircleWrapper>
        <CircleWrapper>
          {isLoadingData ? (
            <LoaderCircle />
          ) : (
            dataDashboard && (
              <>
                <h3>NPS</h3>
                <CircleContainer>
                  <ReactSpeedometer
                    segments={10}
                    value={dataDashboard.nps?.average_score}
                    minValue={-100}
                    maxValue={100}
                    width={250}
                    textColor="var(--gray-60)"
                    labelFontSize="11px"
                    valueTextFontSize="16px"
                  />
                </CircleContainer>
                <p>{t('titles.evaluation')}</p>
                <QuantityRatings>
                  {dataDashboard.nps?.quantity
                    ? `${dataDashboard.nps?.quantity}`
                    : '-'}
                </QuantityRatings>
              </>
            )
          )}
        </CircleWrapper>
      </GeneralInfo>
      <ChartsWrapper>
        <h3>{t('titles.goalComparison')}</h3>
        {isLoadingData ? (
          <LoaderChart />
        ) : (
          dataDashboard && (
            <Chart>
              <strong>CSAT (Customer Satisfaction Score)</strong>
              <LineChart data={generateLineChart(dataDashboard.csat?.chart)} />
            </Chart>
          )
        )}
        {isLoadingData ? (
          <LoaderChart />
        ) : (
          dataDashboard && (
            <Chart>
              <strong>CES (Customer Effort Score)</strong>
              <LineChart
                data={generateLineChart(dataDashboard.ces.chart)}
                type="ces"
              />
            </Chart>
          )
        )}
        {isLoadingData ? (
          <LoaderChart />
        ) : (
          dataDashboard && (
            <Chart>
              <strong>NPS (Net Promoter Score)</strong>
              <LineChart
                data={generateLineChart(dataDashboard.nps?.chart)}
                type="nps"
              />
            </Chart>
          )
        )}
      </ChartsWrapper>
    </>
  );
}
