import React, { useRef, useEffect } from 'react';
import ReactSelect from 'react-select';

import { useField } from '@unform/core';

import { ErrorMessage } from './styles';

export default function Select({ name, len, ...rest }) {
  const customStyles = {
    option: (provided) => ({
      ...provided,
      color: 'var(--blue-60)',
      fontSize: '12px',
    }),
    control: (provided, { isDisabled }) => ({
      ...provided,
      fontWeight: 'bolder',
      fontSize: '12px',
      backgroundColor: 'var(--gray-10)',
      border: 'none',
      cursor: isDisabled ? 'not-allowed' : 'default',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: 'var(--blue-60)',
      whiteSpace: 'wrap',
      fontSize: `${
        Math.trunc(170 / len) < 6 ? 7.5 + Math.trunc(170 / len) : 12
      }px`,
    }),
    placeholder: (provided, { isDisabled }) => ({
      ...provided,
      color: isDisabled ? 'var(--gray-50)' : 'var(--blue-60)',
      textAlign: 'left',
      fontSize: '13px',
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: 'none',
    }),
    noOptionsMessage: (provided) => ({
      ...provided,
      fontSize: '13px',
    }),
  };
  const selectRef = useRef(null);
  const {
    fieldName, defaultValue, registerField, error
  } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: (ref) => {
        if (rest.isMulti) {
          if (!ref.state.value) {
            return [];
          }
          return ref.state.value.map((option) => option.value);
        }
        if (!ref.state.value) {
          return '';
        }
        return ref.state.value.value;
      },
    });
  }, [fieldName, registerField, rest.isMulti]);

  return (
    <>
      <ReactSelect
        defaultValue={defaultValue}
        ref={selectRef}
        classNamePrefix="react-select"
        styles={customStyles}
        {...rest}
      />
      {error && <ErrorMessage> {error} </ErrorMessage>}
    </>
  );
}
