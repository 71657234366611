import React from 'react';

import { Wrapper } from './styles';

export default function Tooltip({ text, color }) {
  return (
    <Wrapper color={color}>
      {text}
    </Wrapper>
  );
}
