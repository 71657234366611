import Lottie from 'react-lottie';

import styled from 'styled-components';

import neutral from '~/assets/images/animations/neutral-face.json';
import satisfied from '~/assets/images/animations/satisfied-face.json';
import unsatisfied from '~/assets/images/animations/unsatisfied-face.json';
import verySatisfied from '~/assets/images/animations/very-satisfied-face.json';
import veryUnsafisfied from '~/assets/images/animations/very-unsatisfied-face.json';

let animationData = {
  loop: true,
  autoplay: true,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const neutralAnimation = { ...animationData, animationData: neutral };
const satisfiedAnimation = { ...animationData, animationData: satisfied };
const verySatisfiedAnimation = {
  ...animationData,
  animationData: verySatisfied,
};
const unsatisfiedAnimation = { ...animationData, animationData: unsatisfied };
const veryUnsatisfiedAnimation = {
  ...animationData,
  animationData: veryUnsafisfied,
};

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  gap: 1rem;

  justify-content: space-between;
`;

export const Label = styled.label`
  width: 100%;
  min-height: 80px;

  padding: var(--metrics-m);

  position: relative;
  cursor: pointer;

  border: 2px solid var(--gray-30);
  border-radius: 8px;

  color: var(--blue-50);
  font-size: var(--font-size-sm);

  strong {
    display: inline-block;
  }

  svg {
    width: 24px;
    height: 24px;

    transition: filter 0.3s;
  }
`;

export const CheckWrapper = styled.div`
  display: inline-block;
  margin-left: auto;

  position: absolute;

  right: 0;
  transform: translateX(-20px);
`;

export const StarsWrapper = styled.div`
  display: flex;
  margin-top: var(--metrics-sm);

  svg {
    fill: var(--yellow-80);
  }
`;

export const Field = styled.input`
  position: absolute;
  visibility: hidden;
  cursor: pointer;

  &:not(:checked) {
    + label {
      &:after {
        opacity: 0;
      }

      svg {
        filter: grayscale(0.7);
      }

      #check-mark {
        visibility: hidden;
      }
    }
  }

  &:checked {
    + label {
      &:after {
        opacity: 1;
      }

      svg {
        filter: inherit;
      }
    }
  }

  + label {
    &:after {
      content: '';
      position: absolute;

      width: 100%;
      height: 100%;
      top: -2px;
      left: -2px;

      border: 2px solid var(--blue-60);
      border-radius: 8px;
    }

    &:hover {
      svg {
        filter: inherit;
      }
    }
  }
`;

export const ErrorMessage = styled.p`
  color: var(--red);
  margin-top: 5px;
  font-size: 10px;
`;

export const Title = styled.label`
  color: var(--blue-50);

  font-family: Inter, sans-serif;
  font-weight: var(--font-weight-b);
  font-size: 0.855rem;

  margin-top: var(--metrics-sm);
`;

export const VeryUnsatisfied = styled(Lottie).attrs(() => ({
  options: veryUnsatisfiedAnimation,
  width: '30px',
  height: '30px',
}))``;

export const Unsatisfied = styled(Lottie).attrs(() => ({
  options: unsatisfiedAnimation,
  width: '30px',
  height: '30px',
}))``;

export const Neutral = styled(Lottie).attrs(() => ({
  options: neutralAnimation,
  width: '30px',
  height: '30px',
}))``;

export const Satisfied = styled(Lottie).attrs(() => ({
  options: satisfiedAnimation,
  width: '30px',
  height: '30px',
}))``;

export const VerySatisfied = styled(Lottie).attrs(() => ({
  options: verySatisfiedAnimation,
  width: '30px',
  height: '30px',
}))``;

export const EmojisWrapper = styled.div`
  display: flex;
  align-items: flex-end;

  flex-wrap: wrap;
  text-align: center;

  gap: 3rem;
  margin-top: var(--metrics-sm);
`;

export const EmojiWrapper = styled.div`
  display: flex;
  flex-direction: column;

  gap: 1rem;

  p {
    color: var(--gray-50);
  }
`;

export const Pill = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: ${(p) => (p['pill-small'] ? '39px' : '80px')};
  padding: 8px 8px;

  text-align: center;

  color: var(--white);
  font-size: var(--font-size-smlr);

  border-radius: 8px;
  background-color: ${(p) => p.color};
`;

export const PillsWrapper = styled.div`
  display: flex;
  gap: 5px;
  margin-top: var(--metrics-sm);
  flex-wrap: wrap;
`;
