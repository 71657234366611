import React from 'react';
import { useParams } from 'react-router-dom';

import UserSkeleton from '~/boilerplates/User';
import EditCampaign from '~/containers/User/Campaign/New';
import { CampaignContextProvider } from '~/contexts/Generic/SurveyContext';

export default function EditCampaignPage() {
  const { id } = useParams();

  return (
    <UserSkeleton>
      <CampaignContextProvider id={id}>
        <EditCampaign />
      </CampaignContextProvider>
    </UserSkeleton>
  );
}
