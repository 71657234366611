const messages = {
  en: {
    translations: {
      titles: {
        headerAdmin: 'Administrative Menu',
        header: 'BB Survey',
        headerConfigCampaign: "Let's set up your campaign",
        stepSetMetric: 'Set the metric',
        stepSetDistribution: 'Distribution',
        stepSetCampaign: 'Campaign',
        stepFinish: 'Finished',
        average: 'Average',
        histogram: 'Histogram',
        votesWith5Grades: 'Votes with 5 notes',
        votesWith7Grades: 'Votes with 7 notes',
        votesWith10Grades: 'Votes with 10 notes',
        evaluation: 'Evaluation',
        evaluations: 'Evaluations',
        goalComparison: 'Comparison of Metrics',
        overview: 'Overview',
        detractors: 'Detractors',
        neuters: 'Neutral',
        promoters: 'Promoters',
        listSentEmails: 'Sent Email List',
        whoCanView: 'Inform who can view the campaign',
        evaluatedKey: "Evaluated Person's Key",
        evaluatedPerson: 'Evaluated Person',
        evaluatorName: 'Evaluator Name',
        evaluatorEmail: 'Evaluator Email',
        tickerNumber: 'Ticket Number',
        confirm: 'confirm',
        campaignInfo: 'Campaign Information',
        noAttendantPeriod: 'There are no attendants evaluated in this period',
        period: 'Period',
        cleanFilters: 'Clean Filter',
        averageClassification: 'Average Classification',
        attendants: 'Attendants',
        attendant: 'Attendant',
        key: 'key',
        grade: 'Grade',
        evaluatedAttendant: 'Evaluated Attendant',
        gradeHistory: 'Grade History',
        campaigns: 'Campaigns',
        seeMore: 'See More',
        customQuestions: 'Custom Questions',
        followCampaignProgess: 'Follow Campaign Progess',
        errorComunicateAPI: "Error communicating with API's",
        newEmail: 'New E-mail',
        resendEmail: 'Resend E-mail',
        of: 'of',
        warning: 'Warning',
        customCampaign: 'Custom Campaign',
        customCampaigns: 'Custom Campaigns',
        docs: 'Docs',
      },
      messages: {
        hello: 'Helo {{name}}',
        welcomeHeader: 'Welcome to BB Survey!',
        welcomeP1: 'I noticed that you are new around here',
        welcomeP2: "let's",
        welcomeP3: 'create a campaign?',
        welcomeP4: "but you don't own",
        welcomeP5: 'necessary roles',
        welcomeP6: 'to create campaigns',
        csatExample:
          'Ex: Uber always asks passengers to rate them after a trip.',
        csatQuestion: "What did you think of Groot advisor's service?",
        csatText:
          'Metric used to measure the level of satisfaction of your customers in relation to your company and at different stages of their journey.',
        npsExample: 'Ex: Degree of satisfaction with a service received.',
        npsQuestion:
          'On a scale of 0 to 5 or 0 to 10, how likely are you to recommend our services to family and friends??',
        npsText:
          'Metric that uses quantitative and qualitative data to measure how satisfied your customers are with the experience they had, or are having, with your company.' +
          ' This indicator also aims to identify the chances of your consumers recommending your products and/or services to other people..',
        cesExample: 'Ex: The degree of difficulty in solving a given problem.',
        cesQuestion: 'Tech support made it easy to resolve my issue?',
        cesText:
          'Metric used to measure success and customer satisfaction. The idea is to measure, through a score, how much effort he had to apply to use your product or service.',
        customExample: 'Ex: The feeling about the received service.',
        customQuestion: "What did you think of our advisor's service?",
        customText:
          'In this modality, the campaign administrator creates personalized questionnaires with open questions, offering five ways of answering: grades, emojis, stars, alternatives (yes/no), and free text.',
        emptyText: 'No data has been added.',
        gettingUserDatas: 'Getting user data',
        checkingUserPermissions: 'Checking user permissions',
        observation: 'Observation',
        preview:
          'it is possible to return to the previous steps if you need to adjust some data.',
        yourApikey: 'Your API Key:',
        yourCampaign: 'Your campaign',

        was: 'was',
        updated: ' updated',
        created: ' created',
        withSuccess: 'with success!',
        noData: 'There is no data for the selected period',
        noAttendants: 'No evaluated attendants were found',
        noAnswer: 'No answer found',
        closeCampaign: 'Confirm the end of the campaign?',
        noCampaign: 'There is no campaigns',
        addCertificate: 'Possibly need to add TLS/SSL certificates',
        accept:
          'to accept the certificates exception. make sure too' +
          "to accept your browser's pop-up opening permissions. After accepting,",
        thisPortal: 'this portal',
        callContact: 'If the problem persists, contact technical support',
        csvEmailWarining:
          'There is some line in CSV which does not contain correct email.',
        csvSizeWarning:
          'It is necessary that the CSV has lines filled in the 4 columns.',
        csvEmailError: 'The email field in one of the data is not correct.',
        importEmails:
          'Import failed, duplicate emails were found in your CSV,' +
          ' remove duplicate lines and try again ',
        noPerson:
          'To proceed, it is necessary to insert at least 1 person to be evaluated in the campaign.',
        noKey:
          'To proceed, it is necessary that the key of the evaluated person is filled in.',
        tagMessage: 'The tag has already been added',
        alreadyCampaing: 'User does not have registered campaigns.',
        createdCampaign: 'Campaign successfully created.',
        modalWarning:
          'The attendant keys imported via CSV will be validated after' +
          ' creating the campaign. If there are inconsistencies in the entered keys,' +
          ' an email will be forwarded to the user.',

        isMandatoryQuestion: 'At least one answer must be mandatory',
        infoQuestion:
          'You can add up to {{maxQuestions}} questions to your form',
      },
      buttons: {
        yes: 'Yes',
        no: 'No',
        newCampaign: 'New Campaign',
        letStart: "Let's start!",
        back: 'Back',
        foward: 'Foward',
        addQuestion: 'Add question',
        addEvaluatorName: 'Add evaluator name',
        removeEvaluatorName: 'Remove reviewer name',
        addInteractionNumber: 'Add Interaction number',
        removeInteractionNumber: 'Remove Interaction number',
        campaign: 'Campaign',
        copyCampaign: 'Copy Campaign',
        updateCampaign: 'Update Campaign',
        createCampaign: 'Create Campaign',
        pauseCampaign: 'Pause Campaign',
        startCampaign: 'Start Campaign',
        closeCampaign: 'End Campaign',
        search: 'search',
        newEmail: 'new email',
        loadingMore: 'Loading More',
        first: 'First',
        last: 'Last',
        clickHere: 'Clique aqui',
        refresh: 'Recarregue',
        send: 'Send',
      },

      tooltips: {
        informations: 'Information',
        exportCSV: 'Export CSV',
        sendApiKey: 'Send API Key (Email)',
        questionSummary: 'Summary questions',
        willExpire: 'Expires in',
        hasExpided: 'Expired ago',
        users: 'Users',
        duplicateCampaing: 'Duplicate Campaign',
        resendEmais: 'Resend Emails',
        editCampaign: 'Edit Campaign',
        detail: 'Detail',
        overallAvgPeriod: 'Overall average for the period',
        add: 'Add',
        selectAll: 'Select All',
      },
      links: {
        overview: 'Overview',
        attendants: 'Attendants',
        campaigns: 'Campaigns',
        documentations: 'Documentations',
        exampleCSV: 'example csv',
        importCSV: 'import csv',
        newEmail: 'new email',
        generalInformation: 'Genaral Info',
        docAPI: 'API',
      },
      pills: {
        totallyDisagree: 'Totally Disagree',
        disagree: 'Disagree',
        partiallyDisagree: 'Partially Disagree',
        neutral: 'Neutral',
        partiallyAgree: 'Partially Agree',
        agree: 'Agree',
        totallyAgree: 'Totally Agree',
      },

      csatEmojis: {
        veryUnsatisfied: 'Very Unsatisfied',
        unsatisfied: 'Unsatisfied',
        neutral: 'Neutral',
        satisfied: 'Satisfied',
        verySatisfied: 'Very Satisfied',
      },

      tableHeaders: {
        question: 'Question',
        answer: 'Answer',
        evaluatedKey: "Evaluated person's key",
        evaluatedPerson: 'Assessed Person',
        evaluatorName: 'Evaluator Name',
        evaluatorEmail: 'Evaluator Email',
        tickerNumber: 'Ticket Number',
        campaign: 'Campaign',
        endsIn: 'Ends in',
        howManyAnswered: 'How many answered',
        emailsSent24h: 'Emails sent (24hrs)',
        customQuestions: 'Custom questions',
        noAnsers: 'Survey without answers.',
        missingAnswer: 'Missing Answer',
      },

      tableColumns: {
        answeredDate: 'Answered Date',
        evaluations: 'Evaluations',
        answers: 'Answers',
        attendant: 'attendant',
        campaign: 'Campaign',
        description: 'Description',
        email: 'Email',
        evaluator: 'Evaluator',
        key: 'Key',
        mean: 'Average',
        meanGrade: 'Average grade',
        name: 'Name',
        notResolved: 'Unresolved',
        grade: 'Grade',
        observations: 'Observations',
        percentMean: 'Average (%)',
        percentAnswered: 'Answered (%)',
        questions: 'Questions',
        resolved: 'Resolved',
        sendDate: 'Send Date',
        sendEmail: 'Send Email',
        ticket: 'Ticket ID (INT)',
        ticketNumber: 'Ticket Number',
        total: 'Total',
        interaction: 'Interaction',
      },

      labels: {
        campaignLanguage: 'Enter the language of the campaign *',
        campaignName: 'Enter the name of the campaign *',
        campaignQuestion:
          'Enter the question that will be asked to the evaluator *',
        campaignGreeting: 'Enter the thank you message',
        campaignPeriod: 'Enter the search period *',
        campaignAppearence: 'Evaluation Appearance *',
        appearenceFiveStars: 'With 5 stars',
        appearenceFiveEmojis: 'With 5 emojis',
        appearenceFiveGrades: 'With 5 grades',
        appearenceSevenGrades: 'With 7 grades',
        appearenceTenGrades: 'With 10 grades',
        appearenceFiveChoices: 'With max 5 alternatives',
        appearenceYesNo: 'With Yes or No',
        appearenceText: 'Text Area',
        portuguese: 'Portuguese',
        english: 'English',
        templateEmailTitle: 'Enter the main title of the email',
        templateEmailBody: 'Enter the highlighted text of the email',
        templateEmailSign: 'Enter email signature',
        averageGradeDay: 'Average grade of the day:',
        averageGrade: 'Average grade',
        numberOfVotes: 'Number of votes',
        votes: 'Votes',
        grade: 'Grade',
        campaignOwner: 'Campaign Owner',
        member: 'Member',
        inProgress: 'In Progress',
        waiting: 'Waiting Publishing',
        finalized: 'Finalized',
        paused: 'Paused',
        notAnswered: 'Not Answered',
        bugReport: 'Bug Report',
      },

      placeholders: {
        attendant: 'Attendant',
        campaign: 'Campaign',
        campaignName: 'Ex: Together we are stronger',
        campaignGreeting: 'Ex: We are grateful for the review.',
        choosenQuestion: 'Choose how this question will be answered',
        insertKey: 'Enter the key...',
        insertSearchKey: 'Enter search key...',
        insertEvaluatedKey: 'Enter the Evaluated Person key',
        evaluatedName: "Evaluated person's name",
        insertEvaluatorName: 'Enter the name of the appraiser',
        insertEvaluatorEmail: "Enter the evaluator's email",
        insertTicket: 'Enter ticket number',
        signature: 'Ex: Technology Board',
        signature2: 'Service Center',
        rateCampaign: "Ex: Rate today's campaign",
      },

      questionExample: {
        csat: 'Ex: What did you think of the last service?',
        nps: 'Ex: How likely are you to recommend BB tech support to a colleague?',
        ces: 'Ex: How easy was it to carry out this process with the company?',
      },

      notify: {
        exportCSV: {
          warning: 'In process of exporting the file.',
          success: 'Completed file export.',
          error: 'This survey has no answers.',
          waiting: 'Creating file',
        },

        getFilterCampaigns: {
          warning: 'User does not have registered campaigns.',
        },
        createCampaign: {
          success: 'Campaign successfully created.',
        },
        editCampaign: {
          success: 'Campaign updated successfully.',
        },
        sendEmails: {
          warning: 'The emails are in the process of being sent.',
        },
        sendApiKeyByEmail: {
          warning: 'Api Key being sent via email.',
        },
        createPrivilege: {
          success: 'Permission successfully added.',
        },
        editUserPrivileges: {
          success: 'User successfully edited.',
        },
        deleteUserPrivileges: {
          success: 'User removed successfully.',
        },
        executeTask: {
          warning: 'Task {{url}} being executed',
        },
      },

      csvColumns: {
        answeredIn: 'Answered in',
        answerReason: 'Answer Reason',
        attendant: 'Attendant',
        description: 'Description',
        evaluatorName: 'Evaluator Name',
        evaluatorEmail: 'Evaluator Email',
        grade: 'Grade',
        observation: 'Observation',
        othersAnswers: 'Others Answers',
        ticket: 'Ticket',
        wasSoved: 'Soved',
      },

      bugReport: {
        title: 'Bug Report',
        description: "What's happening?",
        areaPlaceholder:
          '- Describe in detail the error that occurred\n- Inform the steps you followed until the error occurred',
        characters: 'characters',
        sendText:
          'When you click send, an issue will be opened for analysis by the development team.',
        teamText:
          ' The team may contact you, preferably via teams, for clarification and/or testing.',
        screenshot: 'Screenshot',
        cancel: 'Cancel',
        send: 'Send',
        issueLabel:
          'The issue {{issueNumber}} has been registered for analysis of your case.',
        linkText:
          'You can track this issue in the Fontes app through the link below*:',

        accessText: 'Access to the ALMFDIGA is required to access the link ',
        loadingText: 'Wait... collecting data.',
      },

      infoModal: {
        manager: 'Manager',
        team: 'Team',
        developer: 'Developer',
        system: 'System',
        release: 'Software release',
      },

      schemas: {
        trim: 'There should be no spaces at the beginning and end of the text.',

        attendant_key: {
          required: 'Evaluated person key is a required field',
        },

        evaluated_attendant: {
          required: 'The name of the evaluated person is a required field',
        },
        evaluator_name: {
          required: 'Evaluator name is a required field',
        },
        email: {
          required: "Reviewer's email is a required field",
          email: 'The email entered is invalid',
        },

        ticket_number: {
          required: 'Attendance ticket number is a required field',
        },

        campaignName: {
          required: 'Campaign name is a required field',
        },

        question: {
          required:
            'The question that will be asked to the evaluator is a mandatory field',
        },

        custom_questions: {
          required: 'Pergunta personalizada é obrigatória',
        },

        appearance: {
          required: 'Response type is a required field',
        },

        choices: {
          required: 'Alternatives are required fields',
        },

        startDate: {
          required: 'You must enter a start date for the campaign',
        },

        endDate: {
          required: 'You must enter an end date for the campaign',
        },

        option: {
          required: 'Assessment Appearance is a required field',
        },

        title: {
          required: 'Email highlight text is a required field',
        },

        body: {
          required: 'The body of the email is a required field',
        },

        signature: {
          required: 'Email signature is a required field',
        },

        key: {
          required: 'Member Key is a required field',
          min: 'Key must contain {{min}} characters',
          max: 'Key must contain {{max}} characters',
          matches:
            'The key must start with an A F C T or Z character and have 7 numbers from 0 to 9',
        },
      },
    },
  },
};

export { messages };
