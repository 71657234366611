import styled from 'styled-components';

export const Wrapper = styled.button.attrs((p) => ({
  type: p.type
}))`
  border-radius: 8px;
  width: ${(p) => p.size && p.size.width};
  height: ${(p) => p.size && p.size.height};

  transition: filter .3s;
  margin-top: ${(p) => (p.margin ? p.margin : 'var(--metrics-m)')};
  margin-bottom: ${(p) => (p.margin ? p.margin : 'var(--metrics-m)')};


  cursor: ${(p) => (p.disabled ? 'not-allowed' : 'pointer')};

  background-color: ${(p) => (p.disabled ? 'var(--gray-40)' : p.colors && p.colors.background)};
  color: ${(p) => (p.disabled ? 'var(--gray-60)' : p.colors && p.colors.text)};
  font-size: ${({ size }) => size?.fontSize || 'var(--font-size-m)'};

  &:hover {
    filter: ${(p) => (!p.disabled && 'contrast(1.3)')};
  }
`;
