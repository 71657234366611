import React, { useState, useEffect } from 'react';

import ProgressBar from '~/components/User/ProgressBar';

import { Wrapper, Steps, StepName } from './styles';

export default function Wizard({ steps, currentStep }) {
  const firstStep = 100 / steps.length;
  const [progress, setProgress] = useState(firstStep);

  useEffect(() => {
    setProgress(currentStep * (100 / steps.length));
  }, [currentStep, steps]);

  return (
    <>
      <Wrapper>
        <Steps>
          {steps.map((step, index) => (
            <StepName
              key={Math.random()}
              isActive={progress / (100 / steps.length) >= index + 1}
            >
              {index + 1}{'. '}{step}
            </StepName>
          ))}
        </Steps>
        <ProgressBar progress={progress} />
      </Wrapper>
    </>
  );
}
