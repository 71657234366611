import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Switch, BrowserRouter } from 'react-router-dom';

import Loading from '~/containers/Generic/Loading';
import { authenticate } from '~/middlewares/Login';

import OutsideRoutes from './Outside';
import UserRoutes from './User';

const Routes = () => {
  const currentPath = window.location.pathname;
  const { gotUserData, gotAllData, hasPermissions } = useSelector(
    (store) => store.login
  );

  useEffect(() => {
    if (!currentPath.includes('/campaign/answer/')) {
      authenticate();
    }
  }, [currentPath]);

  return (
    <BrowserRouter>
      <Switch>
        <>
          {currentPath.includes('/campaign/answer/') ? 
            <OutsideRoutes /> : gotAllData ? (
              <UserRoutes />) : (
                <Loading
                  loadedUserData={gotUserData}
                  hasPermissions={hasPermissions} 
                />
          )}
        </>
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
