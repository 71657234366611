import { Form } from '@unform/web';
import styled from 'styled-components';

export const Wrapper = styled.main`
  display: flex;

  min-height: 100vh;
`;

export const SelectWrapper = styled(Form)`
  width: 100px;
`;

export const Content = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  width: 100%;
  padding-bottom: var(--metrics-m);
`;

export const Header = styled.header`
  width: 100%;
  height: 104px;

  padding: 0 100px;
  box-shadow: 0px 8px 24px rgba(217, 217, 217, 0.24);
  box-sizing: border-box;
`;

export const HeaderContent = styled.div`
  height: 100%;
  max-width: 1280px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  margin: 0 auto;

  button {
    margin-left: 4px;
  }
`;

export const HeaderTitle = styled.h3`
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-xb);

  color: var(--blue-90);
`;

export const ChildWrapper = styled.div`
  display: flex;
  flex-direction: column;

  margin: 0 auto;

  flex: 1;
  width: 100%;
  max-width: 1200px;

  padding: var(--metrics-xl);
`;

export const Footer = styled.footer`
  margin-top: auto;

  width: 100%;
  max-width: 1200px;
  text-align: center;

  color: var(--gray-50);
  font-size: var(--font-size-smlr);

  margin: 0 auto;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Button = styled.button`
  padding: 0.2rem;
  img {
    height: 1.5rem;
    width: 2rem;
  }
`;
