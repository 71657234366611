import { setUserInfo } from '~/redux/actions/Boilerplate';
import {
  setLoadedAllData, setUserSignedIn, setHasPermissions
} from '~/redux/actions/Login';
import { dispatch } from '~/redux/store';
import { getUserData } from '~/services/User';

const authenticate = () => {
  getUserData().then((res) => {
    if (res === 403 || res === 500) {
      setHasPermissions(dispatch, 'failed');
      setLoadedAllData(dispatch, false);
    } else if (res !== 400) {
      setHasPermissions(dispatch, 'success');
      setUserInfo(dispatch, res);

      setTimeout(() => {
        setLoadedAllData(dispatch, true);
      }, 1500);
    }

    setUserSignedIn(dispatch, true);
  });
};

export { authenticate };
