import React from 'react';

import i18n from '~/translate/i18n';

import {
  Wrapper, Loading, Spinner, Group, Checkmark, Closemark
} from './styles';

export default function index({ loadedUserData, hasPermissions }) {
  return (
    <Wrapper>
      <Loading />
      <Group>
        <p>{i18n.t('messages.gettingUserDatas')}</p>
        {loadedUserData ? <Checkmark /> : <Spinner /> }
      </Group>
      {loadedUserData && (
        <Group>
          <p>{i18n.t('messages.checkingUserPermissions')}</p>
          {hasPermissions === 'success'
            ? <Checkmark />
            : hasPermissions === 'failed'
              ? <Closemark /> : <Spinner />}
        </Group>
      )}
    </Wrapper>
  );
}
