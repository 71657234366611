import React, { createContext, useContext } from 'react';

import { executeTask } from 'services/User/Admin';

const AdminContext = createContext({});

function AdminContextProvider({ children }) {
  async function handleTask(url) {
    await executeTask(url);
  }

  return (
    <AdminContext.Provider value={{
      handleTask
    }}
    >
      {children}
    </AdminContext.Provider>
  );
}

const useAdmin = () => useContext(AdminContext);

export { AdminContextProvider, useAdmin };
