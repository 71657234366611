import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { AiFillInfoCircle } from 'react-icons/ai';
import { IoIosTrash } from 'react-icons/io';
import { MdExposurePlus1 } from 'react-icons/md';
import { PiWarningDiamondFill } from 'react-icons/pi';

import { Scope } from '@unform/core';

import Button from '~/components/User/Button';
import CheckBox from '~/components/User/Checkbox';
import Input from '~/components/User/Input';
import InputSelect from '~/components/User/InputSelect';
import { useCampaign } from '~/contexts/Generic/SurveyContext';

import {
  QuestionsWrapper,
  SelectWrapper,
  Question,
  ChoicesWrapper,
  InfoQuestionWrapper,
  InfoQuestion,
  WarningQuestionWrapper,
  WarningQuestion,
} from '../../styles';
import { CustomButtom } from './styles';

export default function CustomQuestions({ custom_questions, formRef }) {
  const {
    currentMetric,
    options,
    questionExample,
    addButtonOptions,
    deleteButtonOptions,
    isEditting,
    buttonOptions,
  } = useCampaign();

  const [numQuestions, setNumQuestions] = useState(
    custom_questions?.length || 0
  );
  const [activeItemsCount, setActiveItemsCount] = useState(0);
  const questionsRef = useRef();

  const { t } = useTranslation();
  const [choicesCount, setChoicesCount] = useState(
    custom_questions
      ? custom_questions?.map((q) =>
          q?.choices?.length > 1 ? q.choices.length : 2
        )
      : []
  );
  const [appearances, setAppearances] = useState(
    custom_questions
      ? custom_questions?.map((q) => q.appearance)
      : [null, null, null, null]
  );

  const [questions, setQuestions] = useState(
    custom_questions ? custom_questions.length : 0
  );
  const [fieldsQuantity, setFieldsQuantity] = useState(
    custom_questions ? custom_questions.length : 0
  );

  const deleteField = (id) => {
    setNumQuestions((prev) => prev - 1);
    setActiveItemsCount((prev) => prev - 1);
    const field = questionsRef.current?.children?.namedItem(`question${id}`);
    field.parentNode.removeChild(field);
    setFieldsQuantity(questionsRef.current.childElementCount);
    const { current } = formRef;
    current.setFieldValue(`custom_questions[${id}].question`, 'deleted');
  };

  const handleChange = (e, index) => {
    const newAppearances = appearances;
    newAppearances.splice(index, 1, e.value);
    setAppearances([...newAppearances]);
  };

  const handleCheckBoxChange = (e) => {
    const { checked } = e.target;

    if (!checked) {
      setActiveItemsCount((prev) => prev - 1);
    } else {
      setActiveItemsCount((prev) => prev + 1);
    }
  };

  const handleChoices = (index, status) => {
    const newChoices = choicesCount;
    if (status === 'add') {
      newChoices.splice(index, 1, choicesCount[index] + 1);
      setChoicesCount([...newChoices]);
    } else {
      newChoices.splice(index, 1, choicesCount[index] - 1);
      setChoicesCount([...newChoices]);
    }
  };

  const addQuestions = useCallback(() => {
    setNumQuestions((prev) => prev + 1);
    setActiveItemsCount((prev) => prev + 1);
    setQuestions((prev) => prev + 1);
    setFieldsQuantity((prev) => prev + 1);
    setChoicesCount((prev) => {
      prev.push(2);
      return prev;
    });
  }, [setActiveItemsCount, setNumQuestions]);

  const isEdittingField = (index) =>
    isEditting && index <= custom_questions?.length - 1;
  const showActionButtons = (i, index, length) =>
    i === length - 1 && !isEdittingField(index);

  const maxCustomQuestion = {
    CSAT: 2,
    NPS: 4,
    CES: 4,
    CUSTOM: 30,
  };

  useEffect(() => {
    if (currentMetric === 'CUSTOM' && custom_questions === undefined) {
      addQuestions();
    }
  }, [currentMetric, custom_questions, addQuestions]);

  useEffect(() => {
    if (custom_questions && currentMetric === 'CUSTOM') {
      setNumQuestions(custom_questions?.length);

      const countCustomQuestionsChecked = custom_questions?.filter(
        (item) => item?.is_mandatory
      );
      setActiveItemsCount(countCustomQuestionsChecked?.length);
    }
  }, [custom_questions, currentMetric]);

  return (
    <>
      {fieldsQuantity < maxCustomQuestion[currentMetric] && (
        <>
          <Button
            title={t('buttons.addQuestion')}
            options={buttonOptions}
            onClick={addQuestions}
            type="button"
            data-test="add-question-button"
          />
          <InfoQuestionWrapper>
            <AiFillInfoCircle fill="var(--yellow-80)" />
            <InfoQuestion>
              {' '}
              {t('messages.infoQuestion', {
                maxQuestions: maxCustomQuestion[currentMetric] - numQuestions,
              })}
            </InfoQuestion>
          </InfoQuestionWrapper>
        </>
      )}

      <section ref={questionsRef} data-test="questions">
        {[...Array(questions)].map((_, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Scope path={`custom_questions[${index}]`} key={`question${index}`}>
            <Question id={`question${index}`}>
              <QuestionsWrapper>
                <Input
                  name="question"
                  title={t('labels.campaignQuestion')}
                  placeholder={questionExample[currentMetric]}
                  disabled={isEdittingField(index)}
                  defaultValue={custom_questions?.[index]?.question}
                  data-test={`Extra-question-${index}`}
                />
                <SelectWrapper data-test={`Extra-question-type-${index}`}>
                  <InputSelect
                    options={options}
                    onChange={(e) => handleChange(e, index)}
                    placeholder={t('placeholders.choosenQuestion')}
                    name="appearance"
                    len={1}
                    defaultValue={
                      options.filter(
                        (opt) => opt.value === appearances[index]
                      )[0]
                    }
                    isDisabled={isEdittingField(index)}
                  />
                </SelectWrapper>
                <CustomButtom
                  type="button"
                  options={deleteButtonOptions}
                  margin="0"
                  id={index}
                  onClick={() => deleteField(index)}
                  data-test={`delete-button-${index}`}
                >
                  {' '}
                  <IoIosTrash />
                </CustomButtom>
                {currentMetric === 'CUSTOM' && (
                  <CheckBox
                    id={index}
                    name="is_mandatory"
                    title="Resposta obrigatória"
                    disabled={isEdittingField(index)}
                    defaultChecked={
                      custom_questions?.find(
                        (_item, newIndex) => newIndex === index
                      )?.is_mandatory ?? true
                    }
                    onChange={handleCheckBoxChange}
                  />
                )}
              </QuestionsWrapper>

              {appearances[index] === 'choices' &&
                [...Array(choicesCount[index])].map((_x, i, arr) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <ChoicesWrapper>
                    <Input
                      name={`choices[${i}]`}
                      placeholder={`Alternativa ${i + 1}`}
                      disabled={isEdittingField(index)}
                      data-test={`input-choices-${index}-${i}`}
                    />
                    {showActionButtons(i, index, arr.length) && (
                      <>
                        {choicesCount[index] < 5 && (
                          <Button
                            type="button"
                            options={addButtonOptions}
                            margin="0"
                            onClick={() => handleChoices(index, 'add')}
                            data-test={`add-choices-${index}-${i}`}
                          >
                            {' '}
                            <MdExposurePlus1 />
                          </Button>
                        )}
                        {i > 1 && (
                          <Button
                            type="button"
                            options={deleteButtonOptions}
                            margin="0"
                            onClick={() => handleChoices(index, 'delete')}
                          >
                            {' '}
                            <IoIosTrash />
                          </Button>
                        )}
                      </>
                    )}
                  </ChoicesWrapper>
                ))}
            </Question>
          </Scope>
        ))}
        {numQuestions > 0 &&
          activeItemsCount === 0 &&
          currentMetric === 'CUSTOM' && (
            <WarningQuestionWrapper>
              <PiWarningDiamondFill fill="var(--red)" />
              <WarningQuestion>
                {' '}
                {t('messages.isMandatoryQuestion')}
              </WarningQuestion>
            </WarningQuestionWrapper>
          )}
      </section>
    </>
  );
}
