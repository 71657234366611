import React, { createContext, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getFilterCampaigns } from '~/services/Generic/Dashboard';
import {
  getCampaignAnswered,
  getCampaignMoreInfos,
  sendEmails,
  editPartialCampaign,
  sendApiKeyByEmail,
  getCampaignQuestionDetail,
} from '~/services/Generic/Survey';
import { getCampaigns } from '~/services/User/NPS/Survey';

import { useSurveyPrivileges } from './SurveyPrivilegesContext';

const SurveyContext = createContext({});

function SurveyContextProvider({ children }) {
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [isLoadingPagination, setIsLoadingPagination] = useState(false);
  const { i18n } = useTranslation();
  const [numberPages, setNumberPages] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [totalPerPage, setTotalPerPage] = useState(50);
  const [tableData, setTableData] = useState([]);
  const [activePage, setActivePage] = useState(0);
  const [currentFilter, setCurrentFilter] = useState({ name: '', order: '' });
  const [surveyOptions, setSurveyOptions] = useState([]);
  const [filterFields, setFilterFields] = useState({
    order: false,
    name: false,
    finished_at: false,
    status: false,
    quantity_answered: false,
  });
  const [filterNav, setFilterNav] = useState({
    campaign: '',
    attendant: '',
    type: 'nps',
    dates: {
      start: '',
      end: '',
    },
  });
  const { isChangingData } = useSurveyPrivileges();
  const [isModalResendEmailOpen, setIsModalResendEmailOpen] = useState(false);
  const [isModalMoreInfosOpen, setIsModalMoreInfosOpen] = useState(false);
  const [resendEmailsData, setResendEmailsData] = useState([]);
  const [moreInfosData, setMoreInfosData] = useState([]);
  const [isLoadingResendEmailsData, setIsLoadingResendEmailsData] =
    useState(false);
  const [isLoadingMoreInfosData, setIsLoadingMoreInfosData] = useState(false);
  const [currentCampaignId, setCurrentCampaignId] = useState(false);
  const [questionDetailData, setQuestionDetailData] = useState([]);
  const [isLoadingQuestionDetailData, setIsLoadingQuestionDetailData] =
    useState(false);
  const [isModalQuestionDetailOpen, setIsModalQuestionDetailOpen] =
    useState(false);

  async function getData() {
    setIsLoadingData(true);

    await getCampaigns(
      activePage + 1,
      currentFilter,
      filterNav,
      totalPerPage,
      i18n.language
    )
      .then((data) => {
        if (numberPages !== data.total_pages) {
          setIsLoadingPagination(true);
          setNumberPages(data.total_pages);
          setTotalElements(data.total);

          setTimeout(() => {
            setIsLoadingPagination(false);
          }, 500);
        }

        setTableData(data.list);
      })
      .finally(() => {
        setIsLoadingData(false);
      });
  }

  function setFilter(index) {
    const keys = Object.keys(filterFields);

    keys.forEach((_, i) => {
      if (i === index) {
        setCurrentFilter({
          name: keys[index],
          order: filterFields[keys[index]] ? 'asc' : 'desc',
        });

        setFilterFields((p) => ({ ...p, [keys[index]]: !p[keys[index]] }));
      } else {
        setFilterFields((p) => ({ ...p, [keys[i]]: false }));
      }
    });
  }

  const modalResendEmail = async (id) => {
    setIsModalResendEmailOpen(true);
    setCurrentCampaignId(id);

    await getCampaignAnswered(id)
      .then((data) => {
        setIsLoadingResendEmailsData(true);
        setResendEmailsData(data);
      })
      .finally(() => {
        setIsLoadingResendEmailsData(false);
      });
  };

  const modalMoreInfos = async (id) => {
    setIsLoadingMoreInfosData(true);
    setMoreInfosData([]);
    setIsModalMoreInfosOpen(true);
    setCurrentCampaignId(id);

    await getCampaignMoreInfos(id)
      .then((data) => {
        setMoreInfosData(data);
      })
      .finally(() => {
        setIsLoadingMoreInfosData(false);
      });
  };

  const modalQuestionDetail = async (id) => {
    setIsLoadingQuestionDetailData(true);
    setQuestionDetailData([]);
    setIsModalQuestionDetailOpen(true);
    setCurrentCampaignId(id);

    await getCampaignQuestionDetail(id)
      .then((data) => {
        setQuestionDetailData(data);
      })
      .finally(() => {
        setIsLoadingQuestionDetailData(false);
      });
  };

  async function resendEmails(campaignId, listIdRatings) {
    const payload = {
      campaign_id: campaignId,
      list_id_ratings: listIdRatings,
    };

    await sendEmails(payload).finally(() => {
      setTimeout(() => {
        getData();
      }, 3000);
    });
  }

  async function resendApiKey(campaign_id) {
    await sendApiKeyByEmail(campaign_id);
  }

  async function setCampaignStatus(id, status, dateStart) {
    const today = new Date();
    const start = new Date(`${dateStart} 00:00`);

    const currentStatus = {
      Pausada: today > start ? 'Em Andamento' : 'Aguardando publicação',
      'Em Andamento': 'Pausada',
      Finalizada: 'Finalizada',
      'Aguardando publicação': 'Pausada',
    };

    const payload = {
      status: currentStatus[status],
    };

    setTableData((list) =>
      list.map((row) => {
        if (row.id === id) {
          row.status = currentStatus[status];
        }

        return row;
      })
    );

    await editPartialCampaign(payload, id);
  }

  useEffect(() => {
    async function getInitialData() {
      await getFilterCampaigns(filterNav).then((data) => {
        setSurveyOptions(data);
      });
    }

    getInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterNav]);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePage, currentFilter, filterNav, isChangingData, totalPerPage]);

  return (
    <SurveyContext.Provider
      value={{
        activePage,
        tableData,
        setActivePage,
        isLoadingData,
        isLoadingPagination,
        numberPages,
        filterFields,
        setFilter,
        surveyOptions,
        setFilterNav,
        setCampaignStatus,
        resendEmails,
        modalResendEmail,
        isModalResendEmailOpen,
        setIsModalResendEmailOpen,
        resendEmailsData,
        isLoadingResendEmailsData,
        currentCampaignId,
        modalMoreInfos,
        isModalMoreInfosOpen,
        setIsModalMoreInfosOpen,
        moreInfosData,
        isLoadingMoreInfosData,
        resendApiKey,
        totalElements,
        setResendEmailsData,
        setTotalPerPage,
        totalPerPage,
        modalQuestionDetail,
        questionDetailData,
        isLoadingQuestionDetailData,
        isModalQuestionDetailOpen,
        setIsModalQuestionDetailOpen,
      }}
    >
      {children}
    </SurveyContext.Provider>
  );
}

const useSurvey = () => useContext(SurveyContext);

export { SurveyContextProvider, useSurvey };
