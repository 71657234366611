import React, { useEffect, useRef } from 'react';

import { useField } from '@unform/core';

import { Wrapper, Group, Label } from './styles';

export default function InputCheckbox({ name, options, setOptionValue = undefined }) {
  const inputRefs = useRef([]);
  const { fieldName, registerField, defaultValue = [] } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRefs.current,
      getValue: (refs) => refs.filter((ref) => ref?.checked).map((ref) => ref.value),
      clearValue: (refs) => {
        refs.forEach((ref) => {
          ref.checked = false;
        });
      },
      setValue: (refs, values) => {
        refs.forEach((ref) => {
          if (values.includes(ref.id)) {
            ref.checked = true;
          }
        });
      }
    });
  }, [defaultValue, fieldName, registerField]);

  return (
    <Wrapper>
      {options.map((option, index) => (
        <Group key={option.id}>
          <Label htmlFor={option.id} key={option.id}>
            <input
              defaultChecked={defaultValue.includes(option.id)}
              ref={(ref) => {
                inputRefs.current[index] = ref;
              }}
              value={option.value}
              type="radio"
              id={option.id}
              name={name}
              onChange={(e) => setOptionValue && setOptionValue(e.target.value)}
            />
            <span>
              {option.label}
            </span>
            <div />
          </Label>
        </Group>
      ))}
    </Wrapper>
  );
}
