import {
  SET_USER_INFO,
  SET_CERTIFICATE_MODAL,
  SET_CHAT_MODAL,
  SET_SHOW_CHILDS_CSAT,
  SET_SHOW_CHILDS_NPS,
  SET_SHOW_CHILDS_CES,
  SET_SHOW_CHILDS_CUSTOM,
  SET_SHOW_CHILDS_DOCS,
} from '~/redux/actions/Boilerplate';

const INITIAL_VALUE = {
  user: {
    name: '',
    photo: '',
    isAdmin: false
  },
  modalVisible: false,
  chatVisible: false,
  showChildsCSAT: false,
  showChildsNPS: false,
  showChildsCES: false,
  showChildsCustom: false,
  showChildsDocs: false,
};

const BoilerplateReducer = (state = INITIAL_VALUE, action) => {
  switch (action.type) {
    case SET_USER_INFO:
      return { ...state, user: action.value };
    case SET_CERTIFICATE_MODAL:
      return { ...state, modalVisible: action.value };
    case SET_CHAT_MODAL:
      return { ...state, chatVisible: action.value };
    case SET_SHOW_CHILDS_CSAT:
      return { ...state, showChildsCSAT: !state.showChildsCSAT };
    case SET_SHOW_CHILDS_NPS:
      return { ...state, showChildsNPS: !state.showChildsNPS };
    case SET_SHOW_CHILDS_CES:
      return { ...state, showChildsCES: !state.showChildsCES };
    case SET_SHOW_CHILDS_CUSTOM:
      return { ...state, showChildsCustom: !state.showChildsCustom };
    case SET_SHOW_CHILDS_DOCS:
      return { ...state, showChildsDocs: !state.showChildsDocs };
    default:
      return state;
  }
};

export default BoilerplateReducer;
