import { Form } from '@unform/web';
import styled from 'styled-components';

export const ModalWrapper = styled.div`
  width: 70vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const ModalForm = styled(Form)`
  display: grid;
  grid-template-columns: 1fr 200px;
  gap: 1.5rem;
  height: 100%;
  width: 100%;
`;

export const BugWrapper = styled.div`
  height: 100%;
  h1 {
    color: var(--blue-90);
    padding-bottom: 8px;
    border-bottom: 2px solid var(--gray-10);
  }

  p {
    color: var(--gray-90);
    margin-top: 4px;
  }

  span {
    color: var(--gray-90);
    font-size: 12px;
    display: block;
    text-align: right;
    margin-top: 4px;
  }
  p ~ p {
    text-align: right;
  }
`;

export const ScreenshotWrapper = styled.div`
  display: grid;
  grid-template-rows: auto 1fr 60px;

  h2 {
    font-size: 1rem;
    font-weight: normal;
    color: var(--gray-90);
  }

  img {
    width: 100%;
    height: 200px;
    border: 1px solid var(--gray-90);
    margin-top: 1rem;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: end;
  justify-content: space-between;
  margin-top: 8px;

  button {
    margin: 0;
    padding: 6px 8px;
    width: 90px;
  }

  button:nth-child(1) {
    border: 1px solid var(--blue-90);
    color: var(--blue-90);
  }

  button:nth-child(2) {
    background-color: var(--blue-90);
    border: 1px solid var(--blue-90);
    color: #fff;
  }
`;
