import { Form } from '@unform/web';
import styled, { css } from 'styled-components';

import LogoBB from '~/assets/images/logo-svg.svg';
import { pulse, scaleIn } from '~/assets/styles/settings/Animations';
import devices from '~/assets/styles/settings/Devices';

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  right: 3rem;
  bottom: 1rem;

  width: 3rem;
  height: 3rem;
  border-radius: 50%;

  background-color: var(--blue-20);
  animation: ${pulse} 1.2s linear infinite;

  svg {
    height: 1.5rem;
    width: 1.5rem;
  }

  &:hover {
    transform: scale(1.1);
  }
`;

export const Wrapper = styled.div`
  position: fixed;
  display: flex;
  align-items: flex-end;
  flex-direction: column;

  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .3);

  right: 0;
  bottom: 0;
  z-index: 2;
`;

export const CardChat = styled.div`
  margin-top: auto;
  margin-right: var(--metrics-sm);
  width: 100%;
  max-width: 370px;
  border-radius: 5px;

  box-shadow: 10px 10px 20px rgba(83, 83, 83, 0.25);
  background-color: var(--white);

  animation: ${scaleIn} 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;

  @media ${devices.tablet} {
    margin-right: 0;
  }
`;

export const MessageWrapper = styled.div`
  display: flex;

  margin: var(--metrics-smlr) calc(var(--metrics-sm) + 4px);
  border-radius: 20px;
`;

export const MessageResponse = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: var(--font-size-sm);

  margin-left: ${(p) => p.from === 'receiver' && 'auto'};
`;

export const MessageResponseText = styled.div`
  max-width: 215px;
  border-radius: 20px;
  padding: calc(var(--metrics-xs) + 2px) var(--metrics-sm);

  ${(p) => (p.from === 'sender'
    ? css`
      background-color: var(--gray-30);
    `
    : css`
      background-color: var(--blue-10);
    `)
}

  p {
    word-wrap: break-word;
    font-size: var(--font-size-smlr);
    line-height: calc(var(--font-size-m) + 2px);
    color: var(--gray-50);
  }
`;

export const CardContent = styled.div`
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
`;

export const Time = styled.span`
  font-size: var(--font-size-xs);
  color: var(--gray-50);
  margin-top: calc(var(--metrics-smlr) - 2px);
  margin-right: calc(var(--metrics-xs) + 1px);
  align-self: flex-end;
`;

export const Tab = styled.div`
  display: flex;
  align-items: center;
  box-sizing: border-box;

  padding-left: calc(var(--metrics-m) + 4px);
  padding-right: calc(var(--metrics-m) + 4px);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  height: 100px;
  width: 100%;

  background-color: var(--blue-90);

  @media ${devices.tablet} {
    padding-left: var(--metrics-sm);
    padding-right: var(--metrics-sm);
  }
`;

export const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 58px;
  width: 58px;
  border-radius: 50%;
  background-color: var(--yellow-50);
`;

export const Logo = styled.img.attrs(() => ({
  src: LogoBB
}))`
  height: 36px;
  width: 35px;
  object-fit: cover;
`;

export const Title = styled.h3`
  margin-left: calc(var(--metrics-smlr) + 1px);
  font-size: var(--font-size-sm);
  color: var(--white);
`;

export const Actions = styled(Form)`
  position: relative;
  width: 100%;
  box-sizing: border-box;
  padding: var(--metrics-sm);
  display: inline-block;
  position: relative;
`;

export const SubmitButton = styled.button`
  position: absolute;
  height: 70px;
  width: 19%;

  top: 20px;
  right: 28px;
  font-size: var(--font-size-m);

  color: var(--blue-90);
  background-color: var(--gray-10);

  border: none;
  border-left: 1px solid var(--gray-50);
  outline: none;
  cursor: pointer;
`;

export const CloseButton = styled.button`
  width: 60px;
  height: 60px;
  margin: 15px 20px 20px 0;

  border-radius: 50%;
  font-size: var(--font-size-xl);

  background-color: var(--blue-90);
  color: var(--white);

  cursor: pointer;
  border: none;
  outline: none;
`;
