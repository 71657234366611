import { createGlobalStyle } from 'styled-components';

const Reset = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    border: 0;

    font-size: .8rem;
    font: inherit;

    vertical-align: baseline;
    box-sizing: border-box;
  }
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }
  ol, ul, li {
    margin: 0;
    padding: 0;

    list-style: none;
  }
  textarea {
    border: none;
    overflow: auto;
    outline: none;
    box-shadow: none;
    resize: none;
  }
  a {
    text-decoration: none;
    color: inherit;
  }

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0,0,0,0.2);
    background-color: var(--gray-10);
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--blue-20);
  }

  button {
    background: none;

    cursor: pointer;
  }

  input[type="file"] {
    display: none;
  }
`;

export default Reset;
