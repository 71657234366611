import styled from 'styled-components';

export const ModalWrapper = styled.div`
  width: 50vw;

  h1 {
    color: var(--blue-90);
    padding-bottom: 8px;
    border-bottom: 2px solid var(--gray-10);
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  margin-top: 4px;
`;

export const Column = styled.div`
  h3 {
    color: var(--gray-90);
    font-weight: normal;
    font-size: 14px;
  }

  a,
  p {
    color: var(--gray-90);
    display: block;
    margin-bottom: 16px;
    font-size: 14px;

    svg {
      transform: translateY(3px);
    }
  }

  > p {
    margin: 0;
  }
`;

export const SystemWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: 20px 15px;
  column-gap: 8px;
  margin-bottom: 16px;

  span {
    padding: 8px;
    background: var(--blue-90);
    color: #fff;
    width: fit-content;
    vertical-align: baseline;
    border-radius: 6px;
    font-size: 14px;
    grid-area: span 2 / 1;
  }

  p {
    grid-area: 2/2;
  }
`;
