import React from 'react';

import UserSkeleton from '~/boilerplates/User';
import NotFound from '~/containers/User/NotFound';

export default function index() {
  return (
    <UserSkeleton>
      <NotFound />
    </UserSkeleton>
  );
}
