import React from 'react';

import UserSkeleton from '~/boilerplates/User';
import Dashboard from '~/containers/User/Dashboard';
import { DashboardContextProvider } from '~/contexts/Generic/DashboardContext';

export default function DashboardPage() {
  return (
    <UserSkeleton>
      <DashboardContextProvider>
        <Dashboard />
      </DashboardContextProvider>
    </UserSkeleton>
  );
}
