import styled from 'styled-components';

export const Wrapper = styled.table`
  width: 100%;
  max-height: 500px;
  overflow-y: auto;

  tr {
    &:not(:last-child) {
      td {
        border-bottom: 2px solid var(--gray-10);
        overflow-wrap: anywhere;
      }
    }
  }

  th {
    &:first-child {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    &:last-child {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      width: 5% !important;
    }
  }

  th,
  td {
    padding: 0.95rem 1rem;
  }

  th {
    color: var(--blue-90);
    background-color: var(--blue-10);
    font: 500 0.825rem Lexend, sans-serif;
    text-align: left;
    vertical-align: middle;
  }

  td {
    color: var(--gray-60);
    font-size: 0.75rem;
    text-align: left;
  }
`;

export const SortButton = styled.button.attrs(() => ({
  type: 'button',
}))`
  position: absolute;

  height: 0.9rem;
  width: 0.9rem;

  transform: translateX(12px);

  svg {
    fill: var(--gray-40);

    height: 0.9rem;
    width: 0.9rem;
  }
`;

export const ButtonAction = styled.button.attrs(() => ({
  type: 'button',
}))`
  position: relative;
  width: 24px;
  height: 24px;

  > svg {
    transition: fill 0.3s;
    vertical-align: middle;

    width: 18px;
    height: 18px;
  }

  &:hover {
    svg {
      fill: var(--blue-50);
    }

    span {
      visibility: visible;
    }
  }
`;

export const ButtonActionModal = styled.button.attrs(() => ({
  type: 'button',
}))`
  position: relative;
  margin-left: 5px;

  > svg {
    transition: fill 0.3s;
    vertical-align: middle;

    width: 18px;
    height: 18px;
  }

  &:hover {
    svg {
      fill: var(--blue-50);
    }

    span {
      visibility: visible;
    }
  }
`;
