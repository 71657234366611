import React from 'react';

import UserSkeleton from '~/boilerplates/User';
import Survey from '~/containers/User/CES/Survey';
import { SurveyContextProvider } from '~/contexts/CES/SurveyContext';
import { SurveyPrivilegesContextProvider } from '~/contexts/CES/SurveyPrivilegesContext';

export default function TeamsPage() {
  return (
    <UserSkeleton>
      <SurveyPrivilegesContextProvider>
        <SurveyContextProvider>
          <Survey />
        </SurveyContextProvider>
      </SurveyPrivilegesContextProvider>
    </UserSkeleton>
  );
}
