import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  margin-top: 0.5rem;

  gap: 1rem;
`;

export const LabelTitle = styled.label`
  color: var(--blue-50);
  position: absolute;
  top: -24px;
  left: 0;
  font-family: Inter, sans-serif;
  font-weight: var(--font-weight-b);
  font-size: 0.855rem;
`;

export const Label = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 120px;
  height: 40px;

  position: relative;
  cursor: pointer;
  text-transform: uppercase;

  border: 2px solid var(--gray-30);
  border-radius: 8px;
  background-color: var(--blue-90);

  color: var(--white);
  font-size: var(--font-size-sm);

  transition: filter .3s;

  &:hover {
    filter: contrast(1.3);
  }
`;

export const Field = styled.input`
  position: absolute;
  visibility: hidden;
  cursor: pointer;

  &:not(:checked) {
    + label {
      &:after {
        opacity: 0;
      }
    }
  }

  &:checked {
    + label {
      background-color: var(--blue-50);

      &:after {
        opacity: 1
      }
    }
  }

  + label {
    &:after {
      content: "";
      position: absolute;

      width: 100%;
      height: 100%;
      top: -2px;
      left: -2px;

      border: 2px solid var(--green);
      border-radius: 8px;
    }
  }
`;

export const ErrorMessage = styled.p`
  color: var(--red);

  font-size: 10px;
`;
