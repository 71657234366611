import React, {
  createContext, useContext, useEffect, useState
} from 'react';

import { getFilterAttendants, getFilterCampaigns } from '~/services/Generic/Dashboard';
import { getAttendants, getAttendantsDetail } from '~/services/User/CSAT/Teams';

const TeamsContext = createContext({});

function TeamsContextProvider({ children }) {
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [isLoadingPagination, setIsLoadingPagination] = useState(false);
  const [numberPages, setNumberPages] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [totalPerPage, setTotalPerPage] = useState(50);
  const [tableData, setTableData] = useState([]);
  const [activePage, setActivePage] = useState(0);
  const [currentFilter, setCurrentFilter] = useState({ name: '', order: '' });
  const [surveyOptions, setSurveyOptions] = useState([]);
  const [attendantsOptions, setAttendantsOptions] = useState([]);
  const [filterFields, setFilterFields] = useState({
    order: false,
    attendant: false,
    attendant_key: false,
    note: false,
    average: false,
    ratings: false,
    total_not_solved: false,
    total_ratings: false
  });
  const [filterNav, setFilterNav] = useState({
    campaign: '',
    attendant: '',
    attendant_key: '',
    type: 'csat',
    dates: {
      start: '',
      end: ''
    }
  });

  const [modalTeamsData, setModalTeamsData] = useState([]);
  const [isLoadingModalTeams, setIsLoadingModalTeams] = useState(false);
  const [isModalTeamsOpen, setIsModalTeamsOpen] = useState(false);
  const [currentAttendant, setCurrentAttendant] = useState('');

  function setFilter(index) {
    const keys = Object.keys(filterFields);

    keys.forEach((_, i) => {
      if (i === index) {
        setCurrentFilter({
          name: keys[index],
          order: filterFields[keys[index]] ? 'asc' : 'desc'
        });

        setFilterFields((p) => ({ ...p, [keys[index]]: !p[keys[index]] }));
      } else {
        setFilterFields((p) => ({ ...p, [keys[i]]: false }));
      }
    });
  }

  useEffect(() => {
    async function getInitialData() {
      await getFilterCampaigns(filterNav).then((data) => {
        setSurveyOptions(data);
      });

      await getFilterAttendants().then((data) => {
        setAttendantsOptions(data);
      });
    }

    getInitialData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterNav]);

  useEffect(() => {
    async function catchData() {
      setIsLoadingData(true);
      await getAttendants(
        activePage + 1,
        currentFilter,
        filterNav,
        totalPerPage
      ).then((data) => {
        if (numberPages !== data.total_pages) {
          setIsLoadingPagination(true);
          setNumberPages(data.total_pages);
          setTotalElements(data.total_itens);

          setTimeout(() => {
            setIsLoadingPagination(false);
          }, 500);
        }

        setTableData(data.list);
        setIsLoadingData(false);
      });
    }

    catchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePage, currentFilter, filterNav, totalPerPage]);

  const modalTeamsDetail = async (attendant, attendant_key) => {
    if (attendant_key === null) {
      attendant_key = '';
    }
    await getAttendantsDetail(attendant, attendant_key).then((data) => {
      setIsLoadingModalTeams(true);
      setModalTeamsData(data);
      setCurrentAttendant(attendant);
    }).finally(() => {
      setIsModalTeamsOpen(true);
      setIsLoadingModalTeams(false);
    });
  };

  return (
    <TeamsContext.Provider value={{
      activePage,
      tableData,
      setActivePage,
      isLoadingData,
      isLoadingPagination,
      numberPages,
      filterFields,
      setFilter,
      surveyOptions,
      attendantsOptions,
      setFilterNav,
      modalTeamsDetail,
      modalTeamsData,
      isLoadingModalTeams,
      isModalTeamsOpen,
      getAttendantsDetail,
      setIsModalTeamsOpen,
      currentAttendant,
      totalElements,
      totalPerPage,
      setTotalPerPage
    }}
    >
      {children}
    </TeamsContext.Provider>
  );
}

const useTeams = () => useContext(TeamsContext);

export { TeamsContextProvider, useTeams };
