import React, { Fragment, useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { AiFillCheckCircle, AiFillStar } from 'react-icons/ai';

import { useField } from '@unform/core';

import {
  Wrapper,
  Label,
  Field,
  ErrorMessage,
  CheckWrapper,
  StarsWrapper,
  Title,
  Neutral,
  Satisfied,
  VerySatisfied,
  Unsatisfied,
  VeryUnsatisfied,
  EmojisWrapper,
  EmojiWrapper,
  PillsWrapper,
  Pill,
} from './styles';

export default function InputRadioRating({ name, options }) {
  const inputRefs = useRef([]);
  const { fieldName, registerField, defaultValue, error } = useField(name);
  const { t } = useTranslation();
  useEffect(() => {
    registerField({
      name: fieldName,
      path: 'value',
      ref: inputRefs.current,
      getValue(refs) {
        const checked = refs.find((ref) => ref.checked);

        return checked ? checked.value : null;
      },
      setValue(refs, value) {
        const item = refs.find((ref) => ref.value === value);

        if (item) {
          item.checked = true;
        }
      },
    });
  }, [fieldName, registerField]);

  const renderEmote = useCallback(
    (emote) => {
      switch (emote.image) {
        case 'Star':
          return (
            <StarsWrapper>
              {[...Array(emote.quantity)].map(() => (
                <span key={Math.random()}>
                  {' '}
                  <AiFillStar />{' '}
                </span>
              ))}
            </StarsWrapper>
          );
        case 'Emoji':
          return (
            <EmojisWrapper>
              <EmojiWrapper>
                <p>{t('csatEmojis.veryUnsatisfied')}</p>
                <VeryUnsatisfied />
              </EmojiWrapper>
              {emote.quantity === 5 && (
                <EmojiWrapper>
                  <p>{t('csatEmojis.unsatisfied')}</p>
                  <Unsatisfied />
                </EmojiWrapper>
              )}
              <EmojiWrapper>
                <p>{t('csatEmojis.neutral')}</p>
                <Neutral />
              </EmojiWrapper>
              {emote.quantity === 5 && (
                <EmojiWrapper>
                  <p>{t('csatEmojis.satisfied')}</p>
                  <Satisfied />
                </EmojiWrapper>
              )}
              <EmojiWrapper>
                <p>{t('csatEmojis.verySatisfied')}</p>
                <VerySatisfied />
              </EmojiWrapper>
            </EmojisWrapper>
          );
        case 'Pill':
          if (emote.quantity === 7) {
            return (
              <PillsWrapper>
                <Pill color="var(--red)">{t('pills.totallyDisagree')}</Pill>
                <Pill color="var(--red-20)">{t('pills.disagree')}</Pill>
                <Pill color="var(--red-40)">
                  {t('pills.partiallyDisagree')}
                </Pill>
                <Pill color="var(--yellow-80)">{t('pills.neutral')}</Pill>
                <Pill color="var(--green-40)">{t('pills.partiallyAgree')}</Pill>
                <Pill color="var(--green-20)">{t('pills.agree')}</Pill>
                <Pill color="var(--green)">{t('pills.totallyAgree')}</Pill>
              </PillsWrapper>
            );
          }
          if (emote.quantity === 5) {
            return (
              <PillsWrapper>
                {[...Array(3)].map((_, index) => (
                  <Pill color="var(--red)" pill-small key={Math.random()}>
                    {index + 1}
                  </Pill>
                ))}
                {[...Array(1)].map(() => (
                  <Pill color="var(--yellow-80)" pill-small key={Math.random()}>
                    4
                  </Pill>
                ))}
                {[...Array(1)].map(() => (
                  <Pill color="var(--green)" pill-small key={Math.random()}>
                    5
                  </Pill>
                ))}
              </PillsWrapper>
            );
          }
          return (
            <PillsWrapper>
              {[...Array(6)].map((_, index) => (
                <Pill color="var(--red)" pill-small key={Math.random()}>
                  {index + 1}
                </Pill>
              ))}
              {[...Array(2)].map((_, index) => (
                <Pill color="var(--yellow-80)" pill-small key={Math.random()}>
                  {6 + index + 1}
                </Pill>
              ))}
              {[...Array(2)].map((_, index) => (
                <Pill color="var(--green)" pill-small key={Math.random()}>
                  {8 + index + 1}
                </Pill>
              ))}
            </PillsWrapper>
          );
        default:
          return <> </>;
      }
    },
    [t]
  );

  return (
    <Wrapper>
      <Title>{t('labels.campaignAppearence')} *</Title>
      {options?.map((option, index) => (
        <Fragment key={option.id}>
          <Field
            ref={(ref) => {
              inputRefs.current[index] = ref;
            }}
            type="radio"
            name={fieldName}
            value={option.id}
            defaultChecked={defaultValue === option.id}
            id={option.id}
          />
          <Label htmlFor={option.id}>
            <strong>{option.name}</strong>
            <CheckWrapper id="check-mark">
              <AiFillCheckCircle />
            </CheckWrapper>
            <div>{option.emote && renderEmote(option.emote)}</div>
          </Label>
        </Fragment>
      ))}
      {error && <ErrorMessage> {error} </ErrorMessage>}
    </Wrapper>
  );
}
