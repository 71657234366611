import axios from 'axios';
import queryString from 'query-string';

import {
  destroyToken, getToken, setToken
} from '~/helpers/Token';
import { getUrlBackend } from '~/helpers/Url';
import { setCertificateModal } from '~/redux/actions/Boilerplate';
import { dispatch } from '~/redux/store';

const api = axios.create({
  baseURL: getUrlBackend(),
  headers: {
    'Content-type': 'application/json',
  },
});

api.interceptors.response.use(
  (res) => res,
  (err) => {
    if (!err.response) {
      setCertificateModal(dispatch, true);
    }

    if (err.response.status === 401) {
      destroyToken();
      return err;
    }

    return Promise.reject(err);
  }
);

api.interceptors.request.use((config) => {
  const token = getToken();
  const redirect = queryString.parse(window.location.search);

  if (redirect.token) {
    config.headers.Authorization = redirect.token;

    setToken(redirect.token);

    return config;
  }

  if (token) {
    config.headers.Authorization = `${token}`;
  } else {
    destroyToken();
  }

  return config;
});

export default api;
