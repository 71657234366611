import React, { useEffect } from 'react';

import MetricCard from '~/components/User/MetricCard';
import { useCampaign } from '~/contexts/Generic/SurveyContext';

import { Wrapper } from './styles';

export default function SetMetric() {
  const { setDataWizard } = useCampaign();
  const currentPath = window.location.pathname;

  useEffect(() => {
    if (currentPath === '/campaign/new') {
      setDataWizard({
        step: 1,
        distribution: {
          name: '',
          emails: []
        },
        survey: {
          metric: '',
          campaignName: '',
          greetingMessage: '',
          question: '',
          date: { startDate: new Date(), endDate: null }
        }
      });
    }
  }, [setDataWizard, currentPath]);

  return (
    <Wrapper>
      <MetricCard icon="CSAT" />
      <MetricCard icon="NPS" />
      <MetricCard icon="CES" />
      <MetricCard icon="CUSTOM" />
    </Wrapper>
  );
}
