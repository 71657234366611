import i18n from '~/translate/i18n';

const { notify } = require('~/helpers/Toast');
const { default: api } = require('~/services');

const languages = {
  'pt-BR': 'message',
  'en-US': 'message_en',
};

const createBugReport = async (payload) =>
  api
    .post('/bug', payload)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      notify('error', err.response.data.errors[0][languages[i18n.language]]);

      return err.response.data;
    });

export { createBugReport };
