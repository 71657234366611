import React from 'react';
import { useTranslation } from 'react-i18next';
import { GoGraph } from 'react-icons/go';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import ButtonLink from '~/components/User/Button';
import {
  setShowChildsCES,
  setShowChildsCSAT,
  setShowChildsNPS,
  setShowChildsCustom,
  setShowChildsDocs,
} from '~/redux/actions/Boilerplate';

import SideBarMenu from './Menu';
import {
  Wrapper,
  WrapperNavLink,
  LinkIcon,
  LinkName,
  LinkNormal,
} from './styles';

export default function Sidebar() {
  const {
    user,
    showChildsCSAT,
    showChildsNPS,
    showChildsCES,
    showChildsCustom,
    showChildsDocs,
  } = useSelector((store) => store.boilerplate);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { pathname } = useLocation();
  const buttonOptions = {
    size: { width: '190px', height: '40px' },
    colors: { background: 'var(--yellow-80)', text: 'var(--white)' },
  };

  const handleCollapseCSAT = () => {
    setShowChildsCSAT(dispatch);
  };

  const handleCollapseNPS = () => {
    setShowChildsNPS(dispatch);
  };

  const handleCollapseCES = () => {
    setShowChildsCES(dispatch);
  };

  const handleCollapseCustom = () => {
    setShowChildsCustom(dispatch);
  };

  const handleCollapseDocs = () => {
    setShowChildsDocs(dispatch);
  };

  return (
    <Wrapper isAdmin={user?.isAdmin}>
      {user?.isAdmin && (
        <Link to="/campaign/new">
          <ButtonLink
            title={t('buttons.newCampaign')}
            options={buttonOptions}
            onClick={() => {
              if (pathname.includes('new')) {
                window.location.reload();
              }
            }}
          />
        </Link>
      )}
      <WrapperNavLink>
        <LinkNormal to="/dashboard">
          <LinkIcon>
            <GoGraph />
          </LinkIcon>
          <LinkName> Dashboard </LinkName>
        </LinkNormal>
      </WrapperNavLink>
      <SideBarMenu
        name="csat"
        showChilds={showChildsCSAT}
        handleCollapse={handleCollapseCSAT}
      />
      <SideBarMenu
        name="nps"
        showChilds={showChildsNPS}
        handleCollapse={handleCollapseNPS}
      />
      <SideBarMenu
        name="ces"
        showChilds={showChildsCES}
        handleCollapse={handleCollapseCES}
      />

      <SideBarMenu
        name="custom"
        showChilds={showChildsCustom}
        handleCollapse={handleCollapseCustom}
      />

      <SideBarMenu
        name="docs"
        showChilds={showChildsDocs}
        handleCollapse={handleCollapseDocs}
      />
    </Wrapper>
  );
}
