import React from 'react';

import { LoaderTable } from 'components/User/Loader';

import { Wrapper } from './styles';

export default function TableTeams({
  columns, rows, emptyText, isLoadingData,
}) {
  return (
    <Wrapper cellSpacing="0">
      <thead>
        <tr>
          {columns?.map((column) => (
            <th key={Math.random()}>
              {column}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {isLoadingData ? (
          <tr>
            {columns?.map(() => (
              <td key={Math.random()}>
                <LoaderTable />
              </td>
            ))}
          </tr>
        )
          : (
            <>
              {rows?.length === 0
          && (
          <tr>
            <td>{emptyText}</td>
          </tr>
          )}
              {rows?.map((row) => (
                <tr key={Math.random()}>
                  {Object.keys(row).slice(0, columns.length).map((key) => (
                    <td key={Math.random()}>
                      {row[key]}
                    </td>
                  ))}
                </tr>
              ))}
            </>
          )}
      </tbody>
    </Wrapper>
  );
}
