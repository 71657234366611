import { Form } from '@unform/web';
import styled from 'styled-components';

export const Wrapper = styled(Form)`
  flex: 1;
  display: flex;
  justify-content: flex-end;

  gap: 1rem;
`;

export const WrapperInput = styled.div`
  min-width: 158px;
  width: 250px;
`;
