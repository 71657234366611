import React, { Fragment } from 'react';
import { IoIosArrowForward } from 'react-icons/io';

import { Wrapper } from './styles';

export default function index({ trails, currentPage }) {
  return (
    <Wrapper>
      {trails.map((trail) => (
        <Fragment key={Math.random()}>
          <li>
            {trail}
          </li>
          <span>
            <IoIosArrowForward />
          </span>
        </Fragment>
      ))}
      <strong>
        {currentPage}
      </strong>
    </Wrapper>
  );
}
