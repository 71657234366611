import styled from 'styled-components';

export const ConfirmationWrapper = styled.div`
  width: 100%;

  h1 {
    color: var(--blue-90);
    padding-bottom: 8px;
    border-bottom: 2px solid var(--gray-10);
  }

  p {
    color: var(--gray-90);
    margin-top: 16px;
  }

  a {
    display: block;
    margin-top: 16px;
    text-align: center;
    color: var(--gray-90);
    text-decoration: underline;
  }

  button {
    padding: 6px 16px;
    border: 1px solid var(--blue-90);
    color: var(--blue-90);
    border-radius: 4px;
    margin: 16px auto 0px;
    display: block;
  }

  span {
    color: var(--gray-90);
    font-size: 12px;
    display: block;
    text-align: right;
  }
`;
