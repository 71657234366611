import styled from 'styled-components';

export const HeaderWrapper = styled.div`
`;

export const BodyWrapper = styled.div`
  overflow-y: auto;
  overflow-x: hidden;

  max-height: 300px;
`;

export const Table = styled.table`
  width:100%;
  margin-top: 15px;

  tr {

    &:not(:last-child) {
      td {
        border-bottom: 2px solid var(--gray-10);
      }
    }
  }

  th {

    &:first-child {
      width: 29%;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    &:last-child {
      width: 12%;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }

    &:not(:first-child):not(:last-child) {
      width: 125.33px;
      max-width: 125.33px;
    }
  }

  th, td {
    padding: .95rem 1rem;
  }

  th {
    color: var(--blue-90);
    background-color: var(--blue-10);
    font: 500 .825rem Lexend, sans-serif;
    text-align: left;
    vertical-align: middle;
  }

  td {
    color: var(--gray-60);
    font-size: .75rem;
  }

  td {

    &:first-child {
      width: 29%;
      max-width: 182.4px;
      word-wrap: break-word;
    }

    &:last-child{
      width: 12%;
      font-size: 1rem;
    }

    &:not(:first-child):not(:last-child) {
      max-width: 125.33px;
      width: 125.33px;
      word-wrap: break-word;
    }
  }
`;

export const ButtonAction = styled.button.attrs(() => ({
  type: 'button'
}))`
  position: relative;
  width: 24px;
  height: 24px;
  margin-right: 1rem;

  > svg {
    transition: fill .3s;

    width: 18px;
    height: 18px;
  }

  &:hover {
    svg {
      fill: var(--red);
    }

    span {
      visibility: visible;
    }
  }
`;

export const Actions = styled.td`
  text-align: center;
  position: relative;
`;

export const SelectAllButton = styled.button.attrs(() => ({
  type: 'button'
}))`
  position: absolute;

  height: .9rem;
  width: .9rem;

  transform: translateX(12px);

  svg {
    fill: var(--blue-50);

    height: .9rem;
    width: .9rem;
  }

  &:hover {
    span {
      visibility: visible;
    }
  }
`;

export const TdName = styled.td`
  vertical-align: middle;
`;

export const CheckBox = styled.input.attrs(() => ({
  type: 'checkbox'
}))`
  all: unset;
  border-radius: 50%;
  border: 2px solid var(--gray-50);
  top: 0;
  left: 0;
  width: 13px;
  height: 13px;
  display: inline-block;
  transition: 100ms;
  cursor: pointer;


  &:checked{
    background: var(--blue-90);
    border-color: var(--blue-50);
    display: inline-block;

  &:after {
    content: '';
    position: absolute;
    margin-left: -3.2px;
    width: 5px;
    height: 8px;
    border: solid var(--white);
    border-width: 0 2px 2px 0;
    transition: background 250ms;
    transform: rotate(45deg);
  }
}

`;
