import styled from 'styled-components';

import device from '~/assets/styles/settings/Devices';

export const Wrapper = styled.div`
  gap: 1rem;

  max-width: 700px;

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  margin-top: var(--metrics-m);
  margin-bottom: var(--metrics-xl);
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  gap: 1rem;

  @media ${device.laptop} {
    justify-content: center;
  }
`;

export const QuestionsWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 1rem;

  @media ${device.laptop} {
    justify-content: center;
  }
`;

export const Question = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SelectWrapper = styled.div`
  min-width: 158px;
  width: 350px;
`;

export const ChoicesWrapper = styled.div`
  display: flex;
  gap: 1rem;
  align-items: flex-end;
`;

export const InfoQuestionWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const InfoQuestion = styled.p`
  margin-left: 5px;
  color: var(--gray-50);
`;

export const CheckBoxWrapper = styled.div`
  text-align: center;
  position: relative;
  bottom: 7px;
`;

export const WarningQuestionWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1rem;
`;

export const WarningQuestion = styled.p`
  margin-left: 5px;
  color: var(--gray-50);
`;
