import React from 'react';

import { Wrapper } from './styles';

export default function Button({
  children, title, type, options, isRequesting, ...props
}) {
  return (
    <Wrapper
      type={type}
      size={options && options.size}
      colors={options && options.colors}
      disabled={isRequesting}
      {...props}
    >
      {children} {title}
    </Wrapper>
  );
}
