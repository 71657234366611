import React from 'react';
import { useTranslation } from 'react-i18next';

import Modal from '../Modal';
import { BodyWrapper, ThName, Table } from './styles';

export default function TableCustomAnswers({ handleCloseModal, questions }) {
  const { t } = useTranslation();
  return (
    <Modal
      title={t('titles.customQuestions')}
      options={{
        height: '95vh',
        width: '95vw',
        fontSize: '1rem',
        fontWeight: 'bolder',
      }}
      setVisible={handleCloseModal}
    >
      <div>
        <Table cellSpacing="0">
          <thead>
            <tr>
              <th>{t('tableHeaders.question')}</th>
              <th>{t('tableHeaders.answer')}</th>
            </tr>
          </thead>
        </Table>
      </div>
      <BodyWrapper>
        <Table cellSpacing="0">
          <tbody>
            {questions ? (
              questions.map((question) => (
                <tr key={question.id}>
                  <ThName>{question.question}</ThName>
                  <ThName
                    data-test={`question-${question.question_type}-${question.question_id}`}
                  >
                    {question.answer === null || question.answer === "" ? "-" : question.answer}
                  </ThName>
                </tr>
              ))
            ) : (
              <tr>{t('messages.noAnswer')}</tr>
            )}
          </tbody>
        </Table>
      </BodyWrapper>
    </Modal>
  );
}
