import React, { useState } from 'react';

import { t } from 'i18next';

import Breadcrumb from '~/components/User/Breadcrumb';
import Button from '~/components/User/Button';
import Modal from '~/components/User/Modal';
import Pagination from '~/components/User/Pagination';
import TableSurvey from '~/components/User/TableSurvey';
import Filters from '~/containers/Generic/Filters';
import ModalMoreInfos from '~/containers/Generic/ModalMoreInfos';
import ModalQuestionDetail from '~/containers/Generic/ModalQuestionDetail';
import ModalResendEmail from '~/containers/Generic/ModalResendEmail';
import { useSurvey } from '~/contexts/NPS/SurveyContext';
import { useSurveyPrivileges } from '~/contexts/NPS/SurveyPrivilegesContext';

import SetPrivileges from './SetPrivileges';
import {
  NavWrapper, Wrapper, Paragraph, ButtonWrapper
} from './styles';

export default function Survey() {
  const {
    tableData, isLoadingData, isLoadingPagination, activePage, setActivePage,
    numberPages, setFilter, filterFields, surveyOptions,
    setFilterNav, setCampaignStatus, resendEmails, isModalResendEmailOpen,
    setIsModalResendEmailOpen, modalResendEmail, modalMoreInfos, resendEmailsData,
    isLoadingResendEmailsData, currentCampaignId, isModalMoreInfosOpen, setIsModalMoreInfosOpen,
    isLoadingMoreInfosData, moreInfosData, resendApiKey, totalElements, totalPerPage,
    setTotalPerPage, isModalQuestionDetailOpen, questionDetailData, isLoadingQuestionDetailData,
    setIsModalQuestionDetailOpen, modalQuestionDetail
  } = useSurvey();

  const { setPrivilegesData } = useSurveyPrivileges();

  const [modalNoYesVisible, setModalNoYesVisible] = useState(false);
  const [currentRowId, setCurrentRowId] = useState(null);

  const calcelButtonOptions = {
    size: { width: '190px', height: '42px' },
    colors: { background: 'var(--red-20)', text: 'var(--white)' }
  };
  const confirmButtonOptions = {
    size: { width: '190px', height: '42px' },
    colors: { background: 'var(--green)', text: 'var(--white)' }
  };

  const modalNoYes = (rowId) => {
    setModalNoYesVisible(true);
    setCurrentRowId(rowId);
  };

  const handleConfirm = () => {
    setCampaignStatus(currentRowId, 'Finalizada');
    setModalNoYesVisible(false);
  };

  return (
    <>
      <NavWrapper>
        <Breadcrumb trails={['NPS']} currentPage={t('titles.campaigns')} />
        <Filters
          surveyOptions={surveyOptions}
          setFilter={setFilterNav}
          attendantDisabled
          endDateProp={null}
        />
      </NavWrapper>
      <Wrapper>
        <TableSurvey
          columns={
            ['#',
            t('tableHeaders.campaign'),
            t('tableHeaders.endsIn'),
            'Status',
            t('tableHeaders.howManyAnswered')]
}
          rows={tableData}
          isLoadingData={isLoadingData}
          setFilter={setFilter}
          filterFields={filterFields}
          emptyText={t('messages.noCampagns')}
          hasSort
          setCampaignStatus={setCampaignStatus}
          setPrivilegesData={setPrivilegesData}
          modalResendEmail={modalResendEmail}
          modalMoreInfos={modalMoreInfos}
          modalNoYes={modalNoYes}
          resendApiKey={resendApiKey}
          currentMetric="NPS"
          modalQuestionDetail={modalQuestionDetail}
        />
        <Pagination
          totalElements={totalElements}
          numberPages={numberPages}
          isLoading={isLoadingPagination}
          activePage={activePage}
          setActivePage={setActivePage}
          totalPerPage={totalPerPage}
          setTotalPerPage={setTotalPerPage}
        />
        <SetPrivileges />
        {isModalResendEmailOpen && (
          <ModalResendEmail
            columns={[
              t('tableColumns.name'),
              t('tableColumns.email'),
              t('tableColumns.answeredDate'),
              t('tableColumns.sendEmail')]}
            resendEmailsData={resendEmailsData}
            setIsModalResendEmailOpen={setIsModalResendEmailOpen}
            isLoadingData={isLoadingResendEmailsData}
            currentCampaignId={currentCampaignId}
            resendEmails={resendEmails}
          />
        )}
        {isModalMoreInfosOpen && (
          <ModalMoreInfos
            moreInfosData={moreInfosData}
            isLoadingData={isLoadingMoreInfosData}
            setIsModalResendEmailOpen={setIsModalMoreInfosOpen}
            type="NPS"
          />
        )}
        {modalNoYesVisible && (
        <Modal
          title="Aviso"
          options={{ height: '600px', width: '425px' }}
          setVisible={() => setModalNoYesVisible(false)}
        >
          <Paragraph>
            {t('messages.closeCampaign')}
          </Paragraph>
          <ButtonWrapper>
            <Button type="button" onClick={() => setModalNoYesVisible(false)} options={calcelButtonOptions}>Não</Button>
            <Button type="button" onClick={handleConfirm} options={confirmButtonOptions}>Sim</Button>
          </ButtonWrapper>
        </Modal>
        )}
        {isModalQuestionDetailOpen && (
        <ModalQuestionDetail
          rows={questionDetailData}
          isLoadingData={isLoadingQuestionDetailData}
          setIsModalOpen={setIsModalQuestionDetailOpen}
        />
        )}
      </Wrapper>
    </>
  );
}
