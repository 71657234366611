import styled from 'styled-components';

import dashboardPaper from '~/assets/images/generic/dashboard-paper.svg';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  height: 100%;

  h2 {
    margin-top: var(--metrics-m);
    margin-bottom: var(--metrics-smlr);

    color: var(--blue-90);
    text-align: center;
  }

  p {
    color: var(--gray-50);
  }
`;

export const DashboardPaper = styled.img.attrs(() => ({
  src: dashboardPaper
}))``;

export const ButtonWrapper = styled.div`
  margin-top: var(--metrics-m);
`;
