/**
 * Format date object to hours:minutes.
 *
 * @param {date} date
*/

function formatDate(date) {
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const formatedHour = `${hours < 10 ? `0${hours}` : hours}`;
  const formatedMinutes = `${minutes < 10 ? `0${minutes}` : minutes}`;
  const formatedDate = `${formatedHour}:${formatedMinutes}`;

  return formatedDate;
}

/**
 * Generate a chat message.
 *
 * @param {data} object
*/

function generateMessage(data) {
  let message = {
    id: Math.random(),
    type: 'receiver',
    text: data.text,
    currentTime: formatDate(data.date_issue ? new Date(data.date_issue) : new Date())
  };

  return message;
}

function distanceBetweenDates(date) {
  const now = new Date();
  const inputDate = new Date(date);

  const diffTime = Math.abs(now - inputDate);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  const output = `${diffDays} dias`;

  return output;
}

function distanceBetweenHours(date) {
  const now = new Date();
  const inputDate = new Date(date);

  const diffTime = Math.abs(now - inputDate);
  const diffHours = Math.ceil(diffTime / (1000 * 60 * 60)) - 1;

  return diffHours;
}

function formatUtc(utcDate) {
  const days = ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sab'];
  const months = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
    'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
  const date = new Date(utcDate);
  const dayName = days[date.getDay()];
  const monthName = months[date.getMonth()];
  const day = date.getDate();
  const hours = formatDate(date);

  const formatedDate = `${dayName}, ${day} de ${monthName} as ${hours}`;

  return formatedDate;
}

const blobToBase64 = (blob) => {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  return new Promise((resolve) => {
    reader.onloadend = () => {
      resolve(reader.result);
    };
  });
};

function truncateString(str, num) {
  if (str.length <= num) {
    return str;
  }
  return `${str.slice(0, num)}...`;
}

function base64toBlob(dataURI) {
  let byteString = atob(dataURI.split(',')[1]);
  let ab = new ArrayBuffer(byteString.length);
  let ia = new Uint8Array(ab);

  for (let i = 0; i < byteString.length; i += 1) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ab], { type: 'image/jpeg' });
}

function checkStringIsNumber(string) {
  let output = false
  if (Number(string)) {
    output =  true;
  }
  return output;
  

  

}

function sortObjects(objects, field) {
  let output = objects.sort((a, b) => {
    if (a[field] > b[field]) {
      return -1;
    }

    if (a[field] < b[field]) {
      return 1;
    }
    // a must be equal to b
    return 0;
  });

  return output;
}

export {
  formatDate, generateMessage, formatUtc, distanceBetweenDates, blobToBase64,
  truncateString, base64toBlob, checkStringIsNumber, sortObjects, distanceBetweenHours
};
