const sizeDevice = {
  mobileS: '320px',
  mobileM: '420px',
  mobileL: '660px',
  tablet: '801px',
  laptop: '1024px',
  laptopL: '1366px',
  laptopXL: '1536px',
  desktop: '1920px',
};

const devices = {
  mobileS: `screen and (max-width: ${sizeDevice.mobileS})`,
  mobileM: `screen and (max-width: ${sizeDevice.mobileM})`,
  mobileL: `screen and (max-width: ${sizeDevice.mobileL})`,
  tablet: `screen and (max-width: ${sizeDevice.tablet})`,
  laptop: `screen and (max-width: ${sizeDevice.laptop})`,
  laptopL: `screen and (max-width: ${sizeDevice.laptopL})`,
  desktop: `screen and (max-width: ${sizeDevice.desktop})`,
  desktopL: `screen and (max-width: ${sizeDevice.desktop})`,
};

export default devices;
