import styled from 'styled-components';

export const Wrapper = styled.div`
  margin-top: var(--metrics-sm);
`;

export const Label = styled.label`
  color: var(--blue-50);

  font-family: Inter, sans-serif;
  font-weight: var(--font-weight-b);
  font-size: 0.855rem;
`;

export const Field = styled.input.attrs(() => ({
  type: 'text'
}))`
  width: 100%;
  min-height: 30px;
  border: 1px solid var(--gray-30);
  border-radius: 8px;
  margin-top: 8px;

  padding: .8rem var(--metrics-sm);
  outline: none;
`;

export const ErrorMessage = styled.p`
  margin-top: 5px;

  color: var(--red);
  font-size: var(--font-size-smlr);
`;

export const IconWrapper = styled.div`
  position: relative;

  svg {
    position: absolute;

    right: var(--metrics-sm);
    top: 14px;
  }
`;
