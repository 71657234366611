import styled, { css } from 'styled-components';

export const HeaderWrapper = styled.div``;

export const BodyWrapper = styled.div`
  overflow-y: auto;
  overflow-x: hidden;

  max-height: 300px;
`;

export const Table = styled.table`
  width: 100%;
  margin-top: 15px;

  th {
    &:first-child {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;

      width: 60px;
    }

    &:nth-last-child(2) {
      width: 240px;
    }

    &:last-child {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;

      text-align: center;
    }
  }

  th,
  td {
    padding: 0.95rem 0.75rem;
    box-sizing: border-box;

    text-align: left;
  }

  th {
    color: var(--blue-90);
    background-color: var(--blue-10);
    font: 500 0.825rem Lexend, sans-serif;
    vertical-align: middle;

    &:not(:first-child):not(:nth-last-child(2)) {
      width: 125.33px;

      text-align: center;
    }
  }
`;

export const TdName = styled.td`
  vertical-align: middle;
  word-wrap: break-word;
  border-bottom: 2px solid var(--gray-10);

  color: var(--gray-60);
  font-size: 0.75rem;

  &:first-child {
    font-weight: bold;
  }

  &:nth-last-child(2) {
    width: 240px;
    max-width: 240px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: normal;

    & span {
      color: inherit;
      text-decoration: underline;
      cursor: pointer;
      line-height: 20px;
    }
  }

  &:last-child {
    span {
        color: inherit;
        text-decoration: underline;
        cursor: pointer;
        line-height: 20px;
      }
  }

  &:not(:first-child) {
    text-align: center;
  }

  &:not(:first-child):not(:nth-last-child(2)) {
    width: 125.33px;
  }
`;

export const TrName = styled.tr`
  ${(p) => (p.notMean && css`
      display: block;
      flex-direction: column
    `)}

  ${(p) => (p.subQuestion && css`
    margin-left: 5%
  `)}
`;
